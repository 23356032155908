import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedsrvService } from 'src/app/common/services/sharedsrv/sharedsrv.service';
import { WallsService } from 'src/app/services/walls/walls.service';

@Component({
  selector: 'app-clm-liveview-popup',
  templateUrl: './clm-liveview-popup.component.html',
  styleUrls: ['./clm-liveview-popup.component.scss']
})
export class ClmLiveviewPopupComponent implements OnInit{

  hdurl: any;
  newhdurl: any;
  socketStreamUrl: any;
  playUrl: any;
  objectData: any;
  minutes: number = 0;
  event_key: any;
  expirySeconds: any;
  currentTime: any = 1;
  errorMessage: any;
  constructor(
    private route: ActivatedRoute,
    public wallService: WallsService,
    private sanitizer: DomSanitizer,
    private sharedSrv: SharedsrvService,
    private httpClient: HttpClient,
    private router: Router
  ){
    const getEvent = localStorage.getItem('EVENT');
    const eventUrl = JSON.parse(getEvent || '{}');
    this.event_key = eventUrl.key;
    this.expirySeconds = eventUrl.expirySeconds;
  }
  ngOnInit(): void {
    this.getStreamUrl();
  }
  getStreamUrl() {
    this.route.queryParams.subscribe((params: any) => {
      this.newhdurl = params['unique_id'];
      this.hdurl = this.newhdurl;
      let s = this.hdurl.split("?");
      this.hdurl = s[0]+"/view-camera.html?"+s[1];
      if (this.hdurl == null) {
        this.errorMessage = 'We are having trouble connecting to this camera.';
      }

      //https://test-psafe.pro-vigil.us:8103/view-camera.html?cam=ad4f7ca3-b1b4-4515-80c1-5f4a8bf240d6 
    });
    // window.setInterval(() => {
    //   this.currentTime++;
    // }, 1000);

    this.getStream(this.hdurl);
   // this.openSocketStremContinuepopup(this.expirySeconds);
  }
  openSocketStremContinuepopup(expSec: any) {
    let exSec = expSec * 1000;
    setTimeout(() => {
      this.showConfirmPopup();
    }, exSec);
  }

  showConfirmPopup(): void {
    const modal = document.getElementById('closePop__11');
    if (modal) {
      modal.style.display = 'block';
    }
  }
  confirm(event: Event){
    event.preventDefault();
    let screenTime = this.currentTime * 1000;
    let streamExpTime = this.expirySeconds * 1000 + 60000;//from api will get 9 min but stream will continue 10 min
    let streamKey = this.event_key;
    let min = 10;

    const payload = {
      streamKey: streamKey,
      min: min
    }
    // if(screenTime < streamExpTime){
    //   this.wallService.continueStream(payload).subscribe((response: any) => {
    //     if (response && response.data) {
    //       const data = JSON.parse(response.data);
    //       const expirySeconds = data.expirySeconds;
    //       // Call getStream with the updated URL
    //       this.getStream(this.hdurl);
    //       // Restart the timer with the new expirySeconds
    //       this.openSocketStremContinuepopup(expirySeconds);
    //       this.closeConfirmPopup();
    //     }

    //     else {
    //       this.errorMessage = "We are having trouble connecting to this camera."
    //       this.closeConfirmPopup();
    //     }
    //   })
    // }

  }
  getStream(url:any){
    this.socketStreamUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.playUrl = this.socketStreamUrl.changingThisBreaksApplicationSecurity
  }
  closeConfirmPopup(): void {
    const modal = document.getElementById('closePop__11');
    if (modal) {
      modal.style.display = 'none';
    }
  }
}
