import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../../environments/environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HeartbeatService } from '../sharedsrv/heartbeat.service';
import { SharedsrvService } from '../sharedsrv/sharedsrv.service';
import { AccessPermissions } from '../../models/loginpermissions';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl: string = environment.baseUrl;
  //signOut:string = environment.signOut;
  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private heartbeatService: HeartbeatService,
    public inject: Injector

  ) { }


  postLogin = (requestBody: any) => {
    // return this.httpClient.post(`${this.baseUrl}psafe-authentication-autherization/auth/signin`,requestBody);
    return this.httpClient.post(`${this.baseUrl}auth/signin`, requestBody);
  }

  postUserCreation = (requestBody: any) => {
    return this.httpClient.post(`${this.baseUrl}psafe-authentication-autherization/auth/createuser`, requestBody);
  }

  //logout api
  signOutRequest(userId: string): Observable<any> {
    const apiUrl = `${this.baseUrl}auth/signout`;
    let headers: HttpHeaders = new HttpHeaders();
    let proToken = localStorage.getItem('pro-token') || '';
    proToken = proToken.replace(/['"]+/g, '');
    headers = headers.append('PRO-TOKEN', '' + proToken);
    return this.httpClient.post(apiUrl, null, { headers: headers });
  }
  forceSessionOut(user: any) {
    const resetURLAPI = `${environment.apiDomain}:8001/api/auth/user/resetsession`;
    return this.httpClient.post(resetURLAPI + `?userId=${user.username}&supervisorUserId=${user.username}`, {});
  }
  handleSignOutResponse(res: any) {
    if (res.success === true && res.data) {
      //fetch before clear
      let hInterval = localStorage.getItem('heartbeat-interval');
      const hostNameSuccess:any = localStorage.getItem('hostnameSuccess');

      localStorage.clear();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('pro-token');
      //this.userSubject.next(null);
      localStorage.removeItem('userType');
      //clear heartbeat interval
      this.clearHeartBeat(hInterval);

      //this.router.navigate(['/login']);
      //this.router.navigate(['/logout'])
      //location?.reload();
      localStorage.setItem('hostnameSuccess', hostNameSuccess);
      this.navigateToLoginLogout();
    }
  }

  clearHeartBeat(hInterval: any) {
    if (!hInterval && hInterval != null) {
      //stop heartbeat interval
      let interval = parseInt(hInterval, 10);
      //console.log("clearing interval ", interval);
      clearInterval(interval);
      localStorage.removeItem('heartbeat-interval');
    }
  }

  pauseOrResume(payLoad: any) {
    const apiUrl = `${this.baseUrl}activity/user`;
    let headers: HttpHeaders = new HttpHeaders();
    let proToken = localStorage.getItem('pro-token') || '';
    proToken = proToken.replace(/['"]+/g, '');
    headers = headers.append('PRO-TOKEN', '' + proToken);
    return this.httpClient.post(apiUrl, payLoad, { headers: headers });
  }

  public navigateToLoginLogout = () => {
    let sharedsrv = this.inject.get(SharedsrvService);
    const userObj = sharedsrv.getUserValue();
    const levelName = userObj?.user?.group[0];
    if (levelName === AccessPermissions.accessl3) {
      this.router.navigate(['/login']);
      //location?.reload();
    } else {
      this.router.navigate(['/logout']);
    }
  }
}
