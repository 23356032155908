import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as momentTZ from 'moment-timezone';
import { WallsService } from 'src/app/services/walls/walls.service';

@Component({
  selector: 'app-live-view',
  templateUrl: './live-view.component.html',
  styleUrls: ['./live-view.component.scss'],
})
export class LiveViewComponent implements OnInit {
  cameraItem: any;
  base_url = environment.pathUrl;
  activeTime: any = '';
  intervalTime: any = 10000;
  zoomSize = -1;
  imageStyleList = {
    eventDetection: {
      motionDetection: false,
      tripWire: false,
      maskIn: false,
    },
    sensitivity: 6,
    bgs: 3,
    maskInList: [],
    maskOutList: [
      {
        polygonPoints: [
          {
            x: 122,
            y: 120,
          },
          {
            x: 265,
            y: 87,
          },
          {
            x: 265,
            y: 87,
          },
          {
            x: 300,
            y: 187,
          },
          {
            x: 300,
            y: 187,
          },
          {
            x: 123,
            y: 118,
          },
        ],
      },
    ],
    tripWireList: [],
    sensitivityList: [],
  };
  zoomData: any;

  myTimeout: any;
  currentTimeStampOfEventBarOnload: any = "";
  archiveUrl: any = "";
  timeCount: number = 0;
  timeCounter: any;
  isPlay: boolean = true;
  potentialId: any;
  currentTimestamp: any;
  cameraUniqueId: any;
  imgUrl: any;
  zoomMeasures: any = {
    width: 100,
    height: 100
  };
  constructor(public apiService: WallsService) {
    this.cameraItem = localStorage.getItem('cameraItem')
      ? JSON.parse(localStorage.getItem('cameraItem') || '')
      : '';
    this.currentTimeStampOfEventBarOnload = localStorage.getItem('currentTimeStampOfEventBarOnload');
    this.archiveUrl = this.cameraItem.archiveUrl;
    this.cameraUniqueId = this.cameraItem.cameraUniqueId;
    const storedData = JSON.parse(localStorage.getItem('potentialIdAndTimestamp') || '{}');
    this.potentialId = storedData.potentialId;
    this.currentTimestamp = storedData.currentTimestamp;
    this.isPlay = true;
    
    // this.activeTime = momentTZ
    //   .tz(new Date(this.currentTimeStampOfEventBarOnload), 'Canada/Yukon')
    //   .subtract(1, 'minutes')
    //   .format('YYYY-MM-DD-hh:mm:ss');
    // setInterval(() => {
    //   this.updateTimeCount();
    // }, 1000);
    // this.updateSessionId();
    // setInterval(() => {
    //   this.updateSessionId();
    // }, 10000);
    // // this.myTimeout = setTimeout(this.closeWindow, 10000);
    // this.closeWindow();
    // this.getZoomData();
    // this.updateTimeCountForArchive();
  }

  ngOnInit(): void {
    this.intilizArchiveStream();
  }
  intilizArchiveStream() {
    const payload = {
      potentialId: this.potentialId,
      cameraUniqueId: this.cameraUniqueId,
      currentTimestamp: this.currentTimestamp
    }
    this.apiService.getarchiveStream(payload).subscribe((res: any) => {
      if (res.success == true && res.data.archiveUrl) { // Check if the response contains a valid archiveUrl
        this.archiveUrl = res.data.archiveUrl;
        this.updateTimeCountForArchive();
      }
    });
    /// handle the play pause features based on key board.
    document.addEventListener('keyup', event => {
      if (event.code === 'Space' && event.keyCode === 32) {
        if (this.isPlay === false) {
          this.play();
        } else {
          this.pause();
        }
      } else if (event.code === 'ArrowRight' && event.keyCode === 39) {
        this.next();
      } else if (event.code === 'ArrowLeft' && event.keyCode === 37) {
        this.previous();
      } else if (event.code === 'ArrowUp' && event.keyCode === 38) {
        this.dbnext();
      } else if (event.code === 'ArrowDown' && event.keyCode === 40) {
        this.dbprevious();
      }
    });
  }
  getZoomData() {
    this.apiService
      .getData(
        'CameraService',
        '?event=getMotionAnalytics&cameraid=727027VGSTESTF1267CAM41254'
      )
      .subscribe(
        (success: any) => {
          this.zoomData = success;
        },
        (err: any) => { }
      );
  }

  closeWindow() {
    this.myTimeout = setTimeout(() => {
      window.close();
    }, 10 * 60 * 1000);
  }

  updateSessionId() {
    this.updateSessionTimeAPI(this.activeTime);
  }
  updateSessionTimeAPI(currentTime: any) {
    const params =
      '?event=sessionid&cameraid=' +
      this.cameraItem.cameraid +
      '&timestamp=' +
      currentTime;

    // this.apiService.getData('CameraService', params).subscribe(
    //   (success: any) => {
    //     const data = success;
    //     this.cameraItem.sessionId = data.sessionId;
    //     this.updateTimeCount();
    //   },
    //   (err: any) => { }
    // );
  }
  updateTimeCount() {
    this.cameraItem.timeCount = this.cameraItem.timeCount + 1;
  }

  next() {
    this.isPlay = false;
    clearInterval(this.timeCounter);
    this.incrementTimeCount();
    this.archiveUrl = this.archiveUrl.replace(/=play|=forward|=rewind|=previous|=init|=stop/gi, "=next");
    console.log("next", this.archiveUrl);
  }
  previous() {
    this.isPlay = false;
    clearInterval(this.timeCounter);
    this.incrementTimeCount();
    this.archiveUrl = this.archiveUrl.replace(/=play|=forward|=rewind|=next|=init|=stop/gi, "=previous");
  }
  dbprevious() {
    this.isPlay = false;
    clearInterval(this.timeCounter);
    this.incrementTimeCount();
    this.archiveUrl = this.archiveUrl.replace(/=play|=forward|=previous|=next|=init|=stop/gi, "=rewind");
  }
  dbnext() {
    this.isPlay = false;
    clearInterval(this.timeCounter);
    this.incrementTimeCount();
    this.archiveUrl = this.archiveUrl.replace(/=play|=rewind|=previous|=next|=init|=stop/gi, "=forward");
  }

  zoomIn() {
    // if (this.zoomSize == 5) {
    //   this.zoomSize = -1;
    // } else {
    //   this.zoomSize = this.zoomSize + 1;
    // }
    this.zoomMeasures.width = this.zoomMeasures.width + 10;
    this.zoomMeasures.height = this.zoomMeasures.height + 10;

  }
  zoomOut() {
    // if (this.zoomSize == -1) {
    //   this.zoomSize = 5;
    // } else {
    //   this.zoomSize = this.zoomSize - 1;
    // }
    if (this.zoomMeasures.width > 50) {
      this.zoomMeasures.width = this.zoomMeasures.width - 10;
      this.zoomMeasures.height = this.zoomMeasures.height - 10;
    }
  }

  updateTimeCountForArchive = () => {
    this.timeCounter = setInterval(() => {
      this.timeCount = this.timeCount + 1;
      this.archiveUrl = this.archiveUrl;
    }, 1000);
  }

  pause = () => {
    this.isPlay = false;
    clearInterval(this.timeCounter);
    this.archiveUrl = this.archiveUrl.replace(/=play|=forward|=previous|=next|=init|=stop|=rewind/gi, "=next");
  }

  play = () => {
    this.isPlay = true;
    this.archiveUrl = this.archiveUrl.replace(/=stop|=forward|=previous|=next|=init|=stop|=rewind/gi, "=next");
    this.updateTimeCountForArchive();
  }

  incrementTimeCount = () => {
    this.timeCount = this.timeCount + 1;
  }
}