<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-masking-view">
  <div class="modal-content" style="height:inherit">
    <div class="modal-header bg-dark flex-wrap gap-3 p-3">
      <h6 class="modal-title text-white" style="max-width: 42%;">
        {{siteName}}
      </h6>
      <button type="button" class="btn btn-orange px-5" (click)="closePopup()">
        Close
      </button>
    </div>

    <div class="modal-body modal-scroll-body">
      <div class="row g-4">
        <div class="col-xl-12 col-lg-12" *ngFor="let item of cameraViewList; let i = index;">
          <div class="position-relative overflow-hidden">
            <h6 class="camhead">{{item.cameraName}}</h6>
            <div class="row" style="padding-left: 100px;" *ngIf="false">
              <div style="width:45%;" class="col-xl-5 col-lg-6">
                <div style="background-color: black;color: white;padding: 10px;">
                  <div class="row">
                    <div class="col-xl-4 col-md-4">
                      <h6 class="text-white" style="font-size: 15px;">Day Image</h6>
                    </div>
                    <div class="col-xl-8 col-md-8">
                      <h6 class="text-white" style="font-size: 13px;float: right"> {{item.dayImgModifiedDate}}</h6>
                    </div>
                  </div>
                </div>
                <div class="ratio ratio-16x9 overflow-hidden">
                  <img [src]="item.dayImage" style="width: 100%;" />
                </div>
              </div>
              <div style="width:45%;" class="col-xl-5 col-lg-6" *ngIf="false">
                <div style="background-color: black;color: white;padding: 10px;">
                  <div class="row">
                    <div class="col-xl-4 col-md-4">
                      <h6 class="text-white" style="font-size: 15px;">Night Image</h6>
                    </div>
                    <div class="col-xl-8 col-md-8">
                      <h6 class="text-white" style="font-size: 13px;float: right;"> {{item.nightImgModifiedDate}}</h6>
                    </div>
                  </div>
                </div>
                <div class="ratio ratio-16x9 overflow-hidden">
                  <img [src]="item.nightImage" style="width: 100%;" />
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;padding-left: 100px;">
              <div style="width:45%;" class="col-xl-5 col-lg-6">
                <div style="background-color: black;color: white;padding: 10px;">
                  <h6 class="text-white" style="font-size: 15px;">Day Boundary Image</h6>
                </div>
                <div class="ratio ratio-16x9 overflow-hidden">
                  <img [src]="item.dayBoundaryImage" style="width: 100%;" />
                </div>
              </div>
              <div style="width:45%;" class="col-xl-5 col-lg-6">
                <div style="background-color: black;color: white;padding: 10px;">
                  <h6 class="text-white" style="font-size: 15px;">Night Boundary Image</h6>
                </div>
                <div class="ratio ratio-16x9 overflow-hidden">
                  <img [src]="item.nightBoundaryImage" style="width: 100%;" />
                </div>
              </div>
            </div>
            <h6 class="destxt">{{item.cameraDescription}}</h6>
          </div>
          <hr>
        </div>
        <div class="col-xl-12 col-lg-12" *ngIf="isdivShow">
          <h6 class="nodata">No camera views available</h6>
        </div>
      </div>
    </div>
  </div>
</div>