<div>
  <div class="section" style="height:100vh;overflow:auto;padding-bottom:50px">
    <!-- <div class="d-flex justify-content-center" *ngIf="!siteInfoDetails?.siteName && !failedFetchAll">
      <div class="spinner-border text-primary" role="status" style="width: 6rem; height: 6rem; margin-top: 20%;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->
    <div class="container" *ngIf="!failedFetchAll && !isShowMsgPanelInfoSpinner">
      <div class="container-fluid" *ngIf="failedFetchAll && !isShowMsgPanelInfoSpinner">
        <div class="alert alert-danger text-center fs-5" role="alert" >
          Please click More info icon on the wall to view the data.
        </div>        
      </div>
      <div class="container-fluid" *ngIf="!failedFetchAll && potentialId">
        <div class="row">
          <div class="card1">

            <div class="w-100">
              <div class="info">         
                <div class="w-100" style="display: flex;justify-content: space-between;">
                  <div class="main_sub" style="width: calc(100% - 575px);overflow: auto;">                
                    <div class="item3">
                      <h2>  Site : {{siteInfoDetails?.siteName}}</h2>
                    </div>
                    <h3 class="sub">
                      Account: {{siteInfoDetails?.accountName}} (Potential # :
                      {{potentialId}})
                    </h3>
                    <div class="menu2" *ngIf="unitIds && unitIds.length > 0 && !hideMobileSitesWith_F() && reloadMobileSite">         
                      <span>Mobile Sites:</span>       
                      <!---Showing vertical View-->        
                        <div class="menu2-cov2">
                          <a *ngFor="let unit of unitIds" class="borderNew" (click)="getUnitInformation(unit.potentialId)">{{ unit.unitId }}</a>
                        </div>
                    </div>
                  </div>
             <div class="rightBox">
              <div class="icon-3" style="display: flex;">
                <div class="image-50 d-none">
                  <!-- <i class="fa-solid fa-layer-group"></i> -->
                  <!-- <i class='fa fa-cloud-sun-rain' style="color:white;font-size: 20px;"></i> -->
                  <i class="fa fa-battery-3 i20px" title="Camera health"></i>
                </div>
                <div class="image-50 d-none">

                  <!-- <i class='fa fa-cloud-sun-rain' style="color:white;font-size: 20px;"></i> -->
                  <i class='fa fa-blind' style="color:white;font-size: 22px;" title="Site Age"></i>
                </div>
                <div class="image-50" title="Refresh" (click)="refresh()" style="cursor: pointer;">
                  <i class="fa fa-rotate-right" style="font-size:24px"></i>
                </div>
                <div style="width:220px;display: none;">               
                    <form  role="search" class="search-form">
                      <input type="submit" value="" class="search-submit">
                      <input type="search" name="q" class="search-text" placeholder="Search..." autocomplete="off">
                  </form>                                       
                </div>       

              </div>
              <div class="linkBox">
                <div (click)="showAllLiveViewsPopup()" class="linkBox">
                  <a class="link disabled">Live View</a>
                </div>
                <div (click)="showCamereViewsPopup()" class="linkBox">
                  <a class="link disabled">Camera View</a>
                </div>
                <div (click)="showSitemapPopup()" class="linkBox">
                  <a class="link disabled">Site Map</a>
                </div>
                <div class="linkBox">
                  <a href="https://login.salesforce.com/" target="_blank" rel="noopener noreferrer"
                    class="link">SalesForce</a>
                </div>
                <div class="linkBox">
                  <a href="https://pro-safe-admin.pro-vigil.info/siteinfo/{{potentialId}}" class="link" target="_blank">Admin Portal</a>
                </div>
                <div class="linkBox">
                  <a href="https://portal.pro-vigil.info/login" target="_blank" class="link">Customer Portal</a>
                </div>
                <div class="linkBox">
                  <a href="https://pro-vigil.zendesk.com/agent/organizations/{{siteInfoDetails?.zendeskOrgId}}"
                    target="_blank" rel="noopener noreferrer" class="link">Zendesk</a>
                </div>
              </div>
             </div>
               
           

                </div>
              </div>



            </div>


          </div>
          <div class="card1 mt-5" style="display: flex; justify-content: space-between;align-items: flex-start;gap: 20px;;">
            <div class="info">
              <div class="sub" style="align-items: flex-start;">
                <p *ngIf="!armDisarmStatusData">
                  This site is currently ARMED and INSIDE monitoring hours.
                </p>
                <div class="status-container">
                  <span *ngIf="armDisarmStatusData" [innerHTML]="armDisarmStatusData"></span>
                </div>
                <div class="arm-disarm-links">
                  <a class="arm-disarm-text" (click)="armDisarmModal('Armed')">Armed</a> <span
                    style="margin-left: 5px;">/</span>
                  <a class="arm-disarm-text" (click)="armDisarmModal('Disarmed')" style="margin-left: 5px;">Disarmed</a>
                </div>
              </div>

              <div class="monitoring_hou" style="color:white">
                <div>
                  <u class="fw-bold">Monitoring Hours: </u>
                  <div>{{siteInfoDetails?.monitoringHours=='NULL'? '--': siteInfoDetails?.monitoringHours}}</div>
                </div>
                <div>
                  <u class="fw-bold">Additional Monitoring Hours:</u>
                  <div>{{siteInfoDetails?.additionalMonitoringHours=='NULL'? '--':
                    siteInfoDetails?.additionalMonitoringHours}}</div>
                </div>
              </div>

              <!-- <div class="title">Here's another one to even all out to four.</div>
                    <button class="btn">Find out</button> -->
            </div>
            <!-- <div class="sub card1">
              SafeWord: null<br>
              Do Not DisarmPin:<br>
              ArmDisarmPin: 670995
            </div> -->
            <div class="card1 secondPanel" style="width: 40%;">
              <div class="w-100">
                <!-- <h5 class="title">Contact Info
                </h5> -->
                <table class="table table-dark table-sm">
                  <tr>
                    <th style="padding: 5px 0px 5px 0px; font-weight: bolder;">Contact</th>
                    <th style="padding: 5px 0px 5px 0px; font-weight: bolder;">Name</th>
                    <th style="padding: 5px 0px 5px 0px; font-weight: bolder;">Number</th>
                    <th style="padding: 5px 0px 5px 0px; font-weight: bolder;">Email</th>
                    <!-- <th style="padding: 5px 0px 5px 0px;">Cross street</th>
                    <th style="padding: 5px 0px 5px 0px;">Cross street2</th> -->

                  </tr>

                  <tr *ngFor="let contact of siteInfoDetails?.contactInfoList">
                    <td style="padding: 5px 0px 5px 0px;">{{contact?.contactType}}</td>
                    <td style="padding: 5px 0px 5px 0px;">{{contact?.contactType == 'Police' ?
                      siteInfoDetails?.miscDetails?.policeDeptName : contact?.name}}</td>
                    <td style="padding: 5px 0px 5px 0px;min-width:100px">
                      <a href="rcapp://r/ {{contact?.contactNo}}"
                        class="text-white bg-transparent">{{contact?.contactNo}}</a>
                    </td>
                    <td style="padding: 5px 0px 5px 0px;">{{contact?.email}}</td>
                  </tr>


                  <!-- <tr>
                    <td style="padding: 5px 0px 5px 0px;">Primary</td>
                    <td style="padding: 5px 0px 5px 0px;">Roger Martin</td>
                    <td style="padding: 5px 0px 5px 0px;">
                      (303) 761-7410
                    </td>
                    <td style="padding: 5px 0px 5px 0px;">roger@momentumofdenver.com</td>
                  </tr>
  
  
                  <tr>
                    <td style="padding: 5px 0px 5px 0px;">Secondary</td>
                    <td style="padding: 5px 0px 5px 0px;">Oscar Solano</td>
  
                    <td style="padding: 5px 0px 5px 0px;">
                      (720) 297-4466 </td>
                    <td style="padding: 5px 0px 5px 0px;">oscar@momentumofdenver.com</td>
                  </tr> -->
                </table>
              </div>


            </div>
            <div class="card1">
              <div>
                <div class="timeZon">
                  <div class="label">Site Time({{siteInfoDetails?.timeZone}}):</div>
                  <div class="value">{{siteInfoDetails?.siteTimeCustomer}}</div>
                </div>
                <div class="timeZon">
                  <div class="label">CST Time:</div>
                  <div class="value">{{siteInfoDetails?.siteTimeCT}}</div>
                </div>
                <div class="timeZon">
                  <div class="label">IST Time:</div>
                  <div class="value">{{siteInfoDetails?.siteTimeIST}}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-5">
          <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 paddingLeft">
            <div class="card1">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">IFTTT:</h5>
                </div>
                <table width="100%" class="table  bgnew cell_color">

                  <thead>
                    <tr>
                      <th width="80%" style="padding-top:10px;font-weight:bolder;">ACTIVITY</th>
                      <th width="20%" style="padding-top:10px;font-weight:bolder;">ACTION</th>

                    </tr>
                  </thead>

                  <tbody>
                    <!-- <tr *ngFor="let item of newDataFttt[0]" style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                      <td>
                        {{item.actionDesc}}       
                      </td> 
                      <td *ngFor="let item of ifttt.events"  style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                        {{item.eventDesc}}         
                      </td> 
                    </tr> -->
                    <!-- <tr><td>{{finalFttt[0].eventDesc}}</td></tr> -->
                    <tr *ngFor="let event of newObject?.events; let i = index">
                      <td style="width:min-content !important">{{event}}</td>
                      <td style="width:max-content !important">{{newObject?.action[i] ? newObject?.action[i] : ''}}</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="newObject?.events?.length==0">
                      <span class="noData">Nothing found to display</span>
                    </tr>
                    <td style="padding-right: 5px;" align="left" *ngIf="modifiedDate_tz">
                      <i class="fa fa-refresh" aria-hidden="true" style="margin-right: 8px;color: wheat"></i>
                      <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;{{modifiedDate_tz}}
                    </td>
                  </tbody>

                </table>
              </div>

            </div>
            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Recent Escalation(s)</h5>
                  <div class="d-flex" style="min-width: 30%;justify-content: space-between;gap:15px">
                    <!-- <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                      aria-expanded="false">
                      Filter
                    </button> -->
                    <select 
                      aria-label="Filter by level"
                      class="form-select form-select-sm w-auto d-none"
                      style="height: 32px;">
                      <option value="L2L3" selected>L2 & L3</option>
                      <option value="L1">L1</option>
                      <option value="L2">L2</option>
                      <option value="L3">L3</option>
                    </select>
                    <select 
                    aria-label="Filter by level"
                    class="form-control form-select form-select-sm w-auto"
                    style="height: 32px;" (change)="selectTimeZone($event)">
                    <option value="CT" selected>CT</option>
                    <option [value]="siteInfoDetails?.timeZone">Customer Timezone ({{siteInfoDetails?.timeZone}})</option>
                    <option value="IST">IST</option>
                  </select>
                    <!-- <button type="button" class="btn btn-outline-success" (click)="last4Hours('4hours')">Last 4
                      Hours</button> -->
                    <button type="button" class="btn btn-outline-success" (click)="yesterday('24hrs')">1 Day</button>
                    <button type="button" class="btn btn-outline-success" (click)="Past7Days('7Days')">7 Days</button>
                    <!-- <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                      data-bs-target="#exampleModal" aria-expanded="false">
                      View All
                    </button> -->
                    <button  type="button" class="btn btn-outline-success" (click)="navigateToConsole()">
                      View in Console
                    </button>
                  </div>
                </div>
                <div style="max-height: 300px; overflow-y: auto;">
                  <table class="table  bgnew cell_color">
                    <thead class="sticky-header">
                      <tr>
                        <th>Escalation Id</th>
                        <th>Unit Id</th>
                        <th>Camera Name</th>
                        <th>Event Time ({{timeZoneSelect}})</th>
                        <th>User</th>
                        <th>Notes</th>
                        <th>Action</th>
                        <th>More</th>
                      </tr>
                    </thead>
                    <tbody style="max-height: 300px; overflow-y: auto;">
                      <tr class="even" [ngClass]="{'odd': i/2 != 0}"
                        *ngFor="let recentEscItem of recentEscalationList;let i = index;" [ngClass]="{'make-gold': recentEscItem?.currentLevelId == 'L3'}">
                        <td>{{recentEscItem?.eventid || '-'}}</td>
                        <td>{{recentEscItem?.unitId || '-'}}</td>
                        <td>{{recentEscItem?.cameraName || '-'}}</td>
                        <!-- <td>{{recentEscItem?.eventTimeStampStr}} {{siteInfoDetails?.timeZone}}</td> -->
                        <td>{{ getFormattedTimestamp(recentEscItem) }}</td>
                        <!-- <td>{{recentEscItem?.action}}</td> -->
                        <td>{{recentEscItem?.userId || '-'}}</td> 
                        <!-- <td style="max-width:min-content">{{recentEscItem?.userId}}</td> -->
                        <td>{{recentEscItem?.actionNote || '-'}}</td>
                        <td>{{recentEscItem?.action || '-'}}</td>
                        <!-- <td style="width:60px">{{recentEscItem?.previousLevel}}</td> -->
                        <td class="p-2">
                          <div class="d-flex icon-colum">
                            <i class="fa fa-play" style="cursor: pointer;" aria-hidden="true"
                            (click)="viewEvent(recentEscItem)"></i>
                            <i class="fa fa-video-camera" style="margin-left: 10px;cursor: pointer;"
                            aria-hidden="true" (click)="getIvigilURL(recentEscItem)"></i>
                          <i class="fa fa-copy" style="margin-left: 10px; margin-right: 10px; cursor: pointer;"
                            aria-hidden="true" (click)="copyToClip(recentEscItem)"></i>
                          <i class="fa fa-info-circle" style="margin-left: 10px; margin-right: 10px; cursor: pointer;"
                            (click)="recentEscMoreDetails(recentEscItem)"></i>
                          </div>
                        </td>
                        <!-- <td>
                        <div class="d-flex">
                          <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i>
                        </div>
                      </td> -->
                      </tr>

                      <tr style="color: #0c5b82;" *ngIf="currentlyLoading.indexOf('reEsclationRemoveSpinner') >= 0 || currentlyLoading.indexOf('yesterdayRemoveSpinner') >= 0 || currentlyLoading.indexOf('las4hrsRemoveSpinner') >= 0">
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                        <td class="borderNone">
                          <app-spinner-load></app-spinner-load>
                        </td>
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                      </tr>
                      <!-- <tr > -->
                      <tr style="height:20px;" *ngIf="recentEscalationList.length == 0 && currentlyLoading.indexOf('reEsclationRemoveSpinner') < 0 && currentlyLoading.indexOf('yesterdayRemoveSpinner') < 0 && currentlyLoading.indexOf('las4hrsRemoveSpinner') < 0">
                        <span class="noData">No Data found {{recentEscFetchMsg}}</span>
                      </tr>
                      <!-- </tr> -->

                      <!-- <tr class="even">
                      <td>78059137</td>
                      <td>F3448 Cam06</td>
                      <td>2023-05-18 01:25:51(CT)</td>
                      <td>Out Of Bounds</td>
                      <td>acc According to the boundary line , The person seen at off site not enter...</td>
                      <td>pd4159.prasad</td>
                      <td>
                        <div class="d-flex">
                          <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i>
                        </div>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>78059137</td>
                      <td>F3448 Cam06</td>
                      <td>2023-05-17 08:15:35(CT)</td>
                      <td>Continue to Monitor</td>
                      <td>Person seen passing through ..in this cam viewwith dog</td>
                      <td>pd3639.sireesha</td>
                      <td>
                        <div class="d-flex">
                          <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i>
                        </div>
                      </td>
                    </tr>
                    <tr class="even">
                      <td>78055871</td>
                      <td>F3448 Cam01</td>
                      <td>2023-05-17 05:59:35(CT)</td>
                      <td>Vehicle Out of Bound</td>
                      <td>Vehicle seenat off-site not enter into the site</td>
                      <td>pd3810.lavanya</td>
                      <td>
                        <div class="d-flex">
                          <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i>
                        </div>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>78055871</td>
                      <td>F3448 Cam06</td>
                      <td>2023-05-17 03:07:40(CT)</td>
                      <td>Out Of Bounds</td>
                      <td>According to the boundary line , The person seen passing through off site . </td>
                      <td>pd3479priyanka</td>
                      <td>
                        <div class="d-flex">
                          <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i>
                        </div>
                      </td>
                    </tr> -->
                    </tbody>
                
                  </table>
                
                </div>

              </div>

            </div>

            <div class="card1 mt-5">
              <div class="w-100">
                <h5 class="title">Site Details:
                </h5>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <td>Address</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.addressDetails?.address}}</span></td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.addressDetails?.city}}</span></td>
                    </tr>
                    <tr>
                      <td>State/Zip</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.addressDetails?.stateZip}}</span></td>
                    </tr>
                    <tr>
                      <td>Cross street</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.crossStreet}}</span></td>
                    </tr>
                    <tr>
                      <td>Cross street2</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.crossStreet2}}</span></td>
                    </tr>
                    <tr>
                      <td>Police Dept Permit Number</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.policeDeptPermit == 'null' ? '--'
                          : siteInfoDetails?.miscDetails?.policeDeptPermit}}</span></td>
                    </tr>
                    <tr>
                      <td>Police Gate Code</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.gateCode == 'null' ? '--' :
                          siteInfoDetails?.miscDetails?.gateCode}}</span></td>
                    </tr>

                    <tr>
                      <td>Activity Email</td>
                      <td style="max-width: 250px;overflow: auto;">:
                        <span class="infoDetails" style="white-space: pre-line;">
                          {{siteInfoDetails?.miscDetails?.activityEmail?.split(',').join('\n')}}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Monitoring Service</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.monitoringService}}</span></td>
                    </tr>
                    <tr>
                      <td>Monitoring Service Pin</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.monitoringServicePin}}</span></td>
                    </tr>

                    <tr>
                      <td>SafeWord</td>
                      <td>: <span style="white-space: pre-line;">{{!siteInfoDetails?.safeWords?.safeWords ? '--' :
                        siteInfoDetails?.safeWords?.safeWords.split(',').join(', ')}}</span></td>
                    </tr>
                    <tr>
                      <td>Do Not Disarmpin</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.safeWords?.doNotDisArmPin}}</span></td>
                    </tr>
                    <tr>
                      <td>ArmDisarmPin</td>
                      <td>: <span class="infoDetails">
                          {{siteInfoDetails?.safeWords?.armDisArmPin?.split(',').join(',\n')}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Recent Activation:</h5>
                  <div class="d-flex" style="justify-content: space-between;gap:15px">
                    <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                      data-bs-target="#alarmhistoryModal" aria-expanded="false">
                      View All
                    </button>
                  </div>

                </div>
                <div style="max-height: 300px; overflow-y: auto;">
                  <table class="table  bgnew cell_color">
                    <tbody>
                      <tr style="height:20px;">
                        <th>Time</th>
                        <th>User Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Status</th>
                      </tr>

                      <tr style="height:20px;" *ngFor="let item of recentActivations">
                        <td>{{item?.time || item?.timeStamp}}</td>
                        <td>{{item?.user || item?.userId}}</td>
                        <td>{{item?.type || 'N/A'}}</td>
                        <td>{{item?.description}}</td>
                        <td>{{item?.status}}</td>
                      </tr>

                      <tr style="color: #0c5b82;" *ngIf="currentlyLoading.indexOf('RecentActivationRemoveSpinner') >= 0">
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                        <td class="borderNone">
                          <app-spinner-load></app-spinner-load>
                        </td>
                        <td class="borderNone"></td>
                        <td class="borderNone"></td>
                      </tr>

                      <tr *ngIf="recentActivations.length == 0 && currentlyLoading.indexOf('RecentActivationRemoveSpinner') < 0">
                        <span class="noData">Nothing found to display</span>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
            <div class="card1 mt-0">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Site Notes:</h5>
                  <div class="d-flex" style="min-width: 30%;justify-content: space-between;gap:15px">
                    <div class="btn-group">
                    </div>
                    <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
                      data-bs-target="#filterModal" *ngIf="siteTimeCustomer">Add Notes
                      <!-- <i class="fa fa-gear" data-bs-toggle="modal" data-bs-target="#filterModal"></i> -->
                    </button>
                    <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                    data-bs-target="#broadAdhocModal" aria-expanded="false" >
                    View All
                  </button>
                                  </div>
                </div>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr style="height:20px;">
                      <th>Message</th>
                      <th>Type</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                    <tr style="height:20px;" *ngFor="let sitenoteItem of allMessages;" [ngClass] ="{'highlight-row' : sitenoteItem.type === 'Bolo'}">
                      <td>{{sitenoteItem.message}}</td>
                      <td>{{sitenoteItem.type}}</td>
                      <td *ngIf="sitenoteItem?.type === 'Bolo'">
                        {{ sitenoteItem?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} ({{siteInfoDetails?.timeZone  }})
                      </td>
                      <td *ngIf="sitenoteItem?.type !== 'Bolo'">
                        {{ sitenoteItem?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} (CT)
                      </td>
                      <td *ngIf="sitenoteItem?.type === 'Bolo'">
                        {{sitenoteItem.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} ({{ siteInfoDetails?.timeZone  }})
                      </td>
                      <td *ngIf="sitenoteItem?.type !== 'Bolo'">
                        {{sitenoteItem.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} (CT)
                      </td>
                    </tr>
                    <tr style="color: #0c5b82;" *ngIf="currentlyLoading.indexOf('addNotesModal') >= 0">
                      <td class="borderNone"></td>
                      <td class="borderNone">
                        <app-spinner-load></app-spinner-load>
                      </td>
                      <td class="borderNone"></td>
                      <td class="borderNone"></td>
                    </tr>

                    <tr style="height:20px;" *ngIf="allMessages.length == 0 && currentlyLoading.indexOf('addNotesModal') < 0">
                      <span class="noData">Nothing found to display</span>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="card1 mt-7">
  
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">Recent Reverse Escalations:
                  </h5>
                  <button type="button" class="btn btn-outline-success">View All</button>
                </div>
  
                <div class="sub">
                  <p class="adhoc">
                    Nothing found to display.
                  </p>
                </div>
            
              </div>
            </div> -->
            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Cleaning Crew & Guard</h5>
                  <div class="d-flex" style="min-width: 30%;justify-content: space-between;gap:15px">
                    <div class="btn-group">
                    </div>
                    <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
                      data-bs-target="#CleaningCrewModal">Add Contact
                    </button>
                  </div>
                </div>

                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Type</th>
                      <th>
                        <div class="d-flex" style="justify-content: space-between;">
                          <span>Details</span>
                          <!-- <i class="fa fa-gear"></i> -->
                        </div>
                      </th>
                    </tr>
                    <tr *ngFor="let item of crewAndGuardList">
                      <td>{{item?.name}}</td>
                      <td><a href="rcapp://r/ {{item.contact}}" class="text-white">{{item.contact}}</a></td>
                      <td>{{item?.type}}</td>
                      <td>{{item?.details}}</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="crewAndGuardList?.length==0">
                      <span class="noData">Nothing found to display</span>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card1 mt-5" hidden>
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Guard</h5>
                  <div class="d-flex" style="min-width: 30%;justify-content: space-between;gap:15px">
                    <div class="btn-group">
                    </div>
                    <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
                      data-bs-target="#guardModal">Add Guard
                    </button>
                  </div>
                </div>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Number</th>
                      <th>
                        <div class="d-flex" style="justify-content: space-between;">
                          <span>Details</span>
                          <!-- <i class="fa fa-gear"></i> -->
                        </div>
                      </th>
                    </tr>
                    <!-- <tr *ngFor="let item of guard">
                      <td>{{item?.name}}</td>
                      <td><a href="rcapp://r/ {{item.contact}}" class="text-white">{{item.contact}}</a></td>
                      <td>{{item?.details}}</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="guard?.length==0 ">
                      <span class="noData">Nothing found to display</span>
                    </tr> -->

                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="card1 mt-7">
              <div class="w-100">
                <h5 class="title">Broadcast
                </h5>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <td>Event Date</td>
                      <td>Expiry Date</td>
                      <td>Message</td>
                      <td class="d-flex" style="justify-content: space-between;"><h6>UdId</h6><i class="fa fa-gear"></i></td>
                    </tr>
                    <tr *ngFor="let item of broadcast">
                      <td>{{item.eventDate}}</td>
                      <td>{{item.expiryDate}}</td>                
                      <td>{{item.message}}</td>    
                      <td>{{item.udid}}</td>  
                    </tr> 
                  </tbody>
                </table>
              </div>
            </div> -->
            <!-- <div class="card1 mt-7">
  
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">Recent Police Escalations:
                  </h5>
                  <button type="button" class="btn btn-outline-success">View All</button>
                </div>
  
                <div class="sub">
                  <p class="adhoc">
                    Nothing found to display.
                  </p>
                </div>
              </div>
            </div> -->
            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">Arm/disarm History</h5>
                  <div class="d-flex" style="justify-content: space-between;gap:17px;margin-left:20px">
                    <!-- <div class="btn-group">
                      <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                        data-bs-target="#exampleModal" aria-expanded="false">
                        Filter
                      </button>
                    </div> -->
                    <!-- <button type="button" class="btn btn-outline-success">Add Notes</button> -->
                    <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
                      data-bs-target="#armModal" aria-expanded="false">View All</button>
                  </div>
                </div>

                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr style="height:20px;">
                      <th>Event Date</th>
                      <th>Expiry Date</th>
                      <th>Message</th>
                      <!-- <th><i class="fa fa-gear"></i></th> -->
                    </tr>

                    <tr style="height:20px;" *ngFor="let item of armDisarmHistory ">
                      <td style="width: 40%;">{{item?.siteName}}</td>
                      <td>{{item?.eventType}}</td>
                      <td>{{item?.message}}</td>
                      <td> 
                        <i class="fa fa-edit p-1 d-none"></i> 
                        <i class="fa fa-trash-o p-1 d-none"></i>
                      </td>
                    </tr>

                    <tr style="color: #0c5b82;" *ngIf="currentlyLoading.indexOf('armHistoryRemoveSpinner') >= 0">
                      <td class="borderNone"></td>
                      <td class="borderNone">
                        <app-spinner-load></app-spinner-load>
                      </td>
                      <td class="borderNone"></td>
                      <td class="borderNone"></td>
                    </tr>

                    <tr style="height:20px;" *ngIf="armDisarmHistory.length == 0 && currentlyLoading.indexOf('armHistoryRemoveSpinner') < 0">
                      <span class="noData">Nothing found to display</span>
                    </tr>

                    <!-- <tr style="height:20px;">
                      <td>2023-05-18 0:27:35</td>
                      <td>2023-05-18 0:27:35</td>
                      <td>xxyyzz1212</td>
                      <td>success</td>
                      <td> <i class="fa fa-edit p-1"></i> <i class="fa fa-trash-o p-1"></i></td>
  
                    </tr> -->

                  </tbody>
                </table>
              </div>
            </div>
            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">Current and Future PSA's:</h5>
                  <div class="d-flex" style="justify-content: space-between;gap:17px;margin-left:20px">
                    <!-- Example single danger button -->
                    <!-- <div class="btn-group">
                      <button type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                        data-bs-target="#exampleModal" aria-expanded="false">
                        Filter
                      </button>
                    </div> -->
                    <button hidden type="button" class="btn btn-outline-success" type="button" data-bs-toggle="modal"
                        data-bs-target="#addPSAModal" aria-expanded="false">
                        Add PSA
                      </button>
                    <!-- <button type="button" class="btn btn-outline-success">Add</button> -->
                    <!-- <button type="button" class="btn btn-outline-success">Add Planned Site Activity</button> -->
                  </div>
                  
  
                </div>

               

                <div class="d-block">
                  <table class="table bgnew cell_color">
                    <tbody>
                      <tr style="height:20px;">
                        <th>Message</th>
                        <!-- <th>Event Status</th> -->
                        <!-- <th>Source</th> -->
                        <!-- <th>Create Date</th> -->
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>

                      <tr style="height:20px;" *ngFor="let item of PSAListData;">
                        <td>{{item?.subject}}</td>
                        <!-- <td>{{item?.eventStatus}}</td> -->
                        <!-- <td>{{item?.source}}</td> -->
                        <!-- <td>{{item?.createDate}}</td> -->
                        <td>{{item?.startDt}}</td>
                        <td>{{item?.endDt}}</td>
                      </tr>
                      <tr style="height:20px;width: 200px;" *ngIf="PSAListData?.length==0 ">
                        <span class="noData">Nothing found to display</span>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>

            </div>

            <div class="card1 mt-5" hidden>
              <div class="w-100">
                <h5 class="title">Misc Details:
                </h5>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <td>Activity Email</td>
                      <td style="max-width: 250px;overflow: auto;">:
                        <span class="infoDetails" style="white-space: pre-line;">
                          {{siteInfoDetails?.miscDetails?.activityEmail?.split(',').join('\n')}}
                        </span>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>Guard Service</td>
                      <td>: {{siteInfoDetails?.miscDetails?.guardService}}</td>
                    </tr> -->
                    <tr>
                      <td>Monitoring Service</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.monitoringService}}</span></td>
                    </tr>
                    <!-- <tr>
                      <td>Monitoring Service Pin</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.miscDetails?.monitoringServicePin}}</span></td>
                    </tr> -->
                    <tr>
                      <td>SafeWord</td>
                      <td>: <span class="infoDetails" style="white-space: pre-line;">{{siteInfoDetails?.safeWords?.safeWords === 'null' ? '--' :
                          siteInfoDetails?.safeWords?.safeWords?.split(',').join('\n')}}</span></td>
                    </tr>
                    <tr>
                      <td>Do Not Disarmpin</td>
                      <td>: <span class="infoDetails">{{siteInfoDetails?.safeWords?.doNotDisArmPin}}</span></td>
                    </tr>
                    <tr>
                      <td>ArmDisarmPin</td>
                      <td>: <span class="infoDetails">
                          {{siteInfoDetails?.safeWords?.armDisArmPin?.split(',').join('\n')}}
                        </span></td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>


          </div>

        </div>

        <div class="row mt-5">


        </div>


        <div class="row mt-5">
          <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 paddingLeft">


            <!-- <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Recent Activation:</h5>
                  <button type="button" class="btn btn-outline-success">View All</button>
                </div>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr style="height:20px;">
                      <th>Time</th>
                      <th>User Name</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Status</th>
                    </tr>
  
                    <tr style="height:20px;">
                      <td>2023-05-18 0:27:35</td>
                      <td>4649.pavan</td>
                      <td>ALARM</td>
                      <td>Trigger On</td>
                      <td>success</td>
                    </tr>
                    <tr style="height:20px;">
                      <td>2023-05-18 0:27:35</td>
                      <td>4649.pavan</td>
                      <td>STROBE</td>
                      <td>Trigger On</td>
                      <td>success</td>
                    </tr>
  
                  </tbody>
                </table>
              </div>
            </div> -->
            <!-- <div class="card1 mt-7">
              <div class="w-100">
                <div>
                  <h5 class="title">
                    Links:</h5>
                </div>
                <div class="d-block">
                  <a class="link"><i class='fas fa-long-arrow-alt-right customTransition'></i>Send SMS</a>
                  <a class="link"><i class='fas fa-long-arrow-alt-right customTransition'></i>View in I-vigil*</a>
                  <a class="link"><i class='fas fa-long-arrow-alt-right customTransition'></i>View in Portal*</a>
                  <a class="link"><i class='fas fa-long-arrow-alt-right customTransition'></i>View in Profit</a>
                  <a class="link"><i class='fas fa-long-arrow-alt-right customTransition'></i>Turn Outputs Off*</a>
                </div>
              </div>
  
            </div> -->
            <!-- <div class="card1 mt-7">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Recent Tickets</h5>
                </div>
                <div class="d-block">
                  <p class="adhoc">View In Zendesk
                  </p>
                </div>
              </div>
  
            </div> -->
            <!-- <div class="card1 mt-7">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Open Trouble Tickets:</h5>
                  <button type="button" class="btn btn-outline-success">View All</button>
                </div>
                <div class="d-flex" style="justify-content: space-between;">
  
                </div>
                <div class="d-block">
                  <p class="adhoc">Nothing found to display.
                  </p>
                  <p class="adhoc">
                    *Last synced time unknown
                  </p>
                </div>
              </div>
  
            </div> -->
          </div>
          <!-- <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
       
  
            <div class="card1 mt-5">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">Site Monitoring Protocols:</h5>
                  <button type="button" class="btn btn-outline-success">View All</button>
                  <button type="button" class="btn btn-outline-success">Broadcast Message </button>
                  <button type="button" class="btn btn-outline-success">Add Note</button>
  
                </div>
  
                <table class="table  bgnew cell_color">
  
                  <tbody>
                    <tr>
                      <td style="padding-top: 3px; padding-bottom: 3px; width: 500px;">
                        <b>Industry<span style="margin-left:58px;"></span>:</b>&nbsp;&nbsp;Dealership Auto
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td style="padding-right: 5px;">
                        <b>Permit Number<span style="margin-left:12px;"></span>:</b>&nbsp;&nbsp;-
                      </td>
                    </tr>
  
  
                    <tr>
                      <td style="padding-top: 3px; padding-bottom: 3px; width: 500px;">
                        <b>Industry Group<span style="margin-left:18px;"></span>:</b>&nbsp;&nbsp;Dealership
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td style="padding-right: 5px;" align="left">
                        <b>Police Gate Code&nbsp;:</b>&nbsp;&nbsp;-
                      </td>
                    </tr>
  
                    <tr>
                      <td style="padding-top: 3px; padding-bottom: 3px; width: 500px;">
                        <b>Fenced and Gated&nbsp;:</b>&nbsp;&nbsp;No
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td style="padding-right: 5px;" align="left">
  
                        <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;Wed Apr 12 2023 23:00:00
                        MT
  
                      </td>
                    </tr>
  
  
                    <tr>
                      <td
                        style="padding-top: 3px; padding-bottom: 3px; width: 500px; display: inline-block; white-space: normal;">
                        <b>Other Notes<span style="margin-left:39px;"></span>:</b>&nbsp;
  
                        <div>
                          {{messagePanelData?.broadcast?.bmessages[0]?.message}}
                       
  
                        </div>                    
                      </td>
                    </tr>
  
                  </tbody>
                </table>
              </div>
  
              <div>
  
  
  
              </div>
  
            </div>
  
            <div class="card1">
              <div class="w-100">
                <h5 class="title">Adhoc Monitoring Notes:
                </h5>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr>
                      <td>Event Date</td>
                      <td>Expiry Date</td>
                      <td>Udid</td>
                      <td class="d-flex" style="justify-content: space-between;"><h6>message</h6><i class="fa fa-gear"></i></td>
                    </tr>
                    <tr *ngFor="let item of adhoc">
                      <td>{{item?.eventDate}}</td>
                      <td>{{item?.expiryDate}}</td>                
                      <td>{{item?.message}}</td>    
                      <td>{{item?.udid}}</td>  
                    </tr> 
                  </tbody>
                </table>
  
              </div>
            </div>
  
            <div class="card1 mt-7">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <h5 class="title">
                    Site Images</h5>
                </div>
                <div>
                  <div style="display:table-cell; vertical-align:middle;">
  
                    <form class="d-flex">
                      <div>
                        <b><span class="sub">Guard Images:</span></b>&nbsp;<br>
                        <div><input type="file" class="custom-file-input"><br><span>(Jpg and Png formats are
                            allowed)</span><br><br></div>
                        <div><input type="file" class="custom-file-input"><br><span>(Jpg and Png formats are
                            allowed)</span><br><br></div>
                        <div><input type="file" class="custom-file-input"><br><span>The Maximum File upload Size is
                            500KB</span> <br><br></div>
                        <button class="btn btn-outline-success">View All
                          Guard images</button>
                        <div></div>
                        <div></div>
                      </div>
                      <div>
                        <b><span class="sub">Site Monitoring Images:</span></b>&nbsp;<br>
                        <div><input type="file" class="custom-file-input"><br><span>(Jpg and Png formats are
                            allowed)</span><br><br></div>
                        <div><input type="file" class="custom-file-input"><br><span>(Jpg and Png formats are
                            allowed)</span><br><br></div>
                        <div><input type="file" class="custom-file-input"><br><span>The Maximum File upload Size is
                            500KB</span> <br><br></div>
                        <button class="btn btn-outline-success">View All
                          Monitoring Site images</button>
                        <div></div>
                        <div></div>
                      </div>
  
  
                      <div style="text-align: left; margin-bottom: 10px;">
  
                      </div>
                    </form>
  
                  </div>
  
                </div>
              </div>
  
            </div>
  
          </div> -->
        </div>

      </div>
      <div class="container-fluid" *ngIf="!failedFetchAll && !potentialId">
        <div class="alert alert-warning text-center fs-5" role="alert">
          Please click More info icon on the wall to view the data.
        </div>
      </div>
    </div>
    <div class="container" *ngIf="!failedFetchAll && isShowMsgPanelInfoSpinner">
      <div class="container-fluid" style="text-align: center;" >
        <app-spinner-load></app-spinner-load>
      </div>
    </div>
    <!---get Recent Esc History-->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <app-filtermodal 
        *ngIf="armFilterForm"
        [filterForm]="alarmFilterForm" 
        [title]="'Recent Escalation'"
        (filterApplied)="updateReverseEscForm($event)"></app-filtermodal>
    </div>
    <div class="modal fade" id="broadAdhocModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <app-filtermodal 
        *ngIf="armFilterForm"
        [filterForm]="alarmFilterForm" 
        [title]="'Broadcast / Adhoc'"
        (filterApplied)="updateBroadAdhocForm($event)"></app-filtermodal>
    </div>
    <!---get alarm history-->
    <div class="modal fade" id="alarmhistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <app-filtermodal 
        *ngIf="alarmFilterForm"
        [filterForm]="alarmFilterForm" 
        [title]="'Recent Activation'"
        (filterApplied)="recentActivationFilter($event)">
      </app-filtermodal>
    </div>

    <!---arm filter-->
    <div class="modal fade" id="armModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <app-filtermodal 
        *ngIf="armFilterForm"
        [filterForm]="armFilterForm" 
        [title]="'Arm/Disarm'"
        (filterApplied)="armDisarmFilter($event)">
      </app-filtermodal>
    </div>
    <div class="modal" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
      style="z-index: 9999999;">
      <div class="modal-dialog" style="margin-top: 40px;">
        <div class="modal-content"
          style="box-shadow: 3px 3px 6px 1px #272727;width: 82%;margin:8% 9%;position: absolute;">
          <div class="modal-header"
            style="background-color: #0c5b82;border: 1px solid white;color: white;height: 50px;">
            <h5 class="modal-title" id="filterModalLabel1">Add Site Notes</h5>
            <button type="button" class="close" #filterModal data-bs-dismiss="modal" aria-label="Close"
              style="background: none;border: none;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color: white;">
            <form [formGroup]="addSiteNotesForm" *ngIf="addSiteNotesForm">
              <div class="textArea" style="width: 100%;">
                <h5>Please Leave your message</h5>
                <div>
                  <textarea id="b_message" formControlName="recordsmessage" name="b_message" rows="4" cols="50"
                    style="height: 150px; width: 100%;" [(ngModel)]="text_broad" class="form-control"
                    (keydown)="onKeyDown($event,text_broad)" 
                    [ngClass]="{ 'is-invalid': submitted && form['recordsmessage'].errors}">
                  </textarea>
                  <span class="counter">{{getRemainingCount(text_broad)}} Character(s) Remaining</span>
                </div>
              </div>
              <div class="dateBox">
                <label for="date">From Date:</label>
                <input
                 type="date"
                 id="StartDate" 
                 [min]="validatedFromdDate"
                 (change)="changeDate()"
                 formControlName="startDate"
                 class="form-control inputStyle" 
                formControlName="startDate" 
                [ngClass]="{ 'is-invalid': submitted && form['startDate'].errors}"
                 >
                <input [ngModel]="todayTime" type="time" id="appt" 
                formControlName="startTime" 
                style="width: 120px;" class="form-control inputStyle" 
                [ngClass]="{ 'is-invalid': submitted && form['startTime'].errors}"
                >
              </div>
              <div class="date_box">
                <label for="date">To Date:</label>
                <input type="date" id="EndDate" 
                [min]="validatedFromdDate"
                [max]="validatedTodDate"
                formControlName="endDate" class="form-control inputStyle" 
                [ngClass]="{ 'is-invalid': submitted && form['endDate'].errors}">
                <input type="time" id="appt" formControlName="endTime" 
                style="width: 120px;" class="form-control inputStyle" 
                [ngClass]="{ 'is-invalid': submitted && form['endTime'].errors}">
              </div>
              <!---Merging Both Adhoc and Brodcost-->
              <div class="radio-buttons">
                <span>Type :</span>
                <label>
                  <input type="radio" formControlName="broadcastType" value="Adhoc" (change)="onRadioChange('Adhoc')"
                    [checked]="addSiteNotesForm.get('broadcastType').value === 'Adhoc'"><span>Adhoc</span> 
                </label>
                <label>
                  <input type="radio" formControlName="broadcastType" value="Broadcast"
                    (change)="onRadioChange('Broadcast')"
                    [checked]="addSiteNotesForm.get('broadcastType').value === 'Broadcast'"><span>Broadcast</span> 
                </label>
                <label>
                  <input type="radio" formControlName="broadcastType" value="bolo"
                    (change)="onRadioChange('bolo')"
                    [checked]="addSiteNotesForm.get('broadcastType').value === 'bolo'"
                    [ngClass]="{ 'is-invalid': submitted && form['broadcastType'].errors }"> <span>Bolo</span>
                </label>
              </div>
              <div class="modal-footer">
                <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%;">
                  <button type="button" class="btn btn-secondary" 
                    style="margin-right: 20px;"  (click)="closebrodForm($event)">Cancel</button>
                  <button type="button" class="btn btn-primary" (click)="createAddSiteNotesForm(addSiteNotesForm)">Add
                    Notes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!---Add Cleaning Crew-->
    <div class="modal" id="CleaningCrewModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
      style="z-index: 9999999;">
      <div class="modal-dialog" style="margin-top: 40px;">
        <div class="modal-content"
          style="box-shadow: 3px 3px 6px 1px #272727;width: 82%;margin:8% 9%;position: absolute;">
          <div class="modal-header"
            style="background-color: #0c5b82;border: 1px solid white;color: white;height: 50px;">
            <h5 class="modal-title" id="filterModalLabel1">Add Cleaning Crew / Guard</h5>
            <button type="button" class="close" #CleaningCrewModal data-bs-dismiss="modal" aria-label="Close"
              style="background: none;border: none;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color: white;">
            <form [formGroup]="cleaningCrewForm" class="d-flex flex-column">
              <div class="form-row" style="display: flex; flex-direction: column;">
                <div class="form-group">
                  <h5>Name</h5>
                  <input type="text" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': cleancrew && cleaningForm['name'].errors}"
                    style="font-size: 14px;border: 1px solid black;">
                </div>
                <div class="form-group" style="margin-top: 7px;">
                  <h5>Number</h5>
                  <input type="text" formControlName="contact" 
                  class="form-control no-autofill-icon"
                  [ngClass]="{ 'is-invalid': cleancrew && cleaningForm['contact'].errors}"
                    style="font-size: 14px;border: 1px solid black;">
                </div>
                <div class="radio-buttons">
                  <span>Type :</span>
                  <label>
                    <input type="radio" formControlName="cleaningCrewType" value="Cleaning Crew" (change)="onCrewAndGuardRadioChange('Cleaning Crew')"
                      [checked]="cleaningCrewForm?.get('cleaningCrewType').value === 'Cleaning Crew'"><span> Cleaning Crew</span>
                  </label>
                  <label>
                    <input type="radio" formControlName="cleaningCrewType" value="Guard"
                      (change)="onCrewAndGuardRadioChange('Guard')"
                      [checked]="cleaningCrewForm?.get('cleaningCrewType').value === 'Guard'"> <span>Guard</span>
                  </label>
                </div>
                <div class="form-group" style="margin-top: 7px;">
                  <h5>Please leave your notes/comments</h5>
                  <textarea id="b_message" formControlName="details" name="b_message" rows="3" [(ngModel)]="text_Crew"
                    (keydown)="onKeyDown($event,text_Crew)" cols="50"
                    style="height: 150px; width: 100%; font-size: 14px; white-space: pre-wrap;"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': cleancrew && cleaningForm['details'].errors}"
                    class="form-control"></textarea>
                  <div class="error_Message">
                    <span class="counter">{{ getRemainingCount(text_Crew) }} Character(s) Remaining</span>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%;">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    style="margin-right: 20px;" (click)="closeCrewForm($event)">Close</button>
                  <button type="button" class="btn btn-primary"
                    (click)="cleaningCrewUpdate(cleaningCrewForm)">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!---Add Guard-->
    <!-- <div class="modal" id="guardModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
      style="z-index: 9999999;">
      <div class="modal-dialog" style="margin-top: 40px;">
        <div class="modal-content"
          style="box-shadow: 3px 3px 6px 1px #272727;width: 82%;margin:8% 9%;position: absolute;">
          <div class="modal-header"
            style="background-color: #0c5b82;border: 1px solid white;color: white;height: 50px;">
            <h5 class="modal-title" id="filterModalLabel1">Add Guard</h5>
            <button type="button" class="close" #guardModal data-bs-dismiss="modal" aria-label="Close"
              style="background: none;border: none;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color: white;">
            <form [formGroup]="guardform" *ngIf="guardform" class="d-flex flex-column">
              <div class="form-row" style="display: flex; flex-direction: column;">
                <div class="form-group">
                  <h5>Name</h5>
                  <input type="text" formControlName="name" class="form-control"
                    style="font-size: 14px;border: 1px solid black;">
                </div>
                <div class="form-group" style="margin-top: 7px;">
                  <h5>Contact</h5>
                  <input type="text" formControlName="contact" class="form-control no-autofill-icon"
                    style="font-size: 14px;border: 1px solid black;">
                </div>
                <div class="form-group" style="margin-top: 7px;">
                  <h5>Please leave your notes/comments</h5>
                  <textarea id="b_message" formControlName="details" name="b_message" rows="3" cols="50"
                    [(ngModel)]="text_guard" (keydown)="onKeyDown($event,text_guard)"
                    style="height: 150px; width: 100%; font-size: 14px; white-space: pre-wrap;"
                    class="form-control"></textarea>
                  <div class="error_Message">
                    <span class="counter">{{ getRemainingCount(text_guard) }} Character(s) Remaining</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%;">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                style="margin-right: 20px;">Close</button>
              <button type="button" class="btn btn-primary" (click)="guardUpdate(cleaningCrewForm)">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="modal" [id]="'msgpanelLiveview'+panelName" tabindex="-1" aria-labelledby="allcammaskingviewLabel"
      style="display: none;z-index: 998;overflow: hidden;" aria-hidden="true">
      <div *ngIf="showLiveviewPanel" style="height: 100%;">
        <app-liveview-list [wallsData]="wallObjData" [panelName]="panelName" [triggerAlarmNeeded]="false"
          [isMaskingView]="true" (closeAllViewModal)="closeAllViewModal()"></app-liveview-list>
      </div>
    </div>

    <div [id]="'siteMapModal'+panelName" class="modal" tabindex="-1"  aria-labelledby="siteMapLabel">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="height:100%">
          <div class="modal-header" style="background-color: black;color: antiquewhite;">
            <h5 class="modal-title">Site Map of - {{siteInfoDetails?.siteName}} </h5>
            <button type="button" class="btn btn-orange px-5" (click)="closeSiteMapModal()">
              Close
            </button>
          </div>
          <div class="modal-body c-lv-view">
            <div class="siteMapImage-container">
              <img [src]="siteMapImgSrc" *ngIf="siteMapImgSrc" alt="Site plan preview is not available"
                class="siteMapImage" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" [id]="'msgpanelCameraview'" tabindex="-1" aria-labelledby="cameraViews"
      style="display: none;z-index: 998;overflow: hidden;" aria-hidden="true">
      <div *ngIf="showCameraviewPanel" style="height: 100%;">
        <app-camera-views [potentialId]="potentialId" [siteName]="siteInfoDetails?.siteName"
          (closeCameraViewModal)="closeCameraViewModal()"></app-camera-views>
      </div>
    </div>

    <!----Reversce esc details-->

    <div class="modal" id="eventUserInfo" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
      style="z-index:99999;height:fit-content">
      <div class="modal-dialog modal-xl" style="margin-top: 40px;">
        <div class="modal-content" style="width: 100%;box-shadow: 3px 3px 6px 1px #272727;">
          <div class="modal-header" style="background-color: #0c5b82;color: white;border: 1px solid white;padding: 0px 5px;height: 34px;">
            <h5 class="modal-title" id="filterModalLabel">Escalation Details - {{siteInfoDetails?.siteName}}, {{escId}} </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"
              style="background: none;border: none;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color: white;padding: 4px;">
            <div class="radio-buttonGroup">
              <div class="timezone" style="border: 1px solid #ccc; padding: 10px;">
                <h4>Escalation Report By Timezone</h4>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="timezone" id="ctRadio" value="CT" (click)="setTimezone('CT')" [checked]="selectedTimezone === 'CT'">
                  <label class="form-check-label" for="ctRadio">CT</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="timezone" id="ptRadio" value="PT" (click)="setTimezone('PT')" [checked]="selectedTimezone === 'PT'">
                  <label class="form-check-label" for="ptRadio">Customer Timezone ({{siteInfoDetails?.timeZone}})</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="timezone" id="istRadio" value="IST" (click)="setTimezone('IST')" [checked]="selectedTimezone === 'IST'">
                  <label class="form-check-label" for="istRadio">IST</label>
                </div>
              </div>
            </div>
            
            <table class="table table-responsive recentEsc" *ngIf="!eventInfo">
              <thead style="border-bottom: 1px solid white;">
                <tr class="recentNotes">
                  <th class="p-1 fw-semibold border-0 align-baseline">User</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Level</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">User Hold time</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Received Time</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Review Start Time</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Review End Time</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Review Duration</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Tag</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Action</th>
                  <th class="p-1 fw-semibold border-0 align-baseline">Notes</th> 
                </tr>
              </thead>
              <tbody class="tbody">
                <tr *ngFor="let item of eventUserData" class="recentdata" [ngClass]="{'make-gold': item?.currentLevelId == 'L3'}">
                  <td class="p-2">{{ item?.userId || '-' }}</td>
                  <td class="p-2">{{ item?.levelName || '-'}}</td>
                  <td class="p-2">{{ item?.userHoldTime || '-' }}</td>
                  <td class="p-2">{{ selectedTimezone === 'PT' ? item?.receiveTimeStringInSiteTZ : selectedTimezone === 'IST' ? item?.receiveTimeStringInIST : item?.receiveTimeString }}</td>
                  <td class="p-2">{{ selectedTimezone === 'PT' ? item?.reviewTimeStringInSiteTZ : selectedTimezone === 'IST' ? item?.reviewTimeStringInIST : item?.reviewTimeString || '-' }}</td>
                  <td class="p-2">{{ selectedTimezone === 'PT' ? item?.actionTimeStringInSiteTZ : selectedTimezone === 'IST' ? item?.actionTimeStringInIST : item?.actionTimeString || '-' }}</td>
                  <td class="p-2">{{ item?.userReviewTime || '-' }}</td>
                  <td class="p-2">{{ item?.action || '-'}}</td>
                  <td class="p-2">{{ item?.actionType || '-'}}</td>
                  <td class="p-2">{{ item?.actionNote || '-' }}</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="eventInfo">
              <p>No event information</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="{ 'show': armDisarmed }" [ngStyle]="{ 'display': armDisarmed ? 'block' : 'none', 'background-color': 'rgba(0, 0, 0, 0.5)' }">
  <div class="modal-dialog" style="margin-top: 40px;width: 300px;">
    <div class="modal-content">
      <div class="modal-header">
        {{armDisarmStatus}}
        <h5 class="modal-title" id="filterModalLabel">Confirm {{ getTitle() }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismissModal()"></button>
      </div>
      <div class="modal-body" style="background-color: white;">
        <div class="row">
          <div class="col-3"><strong>Action:</strong></div>
          <div class="col-9">{{ armDisarmStatus }}</div>
        </div>
        <div class="mb-3">
          <label for="nameInput" class="form-label">Name:</label>
          <input type="text" class="form-control" id="nameInput" placeholder="Type text here">
        </div>
        <div class="mb-3">
          <label for="notesTextarea" class="form-label">Textarea:</label>
          <textarea class="form-control" id="notesTextarea" rows="3" placeholder="Type text here"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitModal()">Submit</button>
      </div>
    </div>
  </div>
</div> -->
 <!---sub-->
    <app-armdisarm-modal [armDisarmed]="armDisarmed" [armDisarmStatus]="armDisarmStatus" (closeModal)="dismissModal()"
      (submitModal)="submitArmDisarm($event)"></app-armdisarm-modal>

      <div class="toaster-container" [class.show]="showToaster" *ngIf="showToaster">
        <i class="fa fa-right" class="text-success"></i>
        {{ toasterMessage }}
      </div>
      <div class="toaster-container-Error" [class.show]="errorToaster" *ngIf="errorToaster">
        <i class="fa fa-arrow-right" class="text-success"></i>
        {{ toasterMessage }}
      </div>

</div>




<!--Site Map Modal Popup-->
<!-- <div [id]="'siteMapModal'+panelName" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content" style="height:inherit">
      <div class="modal-header">
        <h5 class="modal-title">Site Map of - {{siteInfoDetails.siteName}} </h5>
        <span class="close" (click)="closeSiteMapModal()">&times;</span>
      </div>
      <div class="modal-body c-lv-view">
        <div class="spinner-container" *ngIf="showLoading">
          <div class="loading-text">Loading...</div>
        </div>
        <div class="siteMapImage-container">
          <img [src]="siteMapImgSrc" *ngIf="siteMapImgSrc" alt="Site plan preview not available"
            class="siteMapImage" />
          <div class="loading-text" *ngIf="noImg">{{ noImg }}</div>
        </div>
      </div>
    </div>
  </div>
</div> -->



<!---Add Planned Site Activity Modal-->
<div class="modal" id="addPSAModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
style="z-index: 9999999;">
<div class="modal-dialog" style="margin-top: 40px;">
  <div class="modal-content"
    style="box-shadow: 3px 3px 6px 1px #272727;width: 82%;margin:8% 9%;position: absolute;">
    <div class="modal-header"
      style="background-color: #0c5b82;border: 1px solid white;color: white;height: 50px;">
      <h5 class="modal-title" id="filterModalLabel1">Add Planned Site Activity</h5>
      <button type="button" class="close" #addPSAModal data-bs-dismiss="modal" aria-label="Close"
        style="background: none;border: none;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="background-color: white;">
      <form [formGroup]="psaform" *ngIf="psaform" class="d-flex flex-column">
        <div class="form-row" style="display: flex; flex-direction: column;">
          <div class="form-group">
            <h5>Account Name</h5>
            <input type="text" formControlName="accountName" class="form-control"
              style="font-size: 14px;border: 1px solid black;">
          </div>
          <div class="form-group" style="margin-top: 7px;">
            <h5>Source</h5>
            <input type="text" formControlName="source" class="form-control no-autofill-icon"
              style="font-size: 14px;border: 1px solid black;">
          </div>
          <div class="date_box">
            <label for="date">Start DateTime:</label>
            <input type="date" id="StartDate" formControlName="startDate">
            <input type="time" id="appt" formControlName="startTime" style="width: 120px;">
          </div>
          <div class="date_box">
            <label for="date">End DateTime:</label>
            <input type="date" id="EndDate" formControlName="endDate">
            <input type="time" id="appt" formControlName="endTime" style="width: 120px;">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%;">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          style="margin-right: 20px;">Close</button>
        <button type="button" class="btn btn-primary" (click)="addOrUpdatePSA(psaform)">Submit</button>
      </div>
    </div>
  </div>
</div>
</div>