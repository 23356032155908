import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './common/authguard/authguard.guard';
import { FullscreenComponent } from './pages/fullscreen/fullscreen.component';
import { LoginComponent } from './common/components/login/login.component';
import { L2OneWallsComponent } from './pages/l2-one-walls/l2-one-walls.component';
import { MessagepanelComponent } from './common/components/messagepanel/messagepanel.component';
import { LiveViewModule } from './common/components/all-liveview/live-view.module';
import { CameraViewComponent } from './pages/camera-view/camera-view.component';
import { TagsResolverService } from './common/services/tags-resolver/tags-resolver.service';
import { LogoutPageComponent } from './common/components/logout-page/logout-page.component';
import { LiveMonitoringListComponent } from './pages/CLM/live-monitoring-list/live-monitoring-list.component';
import { ClmLiveviewPopupComponent } from './pages/CLM/clm-liveview-popup/clm-liveview-popup.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'l1',
    component: FullscreenComponent,
    canActivate: [AuthguardGuard],
    resolve: { tagsResolverList: TagsResolverService },
  },
  {
    path: 'l2-one',
    component: L2OneWallsComponent,
    canActivate: [AuthguardGuard],
    resolve: { tagsResolverList: TagsResolverService },
  },
  {
    path: 'l3-one',
    component: L2OneWallsComponent,
    canActivate: [AuthguardGuard],
    resolve: { tagsResolverList: TagsResolverService },
  },
  {
    path: 'live-monitoring',
    component: LiveMonitoringListComponent,
    canActivate: [AuthguardGuard],
    resolve: {},
  },
  {
    path: 'l3-messagepanel',
    component: MessagepanelComponent,
  },
  {
    path: 'cameraview',
    component: CameraViewComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: 'clm-liveview-popup',
    component: ClmLiveviewPopupComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: 'logout',
    component: LogoutPageComponent
  },
  { path: 'live-view', loadChildren: () => LiveViewModule },
  {
    path: '**',
    redirectTo: 'fullscreen',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
