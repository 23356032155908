import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, fromEvent, of, takeUntil } from 'rxjs';
import { AccessPermissions } from '../../models/loginpermissions';
import { LoginService } from '../login/login.service';
import { permissions } from '../../models/globalpermissions';
import { WallsService } from 'src/app/services/walls/walls.service';

@Injectable({
  providedIn: 'root'
})
export class SharedsrvService {
  /// for header show
  public isHeaderDisplay = new EventEmitter();
  public isHeaderShow: Observable<any>;
  public isplayPauseChange = new EventEmitter();
  public isplayPause: Observable<any>;
  public isplayPauseBehaviourValue: BehaviorSubject<any | null>;
  public isplayPauseValue: Observable<any | null>;
  /// for user data access
  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;

  /// for paneltable table show
  private panelTableSubject = new EventEmitter();
  public panelTableData: Observable<any | null>;

  ///  for panelactive on walls
  public panelActiveData = new BehaviorSubject<any>([]);
  public _panelactiveDataArray: any = [];

  ///  for panel active playpause on walls
  public ppPanelActiveData = new BehaviorSubject<any>([]);
  public _ppPanelactiveDataArray: any = []

  ///  forfullscreen status for walls
  public isfullscreenMode = new BehaviorSubject<any>([]);
  public fullscreenEventEmitter = new EventEmitter();
  public isFullScreenModeStatus: Observable<any | null>;

  ///
  public isMirrorWindowDisplay = new EventEmitter();
  public isMirrorEnable: Observable<any>;
  private unsubscriber: Subject<void> = new Subject<void>();

  //show warning icon L2 and L1 Users for Bolo Message 
  private boloNotesSub$ = new BehaviorSubject<any>('');
  boloNotes$ = this.boloNotesSub$.asObservable();  

  private siteTimeSub$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.siteTimeSub$.asObservable();
  private wallsService:WallsService;
  constructor(
    private router: Router,
    private loginService: LoginService,
  ) {
    this.isHeaderShow = this.isHeaderDisplay.asObservable();
    this.isMirrorEnable = this.isMirrorWindowDisplay.asObservable();
    this.isplayPause = this.isplayPauseChange.asObservable();
    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();

    this.isplayPauseBehaviourValue = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.isplayPauseValue = this.isplayPauseBehaviourValue.asObservable();
    this.panelTableData = this.panelTableSubject.asObservable();
    this.isFullScreenModeStatus = this.fullscreenEventEmitter.asObservable();
  }
  public setActivePanel(data: any) {
    if (data) {
      if (this._panelactiveDataArray.filter((x: any) => x.panelName === data.panelName).length === 0) {
        this._panelactiveDataArray.push(data)
      }
    }
    this.panelActiveData.next(this._panelactiveDataArray);
  }
  public getActivePanel() {
    return this.panelActiveData.asObservable();
  }
  public clearActivePanel() {
    this._panelactiveDataArray = [];
  }


  getPanelActiveDataStoreSharedSrv(panelName: string, isSafeLogout?: boolean) {
    switch (panelName) {
      case "panel1":
        this.setActivePanel({ "panelName": panelName });
        break;
      case "panel2":
        this.setActivePanel({ "panelName": panelName });
        break;
      case "panel3":
        this.setActivePanel({ "panelName": panelName });
        break;
      case "panel4":
        this.setActivePanel({ "panelName": panelName });
        break;
      default:
        break;
    }
    /// check PausedWalls

    /// auto logout if all events cleared on wall
    if (isSafeLogout) {
      let levelName = this.getMonitorLevels();
      if (levelName == AccessPermissions.levelName1) {
        if (this._panelactiveDataArray.length == AccessPermissions.l1_slotsCount) {
          this.userLogout();
        }
      }
      if (levelName == AccessPermissions.levelName2) {
        if (localStorage.getItem("isMirrorEnabled") == "true") {
          if (this._panelactiveDataArray.length == AccessPermissions.l2_mirrorSlotsCount) {
            let popupwindow: any = window.open(window.location.href + "?mirror=true", "duplicate", "toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no");
            popupwindow.close();
            setTimeout(() => {
              this.userLogout();
            }, 10);
          }

        } else {
          if (this._panelactiveDataArray.length == AccessPermissions.l2_slotsCount) {
            this.userLogout();
          }
        }
      }
      if (levelName == AccessPermissions.levelName3) {
        let msgPanel = window.open("",'message_panel','toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no');
         msgPanel?.close();
         let L3_TrainerSlotsCount:number = parseInt(localStorage.getItem("Slots"));
         
         if(typeof(L3_TrainerSlotsCount) == "number" && NaN){
           AccessPermissions.l3_slotsCount = L3_TrainerSlotsCount;
         }else{
           AccessPermissions.l3_slotsCount;
         }
         if (this._panelactiveDataArray.length == AccessPermissions.l3_slotsCount) {
           if (permissions?.messagePanel) {
             //@ts-ignore
             permissions?.messagePanel?.close();
           }
           this.userLogout();
         }
       }
    }
  }


  //// 
  public set_ppActivePanel(data: any) {
    if (data) {
      if (this._ppPanelactiveDataArray.filter((x: any) => x.panelName === data.panelName).length === 0) {
        this._ppPanelactiveDataArray.push(data)
      }
    }
    this.ppPanelActiveData.next(this._ppPanelactiveDataArray);
  }
  public get_ppActivePanel() {
    return this.ppPanelActiveData.asObservable();
  }
  public clear_ppActivePanel() {
    this._panelactiveDataArray = [];
  }


  get_ppPanelActiveDataStoreSharedSrv(panelName: string, isSafeLogout?: boolean) {
    if (isSafeLogout == false) {
      switch (panelName) {
        case "panel1":
          this.set_ppActivePanel({ "panelName": panelName });
          break;
        case "panel2":
          this.set_ppActivePanel({ "panelName": panelName });
          break;
        case "panel3":
          this.set_ppActivePanel({ "panelName": panelName });
          break;
        case "panel4":
          this.set_ppActivePanel({ "panelName": panelName });
          break;
        default:
          break;
      }
    }
  }
  ////
  public removeActivePanel() {
    this._panelactiveDataArray = [];
    this.panelActiveData.next([]);
  }

  public setHeadingEnabled(obj: any) {
    this.isHeaderShow = obj;
    this.isHeaderDisplay.emit(obj);
  }
  public getHeadingEnabled() {
    return this.isHeaderShow;
  }
  public setMirrorWindowEnabled(obj: any) {
    this.isMirrorEnable = obj;
    this.isMirrorWindowDisplay.emit(obj);
  }
  public getMirrorWindowEnabled() {
    return this.isMirrorEnable;
  }
  public setUserValue(user: any) {
    this.userSubject.next(user);
  }
  public getUserValue() {
    return this.userSubject.value;
  }
  public setUserdataPlaypause(value: string) {
    this.isplayPauseBehaviourValue.next(value);
  }
  public getUserdataPlaypause() {
    return this.isplayPauseValue;
  }
  public setPlayPause(value: any) {
    localStorage.setItem("playpause", value.toString());
    this.isplayPause = value;
    this.isplayPauseChange.emit(value);
  }
  public getPlayPause(): Observable<any> {
    return of(this.isplayPause);
  }
  public setPanelTableData(value: any) {
    this.panelTableData = value;
    this.panelTableSubject.emit(value);
  }
  public getPanelTableData(): Observable<any> {
    return of(this.panelTableData);
  }
  public setFullscreenEvent(value: boolean) { 
    this.isfullscreenMode.next(value);
    this.fullscreenEventEmitter.emit(this.isfullscreenMode);
  }
  public getFullscreenEvent() {
    return this.isfullscreenMode
  }
  public clearFullscreenEvent(value: boolean) {
    this.isfullscreenMode.next(false)
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('pro-token');
    this.userSubject.next(null);
    localStorage.removeItem("userType");
    localStorage.removeItem("PlayText");
    localStorage.removeItem("isMirrorEnabled");
    localStorage.removeItem("isMirrorSafeLogout");
    localStorage.removeItem("isMirrorPanelLogout4");
    localStorage.removeItem("isMirrorPanelLogout3");
    localStorage.removeItem("isMirrorPanel3");
    localStorage.removeItem("isMirrorPanel4");
    //this.router.navigate(['/login']);
    //this.router.navigate(['/logout'])
    //location?.reload();
    this.navigateToLoginLogout();
  }

  public getMonitorLevels() {
    let monitorLeve1 = localStorage.getItem('userType') || "";
    let levelname = "";
    if (monitorLeve1 == AccessPermissions.accessl1) {
      levelname = AccessPermissions.levelName1
    }
    if (monitorLeve1 == AccessPermissions.accessl2) {
      levelname = AccessPermissions.levelName2
    }
    if (monitorLeve1 == AccessPermissions.accessl3) {
      levelname = AccessPermissions.levelName3
    }
    return levelname
  }
  userLogout = () => {
    let userId = "";
    this.clearWindows();
    if (localStorage.getItem('user')) {
      //@ts-ignore
      let userDetails = JSON.parse((localStorage.getItem('user'))).user;
      userId = userDetails?.username;
    }
    this.loginService.signOutRequest(userId).subscribe((res: any) => {
      this.loginService.handleSignOutResponse(res);
      this.logout();
    });
  }
  prepareWindowName = (windowPrefix: string) => {
    permissions.windowCounter = permissions.windowCounter + 1;
    return windowPrefix + permissions.windowCounter;
  }
  clearWindows = () => {
    for (const key in permissions.allWindows) {
      if (Object.prototype.hasOwnProperty.call(permissions.allWindows, key)) {
        const element = permissions.allWindows[key];
        // if (key.includes('liveviewAuditId_')) { /// comented for liveview
        //   const accountId = key.split('AuditId_')[1]?.split("=")[0];
        //   this.wallsService.endLiveViewAuditLog(accountId);
        // }
        element.close();
      }
    }
    permissions.allWindows = {};
  }

  public navigateToLoginLogout = () => {
    let levelName = this.getMonitorLevels();
    if (levelName === AccessPermissions.levelName1 || AccessPermissions.levelName2) {
      this.router.navigate(['/logout']);
    }
    else {
      this.router.navigate(['/login']);
      // location?.reload();
    }
  }

  initDisableBackButtonOnInit = () => {
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }
  destroyDisableBackButtonOnInit = () => {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  //for Bolo Message 
  updateBoloNoteStatus(hasBoloNotes:boolean) {
    this.boloNotesSub$.next(hasBoloNotes);
  }
 
  setTime(data:any){
    this.siteTimeSub$.next(data);
  }
  getTime():any{
    return this.siteTimeSub$.getValue();
  }

  getCanvasContextForMasking = (context: any, datapoints: any[], fScreen?: boolean, positionInfo?: any, wallsDataObject?: any) => {
    if(wallsDataObject?.pointsFromCamera && wallsDataObject?.pointsFromCamera == true && permissions.isUnifiedMaskingLocalEnabled == true) {
      return this.getCanvasForAID7Masking(context, datapoints, fScreen, positionInfo, wallsDataObject);
    }
    let posHeight = positionInfo.height;
    let posWidth = positionInfo.width;
    context.beginPath();
    datapoints.forEach((points: any) => {
      if (points.x == 0 && points.y == 0) {
        context.lineTo(points.x, points.y);
      } else if (points.x == 0) {
        context.lineTo(points.x, points.y * posHeight / wallsDataObject.height);
      } else if (points.y == 0) {
        context.lineTo(points.x * posWidth / wallsDataObject.width, points.y);
      } else {
        context.lineTo(Math.round(((points.x * posWidth) / wallsDataObject.width)), Math.round(((points.y * posHeight) / wallsDataObject.height)));
      }
    });
    context.fillStyle = "rgb(255 47 0 / 60%)";
    context.stroke();
    //context.clip();
    context.fill();
    return context;
  }

  getCanvasForAID7Masking = (context: any, datapoints: any[], fScreen?: boolean, positionInfo?: any, wallsDataObject?: any) => {
    let canvasCleared: boolean = false;
    let maskDrawn: boolean = false;
    let json:any = datapoints
    let poly = json.slice(0, -5);
    let posHeight = positionInfo.height;
    let posWidth = positionInfo.width;
  
    if (!canvasCleared) {
      context.clearRect(0, 0, posWidth, posHeight);
      canvasCleared = true; // Mark the canvas as cleared
    }
  
    if (!maskDrawn) {
      context.beginPath();
      context.moveTo(0, 0);
      context.lineTo(posWidth, 0);
      context.lineTo(posWidth, posHeight);
      context.lineTo(0, posHeight);
      context.closePath();
      context.fillStyle = "rgba(255, 47, 0, 0.6)"; // Use a semi-transparent red color for the mask
      context.fill();
      maskDrawn = true; // Mark the mask as drawn
    }
  
     context.beginPath();
    poly.forEach((point: any, index: number) => {
      let x = point.x * posWidth / wallsDataObject.width;
      let y = point.y * posHeight / wallsDataObject.height;
      if (index === 0) {
        context.moveTo(x, y);
      } else {
        context.lineTo(x, y);
      }
    });
    context.closePath();
    context.globalCompositeOperation = 'destination-out'; 
    context.fillStyle = "rgba(0, 0, 0, 8)"; 
    context.fill(); 
    context.globalCompositeOperation = 'source-over';
  
    return context;
  }
}
