  <div class="modal-dialog" style="margin-top: 40px;width: 300px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="filterModalLabel">Filter by {{title}}</h5>
        <button type="button" class="btn-close" #alarmhistoryModal data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="background-color: white;">
        <form [formGroup]="filterForm">
          <div>
            <select class="form-select Primary select" aria-label="Default select example" formControlName="records"
              style="margin-bottom: 20px;border:1px solid grey; padding:5px">
              <option value="" disabled selected>Choose records</option>
              <option value="recent">Recent</option>
              <option value="viewAll">View All</option>
            </select>
            <div>
              <div class="date_box m-0">
                <label for="date"> Start Date:</label>
                <input type="date" id="StartDate" formControlName="startDate">
                <input type="time" id="appt" formControlName="startTime">
              </div>
              <div class="date_box">
                <label for="date">End Date:</label>
                <input [ngModel]="todayDate" type="date" id="StartDate" formControlName="endDate" >
                <input [ngModel]="todayTime" type="time" id="appt" formControlName="endTime" >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply</button>
      </div>
    </div>
  </div>
