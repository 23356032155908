<div class="tab-content content" style="height: 100%;overflow-y: auto;" id="pills-tabContent1"
  *ngIf="panelTableData?.panelName">
  <div class="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab">
    <div class="accordion" id="accordionPanelsStayOpenExample" *ngIf="levelName == 'Level 2' && isInitialDataLoaded">
      <div class="accordion-item">
        <!-- <h5 class="text-primary text-center"><b>{{siteName}}</b></h5> -->
        <div class="monitoring_hou">
          <div>
            <div>
              <u class="fw-bold monitoringColor">Site Name: </u>
              <span class="monitoringColor"><b>{{siteName}}</b></span>
            </div>
            <u class="fw-bold monitoringColor">Monitoring Hours: </u>
            <div>{{siteInfoDetails?.monitoringHours=='NULL'? '--': siteInfoDetails?.monitoringHours}}</div>
          </div>
          <div>
            <u class="fw-bold monitoringColor">Additional Monitoring Hours:</u>
            <div>{{siteInfoDetails?.additionalMonitoringHours=='NULL'? '--':
              siteInfoDetails?.additionalMonitoringHours}}</div>
          </div>
        </div>
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#recentEsclations" aria-expanded="true">
          Recent Escalation(s)

          <!-- PanelName: {{panelTableData?.panelName}} -->
        </button>
        <!-- Recent Escalations of potentialId - {{panelTableData?.panelObj?.potentialId}} -->

        <ng-template #noDataMessage>
          <p class="errorMessageTemplate">No records found.</p>
        </ng-template>

        <div id="recentEsclations" class="accordion-collapse collapse show" data-bs-parent="#Tabs01">
          <div class="d-flex hours">
            <select aria-label="Filter by level" class="form-select form-select-sm w-auto d-none"
              [(ngModel)]="selectLevel">
              <option value="L2L3" selected>L2 & L3</option>
              <option value="L1">L1</option>
              <option value="L2">L2</option>
              <option value="L3">L3</option>
            </select>
            <select aria-label="Filter by level" class="form-control form-select form-select-sm w-auto btn-sm timezone"
              (change)="selectTimeZone($event)">
              <option value="CT" selected>CT</option>
              <option [value]="siteInfoDetails?.timeZone">Customer Timezone ({{siteInfoDetails?.timeZone}})</option>
              <option value="IST">IST</option>
            </select>
            <!-- <button type="button" class="btn btn-outline-success btn btn-sm" (click)="pastEsclations('4hours')">Last 4 Hours</button> -->
            <button type="button" class="btn btn-outline-success btn btn-sm" (click)="pastEsclations('24hrs')">1
              Day</button>
            <button type="button" class="btn btn-outline-success btn btn-sm" (click)="pastEsclations('7Days')">7
              Days</button>
            <!-- <button type="button" class="btn btn-outline-success viewAll btn btn-sm" type="button"  aria-expanded="false"  data-bs-toggle="modal"
              data-bs-target="#filterModal">View All
            </button> -->
          </div>
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Broadcast
                </h5> -->
                <table class="table table-responsive tableEsc">
                  <thead>
                    <tr class="accordion-item text-primary-00">
                      <th class="p-1 fw-semibold border-0 align-baseline">Escalation ID</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Unit Id</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Camera Name</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Event Time ({{timeZoneSelect}})</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">User</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Notes</th>
                      <!-- <th class="p-1 fw-semibold border-0 align-baseline">Previous Tag</th> -->
                      <th class="p-1 fw-semibold border-0 align-baseline">Action</th>
                      <!-- <th class="p-1 fw-semibold border-0 align-baseline">Previous Level</th> -->
                      <!-- <th class="p-1 fw-semibold border-0 align-baseline">Action</th>
                      <th class="p-1 fw-semibold border-0 align-baseline" style="text-align: right;"><i class="fa fa-gear"></i></th> -->
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">More</h6>
                        <!-- <i class="fa fa-gear" data-bs-toggle="modal"
                          data-bs-target="#filterModal"></i> -->
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr *ngIf="recentEscList?.length > 0; else noDataMessage" class="showErrorMessage"></tr> -->
                    <tr *ngIf="recentEscList?.length==0 && !isFetchingRecentEsc" class="showErrorMessage">
                      <td colspan="8">
                        No records found {{recentEscFetchMsg}}
                      </td>
                    </tr>
                    <tr *ngIf="isFetchingRecentEsc" class="showErrorMessage">
                      <!-- <span class="errorMessageTemplate">Loading...</span> -->
                      <div class="spinner-container">
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden loader"></span>
                        </div>
                        <span style="margin-left: 5px;">Loading...</span>
                      </div>
                    </tr>

                    <tr class="accordion-item text-primary-00" *ngFor="let item of recentEscList"
                      [ngClass]="{'make-gold': item?.currentLevelId == 'L3'}">
                      <td class="p-2">{{item?.eventid}}</td>
                      <td class="p-2 ">{{item?.unitId || '-'}}</td>
                      <td class="p-2">{{item?.cameraName}}</td>
                      <!-- <td class="p-2 ">{{item?.eventTimeStampStr}}</td> -->
                      <td>{{ getFormattedTimestamp(item) }}</td>
                      <!-- <td class="p-2 ">{{item?.previousNotes}}</td> -->
                      <td class="p-2 ">{{item?.userId || '-'}}</td>
                      <td class="p-2 ">{{item?.actionNote || '-'}}</td>
                      <td class="p-2 ">{{item?.action || '-'}}</td>
                      <!-- <td class="p-2 ">{{item?.previousLevel}}</td> -->
                      <td class="p-2" style="width: 100px;">
                        <i class="fa fa-play" style="cursor: pointer;" aria-hidden="true" (click)="viewEvent(item)"></i>
                        <i class="fa fa-video-camera" style="margin-left: 10px;cursor: pointer;" aria-hidden="true"
                          (click)="getIvigilURL(item)"></i>
                        <i class="fa fa-copy" style="margin-left: 10px; margin-right: 10px; cursor: pointer;"
                          aria-hidden="true" (click)="copyToClip(item)"></i>
                        <img src="assets/images/icons/info-circle.svg" style="cursor: pointer;" class="circle_svg"
                          data-bs-toggle="modal" data-bs-target="#eventUserInfo" (click)="recentEscMoreDetails(item)">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#sitemonitorprotocols" aria-expanded="true">SMP Notes</button>
        <div id="sitemonitorprotocols" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-0">
            <div class="card1 smp">
              <div class="w-100">
                <!-- <div class="d-flex mb-3" style="justify-content: space-between;">
                  <h6 class="title">Site monitoring protocols</h6>
                  <button type="button" class="btn btn-outline-success">View All</button>
                  <button type="button" class="btn btn-outline-success">Broadcast Message </button>
                  <button type="button" class="btn btn-outline-success">Add Sticky Note</button>
  
                </div> -->

                <!-- <button class="btn"></button> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">Industry</td>
                      <td class="data">: {{siteInfo?.industry}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Industry Group</td>
                      <td>: {{siteInfo?.industryGroup}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Fenced and Gated</td>
                      <td>: {{siteInfo?.isSiteFenced}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Other Notes</td>
                      <td>: <div [innerHTML]="iftttNotes"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>



              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#broadcastmessages" aria-expanded="true">IFTTT Table </button>
        <div id="broadcastmessages" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <!-- <h5 class="title">IFTTT</h5> -->
                </div>
                <table width="100%" class="table  bgnew cell_color">

                  <thead>
                    <tr class="accordion-item">
                      <th class="p-2 fw-semibold border-0">ACTIVITY</th>
                      <th class="p-2 fw-semibold border-0">ACTION</th>

                    </tr>
                  </thead>

                  <tbody *ngIf="ifttt.length>0">
                    <!-- <tr *ngFor="let item of newDataFttt[0]" style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                      <td>
                        {{item.actionDesc}}       
                      </td> 
                      <td *ngFor="let item of ifttt.events"  style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                        {{item.eventDesc}}         
                      </td> 
                    </tr> -->
                    <!-- <tr><td>{{finalFttt[0].eventDesc}}</td></tr> -->
                    <tr *ngFor="let event of ifttt; let i = index" class="accordion-item">
                      <td style="width:min-content !important">{{event?.eventDesc}}</td>
                      <td style="width:max-content !important">{{event?.recAction}}</td>
                    </tr>
                    <td style="padding-right: 5px;" align="left" *ngIf="modifiedDate_tz">
                      <i class="fa fa-refresh" aria-hidden="true" style="margin-right: 8px;margin-left: 5px;"></i>
                      <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;{{modifiedDate_tz}}
                    </td>

                  </tbody>

                </table>
              </div>

            </div>
          </div>
        </div>
      </div>

      

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#guard" aria-expanded="true">Guard</button>
        <div id="guard" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Guard
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Number</td>
                      <td class="d-flex p-1 fw-semibold border-0  align-baseline"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">Details</h6>
                        <!-- <i class="fa fa-gear"></i> -->
                      </td>
                    </tr>
                    <tr *ngIf="guard?.length > 0; else noDataMessage" class="showErrorMessage"></tr>

                    <tr *ngFor="let item of guard " class="accordion-item">
                      <td>{{item?.name}}</td>
                      <td>{{item?.contact}}</td>
                      <td>{{item?.details}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#crew" aria-expanded="true">Cleaning Crew & Guard</button>
        <div id="crew" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Cleaning Crew
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Number</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Type</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">Details</h6>
                        <!-- <i class="fa fa-gear"></i> -->
                      </td>
                    </tr>
                    <tr *ngIf="crewAndGuards?.length > 0; else noDataMessage" class="showErrorMessage"></tr>

                    <tr *ngFor="let item of crewAndGuards " class="accordion-item">
                      <td>{{item?.name}}</td>
                      <td>{{item?.contact}}</td>
                      <td>{{item?.type}}</td>
                      <td>{{item?.details}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#adhocNotes" aria-expanded="true">Adhoc Notes</button>
        <div id="adhocNotes" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-0">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Adhoc Monitoring Notes:
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Start Time</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">End Time</h6>
                        <!-- <i class="fa fa-gear" data-bs-toggle="modal" data-bs-target="#filterModal"></i> -->
                      </td>
                    </tr>
                    <tr *ngIf="adhoc?.length > 0; else noDataMessage" class="showErrorMessage"></tr>

                    <tr class="accordion-item" *ngFor="let item of adhoc">
                      <td>{{item?.message}}</td>
                      <td>{{item?.eventDate}}</td>
                      <td>{{item?.expiryDate}}</td>
                      <!-- <td>{{item.udid}}</td>   -->
                    </tr>
                  </tbody>
                </table>

              </div>
              <!-- Button trigger modal -->
              <!-- <button type="button" class="btn btn-primary" >
  Launch demo modal
</button> -->

            </div>

          </div>
        </div>
      </div>

            <!---Adding Bolo Message Accordian here -->

            <div class="accordion-item">
              <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
                data-bs-target="#boloNotes" aria-expanded="true"> <span
                  [ngClass]="{'highlight-border': boloNotes && boloNotes.length > 0}">Bolo Message(s)</span></button>
              <div id="boloNotes" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
                <div class="accordion-body p-0">
                  <div class="card1">
                    <div class="w-100">
                      <table class="table  bgnew cell_color">
                        <tbody>
                          <tr class="accordion-item">
                            <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                            <td class="p-1 fw-semibold border-0 align-baseline">Start Time</td>
                            <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                              style="justify-content: space-between;">
                              <h6 class="fw-semibold">End Time</h6>
                            </td>
                          </tr>
                          <tr *ngIf="boloNotes?.length > 0; else noDataMessage" class="showErrorMessage"></tr>
      
                          <tr class="accordion-item" *ngFor="let item of boloNotes">
                            <td>{{item?.message}}</td>
                            <!-- <td>{{item?.eventDate}}</td>
                            <td>{{item?.expiryDate}}</td> -->
                            <td *ngIf="item?.type === 'Bolo'">
                              {{ item?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} ({{ siteTimeZone }})
                            </td>
                            <td *ngIf="item?.type !== 'Bolo'">
                              {{ item?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} (CT)
                            </td>
                            <td *ngIf="item?.type === 'Bolo'">{{item?.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} ({{
                              siteTimeZone }})</td>
                            <td *ngIf="item?.type !== 'Bolo'">{{item?.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} (CT)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
      
                </div>
              </div>
            </div>
            <!---Endig Bolo Message Accordian here -->

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#broadcast" aria-expanded="true">
          Broadcast Messages
        </button>

        <div id="broadcast" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <form [formGroup]="broadCastform" (ngSubmit)="updateBroadCast()">

                  <div>
                    <label for="name">
                      Name
                    </label>
                    <input id="name" type="text" formControlName="name">
                  </div>
                
                  <div>
                    <label for="address">
                      Address
                    </label>
                    <input id="address" type="text" formControlName="address">
                  </div>
                
                  <button class="button" type="submit">Purchase</button>
                
                </form> -->
                <!-- <h5 class="title">Broadcast
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Start Time</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">End Time</h6>
                        <!-- <i class="fa fa-plus"
                          (click)="broadcastExpand =!broadcastExpand" style="cursor: pointer;"></i> -->
                      </td>
                    </tr>
                    <tr *ngIf="broadCastList && broadCastList.length > 0; else noDataMessage" class="showErrorMessage">
                    </tr>

                    <tr class="accordion-item" *ngFor="let item of broadCastList">
                      <td>{{item.message}}</td>
                      <td>{{item.eventDate | date: 'YYYY-MM-dd HH:MM:SS'}}</td>
                      <td>{{item.expiryDate | date: 'YYYY-MM-dd HH:MM:SS'}}</td>
                      <!-- <td>{{item.udid}}</td>   -->
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div *ngIf="!broadcastExpand">
              <form [formGroup]="broadCastform">
                <div class="d-flex">
                  <div class="textArea" style="width: 50%;">
                    <h5>Please Leave your message</h5>
                    <div>
                      <textarea id="b_message" formControlName="message" name="b_message" rows="4" cols="50"
                        style="height: 150px; width: 100%;"></textarea>
                    </div>

                  </div>
                  <div style="padding:5px;width: 50%;font-size: 14px;">
                    <h5><a href="javascript:void(0);">Choose date range</a></h5>

                    <div>
                      <div class="date_box m-0">
                        <label for="date"> Start Date:</label>
                        <input type="date" id="b_startDate" formControlName="startDate">
                        <input type="time" id="b_startTime" formControlName="startTime">
                      </div>
                      <div class="date_box">
                        <label for="date">End Date:</label>
                        <input type="date" id="b_endDate" formControlName="endDate">
                        <input type="time" id="b_endTime" formControlName="endTime">
                      </div>
                    </div>
                    <div>
                      <!-- <div class="date_box">
                       
            
                      </div>
                      <div class="date_box">
                        <label for="appt">To</label>
                  
                      </div> -->
                    </div>
                    <hr>
                    <div class="d-flex submit_button">
                      <button type="button" class="btn btn-danger"
                        (click)="broadcastExpand = !broadcastExpand">Cancel</button>
                      <button type="button" class="btn btn-success" [disabled]="broadCastform.status === 'INVALID'"
                        (click)="updateBroadCast()">Submit</button>
                    </div>
                  </div>
                </div>
                <!-- <hr>
                <div class="d-flex p-3 submit_button">
                  <button type="button" class="btn btn-danger" (click)="broadcastExpand = !broadcastExpand">Cancel</button>
            
                  <button type="button" class="btn btn-success" (click)="updateBroadCast()">Submit</button>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#Contact" aria-expanded="true">
          Contact Information
        </button>

        <div id="Contact" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Contact Info
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tr class="accordion-item">
                    <th class="p-1 fw-semibold border-0 align-baseline">Contact</th>
                    <th class="p-1 fw-semibold border-0 align-baseline">Name</th>
                    <th class="p-1 fw-semibold border-0 align-baseline">Number</th>
                    <th class="p-1 fw-semibold border-0 align-baseline">Email</th>

                  </tr>
                  <tr *ngIf="siteInfo?.contactInfoList?.length > 0; else noDataMessage" class="showErrorMessage"
                    colspan="4"></tr>

                  <tr class="accordion-item" *ngFor="let item of siteInfo?.contactInfoList">
                    <td>{{item.contactType}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.contactNo}}</td>
                    <td>{{item.email}}</td>
                  </tr>
                </table>
              </div>


            </div>
          </div>
        </div>
      </div>


      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#Address" aria-expanded="true">
          Address Details
        </button>

        <div id="Address" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Address Details:
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">Address</td>
                      <td class="data">: {{siteInfo?.addressDetails?.address}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">City</td>
                      <td>: {{siteInfo?.addressDetails?.city}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">State/Zip</td>
                      <td>: {{siteInfo?.addressDetails?.stateZip}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Local Police</td>
                      <td>: {{siteInfo?.addressDetails?.localPolice}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#Miscellaneous" aria-expanded="true">
          Miscellaneous Details
        </button>

        <div id="Miscellaneous" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Misc Details:
                </h5> -->
                <table class="table">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">Activity Email</td>
                      <td class="data">: {{siteInfo?.miscDetails?.activityEmail.trim()}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Guard Service</td>
                      <td>: {{siteInfo?.miscDetails?.guardService}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Gate Code</td>
                      <td>: {{siteInfo?.miscDetails?.gateCode ?? 'N/A'}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Monitoring Service</td>
                      <td>: {{siteInfo?.miscDetails?.monitoringService}}</td>

                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Monitoring Service Pin</td>
                      <td>: {{siteInfo?.miscDetails?.monitoringServicePin}}</td>

                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Full Address</td>
                      <td>: {{siteInfo?.miscDetails?.fullAddress}}</td>

                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Cross street</td>
                      <td>: {{siteInfo?.miscDetails?.crossStreet}}</td>

                    </tr>

                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Cross street2</td>
                      <td>: {{siteInfo?.miscDetails?.crossStreet2}}</td>

                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Police dept name</td>
                      <td>: {{siteInfo?.miscDetails?.policeDeptName}}</td>

                    </tr>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Police dept permit#</td>
                      <td>: {{siteInfo?.miscDetails?.policeDeptPermit ?? 'N/A'}}</td>

                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#SafeWord" aria-expanded="true">
          SafeWord/Pin
        </button>

        <div id="SafeWord" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Broadcast
                </h5> -->
                <table class="table">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">SafeWord</td>
                      <!-- <td class="data">: {{siteInfo?.safeWords?.safeWords ?? 'N/A'}}</td> -->
                      <td class="data" style="white-space: pre-line;">: {{!siteInfoDetails?.safeWords?.safeWords ? '--'
                        :
                        siteInfoDetails?.safeWords?.safeWords.split(',').join(', ')}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">Do Not DisarmPin</td>
                      <!-- <td class="data">: {{siteInfo?.safeWords?.doNotDisArmPin}}</td> -->
                      <td class="data">: {{siteInfoDetails?.safeWords?.doNotDisArmPin}}</td>
                    </tr>
                    <tr class="accordion-item">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">ArmDisarmPin</td>
                      <!-- <td class="data">: {{siteInfo?.safeWords?.armDisArmPin}}</td> -->
                      <td class="data">: {{siteInfoDetails?.safeWords?.armDisArmPin}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#Activities" aria-expanded="true">
          Recent Activation(s)
        </button>

        <div id="Activities" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <!-- <h5 class="title">
                    Recent Activation:</h5>
                  <button type="button" class="btn btn-outline-success">View All</button> -->
                </div>
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <th class="p-1 fw-semibold border-0 align-baseline">Time</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">User Name</th>
                      <!-- <th class="p-1 fw-semibold border-0 align-baseline">Type</th> -->
                      <th class="p-1 fw-semibold border-0 align-baseline">Description</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Status</th>
                    </tr>
                    <tr *ngIf="recentActivations?.length > 0; else noDataMessage" class="showErrorMessage"></tr>

                    <tr class="accordion-item" *ngFor="let item of recentActivations">
                      <td>{{item?.time}}</td>
                      <td>{{item?.user}}</td>
                      <!-- <td>ALARM</td> -->
                      <td>{{item?.description}}</td>
                      <td>{{item?.status}}</td>
                    </tr>
                    <!-- <tr class="accordion-item">
                      <td>2023-05-18 0:27:35</td>
                      <td>4649.pavan</td>
                      <td>ALARM</td>
                      <td>Trigger On</td>
                      <td>success</td>
                    </tr>   -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#armdisarmhistory" aria-expanded="true">Arm/Disarm History</button>
        <div id="armdisarmhistory" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Guard
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Site Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Type</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Status</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>

                    </tr>
                    <tr *ngIf="armDisarmHistory?.length > 0; else noDataMessage" class="showErrorMessage"></tr>

                    <tr class="accordion-item" *ngFor="let item of armDisarmHistory ">
                      <td>{{item?.siteName}}</td>
                      <td>{{item?.eventType}}</td>
                      <td>{{item?.status}}</td>
                      <td>{{item?.message}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#psahistory" aria-expanded="true">Current and Future PSA's:</button>
        <div id="psahistory" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Guard
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Messsage</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Start Date</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">End Date</td>
                      <!-- <td class="p-1 fw-semibold border-0 align-baseline">End Time</td> -->
                    </tr>
                    <tr class="accordion-item" *ngFor="let item of PSAListData ">
                      <!-- <td>{{item?.accountname}}</td> -->
                      <td>{{item?.subject}}</td>
                      <td>{{item?.startDt}}</td>
                      <td>{{item?.endDt}}</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="showNoDataMessage">
                      <!-- <span class="noData">Nothing found to display</span> -->
                      <td colspan="4" class="error" class="noData">Nothing found to display.</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="hasApiError">
                      <td colspan="4" class="error">An error occurred while fetching data. Please try again later.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--  -->
    </div>
    <ng-template #noDataMessage>
      <p class="errorMessageTemplate">No records found.</p>
    </ng-template>
    <!-- <h3 style="text-align: center;">Site Name</h3> -->
    <div class="accordion" id="accordionPanelsStayOpenExample" *ngIf="levelName == 'Level 3' && isInitialDataLoaded">

     

      <!-- For Commeting based abdullah points  -->
      <!-- <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#adhocNotes" aria-expanded="true">Adhoc Notes</button>
        <div id="adhocNotes" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-0">
            <div class="card1">
              <div class="w-100">
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Start Time</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">End Time</h6>
                        <i class="fa fa-plus"
                        (click)="adhocPanelExpand()" style="cursor: pointer;"></i>
                         </td>
                    </tr>
                    <tr *ngIf="adhoc?.length > 0; else noDataMessage" class="showErrorMessage"></tr>
                    <ng-container *ngIf="AdhocExpand">
                      <tr class="accordion-item" *ngFor="let item of adhoc">
                        <td>{{item?.message}}</td>
                        <td>{{item?.eventDate | date: 'MM-dd-YYYY HH:MM:SS'}}</td>
                        <td>{{item?.expiryDate | date: 'MM-dd-YYYY HH:MM:SS'}}</td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="!AdhocExpand">
                      <td colspan="3">
                        <form [formGroup]="AdhocForm">
                          <div class="d-flex">
                            <div class="textArea" style="width: 50%;">
                              <h5>Please Leave your notes</h5>
                              <div>
                                <textarea id="b_message" formControlName="message" name="b_message" rows="4" cols="50"
                                  style="height: 150px; width: 100%;" [(ngModel)]="text"  (keydown)="onKeyDown($event)"></textarea>
                                  <span class="counter">{{getRemainingCount()}} Character(s) Remaining</span>
                              </div>
          
                            </div>
                            <div style="padding:5px;width: 50%;font-size: 14px;">
                              <h5><a href="javascript:void(0);">Choose date range</a></h5>
          
                              <div>
                                <div class="date_box m-0">
                                  <label for="date"> Start Date:</label>
                                  <input type="date" id="b_startDate" formControlName="startDate">
                                  <input type="time" id="b_startTime" formControlName="startTime">
                                </div>
                                <div class="date_box">
                                  <label for="date">End Date:</label>
                                  <input type="date" id="b_endDate" formControlName="endDate">
                                  <input type="time" id="b_endTime" formControlName="endTime">
                                </div>
                              </div>
                              <div>
                              </div>
                              <hr>
                              <div class="d-flex submit_button">
                                <button type="button" class="btn btn-danger"
                                  (click)="AdhocExpand = !AdhocExpand">Cancel</button>
                                <button type="button" class="btn btn-success" 
                                [disabled]="AdhocForm.status === 'INVALID'" 
                                (click)="UpdateAdhocForm()">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </td>
                 
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- For Commeting based abdullah points  -->
      <h5 class="text-primary text-center"><b>{{siteName}}</b></h5>
      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#sitemonitorprotocols" aria-expanded="true">SMP Notes</button>
        <div id="sitemonitorprotocols" class="accordion-collapse show" data-bs-parent="#Tabs01">
          <div class="accordion-body p-0">
            <div class="card1 smp">
              <div class="w-100">
                <!-- <div class="d-flex mb-3" style="justify-content: space-between;">
                  <h6 class="title">Site monitoring protocols</h6>
                  <button type="button" class="btn btn-outline-success">View All</button>
                  <button type="button" class="btn btn-outline-success">Broadcast Message </button>
                  <button type="button" class="btn btn-outline-success">Add Sticky Note</button>
                </div> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item" *ngIf="levelName == 'Level 2'">
                      <td class="heading p-1 fw-semibold border-0 align-baseline">Industry</td>
                      <td class="data">: {{siteInfo?.industry}}</td>
                    </tr>
                    <tr class="accordion-item" *ngIf="levelName == 'Level 2'">
                      <td class="p-1 fw-semibold border-0 align-baseline">Permit Number</td>
                      <td>: </td>
                    </tr>
                    <tr class="accordion-item" *ngIf="levelName == 'Level 2'">
                      <td class="p-1 fw-semibold border-0 align-baseline">Industry Group</td>
                      <td>: {{siteInfo?.industryGroup}}</td>
                    </tr>
                    <tr class="accordion-item" *ngIf="false">
                      <td class="p-1 fw-semibold border-0 align-baseline">Police Gate Code</td>
                      <td>: {{siteInfo?.miscDetails?.gateCode}}</td>
                    </tr>
                    <!-- <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Fenced and Gated</td>
                      <td>: {{siteInfo?.isSiteFenced}}</td>
                    </tr> -->
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Other Notes</td>
                      <td>: <div [innerHTML]="iftttNotes"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="background: #b6d4e4;" *ngIf="modifiedDate_tz">
                  <i class="fa fa-refresh" aria-hidden="true" style="margin-right: 8px;margin-left: 5px;"></i>
                  <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;{{modifiedDate_tz}}
                </div>

                <!-- <button class="btn"></button> -->
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#broadcastmessages" aria-expanded="true">IFTTT Table </button>
        <div id="broadcastmessages" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <div class="d-flex" style="justify-content: space-between;">
                  <!-- <h5 class="title">IFTTT</h5> -->
                </div>
                <table width="100%" class="table  bgnew cell_color">

                  <thead>
                    <tr class="accordion-item">
                      <th class="p-1 fw-semibold border-0 align-baseline">ACTIVITY</th>
                      <th class="p-1 fw-semibold border-0 align-baseline">Action</th>

                    </tr>
                  </thead>

                  <tbody>
                    <!-- <tr *ngFor="let item of newDataFttt[0]" style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                      <td>
                        {{item.actionDesc}}       
                      </td> 
                      <td *ngFor="let item of ifttt.events"  style="text-align:left;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;">
                        {{item.eventDesc}}         
                      </td> 
                    </tr> -->
                    <!-- <tr><td>{{finalFttt[0].eventDesc}}</td></tr> -->
                    <!-- <tr class="accordion-item" *ngFor="let event of newObject.events; let i = index">
                      <td style="width:min-content !important">{{event}}</td>
                      <td style="width:max-content !important">{{newObject?.action[i] ? newObject.action[i] : ''}}</td>
                    </tr>
                    <td style="padding-right: 5px;" align="left" *ngIf="modifiedDate_tz">
                      <i class="fa fa-refresh" aria-hidden="true" style="margin-right: 8px;margin-left: 5px;"></i>
                      <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;{{modifiedDate_tz}}
                    </td> -->
                    <tr *ngFor="let event of ifttt; let i = index" class="accordion-item">
                      <td style="width:min-content !important">{{event?.eventDesc}}</td>
                      <td style="width:max-content !important">{{event?.recAction}}</td>
                    </tr>
                    <td style="padding-right: 5px;" align="left" *ngIf="modifiedDate_tz">
                      <i class="fa fa-refresh" aria-hidden="true" style="margin-right: 8px;margin-left: 5px;"></i>
                      <b>Last updated on<span style="margin-left:7px;"></span>:</b>&nbsp;&nbsp;{{modifiedDate_tz}}
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#broadcast" aria-expanded="true" *ngIf="siteTimeCustomer">
          Site Notes
        </button>

        <div id="broadcast" class="accordion-collapse collapse show" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Broadcast
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Type</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Start Time</td>
                      <td class="p-1 fw-semibold border-0 align-baseline"
                        style="justify-content: space-between;display: flex;">
                        <h6 class="fw-semibold">End Time</h6>
                        <i class="fa fa-plus" (click)="broadcastPanel()" style="cursor: pointer;"></i>
                      </td>
                    </tr>
                    <tr *ngIf="allMessages.length > 0; else noDataMessage" class="showErrorMessage"></tr>
                    <ng-container *ngIf="broadcastExpand">
                      <tr class="accordion-item" *ngFor="let item of allMessages"
                        [ngClass]="{'highlight-row': item.type === 'Bolo'}">
                        <td style="width: 50%;">{{item?.message}}</td>
                        <td>{{ item?.type }}</td>
                        <td *ngIf="item?.type === 'Bolo'">
                          {{ item?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} ({{ showSiteTimeZone }})
                        </td>
                        <td *ngIf="item?.type !== 'Bolo'">
                          {{ item?.eventDate | date: 'YYYY-MM-dd HH:mm:ss' }} (CT)
                        </td>
                        <td *ngIf="item?.type === 'Bolo'">{{item?.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} ({{
                          showSiteTimeZone }})</td>
                        <td *ngIf="item?.type !== 'Bolo'">{{item?.expiryDate | date: 'YYYY-MM-dd HH:mm:ss'}} (CT)</td>
                        <!-- <td>{{item.udid}}</td>   -->
                      </tr>
                    </ng-container>
                    <tr *ngIf="!broadcastExpand">
                      <td colspan="5">
                        <div class="form-popup">
                          <form [formGroup]="broadCastform">
                            <div class="d-flex">
                              <div class="textArea" style="width: 50%;">
                                <h5>Please Leave your message</h5>
                                <div>
                                  <textarea id="b_message" formControlName="message" name="b_message" rows="4" cols="50"
                                    style="height: 150px; width: 100%;" [(ngModel)]="text_Broadcost"
                                    class="form-control" (keydown)="onKeyDown($event,text_Broadcost)"
                                    [ngClass]="{ 'is-invalid': submitted && form['message'].errors}"></textarea>
                                  <span class="counter">{{getRemainingCount(text_Broadcost)}} Character(s)
                                    Remaining</span>
                                </div>
                                <div class="error_Message"
                                  *ngIf="broadCastform.get('message').invalid && (broadCastform.get('message').dirty || broadCastform.get('message').touched)">
                                  <span class="text-danger">Please provide valid notes/comments</span>
                                </div>
                              </div>
                              <div style="padding:5px;width: 50%;font-size: 14px;">
                                <h5><a href="javascript:void(0);">Choose date range</a></h5>
                                <div>
                                  <div class="dateBox">
                                    <label for="date">Start Date:</label>
                                    <input type="date" id="b_startDate" (change)="changeDate()"
                                      [min]="validatedFromdDate" class="form-control inputStyle"
                                      formControlName="startDate"
                                      [ngClass]="{ 'is-invalid': submitted && form['startDate'].errors}">
                                    <input type="time" id="b_startTime" formControlName="startTime"
                                      class="form-control inputStyle"
                                      [ngClass]="{ 'is-invalid': submitted && form['startTime'].errors}">
                                  </div>
                                  <div class="error_Message"
                                    *ngIf="( broadCastform.get('startDate').invalid || broadCastform.get('startTime').invalid ) &&
                                  ( ( broadCastform.get('startDate').dirty || broadCastform.get('startTime').dirty ) || ( broadCastform.get('startDate').touched || broadCastform.get('startTime').touched ) ) ">
                                    <span class="text-danger">Please provide valid date & time</span>
                                  </div>
                                  <div class="dateBox">
                                    <label for="date">End Date:</label>
                                    <input type="date" id="b_endDate" [min]="validatedFromdDate"
                                      [max]="validatedTodDate" formControlName="endDate" class="form-control inputStyle"
                                      [ngClass]="{ 'is-invalid': submitted && form['endDate'].errors}">
                                    <input type="time" id="b_endTime" formControlName="endTime"
                                      class="form-control inputStyle"
                                      [ngClass]="{ 'is-invalid': submitted && form['endTime'].errors}">
                                  </div>
                                  <div class="error_Message"
                                    *ngIf="( broadCastform.get('endDate').invalid || broadCastform.get('endTime').invalid ) &&
                                  ( ( broadCastform.get('endDate').dirty || broadCastform.get('endTime').dirty ) || ( broadCastform.get('endDate').touched || broadCastform.get('endTime').touched ) ) ">
                                    <span class="text-danger">Please provide valid date & time</span>
                                  </div>
                                </div>
                                <div class="radio-buttons">
                                  <!---Merging Adhoc and BroadCOst-->
                                  <span>Type :</span>
                                  <label>
                                    <input type="radio" formControlName="broadcastType" value="Adhoc"
                                      (change)="onRadioChange('Adhoc')"
                                      [checked]="broadCastform.get('broadcastType').value === 'Adhoc'"
                                      [ngClass]="{ 'is-invalid': submitted && form['broadcastType'].errors }"> Adhoc
                                  </label>
                                  <label>
                                    <input type="radio" formControlName="broadcastType" value="Broadcast"
                                      (change)="onRadioChange('Broadcast')"
                                      [checked]="broadCastform.get('broadcastType').value === 'Broadcast'"
                                      [ngClass]="{ 'is-invalid': submitted && form['broadcastType'].errors }"> Broadcast
                                  </label>
                                  <label>
                                    <input type="radio" formControlName="broadcastType" value="bolo"
                                      (change)="onRadioChange('bolo')"
                                      [checked]="broadCastform.get('broadcastType').value === 'bolo'"
                                      [ngClass]="{ 'is-invalid': submitted && form['broadcastType'].errors }"> Bolo
                                  </label>
                                </div>
                                <div class="error_Message"
                                  *ngIf="broadCastform.get('broadcastType').invalid && (broadCastform.get('broadcastType').dirty || broadCastform.get('broadcastType').touched)">
                                  <span class="text-danger">Please select type</span>
                                </div>
                                <hr>
                                <div class="d-flex submit_button">
                                  <button type="button" class="btn btn-danger"
                                    (click)="closeBroadcast()">Cancel</button>
                                  <button type="submit" class="btn btn-success"
                                    (click)="updateBroadCast()">Submit</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#crew" aria-expanded="true">Cleaning Crew & Guard</button>
        <div id="crew" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Number</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Type</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">Notes/Comments</h6>
                        <i class="fa fa-plus" (click)="cleaningCrewExpandPanel()" style="cursor: pointer;"></i>
                      </td>
                    </tr>

                    <ng-container *ngIf="cleaningCrewExpand">
                      <tr class="accordion-item" *ngFor="let item of crewAndGuards">
                        <td>{{item.name}}</td>
                        <td>{{item.contact}}</td>
                        <td>{{item.type}}</td>
                        <td>{{item.details}}</td>
                      </tr>

                      <tr style="height:20px;" *ngIf="crewAndGuards?.length==0">
                        <span class="noData">Nothing found to display</span>
                      </tr>

                    </ng-container>
                    <tr *ngIf="!cleaningCrewExpand">
                      <td colspan="3">
                        <div class="form-popup">
                          <form [formGroup]="cleaningCrewform" class="d-flex flex-column">
                            <div class="form-row" style="display: flex;">
                              <div class="form-group col-md-6">
                                <h5>Please leave your notes/comments</h5>
                                <textarea id="b_message" formControlName="details" name="b_message" rows="3" cols="50"
                                  style="height: 150px; width: 100%;" class="form-control" [(ngModel)]="text_Crew"
                                  [ngClass]="{ 'is-invalid': cleaningSubmitted && cleaningForm['details'].errors}"
                                  (keydown)="onKeyDown($event,text_Crew)"></textarea>
                                <div class="error_Message">
                                  <span class="counter">{{ getRemainingCount(text_Crew) }} Character(s) Remaining</span>
                                  <span class="second-line">{{ isFormInvalid }}</span>
                                </div>
                                <div class="error_Message"
                                  *ngIf="cleaningCrewform.get('details').invalid && (cleaningCrewform.get('details').dirty || cleaningCrewform.get('details').touched)">
                                  <span class="text-danger">Please provide valid notes/comments</span>
                                </div>
                              </div>
                              <div class="form-group col-md-6" style="margin-left: 10px;
                              margin-right: 10px;
                              width: 300px;">
                                <div class="d-flex flex-column">
                                  <div>
                                    <h5>Name</h5>
                                    <input type="text" formControlName="name" class="form-control"
                                      style="font-size: 14px;"
                                      [ngClass]="{ 'is-invalid': cleaningSubmitted && cleaningForm['name'].errors}">
                                  </div>
                                  <div class="error_Message"
                                    *ngIf="cleaningCrewform.get('name').invalid && (cleaningCrewform.get('name').dirty || cleaningCrewform.get('name').touched)">
                                    <span class="text-danger">Please enter name</span>
                                  </div>
                                  <div style="margin-top: 20px;">
                                    <h5>Number</h5>
                                    <input type="text" formControlName="contact" class="form-control no-autofill-icon"
                                      [ngClass]="{ 'is-invalid': cleaningSubmitted && cleaningForm['contact'].errors}"
                                      style="font-size: 14px;">
                                  </div>
                                  <div class="error_Message"
                                    *ngIf="cleaningCrewform.get('contact').invalid && (cleaningCrewform.get('contact').dirty || cleaningCrewform.get('contact').touched)">
                                    <span class="text-danger">Please enter contact</span>
                                  </div>

                                  <div class="radio-buttons">
                                    <span>Type :</span>
                                    <label>
                                      <input type="radio" formControlName="cleaningCrewType" value="Cleaning Crew"
                                        (change)="onCrewAndGuardRadioChange('Cleaning Crew')"
                                        [checked]="cleaningCrewform.get('cleaningCrewType').value === 'Cleaning Crew'">
                                      Cleaning Crew
                                    </label>
                                    <label>
                                      <input type="radio" formControlName="cleaningCrewType" value="Guard"
                                        (change)="onCrewAndGuardRadioChange('Guard')"
                                        [checked]="cleaningCrewform.get('cleaningCrewType').value === 'Guard'"> Guard
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-end mt-auto" style="gap: 15px;">
                              <button type="button" class="btn btn-danger mr-2"
                                (click)="closeCleaningCrew()">Cancel</button>
                              <button type="button" class="btn btn-success"
                                (click)="cleaningCrewUpdate()">Submit</button>
                            </div>
                          </form>
                        </div>


                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="accordion-item" hidden>
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#guard" aria-expanded="true">Guard</button>
        <div id="guard" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Number</td>
                      <td class="p-1 fw-semibold border-0 align-baseline d-flex"
                        style="justify-content: space-between;">
                        <h6 class="fw-semibold">Notes/Comments</h6>
                        <i class="fa fa-plus" (click)="guardExpand =!guardExpand" style="cursor: pointer;"></i>
                      </td>
                    </tr>
                    <ng-container *ngIf="guardExpand">
                      <tr class="accordion-item" *ngFor="let item of guard ">
                        <td>{{item.name}}</td>
                        <td>{{item.contact}}</td>
                        <td>{{item.details}}</td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="!guardExpand">
                      <td colspan="3">
                        <div class="form-popup">
                          <form [formGroup]="guardform" class="d-flex flex-column">
                            <div class="form-row" style="display: flex;">
                              <div class="form-group col-md-6">
                                <h5>Please leave your notes/comments</h5>
                                <textarea id="b_message" formControlName="details" name="b_message" rows="3" cols="50"
                                  style="height: 150px; width: 100%;" class="form-control" [(ngModel)]="text_guard"
                                  (keydown)="onKeyDown($event,text_guard)"></textarea>
                                <div class="error_Message">
                                  <span class="counter">{{ getRemainingCount(text_guard) }} Character(s)
                                    Remaining</span>
                                  <span class="second-line">{{ isFormInvalid }}</span>
                                </div>
                              </div>
                              <div class="form-group col-md-6" style="margin-left: 10px;
                              margin-right: 10px;
                              width: 300px;">
                                <div class="d-flex flex-column">
                                  <div>
                                    <h5>Name</h5>
                                    <input type="text" formControlName="name" class="form-control"
                                      style="font-size: 14px;">
                                  </div>
                                  <div style="margin-top: 20px;">
                                    <h5>Number</h5>
                                    <input type="text" formControlName="contact" class="form-control no-autofill-icon"
                                      style="font-size: 14px;">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-end mt-auto" style="gap: 15px;">
                              <button type="button" class="btn btn-danger mr-2"
                                (click)="guardExpand = !guardExpand">Cancel</button>
                              <button type="button" class="btn btn-success" [disabled]="guardform.status === 'INVALID'"
                                (click)="guardUpdate()">Submit</button>
                            </div>
                          </form>
                        </div>


                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="accordion-item">
        <button class="accordion-button collapsed bg-primary text-white" type="button" data-bs-toggle="collapse"
          data-bs-target="#armdisarmhistory" aria-expanded="true">Arm/Disarm History</button>
        <div id="armdisarmhistory" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
          <div class="accordion-body p-1">
            <div class="card1">
              <div class="w-100">
                <!-- <h5 class="title">Guard
                </h5> -->
                <table class="table  bgnew cell_color">
                  <tbody>
                    <tr class="accordion-item">
                      <td class="p-1 fw-semibold border-0 align-baseline">Site Name</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Type</td>
                      <td class="p-1 fw-semibold border-0 align-baseline">Message</td>
                    </tr>
                    <tr class="accordion-item" *ngFor="let item of armDisarmHistory ">
                      <td>{{item?.siteName}}</td>
                      <td>{{item?.eventType}}</td>
                      <td style="width: 35%;">{{item?.message}}</td>
                    </tr>
                    <tr style="height:20px;" *ngIf="armDisarmHistory?.length==0">
                      <span class="noData">Nothing found to display</span>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
  <div class="tab-content content" style="height: auto;text-align: center;margin-top: 30%; font-size: 20px;"
    id="pills-tabContent1" *ngIf="panelTableData?.panelName && !isInitialDataLoaded">
    {{isInitialDataLoadingMsg}}
    <span
      *ngIf="isInitialDataLoadingMsg== my_permissions.tblPnlLoadingDataEmptyMsg|| isInitialDataLoadingMsg==my_permissions.tblPnlLoadingErrorMsg">
      please <a (click)="getByPotentialId()" style="color: blue;font-size: 20px;cursor: pointer;"> Click here </a> to
      reload the data.
    </span>
  </div>
  <div class="tab-pane fade" id="pills-profile1" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
</div>
<div class="tab-content content" style="height: auto;text-align: center;margin-top: 40%; font-size: 14px;"
  id="pills-tabContent1" *ngIf="!panelTableData?.panelName">
  Please click More info icon on the wall to view the data.
</div>


<!-- Modal -->
<div class="modal" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
  style="z-index: 9999999;">
  <div class="modal-dialog" style="margin-top: 40px;" [style.float]="mirror ? 'left' : 'right'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="filterModalLabel">Filter by</h5>
        <button type="button" class="btn-close" #closeFilter data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="background-color: white;">
        <form [formGroup]="filterForm">
          <div>
            <select class="form-select Primary select" aria-label="Default select example"
              style="margin-bottom: 20px;border:1px solid grey; padding:5px" formControlName="records">
              <option value="" disabled selected>Choose records</option>
              <option value="recent">Recent</option>
              <option value="viewAll">View All</option>
            </select>
            <div class="date_box m-0">
              <label for="date"> Start Date:</label>
              <input type="date" id="StartDate" formControlName="startDate">
              <input type="time" id="appt" formControlName="startTime">
            </div>
            <div class="date_box">
              <label for="date">End Date:</label>
              <input type="date" id="StartDate" formControlName="endDate">
              <input type="time" id="appt" formControlName="endTime">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateFilterForm(filterForm)">Apply</button>
      </div>
    </div>
  </div>
</div>

<!----Reversce esc details-->

<div class="modal" id="eventUserInfo" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true"
  style="z-index:9999999;">
  <div class="modal-dialog modal-xl" style="margin-top: 40px; margin-right: 4px"
    [style.float]="mirror ? 'left' : 'right'">
    <div class="modal-content" style="width: 100%;">
      <div class="modal-header" style="background-color: #0c5b82;color: white;height:40px ">
        <h5 class="modal-title" id="filterModalLabel">Escalation Details - {{siteName}}, {{escId}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"
          style="background: none;border: none;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: white;padding: 4px;">
        <div class="radio-buttonGroup">
          <div class="timezonebyEsc" style="border: 1px solid #ccc; padding: 10px;">
            <h4>Escalation Report By Timezone</h4>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="timezone" id="ctRadio" value="CT"
                (click)="setTimezone('CT')" [checked]="selectedTimezone === 'CT'">
              <label class="form-check-label" for="ctRadio">CT</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="timezone" id="ptRadio" value="PT"
                (click)="setTimezone('PT')" [checked]="selectedTimezone === 'PT'">
              <label class="form-check-label" for="ptRadio">Customer Timezone ({{siteInfoDetails?.timeZone}})</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="timezone" id="istRadio" value="IST"
                (click)="setTimezone('IST')" [checked]="selectedTimezone === 'IST'">
              <label class="form-check-label" for="istRadio">IST</label>
            </div>
          </div>
        </div>
        <table class="table table-responsive" *ngIf="eventInfo">
          <thead style="border-bottom: 1px solid white;">
            <tr>
              <th class="p-1 fw-semibold border-0 align-baseline">User</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Level Name</th>
              <th class="p-1 fw-semibold border-0 align-baseline">User Hold time</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Received Time</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Review Start Time</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Review End Time</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Review Duration</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Tag</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Action</th>
              <th class="p-1 fw-semibold border-0 align-baseline">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of eventUserData" [ngClass]="{'make-gold': item?.levelId == 'L3'}">
              <td class="p-2">{{ item?.userId || '-' }}</td>
              <td class="p-2">{{ item?.levelName || '-'}}</td>
              <td class="p-2">{{ item?.userHoldTime || '-' }}</td>
              <td class="p-2">{{ selectedTimezone === 'PT' ? item?.receiveTimeStringInSiteTZ : selectedTimezone ===
                'IST' ? item?.receiveTimeStringInIST : item?.receiveTimeString }}</td>
              <td class="p-2">{{ selectedTimezone === 'PT' ? item?.reviewTimeStringInSiteTZ : selectedTimezone === 'IST'
                ? item?.reviewTimeStringInIST : item?.reviewTimeString || '-' }}</td>
              <td class="p-2">{{ selectedTimezone === 'PT' ? item?.actionTimeStringInSiteTZ : selectedTimezone === 'IST'
                ? item?.actionTimeStringInIST : item?.actionTimeString || '-' }}</td>
              <td class="p-2">{{ item?.userReviewTime || '-' }}</td>
              <td class="p-2">{{ item?.action || '-'}}</td>
              <td class="p-2">{{ item?.actionType || '-'}}</td>
              <td class="p-2">{{ item?.actionNote || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!eventInfo">
          <p>No event information</p>
        </div>
      </div>
    </div>
  </div>
</div>