import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-armdisarm-modal',
  templateUrl: './armdisarm-modal.component.html',
  styleUrls: ['./armdisarm-modal.component.scss']
})
export class ArmdisarmModalComponent {

  @Input() armDisarmed: boolean = false;
  @Input() armDisarmStatus: string = 'Disarmed';

  @Output() closeModal = new EventEmitter<void>();

  @Output() submitModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('form') form!: NgForm;
  armedFormData: any = {};
  hours: string[] = [];
  minutes: string[] = [];
  selectedDurationOption: string = "";
  constructor()
  {
    for (let i = 0; i <= 23; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      this.hours.push(`${hour}`);
    }

    // Populate the minutes array from 00 min to 59 min
    for (let i = 0; i <= 59; i++) {
      const minute = i < 10 ? `0${i}` : `${i}`;
      this.minutes.push(`${minute}`);
    }
}  
  dismissModal() {
    this.closeModal.emit();
  }

  onSubmitModal() {
    if (this.form.valid) {
      if(this.armDisarmStatus === 'Armed'){
        this.armedFormData.action = 'ACTIVATE';
        this.armedFormData.status = 'Armed';
        this.armedFormData.deviceId = 'prosafe';
        this.armedFormData.user = 'psafeadmin';
        this.armedFormData.source = 'WEB';
      }
      else if(this.armDisarmStatus === 'Disarmed'){
        this.armedFormData.action = 'DEACTIVATE';
        this.armedFormData.status = 'Disarmed';
        this.armedFormData.deviceId = 'prosafe';
        this.armedFormData.user = 'psafeadmin';
        this.armedFormData.from = 'console';
        this.armedFormData.source = 'WEB';
      }
      this.submitModal.emit(this.armedFormData);
      this.armedFormData = {};
      this.dismissModal();
    }
  }
}
