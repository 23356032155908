export const AccessPermissions = {
    routingl1:"/l1",
    accessl1:"L1_Monitoring",
    levelName1:"Level 1",
    l1_slotsCount:4,
    routingl2:"/l2-one",
    accessl2:"L2_Monitoring",
    levelName2:"Level 2",
    l2_slotsCount:2,
    l2_mirrorSlotsCount:4,
    routingl3:"/l3-one",
    accessl3:"L3_Monitoring",
    levelName3:"Level 3",
    l3_slotsCount:2,    
    CLM_Agent:"L1_montoring_agent",
    CLM_Role:"CLM_User",
    CLM_Group:"CLM_Group",

    routingCLM:'live-monitoring',
    l1_tagsKey:"645b92362bb1dd6b8abb83fb",
    l2_tagsKey:"645b8da60c578914eec7860b",
    l3_tagsKey:"645b8daf0c578914eec7860c",
    AI_tagsKey:"646378419eaeca771b74d29d",
  };