<div style="padding: 3px 11px 3px 3px;overflow-y: auto;min-height: 93vh;overflow-x: hidden;background: #fff;">
    <div class="row" style="height: 26vh;" *ngIf="liveViewList?.length == 9">
        <div class="col-sm-4" style="border-radius: 5px;margin: 0px;padding-right: 0px !important;" *ngFor="let item of liveViewList; let i = index;">
            <!-- <div style="width: 544px !important;height: 345px !important;margin-bottom: 2px;">
                <div style="background-color: black;color: white;padding: 6px; display: flex;">
                    <h6 class="text-white">
                        <span class="status-camera"
                            [ngClass]="{'online-sm': item?.connected === 'true', 'offline-sm': item?.connected == 'false'}">
                        </span>
                        {{item?.cameraName}}
                    </h6>
                    <i class="fa fa-video-camera bg-primary"
                        style="margin-left: 10px;cursor: pointer;padding: 5px; border-radius: 5px;"
                        (click)="oldArchiveDownload(item)" aria-hidden="true"></i> 
                    <i class="fa fa-play-circle iconsImg" style="margin-left:56%;"></i>
                    <i class="fa fa-pause-circle iconsImg"></i>
                    <i class="fa fa-video iconsImg"></i>
                    <i class="fa fa-camera iconsImg"></i>
                    <i class="fa fa-info-circle iconsImg"></i>
                    <i class="fa fa-bell iconsImg"></i>
                </div>
                <img [src]="item.archiveUrl+'&test='+item.timeCount"
                    style="width: 544px !important;height: 306px !important;" />
            </div> -->
            <app-live-monitoring-child [camItemData]="item"></app-live-monitoring-child>

        </div>

    </div>
    <div class="row" style="height: 93vh;align-items: center;text-align: center;" *ngIf="liveViewList?.length == 0">
        <span style="font-size: 16px;">
            Liveview's not available.
        </span>
    </div>

    <div class="row" *ngIf="liveViewList?.length == 1">
        <div class="col-sm-12" style="border-radius: 5px;margin: 0px;"
            *ngFor="let item of liveViewList; let i = index;">
            <app-live-monitoring-child [camItemData]="item"></app-live-monitoring-child>
        </div>
    </div>
    <div class="row" *ngIf="liveViewList?.length == 2 || liveViewList?.length == 4">
        <div class="col-sm-6" style="border-radius: 5px;margin: 0px;" *ngFor="let item of liveViewList; let i = index;">
            <app-live-monitoring-child [camItemData]="item"></app-live-monitoring-child>
        </div>
    </div>
    <div class="row" *ngIf="liveViewList?.length == 3 || liveViewList?.length == 5 || liveViewList?.length == 6">
        <div class="col-sm-4" style="border-radius: 5px;margin: 0px;" *ngFor="let item of liveViewList; let i = index;">
            <app-live-monitoring-child [camItemData]="item"></app-live-monitoring-child>
        </div>
    </div>
    <!-- Archive List Pagination -->
    <!-- <div class="pageDiv" *ngIf="page_pageIndexs.length>1">
          <div class="pageItemDiv" *ngFor="let index of page_pageIndexs;">
            <a [id]="'pagination_'+(index+1)" class="pagePerItem" (click)="pageClick(index+1)"> {{index+1}}</a>
          </div>          
        </div> -->
</div>