<!-- <div style="position: relative;" [ngClass]="{'borderRed': wallsObj.reviewCalled == true,'borderOrange': wallsObj.reviewCalled == false}" > -->
  <div style="position: relative;" [ngClass]="{
    'borderOrange':((wallDuration >=60 && wallDuration<=120) && levelName =='Level 2') || ((wallDuration >=180 && wallDuration<=240) && levelName =='Level 3'),
    'borderRed': (wallDuration >120 &&levelName =='Level 2') || (wallDuration >240 && levelName =='Level 3'),
  }">
  <div class="row gt-x-row" style="justify-content: space-evenly;"
    (click)="panelClick($event,panelName);openMoreInfo($event,true,false)">
    <div style="display: contents;" [id]="'videowrapper_'+panelName" (click)="slotHighlight(panelName)"
      class="panelHover">
      <div class="p_lr_10" style="margin-top: 10px;" (click)="getReviewEventId($event)" *ngIf="mirror">
        <div class="col_button icon_flex">
          <div class="btn_background" *ngIf="levelName =='Level 2'">
            <a class="d-block btn  btn-secondary w-100 p-0 cGrey" (click)="openEscalationModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Escalation">
              <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" />
              <!-- <i class="fa fa-phone"></i> -->
            </a>
          </div>
          <div class="btn_background" *ngIf="levelName =='Level 3'">
            <a class="d-block btn  btn-secondary w-100 p-0 cGrey" (click)="openEscalationModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Action">
              <!-- <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" /> -->
              <i class="fa fa-phone"></i>
            </a>
          </div>
          <div class="btn_background" *ngIf="levelName =='Level 2'">
            <a class="d-block btn  btn-outline-success w-100  p-0" (click)="openCloseModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Close">
              <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" />
            </a>
          </div>

        </div>

        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-warning w-100  p-0" (click)="openFeedbackModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Feedback">
              <img src="assets/images/icons/feedback6.svg" class="svgImg_close" />
            </a>
          </div>

          <div class="btn_background">
            <a class="d-block btn  btn-outline-secondary w-100  p-0" (click)="enableMask(false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Masking">
              <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px;top:-1px" />
            </a>
          </div>
        </div>

        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-daynight w-100  p-0" (click)="showDaynightModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Day/Night View">
              <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" />
            </a>
          </div>
          <div class="btn_background">
            <a [id]="'moreinfo_'+panelName" class="d-block btn btn-outline-primary w-100  p-0"
              (click)="openMoreInfo($event,true,true)" data-bs-toggle="tooltip" data-bs-placement="top"
              title="More Info">
              <img src="assets/images/icons/more_info.svg" class="" style="padding: 2px;width:26px" />
            </a>
          </div>
        </div>


        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="showSiteInfoModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Site Info">
              <img src="assets/images/icons/info-circle.svg" class="circle_svg" />
            </a>
          </div>
          <div class="btn_background">
            <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal" type="button"
              aria-expanded="false" [attr.data-bs-target]="'#siteMapModal'+panelName" (click)="openSiteMapModal($event)"
              aria-hidden="true" title="Site Map">
              <i class="fa fa-map-marker" style="color: white;font-size: 18px;"></i>
            </a>
            <!-- <a class="d-block btn btn-outline-primary w-100 svg p-0" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Replay" (click)="replayClicked('video_')">
              <img src="assets/images/icons/replay33.png" class="pngImg" />
            </a> -->
          </div>
        </div>


        <div class="col_button icon_flex">
          <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal"
            (click)="openfullscreenPopup($event)" [href]="'#fullScreen'+panelName" role="button">
            <img src="assets/images/icons/icon-fullscreen.svg" class="repl_svg" data-bs-toggle="tooltip"
              data-bs-placement="top" title="Fullscreen" />
          </a>
        </div>


        <div class="col_button d-flex" style="justify-content: space-between;">
          <a class="" data-bs-toggle="modal" type="button" aria-expanded="false" title="Live View"
            [attr.data-bs-target]="'#myModalCameraview'+panelName" (click)="openAllCamerasModal($event)">
            <i class="fa fa-play playIcon"></i>
          </a>

          <a class="d-block btn btn-outline-primary svg p-0" style="min-width: 30px !important;"
            data-bs-toggle="tooltip" data-bs-placement="top" title="All Live View" (click)="openAllViewsModal()">
            <img src="assets/images/icons/all_play2.svg" class="svgImg" style="padding:1px;" />
          </a>
        </div>
        <!-- <div class="col_button d-flex" style="justify-content: space-between;">
          <a class="d-block btn btn-outline-primary svg p-0" style="min-width: 30px !important;"
            data-bs-toggle="tooltip" data-bs-placement="top" title="Alarm" (click)="alarmTrigger()">
            <img src="assets/images/icons/alarm.png" class="svgImg" style="padding:1px;" />
          </a>
        </div> -->
      </div>
      <audio controls autoplay style="display: none;">
        <source src="../../../../assets/incoming.wav" type="audio/wav">
        Your browser does not support the audio element.
      </audio>
      <video controls="" disablepictureinpicture="" controlslist="nodownload noplaybackrate" [muted]="'true'"
        preload="auto" class="ratio ratio-16x9" [id]="'video_'+panelName" (play)="getReviewEventId($event)"
        appNoRightClick>
        <source type="video/mp4" src={{wallsData?.eventLink}}>
      </video>
      <p [ngClass]="{'left-1':!mirror, 'left-12': mirror }"
          class="event-time-info" >{{wallDuration}}
      </p>
      <div class="card-video-status" [id]="'indicator_'+panelName" *ngIf="isDevEnable">
        <div>
          <span class="indicator" [class.indicator-landed]="panelEventTriggers.landed"
            [class.indicator-clicked]="panelEventTriggers.clicked"
            [class.indicator-inshorttime]="panelEventTriggers.inshorttime"></span>
        </div>
      </div>
      <span class="siteNameSpan" [ngClass]="{'bgOrange':((wallDuration >=60 &&  wallDuration<=120)),'bgRed': (wallDuration>120)}" *ngIf="levelName =='Level 2'"> {{wallsData.siteName}} </span>
      <span class="siteNameSpan" [ngClass]="{'bgOrange':((wallDuration >=180 &&  wallDuration<=240)),'bgRed': (wallDuration>240)}" *ngIf="levelName =='Level 3'"> {{wallsData.siteName}} </span>
      <div class="card-video-status timelineDisplay" [id]="'indicator_'+panelName">
        <!-- <span class="duration-1">Duration: {{wallDuration}}</span> -->
        <span class="siteNameTimeHeading">{{eventCurrentTime}} / {{eventDuration}}</span>
        <div>
          <span class="indicator bg-danger"
            *ngIf="wallsData?.currentQueue == 'SuperHighPriorityPD' || wallsData?.currentQueue == 'SuperHighPriorityDispatch'"></span>
          <span class="indicator bg-warning"
            *ngIf="wallsData?.currentQueue == 'PriorityPD' || wallsData?.currentQueue == 'PriorityDispatch'"></span>
        </div>
      </div>
      <canvas [id]="'canvas_'+panelName" class="canvas newPopupfullmask {{mirror ? 'addMoreLeftCanvas' : ''}}"
      [width]="this.panelPopup.width" [height]="this.panelPopup.height"  style="position:absolute !important"></canvas>
      <!-- removed bottom: 100px; for siteinfo -->
      <div [id]="'cameraInfo_'+panelName" class="cameraInfo  {{mirror ? 'addMoreLeftCanvas' : ''}}"
        style="display:none;height:60px;left:7px;" [style.width]="panelPopup?.width+'px'">
        <div class="row" style="background-color: #ffffff;margin:-5px">
          <div
            style="position: absolute;left:calc(100% - 25px) ;width: 25px;cursor: pointer;font-weight: 800;font-size: 16px;">
            <span (click)="showSiteInfoModal($event,false)">x</span>
          </div>
          <span>
            <span class="site-left">Camera Name:</span>
            <span class="site-right">{{wallsData?.cameraName}}</span>
          </span>
          <span>
            <span class="site-left">Site Name: </span>
            <span class="site-right">{{wallsData?.siteName}}</span>
          </span>
          <span>
            <span class="site-left">Event Time:</span>
            <span class="site-right"> {{wallsData?.eventTimeStampStr}}</span>
          </span>
          <span>
            <span class="site-left">Previous Tag:</span>
            <span class="site-right"> {{wallsData?.previousLevelTag}} </span>
          </span>
          <span *ngIf="levelName =='Level 3'">
            <span class="site-left">Previous Notes:</span>
            <span class="site-right"> {{wallsData?.previousLevelNotes}} </span>
          </span>
          <span *ngIf="levelName =='Level 3' || levelName =='Level 2'">
            <span class="site-left">Queue Name:</span>
            <span class="site-right"> {{wallsData?.currentQueue}} </span>
          </span>
          <span>
            <span class="site-left">Event Id:</span>
            <span class="site-right"> {{wallsData?.eventid}}, {{wallsData?.potentialId}} </span>
            <span class="site-right" (click)="copyInfoDetails(wallsData?.eventLink)">
              <i class="fa fa-solid fa-copy" style="cursor: pointer;"></i>
            </span>
          </span>



          <!-- <span>
            <span class="site-left">Unique Id:</span>
            <span class="site-right">{{wallsData?.unitId}} </span>
          </span> -->
        </div>
      </div>
      <div [id]="'daynightImg_'+panelName" class="daynightImg {{mirror ? 'addMoreLeftCanvas' : ''}}"
        style="display:none;position: absolute;" [style.height]="heightCount">
        <div class="d-flex h-100">
          <div class="row" style="margin: auto;background: #c5c7c7;width:75%;padding-bottom:10px">
            <div (click)="showDaynightModal($event,false)" class="p-0" style="position: absolute;
            width: inherit;"><i class="fa fa-close" style="float: right;padding: 3px;"></i>
            </div>
            <div class="col-6 h-100">
              <span class="text-bold">Day Image</span>
              <img [src]="wallsData?.dayimage" style="width:100%" />
            </div>
            <div class="col-6 h-100">
              <span class="text-bold">Night Image</span>
              <img [src]="wallsData?.nightimage" style="width:100%" />
            </div>
          </div>
        </div>
      </div>
      <div class="p_lr_10" style="margin-top: 10px;" (click)="getReviewEventId($event)" *ngIf="!mirror && wallsData?.eventLink">
        <div class="col_button icon_flex">
          <div class="btn_background" *ngIf="levelName =='Level 2'">
            <a class="d-block btn  btn-secondary w-100 p-0 cGrey" (click)="openEscalationModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Escalation">
              <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" />
              <!-- <i class="fa fa-phone"></i> -->
            </a>
          </div>
          <div class="btn_background" *ngIf="levelName =='Level 3'">
            <a class="d-block btn  btn-secondary w-100 p-0 cGrey" (click)="openEscalationModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Action">
              <!-- <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" /> -->
              <i class="fa fa-phone"></i>
            </a>
          </div>
          <div class="btn_background" *ngIf="levelName =='Level 3'">
            <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal" type="button"
              aria-expanded="false" [attr.data-bs-target]="'#siteMapModal'+panelName" (click)="openSiteMapModal($event)"
              aria-hidden="true" title="Site Map">
              <i class="fa fa-map-marker" style="color: white;font-size: 18px;"></i>
            </a>
            <!-- <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="tooltip" data-bs-placement="top"
              (click)="openSiteMapModal($event)" title="Site Map">
              <i class="fa  fa-map-marker" style="color: white;font-size: 18px;"></i>
            </a> -->
          </div>
          <div class="btn_background" *ngIf="levelName =='Level 2'">
            <a class="d-block btn  btn-outline-success w-100  p-0" (click)="openCloseModal($event)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Close">
              <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" />
            </a>
          </div>

        </div>

        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-warning w-100  p-0" (click)="openFeedbackModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Feedback">
              <img src="assets/images/icons/feedback6.svg" class="svgImg_close" />
            </a>
          </div>

          <div class="btn_background">
            <a class="d-block btn  btn-outline-secondary w-100  p-0" (click)="enableMask(false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Masking">
              <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px;top:-1px" />
            </a>
          </div>
        </div>

        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-daynight w-100  p-0" (click)="showDaynightModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Day/Night View">
              <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" />
            </a>
          </div>
          <div class="btn_background">
            <a [id]="'moreinfo_'+panelName" class="d-block btn btn-outline-primary w-100 p-0"
              (click)="openMoreInfo($event,true,true)" data-bs-toggle="tooltip" data-bs-placement="top"
              title="More Info">
              <img src="assets/images/icons/more_info.svg" class="" style="padding: 2px;width:26px" />
            </a>
          </div>
        </div>


        <div class="col_button icon_flex">
          <div class="btn_background">
            <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="showSiteInfoModal($event,false)"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Site Info">
              <img src="assets/images/icons/info-circle.svg" class="circle_svg" />
            </a>
          </div>
          <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal"
            (click)="openfullscreenPopup($event)" [href]="'#fullScreen'+panelName" role="button"
            *ngIf="levelName =='Level 3'">
            <img src="assets/images/icons/icon-fullscreen.svg" class="repl_svg" data-bs-toggle="tooltip"
              data-bs-placement="top" title="Fullscreen" />
          </a>
          <!-- <a class="btn btn-dark btn-outline-secondary svg w-100" 
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            type="button"
            aria-expanded="false"  
            data-bs-toggle="modal"
            data-bs-target="'#siteMapModal'+panelName"
            (click)="openSiteMapModal($event)" title="Site Map">
            <i class="fa  fa-map-marker" style="color: white;font-size: 18px;"></i>
          </a> #siteMapModalpanel1 
                    [data-bs-target]="'#siteMapModal'+panelName"

                    id="siteMapModalpanel1"
       class="modal cameraLiveViewModel col col-sm-2 col-md-2 col-xl-2" 
       tabindex="-1" 
       aria-labelledby="siteMapModalLabel"
       aria-hidden="true"
        -->
          <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal" type="button"
            aria-expanded="false" [attr.data-bs-target]="'#siteMapModal'+panelName" (click)="openSiteMapModal($event)"
            *ngIf="levelName =='Level 2'" aria-hidden="true" title="Site Map">
            <i class="fa fa-map-marker" style="color: white;font-size: 18px;"></i>
          </a>
          <!-- <div class="btn_background">
            <a class="d-block btn btn-outline-primary w-100 svg p-0" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Replay" (click)="replayClicked('video_')">
              <img src="assets/images/icons/replay33.png" class="pngImg" />
            </a>
          </div> -->


        </div>
        <div class="col_button icon_flex" *ngIf="levelName =='Level 2'">
          <a class="btn btn-dark btn-outline-secondary svg w-100" data-bs-toggle="modal"
            (click)="openfullscreenPopup($event)" [href]="'#fullScreen'+panelName" role="button">
            <img src="assets/images/icons/icon-fullscreen.svg" class="repl_svg" data-bs-toggle="tooltip"
              data-bs-placement="top" title="Fullscreen" />
          </a>
          <a *ngIf="hasBoloNotes" [id]="'boloNotes'+panelName"  class="w-100 bolo" data-bs-toggle="modal" role="button">
          <img src="assets/images/bolo.png" class="repl_bolo" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Bolo Notes" />
        </a>
        </div>
        <div class="col_button d-flex" style="justify-content: space-between;">
          <a data-bs-toggle="modal" type="button" aria-expanded="false" title="Live View"
            [attr.data-bs-target]="'#myModalCameraview'+panelName" (click)="openAllCamerasModal($event)">
            <i class="fa fa-play playIcon"></i>
          </a>
          <a class="d-block btn btn-outline-primary svg p-0" style="min-width: 30px !important;"
            data-bs-toggle="tooltip" data-bs-placement="top" title="All Live View" (click)="openAllViewsModal()">
            <img src="assets/images/icons/all_play2.svg" class="svgImg" style="padding:1px;" />
          </a>
        </div>
        <!-- <div class="col_button d-flex" style="justify-content: space-between;">
          <a class="d-block btn btn-outline-primary svg p-0" style="min-width: 30px !important;"
            data-bs-toggle="tooltip" data-bs-placement="top" title="Alarm" (click)="alarmTrigger()">
            <img src="assets/images/icons/alarm.png" class="svgImg" style="padding:1px;" />
          </a>
        </div> -->
      </div>

    </div>
  </div>
  <div class="slider">

    <div class="card-video-slider">
      <!-- <div class="custom-ngx-slider" *ngIf="sliderLength>0">
        <ngx-slider [(value)]="sliderValue" [options]="options" (userChange)="onUserSliderChange($event)"></ngx-slider>
      </div> -->

      <!-- <span class="slider1" style="height: 30px;position: inherit;width: 100px;">
        <label class="switch" >
          <input type="checkbox" checked>
          <span class="slider round"></span>
        </label>
      </span> -->
      <div class="accordion" id="accordionPanelsStayOpenExample" style="margin-top: -18px;"
        (click)="openMoreInfo($event,true);getReviewEventId($event);" >
        <div class="custom-control custom-switch"
          style="width: calc(100% - 50px);position: relative;top: 24px;z-index: 991;color: white; display: flex; justify-content: center;">
          <span style="margin: 0px 12px 0px 0px;">This cam</span>
          <label class="switch1">
            <input type="checkbox" (change)="switchEventGroup($event)">
            <span class="slider1 round1"></span>
          </label>
          <span style="margin: 0px 1px 0px 12px;color: white;">All Cams</span>
        </div>
        <div class="accordion-item">
          <button class="accordion-button collapsed btn-outline-daynight text-white p-1" type="button"
            data-bs-toggle="collapse" [attr.data-bs-target]="'#videoscroll'+panelName" aria-expanded="true"
            (click)="collapseThumbnail(panelName)">Past & Future events
          </button>

          <div [id]="'videoscroll'+panelName" class="accordion-collapse collapse" data-bs-parent="#Tabs01">
            <div class="accordion-body p-0 overPanel">
              <div class="scrollmenu" *ngIf="isAllCamsViews">
                <a *ngFor="let event of allCamsEventVideoClipsObj;let i = index" [id]="i+panelName"
                  class="eventSelectorclass position-relative"
                  [ngClass]="{'eventDefaultActive':event.eventId == wallsData.eventid}">
                  <div style="font-size: 9px;">{{event.cameraName}}</div>
                  <img style="height:100px;width:100%;max-width: 100%;max-height: 100px;" [id]="i+panelName"
                    [src]="event?.imageUrl" />
                  <div class="d-flex" style="justify-content: space-between;">
                    <span style="font-size: 9px;"
                      *ngIf="event?.eventTimestampStr =='NULL'">{{getDateTransform(event?.timestamp)}}</span>
                    <span style="font-size: 9px;"
                      *ngIf="event?.eventTimestampStr !='NULL'">{{event?.eventTimestampStr}}</span>
                    <span style="font-size: 9px;">AI-Tags {{event?.aiResponse}}</span>
                  </div>
                  <div (click)="eventPlay(event,$event,i+panelName)" class="eventplay-overlay"></div>
                </a>
                <a *ngIf="allCamsEventVideoClipsObj.length==0">
                  No past and future events in the past 12 minutes.
                </a>
              </div>
              <div class="scrollmenu" *ngIf="!isAllCamsViews">
                <a *ngFor="let event of thisCamEventVideoClipsObj;let i = index" [id]="i+panelName"
                  class="eventSelectorclass position-relative"
                  [ngClass]="{'eventDefaultActive':event.eventId == wallsData.eventid}">
                  <div style="font-size: 9px;">{{event.cameraName}}</div>
                  <img style="height:100px;width:100%;max-width: 100%;max-height: 100px;" [id]="i+panelName"
                    [src]="event?.imageUrl" />
                  <div class="d-flex" style="justify-content: space-between;">
                    <span style="font-size: 9px;"
                      *ngIf="event?.eventTimestampStr =='NULL'">{{getDateTransform(event?.timestamp)}}</span>
                    <span style="font-size: 9px;"
                      *ngIf="event?.eventTimestampStr !='NULL'">{{event?.eventTimestampStr}}</span>
                    <span style="font-size: 9px;">AI-Tags {{event?.aiResponse}}</span>
                  </div>
                  <div (click)="eventPlay(event,$event,i+panelName)" class="eventplay-overlay"></div>
                </a>
                <a *ngIf="thisCamEventVideoClipsObj?.length==0">
                  No past and future events in the past 12 minutes.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- Escalation Modal popup -->
  <div [id]="'myModalEscalation'+panelName" class="modal modal-popup popup-d-none {{mirror ? 'addMoreLeftCanvas' : ''}}"
    style="width:calc(100% - 90px);" [style.height]="heightCount">
    <div style="display: flex;height: calc(100% - 30px);">
      <div class="modal-content" style="overflow: auto;height: 70%;width: 70%;margin: auto;border: 1px solid white;">
        <div class="modal-header">
          <h6 class="text" *ngIf="levelName=='Level 2'">ESCALATION TAGS</h6>
          <!-- <mat-tab-group >
            <mat-tab label="First"> Content 1 </mat-tab>
            <mat-tab label="Second"> Content 2 </mat-tab>
            <mat-tab label="Third"> Content 3 </mat-tab>
          </mat-tab-group> -->
          <nav *ngIf="levelName=='Level 3'">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link active" [id]="'nav-action-tab'+this.panelName" data-bs-toggle="tab"
                [attr.data-bs-target]="'#nav-action'+this.panelName" type="button" role="tab" aria-controls="nav-action"
                aria-selected="true">Action Tags</button>
              <button class="nav-link" [id]="'nav-close-tab'+this.panelName" data-bs-toggle="tab"
                [attr.data-bs-target]="'#nav-close'+this.panelName" type="button" role="tab" aria-controls="nav-close"
                aria-selected="false">Close Tags</button>
            </div>
          </nav>

          <span class="close" (click)="closeEscalationModal()">&times;</span>
        </div>
        <div class="modal-body" style="overflow: auto;">
          <div class="row gx-4 gy-2">
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" [id]="'nav-action'+this.panelName" role="tabpanel"
                [aria-labelledby]="'nav-action-tab'+this.panelName">
                <div class="row gx-4 gy-2">
                  <div class="col-6" *ngFor="let etags of L2EscalatingTags; let i=index;">
                    <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                      (click)="escalationTags('escalations', etags.tagname, i, etags)">
                      {{etags.tagname}}
                    </button>
                    <div class="popup-container" *ngIf="etags.notesRequried && (etags.tagname === selectedTagname)">
                      <div class="popup-content">
                        <div class="selected-value" style="margin: 0px;">{{etags.tagname}}</div>
                        <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                        <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes"
                          autofocus (keydown.enter)="submitTag(etags)"></textarea>
                        <span class="esc_error">{{errorMessage}}</span>
                        <div class="mt-2 escalate" *ngIf="levelName=='Level 2'">
                          <button class="btn btn-secondary me-2" (click)="closeTextarea()">Cancel</button>
                          <button class="btn btn-primary" (click)="submitTag(etags)">Submit</button>
                        </div>
                        <div class="mt-2 escalate" *ngIf="levelName=='Level 3'">
                          <button class="btn btn-secondary" (click)="closeTextarea()">Cancel</button>
                          <button class="btn btn-primary ms-2" (click)="submitTag(etags)" type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="tab-pane fade" [id]="'nav-close'+this.panelName" role="tabpanel"
                [aria-labelledby]="'nav-close-tab'+this.panelName">
                <div class="row gx-4 gy-2">
                  <div class="col-6" *ngFor="let ctags of L2ClosingTags; let i=index;" id="content">
                    <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                      (click)="closingTags('close', ctags.tagname, i, ctags)">
                      {{ctags.tagname}}
                    </button>
                    <div class="popup-container" *ngIf="ctags.notesRequried && (ctags.tagname === closedTagname)">
                      <div class="popup-content">
                        <div class="selected-value">{{closedTagname}}</div>
                        <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                        <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes"
                          autofocus (keydown.enter)="submitTag(ctags)"></textarea>
                        <span class="esc_error">{{errorMessage}}</span>
                        <div class="mt-2 escalate">
                          <button class="btn btn-secondary" (click)="closeTextarea()">Cancel</button>
                          <button class="btn btn-primary ms-2" (click)="submitTag(ctags)" type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Close Modal popup -->
  <div [id]="'myModalClose'+panelName" class="modal modal-popup popup-d-none {{mirror ? 'addMoreLeftCanvas' : ''}}"
    style="width:calc(100% - 90px);" [style.height]="heightCount">
    <div style="display: flex;height: inherit;">
      <div class="modal-content" style="overflow: auto;height: 70%;width: 70%;margin: auto;border: 1px solid white;">
        <div class="modal-header">
          <h6 class="text">CLOSE TAGS</h6>
          <span class="close" (click)="closeTagsModal()">&times;</span>
        </div>
        <div class="modal-body" style="overflow: auto;">
          <div class="row gx-4 gy-2">
            <div class="col-6" *ngFor="let ctags of L2ClosingTags; let i=index;">
              <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                (click)="closingTags('close', ctags.tagname, i,ctags)">
                {{ctags.tagname}}
              </button>
              <div class="popup-container" *ngIf="ctags.notesRequried && (ctags.tagname === closedTagname)">
                <div class="popup-content">
                  <div class="selected-value" style="margin: 0px;">{{closedTagname}}</div>
                  <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                  <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes" autofocus
                    (keydown.enter)="submitTag(ctags)"></textarea>
                  <span class="esc_error">{{errorMessage}}</span>
                  <div class="mt-2 escalate">
                    <button class="btn btn-secondary" (click)="closeTextarea()">Cancel</button>
                    <button class="btn btn-primary me-2" style="margin-left: 8px;"
                      (click)="submitTag(ctags)">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- Feed Back Modal popup -->
  <div [id]="'myModalfeedback'+panelName" class="modal modal-popup popup-d-none {{mirror ? 'addMoreLeftCanvas' : ''}}"
    style="width:calc(100% - 90px);" [style.height]="heightCount">
    <div style="display: flex;height: inherit;">
      <div class="modal-content" style="overflow: auto;height: 70%;width: 70%;margin: auto;border: 1px solid white;">
        <div class="modal-header">
          <h6 class="text">FEEDBACK</h6>
          <span class="close" (click)="closeFeedbackModal()">&times;</span>
        </div>
        <div class="modal-body" style="overflow: auto;">
          <div class="row gx-4 gy-2">
            <div class="col-6" *ngFor="let ftags of L2FeedbackTags;let i=index;">
              <button class="btn btn-outline-primary w-100 pd-0  model-btn"
                (click)="feedbackTags('feedback',ftags.tagname)">{{ftags.tagname}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- Site Info Modal popup -->
  <div [id]="'myModalSiteInfo'+panelName" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none"
    [style.left]="panelPopup?.left+(80)+'px'" [style.top]="panelPopup?.top+(20)+'px'">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text">Site Information</h6>
        <span class="close" (click)="closeSiteinfoModal()">&times;</span>
      </div>
      <div class="modal-body">
        <div class="row gx-4 gy-2">
          <div class="row">
            <span>Site Name: {{wallsData?.siteName}}</span>
            <span>Camera Name: {{wallsData?.cameraName}}</span>
            <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
            <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This Camera View -->
  <div [id]="'myModalThisCameraview'+panelName"
    class="modal col col-sm-2 col-md-2 col-xl-2  popup-d-none cameraLiveViewModel" style="overflow: hidden;">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text">This Camera Live View</h6>
        <span class="close" (click)="closeThisCameraViewModal()">&times;</span>
      </div>
      <div class="modal-body" style="height: 60vh;">
        <div class="row" style="height: 100%;overflow-y: auto; ">
          <!-- <div class="col-md-2 mymodalBody_row">
            <div class="camviewDiv">
              <img style="height:100%;width:100%;" class="liveCamviewSelector" (click)="onThisCamLiveviewPlay(camLiveView,$event)" src="assets/images/cam01.png"/>
            </div>
          </div> -->
          <div class="col-md-10 camLiveView-col-12" *ngIf="cameraLiveViewData?.hlsUrl">
            <iframe [id]="'playThisCamLiveview'+panelName"
              [src]='sanitizer.bypassSecurityTrustResourceUrl(cameraLiveViewData?.hlsUrl)'
              style="height:95%;width:100%;"></iframe>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Camera view Modal popup -->
  <div [id]="'myModalCameraview'+panelName" tabindex="-1"
    class="modal cameraLiveViewModel col col-sm-2 col-md-2 col-xl-2">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-lg">
      <div class="modal-content" style="height:inherit">
        <div class="modal-header bg-dark flex-wrap gap-2 p-3">
          <h6 class="text" style="width: 84%;font-size: 16px;">Camera Live View - {{wallsData?.siteName}}</h6>
          <button type="button" class="btn btn-outline-white ms-auto" (click)="alarmTrigger()">
            Trigger Alarm
          </button>
          <button type="button" class="btn btn-orange px-5" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
        <div class="spinner-container" *ngIf="showLoading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden loader"></span>
          </div>
        </div>
        <div class="showError">
          <div class="error">
            {{allLiveviewMessage}}
          </div>
        </div>
        <div class="modal-body c-lv-view" *ngIf="cameraLiveViewsData.length>0">
          <div class="row g-4">
            <div class="col-xl-3 col-lg-6" *ngFor="let camLiveView of cameraLiveViewsData; let i = index;">
              <div class="position-relative overflow-hidden" (dblclick)="openCamView(camLiveView)">
                <div class="bg-light rounded-top gap-3 z-index-1 w-100">
                  <div class="d-flex align-items-center justify-content-between py-2 px-3"
                    style="background: #0c0404d4;">
                    <h6 class="text-white">
                      <span class="status-camera"
                        [ngClass]="{'online-sm': camLiveView.connected, 'offline-sm': !camLiveView.connected}"></span>
                      {{camLiveView.cameraName}}
                    </h6>
                  </div>
                </div>

                <div class="rounded-2 overflow-hidden">
                  <img [src]="((wallsData?.siteURL)+(camLiveView?.snapshotUrl))" style="width: 100%;" allowfullscreen />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fullscreen Modal popup -->


  <div [id]="'fullScreen'+panelName" data-bs-backdrop="false" class="modal hide modal-lg fade" aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel" style="z-index: 9999999;" (click)="openMoreInfo($event,true)">
    <div class="modal-dialog center_align">
      <div class="modal-content content-width m-auto">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalToggleLabel">Full view of - {{wallsData?.cameraName}}</h5>
          <span class="close" (click)="closeFullScreen(true)" data-bs-dismiss="modal">&times;</span>
        </div>
        <div class="modal-body" id="fBody"
          style="overflow: hidden;padding-right:0px;justify-content: space-evenly;padding: 12px 0px;">

          <video controls="" disablepictureinpicture="" controlslist="nodownload noplaybackrate" [muted]="'true'"
            class="ratio ratio-16x9" [id]="'f_video_'+panelName" (play)="getReviewEventId($event)" appNoRightClick>
            <source type="video/mp4" src={{wallsData?.eventLink}}>
          </video>

          <p [left]="panelPopup?.left" class="event-info" *ngIf="isDevEnable">{{wallsData.potentialId}} </p>

          <canvas [id]="'f_canvas_'+panelName" class="canvas" [width]="this.panelPopup.width" [height]="this.panelPopup.height" style="display: none;">
          </canvas>
          <div [id]="'f_cameraInfo_'+panelName" class="cameraInfoModel" style="display:none;" [style.left]="20+'px'"
            [style.top]="(panelPopup?.top+120)+'px'" [style.width]="panelPopup?.width+'px'">
            <div><span class="close" style="color:black" (click)="showSiteInfoModal($event,true)">&times;</span></div>

            <div class="row" style="background-color: #ffffff;margin:-5px">
              <span>
                <span class="site-left">Camera Name:</span>
                <span class="site-right">{{wallsData?.cameraName}}</span>
              </span>
              <span>
                <span class="site-left">Site Name: </span>
                <span class="site-right">{{wallsData?.siteName}}</span>
              </span>

              <span>
                <span class="site-left">Event Time:</span>
                <span class="site-right"> {{wallsData?.eventTimeStampStr}}</span>
              </span>
              <span>
                <span class="site-left">Previous Tag:</span>
                <span class="site-right"> {{wallsData?.previousLevelTag}} </span>
              </span>
              <span *ngIf="levelName =='Level 3'">
                <span class="site-left">Previous Notes:</span>
                <span class="site-right"> {{wallsData?.previousLevelNotes}} </span>
              </span>
              <span *ngIf="levelName =='Level 3' || levelName == 'Level 2'">
                <span class="site-left">Queue Name:</span>
                <span class="site-right"> {{wallsData?.currentQueue}} </span>
              </span>
              <span>
                <span class="site-left">Event Id:</span>
                <span class="site-right"> {{wallsData?.eventid}}, {{wallsData?.potentialId}} </span>
                <span class="site-right" (click)="copyInfoDetails(wallsData?.eventLink)">
                  <i class="fa fa-solid fa-copy" style="cursor: pointer;"></i>
                </span>
              </span>
            </div>
          </div>
          <div [id]="'f_daynightImg_'+panelName" class="daynightImg" style="display:none;position: absolute;left:17px">
            <div class="d-flex h-100">
              <div class="row" style="margin: auto;background: #c5c7c7;width:75%;padding-bottom:10px">
                <div (click)="showDaynightModal($event,true)" class="p-0" style="position: absolute;
              width: inherit;"><i class="fa fa-copy" style="float: right;padding: 3px;"></i>
                </div>
                <div class="col-6 h-100">
                  <span class="text-bold">Day Image</span>
                  <img [src]="wallsData?.dayimage" style="width:100%" />
                </div>
                <div class="col-6 h-100">
                  <span class="text-bold">Night Image</span>
                  <img [src]="wallsData?.nightimage" style="width:100%" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div [id]="'f_daynightImg_'+panelName" class="daynightImg" style="display:none;">
            <div (click)="showDaynightModal($event,true)"> <span class="close"
                style="position: relative;top: -10px;left: 1px;">&times;</span></div>
            <div class="row">
              <div class="col-6 h-100">
                <span class="text-bold">Day Image</span>
                <img [src]="wallsData?.dayimage" style="width:100%" />
              </div>
              <div class="col-6 h-100">
                <span class="text-bold">Night Image</span>
                <img [src]="wallsData?.nightimage" style="width:100%" />
              </div>
            </div>
          </div> -->
          <!-- <div class="p_lr_10" style="align-content: space-between;">
              <div class="col_button icon_flex">
                <div class="btn_background" *ngIf="levelName =='Level 2'">
                  <a class="d-block btn  btn-outline-secondary w-100 p-0" (click)="openEscalationModal($event,true)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Escalation">
                    <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" />
                  </a>
                </div>
                <div class="btn_background" *ngIf="levelName =='Level 3'">
                  <a class="d-block btn  btn-outline-secondary w-100 p-0" (click)="openEscalationModal($event,true)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Action">
                    <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" />
                  </a>
                </div>
                <div class="btn_background">
                  <a class="d-block btn  btn-outline-success w-100  p-0" (click)="openCloseModal($event,true)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Close">
                    <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" />
                  </a>
                </div>
  
              </div>
              <div class="btn_background">
                <a class="d-block btn btn-outline-warning text-center" (click)="openFeedbackModal($event,true)">
                  <img src="assets/images/icons/icon-feedback.svg" class="msg" />
                </a>
              </div>
  
              <div class="btn_background">
                <a class="btn btn-outline-secondary w-100 p-0" (click)="enableMask(true)">
                  <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px;top:-1px" />
                </a>
  
              </div>
  
  
              <div class="btn_background">
                <a class="btn btn-outline-daynight w-100 icon_svg p-0" (click)="showDaynightModal($event,true)">
                  <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" />
                </a>
              </div>
  
              <div class="btn_background">
                <a class="d-block btn btn-outline-primary w-100 svg" (click)="showSiteInfoModal($event, true)">
                  <img src="assets/images/icons/info-circle.svg" class="icon_svg" />
                </a>
              </div>
              <div class="col_button">
                <a class="d-block btn btn-outline-primary w-100 svg p-0">
                  <img src="assets/images/icons/replay.png" class="repl_svg" />
                </a>
              </div>
            </div> -->
          <div class="p_lr_10">
            <div class="col_button btn_background" (click)="openEscalationModal($event,true)">
              <a class="d-block btn  btn-secondary w-100 p-0 cGrey" data-bs-toggle="tooltip" data-bs-placement="top"
                [title]="(levelName=='Level 2')?'Escalate':'Action' ">

                <!-- <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" /> -->
                <i class="fa fa-phone" *ngIf="levelName=='Level 3'"></i>
                <img src="assets/images/icons/escalate1.svg" *ngIf="levelName=='Level 2'" class="svgImg"
                  style="padding: 3px;color:white" />
              </a>
            </div>
            <div class="col_button" *ngIf="levelName=='Level 2'">
              <div class="btn_background">
                <a class="d-block btn  btn-outline-secondary w-100 p-0" (click)="openCloseModal($event,true)"
                  title="Close">
                  <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" />
                </a>
              </div>
            </div>
            <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn btn-outline-warning w-100  p-0" (click)="openFeedbackModal($event,true)"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Feedback">
                  <img src="assets/images/icons/feedback6.svg" class="svgImg_close" />
                </a>
              </div>
            </div>

            <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn  btn-outline-secondary w-100  p-0" (click)="enableMask(true)"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Masking">
                  <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px" />
                </a>
              </div>
            </div>


            <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn btn-outline-daynight w-100  p-0" (click)="showDaynightModal($event,true)"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Day/Night View">
                  <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" />
                </a>
              </div>
            </div>

            <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="showSiteInfoModal($event,true)"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Site Info">
                  <img src="assets/images/icons/info-circle.svg" class="circle_svg" />
                </a>
              </div>
            </div>
            <!-- <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn btn-outline-primary w-100 svg p-0" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Replay" (click)="replayClicked('f_video_')">
                  <img src="assets/images/icons/replay33.png" class="pngImg" />
                </a>
              </div>
            </div> -->
            <!-- <div class="col_button">
              <div class="btn_background">
                <a class="d-block btn btn-outline-primary w-100 svg p-0" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Alarm" (click)="alarmTrigger()">
                  <img src="assets/images/icons/alarm.png" class="pngImg" />
                </a>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button class="btn btn-primary" [data-bs-target]="'#fullScreen'+panelName" data-bs-toggle="modal"
            data-bs-dismiss="modal">Cancel</button>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Escalation Modal popup -->
  <div [id]="'f_myModalEscalation'+panelName"
    class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay newPopupfull">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text" *ngIf="levelName=='Level 2'">ESCALATION TAGS</h6>
        <nav *ngIf="levelName=='Level 3'">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" [id]="'nav-action-tabf'+this.panelName" data-bs-toggle="tab"
              [attr.data-bs-target]="'#nav-actionf'+this.panelName" type="button" role="tab" aria-controls="nav-action"
              aria-selected="true">Action Tags</button>
            <button class="nav-link" [id]="'nav-close-tabf'+this.panelName" data-bs-toggle="tab"
              [attr.data-bs-target]="'#nav-closef'+this.panelName" type="button" role="tab" aria-controls="nav-close"
              aria-selected="false">Close Tags</button>
          </div>
        </nav>
        <span class="close" (click)="closeEscalationModal(true)">&times;</span>
      </div>
      <div class="modal-body modelActionClose">
        <div class="row gx-4 gy-2">
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" [id]="'nav-actionf'+this.panelName" role="tabpanel"
              [aria-labelledby]="'nav-action-tabf'+this.panelName">
              <div class="row gx-4 gy-2">
                <div class="col-6" *ngFor="let etags of L2EscalatingTags; let i=index;">
                  <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                    (click)="escalationTags('escalations', etags.tagname, i, etags)">
                    {{etags.tagname}}
                  </button>
                  <div class="popup-container" *ngIf="etags.notesRequried && (etags.tagname === selectedTagname)"
                    style="height: 25vh !important;">
                    <div class="popup-content">
                      <div class="selected-value">{{etags.tagname}}</div>
                      <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                      <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes"
                        autofocus (keydown.enter)="submitTag(etags)"></textarea>
                      <span class="esc_error">{{errorMessage}}</span>
                      <div class="mt-2 escalate" *ngIf="levelName=='Level 2'">
                        <button class="btn btn-secondary me-2" (click)="closeTextarea()">Cancel</button>
                        <button class="btn btn-primary" (click)="submitTag(etags)">Submit</button>
                      </div>
                      <div class="mt-2 escalate" *ngIf="levelName=='Level 3'">
                        <button class="btn btn-secondary me-2" (click)="closeTextarea()">Cancel</button>
                        <button class="btn btn-primary" (click)="submitTag(etags)">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="tab-pane fade" [id]="'nav-closef'+this.panelName" role="tabpanel"
              [aria-labelledby]="'nav-close-tabf'+this.panelName">
              <div class="row gx-4 gy-2">
                <div class="col-6" *ngFor="let ctags of L2ClosingTags; let i=index;" id="content">
                  <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                    (click)="closingTags('close', ctags.tagname, i,ctags)">
                    {{ctags.tagname}}
                  </button>
                  <div class="popup-container" *ngIf="ctags.notesRequried && (ctags.tagname === closedTagname)"
                    style="height: 25vh !important;">
                    <div class="popup-content">
                      <div class="selected-value">{{closedTagname}}</div>
                      <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                      <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes"
                        autofocus (keydown.enter)="submitTag(ctags)"></textarea>
                      <span class="esc_error">{{errorMessage}}</span>
                      <div class="mt-2 escalate">
                        <button class="btn btn-secondary" (click)="closeTextarea()">Cancel</button>
                        <button class="btn btn-primary ms-2" (click)="submitTag(ctags)">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Close Modal popup -->
  <div [id]="'f_myModalClose'+panelName"
    class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay newPopupfull escTags" style="top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   position: fixed;">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text">CLOSE TAGS </h6>
        <span class="close" (click)="closeTagsModal(true)">&times;</span>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row gx-4 gy-2">
            <div class="col-6" *ngFor="let ctags of L2ClosingTags;let i=index;">
              <button class="btn btn-outline-primary w-100 pd-0 model-btn"
                (click)="closingTags('close',ctags.tagname,i,ctags, panelName)">{{ctags.tagname}}
              </button>
              <div class="popup-container" *ngIf="ctags.notesRequried && (ctags.tagname === closedTagname)">
                <div class="popup-content">
                  <div class="selected-value">{{closedTagname}}</div>
                  <span class="site-right" style="padding: 0px;">Unit Id : {{wallsData?.unitId}}</span>
                  <textarea class="form-control" rows="3" [(ngModel)]="tagTextarea" placeholder="Enter Notes" autofocus
                    (keydown.enter)="submitTag(ctags)"></textarea>
                  <span class="esc_error">{{errorMessage}}</span>
                  <div class="mt-2 escalate">
                    <button class="btn btn-secondary" (click)="closeTextarea(true)">Cancel</button>
                    <button class="btn btn-primary ms-2" (click)="submitTag(ctags)">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Feed Back Modal popup -->
  <div [id]="'f_myModalfeedback'+panelName"
    class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay newPopupfull escTags">
    <div class="modal-content h-inh">
      <div class="modal-header">
        <h6 class="text">FEEDBACK</h6>
        <span class="close" (click)="closeFeedbackModal(true)">&times;</span>
      </div>
      <div class="modal-body" style="overflow: auto;height: 44vh !important;">
        <div class="row gx-4 gy-2">
          <div class="col-6" *ngFor="let ftags of L2FeedbackTags;let i=index;">
            <button class="btn btn-outline-primary w-100 pd-0  model-btn"
              (click)="feedbackTags('feedback',ftags.tagname,true)">{{ftags.tagname}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Site Info Modal popup -->
  <div [id]="'f_myModalSiteInfo'+panelName"
    class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay"
    [style.left]="panelPopup?.left+(150)+'px'" [style.top]="panelPopup?.top+(20)+'px'">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text">Site Information</h6>
        <span class="close" (click)="closeSiteinfoModal(true)">&times;</span>
      </div>
      <div class="modal-body">
        <div class="row gx-4 gy-2">
          <div class="row">
            <span>Site Name: {{wallsData?.siteName}}</span>
            <span>Camera Name: {{wallsData?.cameraName}}</span>
            <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
            <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- All Live view-->
  <div class="modal" [id]="'allcammaskingview1'+panelName" tabindex="-1" aria-labelledby="allcammaskingviewLabel"
    style="display: none;z-index: 999999;;overflow: hidden;" aria-hidden="true">
    <div *ngIf="showLiveviewPanel" style="height: 100%;">
      <app-liveview-list [wallsData]="wallsData" [liveViewList]="liveViewList" [panelName]="panelName"
        [triggerAlarmNeeded]="true" [isMaskingView]="isMaskingView"
        (closeAllViewModal)="closeAllViewModal()"></app-liveview-list>
    </div>
  </div>

  <!-- All  camera Live view-->
  <div class="modal" id="myModalThisCameraview'+panelName" tabindex="-1" aria-labelledby="allcammaskingviewLabel"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-masking-view">
      <div class="modal-content" style="height:inherit">
        <div class="modal-header bg-dark flex-wrap gap-3 p-3">
          <h6 class="modal-title text-white">
            <!-- <span class="label-online"></span>  -->
            {{wallsData?.siteName}} - {{wallsData?.cameraName}}
          </h6>

          <div class="popup-masking-toggle">
            <label>Masking View</label>
            <span>
              <img src="assets/images/icons/icon-armed.svg" />
            </span>
          </div>

          <button type="button" class="btn btn-outline-white ms-auto">
            Trigger Alarm
          </button>
          <button type="button" class="btn btn-orange px-5" (click)="closeAllViewModal()">
            Close
          </button>
        </div>

        <div class="modal-body" style="background:white">
          <div class="row g-4" *ngIf="liveViewList.length > 0">
            <div class="col-xl-4 col-lg-6" *ngFor="let item of liveViewList; let i = index;">
              <div class="position-relative overflow-hidden" (dblclick)="openWinPage(item)">
                <div class="position-absolute top-0 start-0 bg-light bg-opacity-90 rounded-top gap-3 z-index-1 w-100">
                  <div class="d-flex align-items-center justify-content-between py-2 px-3"
                    style="background: #0c0404d4;">
                    <h6 class="text-white">
                      <span class="label-online-sm"></span>
                      {{item.cameraName}}
                    </h6>

                    <!-- <button type="button" class="btn btn-sm btn-orange px-4">
                      Close
                    </button> -->
                  </div>
                </div>

                <div class="ratio ratio-16x9 rounded-2 overflow-hidden">
                  <!-- [src]="base_url+'MobileArchivePlayer?event=forward&sessionId='+item.sessionId+'&test='+item.timeCount" -->
                  <!-- {{item.archiveUrl+'&test='+item.timeCount}} -->
                  <img [src]="item.archiveUrl+'&test='+item.timeCount" style="top: 35px;" allowfullscreen />
                  <!-- <video muted autoplay loop style="width: 500px" class="img-fluid" controls>
                    <source
                      src="https://res.cloudinary.com/demo/video/upload/c_fill,g_auto:faces,h_150,w_150/r_20/v1/docs/walking_talking.mp4"
                      type="video/mp4" />
                    Browser not supported
                  </video> -->
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer bg-dark justify-content-center py-4 px-lg-7">
          <div class="modal-masking-control" #time_bar id="activityBarDiv" style="width: 100%; padding: 16px 60px;">
            <svg height="55" [id]="'svg'+panelName" xmlns="http://www.w3.org/2000/svg" version="1.1"
              style="overflow: visible; width: 100%;">

              <rect id="activityBarView" height="1" x="4" y="52"
                style="fill:white;stroke-width:7px;stroke:#0C5B82;cursor:pointer; width: calc(100% - 7px);">
              </rect>

              <rect id="zoomBar" style="fill:none;stroke-width:2;stroke:red;cursor:pointer" x="10" y="18" width="1"
                height="30"></rect>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Site Map Modal Popup -->
  <div [id]="'siteMapModal'+panelName" class="modal cameraLiveViewModel col col-sm-2 col-md-2 col-xl-2" tabindex="-1"
    aria-labelledby="siteMapModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content" style="height:inherit">
        <div class="modal-header">
          <h5 class="modal-title">Site Map of - {{wallsData?.siteName}} </h5>
          <span class="close" data-bs-dismiss="modal">&times;</span>
        </div>
        <div class="modal-body c-lv-view">
          <div class="spinner-container" *ngIf="showLoading">
            <div class="loading-text">Loading...</div>
          </div>
          <div class="siteMapImage-container">
            <img [src]="siteMapImgSrc" *ngIf="siteMapImgSrc" alt="Site plan preview not available"
              class="siteMapImage" />
            <div class="loading-text" *ngIf="noImg">{{ noImg }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>