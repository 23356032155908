import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToasterService } from "./toaster/toaster.service";
import { environment } from "src/environments/environment";
import { LoginService } from "./login/login.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(public router: Router, private toasterService: ToasterService,
    private loginService: LoginService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        //console.error("Error is intercept", error);
        if (error?.error?.statusMessage == "TOKEN_EXPIRED" || error?.error === "TOKEN_EXPIRED" || error?.error?.error === 'Forbidden') {
          if (localStorage.getItem('user')) {
            //@ts-ignore
            let userDetails = JSON.parse((localStorage.getItem('user'))).user;
            const username = userDetails?.username;
            this.loginService.signOutRequest(username).subscribe((res: any) => {
              localStorage.clear();
              this.router.navigate(['/login']);
              //location?.reload();
            });
          }
        }
        const excludueHeartBeat = error?.url?.indexOf('heartbeat') > 0 ? false : true;
        if (excludueHeartBeat) {
          //this.toasterService.showErrorLeft(error?.message);
        }
        return throwError(error.message);
      })
    );
  }
}