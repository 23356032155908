import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import SwiperCore, { Pagination, Swiper, SwiperOptions } from "swiper";
SwiperCore.use([Pagination]);
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from '../../services/login/login.service';
import { first } from 'rxjs/operators';
import { ToasterService } from '../../services/toaster/toaster.service';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { permissions } from '../../models/globalpermissions';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('swiperSlideShow') swiperSlideShow: any;
  config: SwiperOptions = {};
  username: string = "";
  password: string = "";
  error_password: boolean = false;
  error_username: boolean = false;
  isDevEnable: any = false;
  forceLogout: any = false;
  loginSub = new Subscription();

  public passwordHidden = true;
  currentYear: any =new Date().getFullYear();
  constructor(
    private sharedSrv: SharedsrvService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private authSrv: AuthService,
    private activateRoute: ActivatedRoute,
    private http: HttpClient,
  ) {
    let headerEnabledObj: any = {
      "setHeadingEnabled": false
    }
    this.sharedSrv.setHeadingEnabled(headerEnabledObj);
  }
  ngOnInit(): void {
    this.sharedSrv.initDisableBackButtonOnInit();
    permissions.autoplay = false;
    this.activateRoute.queryParams.subscribe((params) => {
      this.isDevEnable = !!params['dev'] || environment.devFlag;
      this.forceLogout = !!params['force'];
    });
    localStorage.removeItem("PlayText");
    // localStorage.removeItem("isMirrorEnabled");
    localStorage.removeItem("isMirrorSafeLogout");
  }
  ngAfterViewInit(): void {
    this.config = {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    }
  }
  checkLogin = () => {
    if (this.username && this.password) {
      this.requestLogin({
        "username": this.username,
        "password": this.password
      });
    } else {
      if (!this.username) {
        this.error_username = true;
      }
      if (!this.password) {
        this.error_password = true;
      }
      this.spinner.hide();
    }
  }
  requestLogin = (requestBody: any) => {
    if (this.authSrv.hasLoggedInAlready(requestBody) && this.isDevEnable) {
      this.router.navigateByUrl(this.authSrv.navigateRouteTo());
    } else {
      if (this.isDevEnable || this.forceLogout) {
        this.loginService.forceSessionOut(requestBody).subscribe((res: any) => {
          this.spinner.show();
          this.loginSub = this.loginService.postLogin(requestBody)
            .pipe(first())
            .subscribe({
              next: (response: any) => {
                this.spinner.hide();
                if (response?.success == true && response?.data?.token && response["PRO-TOKEN"]) {
                  permissions.browserRefresh = false;
                  localStorage.setItem('user', JSON.stringify(response?.data));
                  localStorage.setItem('token', JSON.stringify(response?.data?.token));
                  localStorage.setItem('pro-token', JSON.stringify(response["PRO-TOKEN"]));
                  this.sharedSrv.setUserValue(response?.data);
                  this.authSrv.setLoginStatus(response["PRO-TOKEN"]);
                  if (response?.data?.user?.group[0]) {
                    localStorage.setItem("userType", response?.data?.user?.group[0]);
                  }
                  this.router.navigateByUrl(this.authSrv.setRouteSegment());
                  this.toasterService.showSuccess("Login Success");
                } else if (response?.success == false && response?.data) {
                  if (response?.data?.message) {
                    this.toasterService.showError(response?.data?.message);
                  }
                  else {
                    this.toasterService.showError(response?.data);
                  }
                } else {
                  this.toasterService.showError("Invalid username or password");
                  this.error_username = true;
                  this.error_password = true;
                }
              },
              error: error => {
                this.spinner.hide();
                this.toasterService.showError("Login Error");
              }
            });
        });
      } else {
        this.spinner.show();
        this.loginSub = this.loginService.postLogin(requestBody)
          .pipe(first())
          .subscribe({
            next: (response: any) => {
              if (response?.success == true && response?.data?.token && response["PRO-TOKEN"]) {
                localStorage.setItem('user', JSON.stringify(response?.data));
                localStorage.setItem('token', JSON.stringify(response?.data?.token));
                localStorage.setItem('pro-token', JSON.stringify(response["PRO-TOKEN"]));
                localStorage.setItem('pro-user', response?.data?.user?.username);
                localStorage.setItem('Slots',response?.data?.user?.slots);
                this.sharedSrv.setUserValue(response?.data);
                this.authSrv.setLoginStatus(response["PRO-TOKEN"]);
                if (response?.data?.user?.group[0]) {
                  localStorage.setItem("userType", response?.data?.user?.group[0]);
                }
                setTimeout(() => {
                  this.spinner.hide();
                  this.router.navigateByUrl(this.authSrv.setRouteSegment());
                  this.toasterService.showSuccess("Login Success");
                    this.hostnameAPI();
                }, 0);
              } else if (response?.success == false && response?.data) {
                if (response?.data?.message) {
                  this.toasterService.showError(response?.data?.message);
                } else {
                  this.toasterService.showError(response?.data);
                }
              } else {
                this.toasterService.showError("Invalid username or password");
                this.error_username = true;
                this.error_password = true;
              }
              this.spinner.hide();
            },
            error: error => {
              this.spinner.hide();
              this.toasterService.showError("Login Error");
            }
          });
      }
    }
  }

  userInputChange = (userinput: string) => {
    switch (userinput) {
      case 'username':
        (this.username.length >= 3) ? this.error_username = false : this.error_username = true;
        break;
      case 'password':
        (this.password.length >= 3) ? this.error_password = false : this.error_password = true;
        break;
      default:
        break;
    }
  }

  togglePasswordVisibility() {
    this.passwordHidden = !this.passwordHidden;
  }

  hostnameAPI() {
    //const apiUrl = 'http://127.0.0.1:8000/api/browser/close';
    const apiUrl = 'http://127.0.0.1:8000/api/hostname'
    this.http.get(apiUrl).subscribe(
      (response) => {
        console.log("************Logged In user system:", response);
        if (response) {
          localStorage.setItem('hostnameSuccess', 'true');
        }
      },
      (error) => {
        if (error) {
          localStorage.setItem('hostnameSuccess', 'false');
        }
      }
    );
  }
  ngOnDestroy() {
    this.loginSub.unsubscribe();
    this.sharedSrv.destroyDisableBackButtonOnInit();
  }
}
