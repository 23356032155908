<h2 mat-dialog-title class="dialog-title" style="background-color: #0c5b82;color: white;">
    <span class="dialog-title-text">Site Map</span>
    <button mat-icon-button mat-dialog-close class="close-button" style="float: right;color: white;margin-top: 5px;">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="dialog-content">
    <div class="example-container mat-elevation-z8" tabindex="0"
        style="width: auto; height: auto; border: 2px solid #282928;margin-top: 5px;">
        <img [src]="siteMapImgSrc" *ngIf="siteMapImgSrc" alt="Site plan preview not available" class="siteMapImage" />
    </div>
</mat-dialog-content>