import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import { WallsService } from 'src/app/services/walls/walls.service';
import { AccessPermissions } from '../../models/loginpermissions';
import { TagsList } from '../../models/tagsList';
import { permissions } from '../../models/globalpermissions';
import { environment } from 'src/environments/environment';
import { ToasterService } from '../../services/toaster/toaster.service';
import { FirebaseNotificationService } from '../../services/firebase-notification/firebase-notification.service';

@Component({
  selector: 'app-wall-slot',
  templateUrl: './wall-slot.component.html',
  styleUrls: ['./wall-slot.component.scss']
})
export class WallSlotComponent implements OnInit, OnDestroy {
  L1EscalatingTags: any = [];
  L1ClosingTags: any = [];
  L1FeedbackTags: any = [];
  @Input() wallsObj: any;
  @Input() permissionsInputObj: any;
  @Input() panelName: string = "";
  @Output() submitEmit = new EventEmitter();
  ctx: any;
  wallsData: any;
  formModal: any;
  thumbnailVideo: any;
  eventObj: any = {
    "commentMsg": "",
    "eventLink": "",
    "sitename": "",
    "potentialId": "",
    "pannelName": ""
  }
  commentMessage: string = "";
  datapoints: any = [
    { x: 0, y: 46 },
    { x: 73, y: 30 },
    { x: 147, y: 39 },
    { x: 300, y: 36 },
    { x: 370, y: 49 },
    { x: 480, y: 59 },
    { x: 480, y: 0 },
    { x: 301, y: 0 },
    { x: 0, y: 0 }
  ];
  isShown: boolean = false; // hidden by default
  videoLink: any;
  ctx_1: any;
  popupWinWidth: number = (window.screen.width) ? window.screen.width / 1.8 : 400;
  popupWinHeight: number = (window.screen.height) ? window.screen.height / 1.85 : 400;
  popupWinTop: number = (window.screen.height) ? window.screen.height / 5.8 : 400;
  popupWinLeft: number = (window.screen.width) ? window.screen.width / 4.3 : 400;
  eventDuration: number = 0;
  eventCurrentTime: number = 0;
  intervalSetter: any;
  eventAutoClosetime: number = 120;
  panelPopup: any = {
    left: 0,
    top: 0,
    bottom: 0,
    width: 545,
    height:245
  };
  wallsCount: number = 4;
  videoHeight: number = 600;
  videoWidth: number = 300;
  enablePlayValue: string = "";
  escalationMessage: string = "";
  levelName: string = "";
  showPanelOption: boolean = false;
  panelEventTriggers = {
    "landed": true,
    "clicked": false,
    "inshorttime": false,
  }
  replayPlayedTimes: number = 0;
  isReplayEnabled: boolean = true;
  isDevFlag: boolean = false;
  isButtonDisabled: boolean = true;
  isMaskingTrimByHeight:boolean=false

  //bolo status
  hasBoloNotes:boolean = false;
  isBolo:boolean = false;
  boloNotes:any = [];
  boloNotesMesaage: any;
  boloDataPotentialId:any = [];
  wallDuration:number = 0;
  eventLandedTimeOWall:Date = new Date();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sharedSrv: SharedsrvService,
    private wallsService: WallsService,
    private toasterService: ToasterService,    
    private fbServie:FirebaseNotificationService,
    private wallService: WallsService,
    private sharedService: SharedsrvService,
  ) {
    permissions.isfullscreen = false;
    if (this.sharedSrv && this.sharedSrv.getPlayPause() && this.sharedSrv.getPlayPause().subscribe()) {
      this.sharedSrv.getPlayPause().subscribe((val: any) => {
        if (val) {
          this.enablePlayValue = val;
        }
        // this.palyPauseAll();
      });
    }
    this.levelName = this.sharedSrv.getMonitorLevels();
    if (this.levelName == AccessPermissions.levelName1) {
      this.L1ClosingTags = TagsList.L1_CloseTagsList;
      this.L1EscalatingTags = TagsList.L1_EscalationTagsList;
      this.L1FeedbackTags = TagsList.L1_FeedbackTagsList;
    }
    if (environment.FEATURE_FLAG.isFirebase) {
      this.fbServie.getFBNotificationEventObj().subscribe((fbEvent: any) => {
         // console.log(fbEvent);
        if (fbEvent?.data?.EventId && this.wallsObj?.eventid === fbEvent?.data?.EventId && fbEvent?.notification?.title === "FORCE_CLOSE") {
          let eventObj = {
            eventId: this.wallsData?.eventid,
            actionTag: "FORCE_CLOSE",
            actionNote: "FORCE_CLOSE",
            away: permissions.isSafeLogoutEnabled,
            login: permissions.isSafeLogoutEnabled ? false : true,
            tagType: "FORCE_CLOSE",
            potentialId: this.wallsData?.potentialId,
            pannelName: this.panelName,
          }
          // console.log("fbEvent?.notification?.body", fbEvent?.notification?.body)
          eventObj["forceCloseMsg"]= fbEvent?.notification?.body;
          this.submitEmit.emit(eventObj); 
          this.wallsObj = {};
        }
      });
    }
  }
  ngOnInit(): void {
    this.wallsData = this.wallsObj;
    
    if (this.permissionsInputObj.autoplay) {
      this.getReviewEventId();
    }
    if (environment.devFlag) {
      this.isDevFlag = true;
    }
     //getting bolo Message status from share service 
     this.sharedSrv.boloNotes$.subscribe((hasBoloObj:any)=>{
      if(hasBoloObj?.panelName == this.panelName){
        this.hasBoloNotes = hasBoloObj?.isBolo;
      }
    })
    this.getByPotentialId();
    
  }
  getByPotentialId() {
    const pid = this.wallsData?.potentialId;
    this.wallService.boloFetchEverySlot(pid).subscribe((res: any) => {
        let boloObj: any = {
            panelName: this.panelName,
            isBolo: false
        };
        if (res?.success && res?.data?.length > 0) {
            const boloRecord = res.data.find((record: any) => record.potentialId === pid);
            if (boloRecord) {
                this.boloNotes = res?.data?.boloNotes?.notes;
                this.boloNotesMesaage = boloRecord?.eventData;
                boloObj.isBolo = true;
            }
        }
        this.sharedService.updateBoloNoteStatus(boloObj);
    });
}
  ngAfterViewInit(): void {
    let eleVideo = (document.getElementById("video_" + this.panelName) as HTMLVideoElement);
    this.getVideoCurrentTime(eleVideo);
    this.eventTimerDuration();
    setTimeout(() => {
      if (eleVideo.currentTime > 0) {
      } else {
        eleVideo.src = this.wallsObj.eventLink;
      }
      this.getVideoCurrentTime(eleVideo);
    }, 3000);
    // if (eleVideo) {
    //   // let playPromiseVideo: any = eleVideo?.pause();
    //   // if (playPromiseVideo !== undefined) {
    //   //   playPromiseVideo?.then(() => {
    //   //     // Automatic playback started!// Show playing UI // We can now safely pause video...
    //   //     eleVideo?.play();
    //   //   })
    //   //     .catch((error: any) => {
    //   //       // Auto-play was prevented // Show paused UI.
    //   //     });
    //   // }
    //   // // eleVideo?.play();
    //   ////
    //   // var isPlaying = eleVideo.currentTime>0 && !eleVideo.paused && !eleVideo.ended
    //   // && eleVideo.readyState>eleVideo.HAVE_CURRENT_DATA;
    //   // if(!isPlaying){
    //   //   eleVideo.play();
    //   // }
    //   ////
    //    // Show loading animation.
    //   var playPromise = eleVideo.play();
    //   if (playPromise !== undefined) {
    //     playPromise.then(_ => {
    //       // Automatic playback started!
    //       // Show playing UI.
    //     })
    //       .catch(error => {
    //         // Auto-play was prevented
    //         // Show paused UI.
    //       });
    //   }

    // } 
    // let eleFullscreenVideo = (document.getElementById("f_video_" + this.panelName) as HTMLVideoElement);
    // if (eleFullscreenVideo) {
    //   eleFullscreenVideo?.play(); 
    // } 
    this.sharedSrv.getFullscreenEvent().subscribe((resp: any) => {
      /// apply changes here for fullscreen/normalscreen
      if (resp != null) {
        let ele = document.getElementById("canvas_" + this.panelName);
        if (ele) {
          if (ele.style.display == 'block') {
            this.ctx?.clearRect(0, 0, 640, 640);
            this.isShown = false;
            ele.style.display = 'none';
            // this.enableMask(false);
          }
        }
      }
    });
    this.startInterval();
  }
  getVideoCurrentTime = (eleVideo: any) => {
    if (eleVideo) {
      setInterval(() => {
        let duration = Math.round(eleVideo.duration);
        if (typeof duration === 'number' && duration > 0) {
          this.eventDuration = Math.trunc(eleVideo.duration);
        }
        this.eventCurrentTime = Math.trunc(eleVideo.currentTime);
      }, 1000);
    }
  }
  eventTimerDuration = () => {
    setInterval(() => {
      this.wallDuration = this.wallDuration + 1;
    }, 1000);
  }
  replayClicked = (element: string) => {
    let ele = document.getElementById(element + this.panelName) as HTMLVideoElement;
    /* if (ele ) {
      if (this.replayPlayedTimes <= 1 ) {
        ele.currentTime = 0;
        ele.play();
      } else {
        this.isReplayEnabled = false;
        this.removeElementPlayControls();
      }
      this.replayPlayedTimes++;      
    } */

    //when second click disabling the controls and button
    if (ele) {
      if (this.replayPlayedTimes === 0) {
        ele.currentTime = 0;
        ele?.play();
        this.isReplayEnabled = false;
      }
      this.replayPlayedTimes++;
    }

  }
  removeElementPlayControls = () => {
    let videoEle = (document.getElementById("video_" + this.panelName) as HTMLVideoElement);
    if (videoEle) {
      videoEle?.removeAttribute('controls');
    }
  }

  ngOnDestroy() {
    let videoEle = document.getElementById('f_video_' + this.panelName) as HTMLVideoElement;
    if (videoEle) {
      videoEle?.pause();
      let source = videoEle.getElementsByTagName('source')[0];
      source?.setAttribute('src', '');
      source?.removeAttribute('src');
      videoEle?.load();
      videoEle?.remove();
    }
    let videoEle1 = (document.getElementById("video_" + this.panelName) as HTMLVideoElement);
    if (videoEle1) {
      videoEle1?.pause();
      let source = videoEle1.getElementsByTagName('source')[0];
      source?.setAttribute('src', '');
      source?.removeAttribute('src');
      videoEle1?.load();
      videoEle1?.remove();
    }
    caches?.keys().then(list => list?.map(key => caches?.delete(key)));
    // let sources1 = videoEle1.getElementsByTagName('source');
    // sources1[0].src = '';
    // videoEle1.removeAttribute('controls');
    // videoEle1.removeAttribute('src');
  }
  submit() {
    this.eventObj = {
      "commentMsg": this.commentMessage,
      "eventLink": this.wallsData?.eventLink,
      "siteName": this.wallsData?.sitename,
      "potentialId": this.wallsData?.potentialId,
      "pannelName": this.panelName
    }
    this.submitEmit.emit(this.eventObj);
  }
  // playVideo(wallsData?: any) {
  //   var video = <HTMLVideoElement>document.getElementById('video_' + this.panelName);
  //   if (video && video.paused) {
  //     video?.play();
  //     wallsData['status'] = 'pause'
  //   } else {
  //     video?.pause()
  //     wallsData['status'] = 'play'
  //   }
  //   this.applyIndicatorColors(this.panelName, "clicked");
  // }
  palyPauseAll = () => {
    for (let i = 1; i <= this.wallsCount; i++) {
      let videoELe = document.getElementById("video_panel" + i) as HTMLVideoElement;
      if (videoELe) {
        if (this.enablePlayValue == "PAUSE") {
          videoELe?.pause();
        } else if (this.enablePlayValue == "PLAY") {
          videoELe?.play();
        } else {
        }
      }
    }
  }

  enableMask(fScreen: boolean) {
    //this.isMaskingTrimByHeight =false
    //this.hideMasking(fScreen);
    // this.isShown = false;
    // this.fbServie.setFirebaseObjForTesting();
    this.closeTagsModal(fScreen);
    this.closeEscalationModal(fScreen);
    this.closeFeedbackModal(fScreen);
    this.closeSiteinfoModal(fScreen);
    this.closeDaynightModal(fScreen);
    this.closeBoloModal(fScreen);
    
    if (fScreen) {
      let videoEle = document.getElementById('f_video_' + this.panelName) as HTMLElement;
      this.panelPopup = videoEle.getClientRects()[0];
    } else {
      let videoEle = document.getElementById("video_" + this.panelName) as HTMLElement;
      this.panelPopup = videoEle.getClientRects()[0];
    }
    this.drawMask(fScreen);
  }

  drawMask(fScreen: boolean) {
    let maskingDataObj: any;
    if (typeof (this.wallsData?.unifiedMaskingdata) != 'object' && this.wallsData?.unifiedMaskingdata && this.wallsData?.unifiedMaskingdata !="-" && permissions.isUnifiedMaskingLocalEnabled == true) {
      maskingDataObj = JSON.parse(this.wallsData?.unifiedMaskingdata);
      if (maskingDataObj?.maskOutList?.length > 0) {
        this.wallsData.maskingdata = maskingDataObj;
        this.wallsData.width = this.wallsData.unifiedMaskwidth;
        this.wallsData.height = this.wallsData.unifiedMaskheight;
      } else {
        if (typeof (this.wallsData?.maskingdata) != 'object') {
          maskingDataObj = JSON.parse(this.wallsData.maskingdata);
          this.wallsData.maskingdata = maskingDataObj;
        }
      }
    } else if (typeof (this.wallsData?.maskingdata) != 'object') {
      maskingDataObj = JSON.parse(this.wallsData.maskingdata);
      this.wallsData.maskingdata = maskingDataObj;
    }   
    this.isShown = !this.isShown;
    let canvas_ = (fScreen) ? "f_canvas_" : "canvas_";
    let canvasEle: any = (document.getElementById(canvas_ + this.panelName) as HTMLCanvasElement);
    if (this.isShown) {
      canvasEle.style.display = 'block';
      if (canvasEle) {
        setTimeout(() => {
          canvasEle.style.height = (this.panelPopup.height) + "px";
          canvasEle.style.width = (this.panelPopup.width) + "px";
          canvasEle.style.left = (this.panelPopup.left) + "px";
          canvasEle.style.top = (this.panelPopup.top) + "px";
          if (fScreen) {
            canvasEle.style.left = 16 + "px";
            canvasEle.style.top = 12 + "px";
          }
          let videoElement = (fScreen) ? document.getElementById('f_video_' + this.panelName) as HTMLElement : document.getElementById('video_' + this.panelName) as HTMLElement;
          this.ctx = canvasEle.getContext('2d');
          this.wallsData.maskingdata.maskOutList.forEach((item: any, index: number) => {
            if (item.polygonPoints) {
              this.datapoints = this.wallsData.maskingdata.maskOutList[index].polygonPoints;
              this.datapoints.push(this.wallsData.maskingdata.maskOutList[index].polygonPoints[0]);
              // this.wallsData.width = 640;
              // this.wallsData.height = 480;
              this.ctx = this.sharedSrv.getCanvasContextForMasking(this.ctx, this.datapoints, fScreen, videoElement?.getBoundingClientRect(), this.wallsData);
            }
          });
        }, 0);
      }
    }
    else {
      this.ctx.clearRect(0, 0, this.panelPopup.width, this.panelPopup.height);
      canvasEle.style.display = 'none';
    }
  }

  // ShowPopup(data: any) {
  //   this.changeDetectorRef.detectChanges();
  //   this.thumbnailVideo = data;
  //   let videoUrl = (document.getElementById('popVideo') as HTMLVideoElement);
  //   if (videoUrl) {
  //     videoUrl.src = this.thumbnailVideo;
  //   }
  //   this.changeDetectorRef.detectChanges();
  //   this.showCanvas();
  // }

  // showCanvas() {
  //   let canvasElem: any = (document.getElementById('canvas_1') as HTMLCanvasElement);
  //   canvasElem.style.visibility = 'visible';
  //   canvasElem.style.top = '10px';
  //   canvasElem.style.position = 'absolute';
  //   canvasElem.style.width = '131%';
  //   this.ctx_1 = canvasElem.getContext('2d');
  //   this.ctx_1 = this.getContext(this.ctx_1, this.datapoints);
  // }
 closePopup() {
    this.thumbnailVideo = '';
    this.ctx_1?.clearRect(0, 0, 640, 60);
    this.changeDetectorRef.detectChanges();
  }
  escalationTags(tagtype: string, tagMessage: string) {
    this.submitEmit.emit(this.getEventObj(tagtype, tagMessage));
    this.escalationMessage = "";
  }
  closingTags(tagtype: string, tagMessage: string) {
    let autoCloseObjDetect = {
      'eventLandedTime': this.eventLandedTimeOWall,
      'eventcloseTime': new Date(),
      'eventReasonToClose': tagMessage + '___' + tagtype,
      'eventId': this.wallsObj?.eventid 
    };
    console.log("closing obj : ", autoCloseObjDetect);
    this.submitEmit.emit(this.getEventObj(tagtype, tagMessage));
    this.escalationMessage = "";
  }
  autoClosingTags(tagtype: string, tagMessage: string) {
    let autoCloseObjDetect = {
      'eventLandedTime': this.eventLandedTimeOWall,
      'eventcloseTime': new Date(),
      'eventReasonToClose': tagMessage + '___' + tagtype,
      'eventId': this.wallsObj?.eventid
    };
    console.log("autoclose obj : ", autoCloseObjDetect);
    this.submitEmit.emit(this.getEventObj(tagtype, tagMessage));
    this.escalationMessage = "";
  }
  feedbackTags(tagtype: string, tagMessage: string, fscreen?: boolean) {
    this.closeFeedbackModal(fscreen);
    let feedBackPayload = {
      "potentialId": this.wallsData?.potentialId,
      "siteName": this.wallsData?.siteName,
      "feedbacktag": tagMessage,
      "eventId": this.wallsData?.eventid,
      "cameraId" : this.wallsData?.cameraId,
      "cameraName": this.wallsData?.cameraName,
      "queueName": this.wallsData?.currentQueue,
      "level" : this.wallsData?.eventFlowLevel,
      "eventLink" : this.wallsData?.eventLink,
      "eventTimeStrInCT" : this.wallsData?.eventTimeStrInCT,
    }
    this.wallsService.postFeedbackEventData(feedBackPayload).subscribe((resp: any) => {
      if (resp) {
        //show success toaster message if require
      }
    }, (error: any) => {
      //show error  toaster messsage if require
    });
  }

  getEventObj(tagtype: string, tagMessage: string) {
    return this.eventObj = {
      "eventId": this.wallsData?.eventid,
      "actionTag": tagMessage,
      "actionNote": (tagtype == 'escalations') ? this.escalationMessage : "",
      // "userId": "1t1",
      "away": permissions.isSafeLogoutEnabled,
      "login": true,
      "tagType": tagtype,
      "potentialId": this.wallsData?.potentialId,
      "pannelName": this.panelName,
    }
  }
  startInterval = () => {
    //this.eventAutoClosetime =120;
    this.intervalSetter = setInterval(() => {
      this.eventAutoClosetime = this.eventAutoClosetime - 1;
      if (this.eventAutoClosetime < 1) {
        this.closeEvent();
      }
      if (this.eventAutoClosetime < 15) {
        this.applyIndicatorColors(this.panelName, "inshorttime")
      }
      if (this.eventAutoClosetime <= 112 ) {
        let videoEle= document.getElementById("video_"+this.panelName) as HTMLVideoElement;
        if(videoEle){
        this.checkVideoCurrentTime(videoEle);
        }       
      }
    }, 1000);
  }
  checkVideoCurrentTime = (videoEle: any) => {
    setTimeout(() => {
      if (videoEle) {
        if (videoEle.currentTime) {
          if (Math.round(videoEle.duration) > 20 && Math.round(videoEle.currentTime) >= 18) {
            this.showPanelOption = true;
          } else if (Math.round(videoEle.currentTime) >= (videoEle.duration-3)) {
            this.showPanelOption = true;
          } else {
            this.showPanelOption = false;
            this.checkVideoCurrentTime(videoEle);
          }
        }
      }
    }, 1000)
  }
  cancelInterval() {
    clearInterval(this.intervalSetter);
  }
  closeEvent() {
    // this.closingTags('AutoClose', 'Event Auto closed');
    this.autoClosingTags('autoclose', 'Event Auto closed');
    this.cancelInterval();
  }

  openFeedbackModal(event: any, fscreen?: boolean) {
    let feedbackModalName = "";
    feedbackModalName = (fscreen) ? "f_myModalfeedback" : "myModalfeedback";
    this.panelPopup = this.getPopupDimentions(feedbackModalName, this.panelName, fscreen);
    this.closeEscalationModal(fscreen);
    this.closeTagsModal(fscreen);
    this.closeSiteinfoModal(fscreen);
    this.hideMasking(fscreen);
    this.isShown = false;
    this.closeDaynightModal(fscreen);
    this.closeBoloModal(fscreen);
  }
  openEscalationModal(event: any, fscreen?: boolean) {
    let escalationModalName = "myModalEscalation";
    escalationModalName = ((fscreen) ? escalationModalName = "f_myModalEscalation" : escalationModalName = "myModalEscalation");
    this.panelPopup = this.getPopupDimentions(escalationModalName, this.panelName, fscreen);
    // this.panelPopup =  this.getPopupDimentions("myModalEscalation",this.panelName);
    this.closeFeedbackModal(fscreen);
    this.closeTagsModal(fscreen);
    this.closeSiteinfoModal(fscreen);
    this.hideMasking(fscreen);
    this.isShown = false;
    this.closeDaynightModal(fscreen);
    this.closeBoloModal(fscreen);


  }
  getPopupDimentions(modalName: string, panelName: string, fscreen?: boolean) {
    let modal1 = document.getElementById(modalName + panelName) as HTMLElement;
    modal1.style.display = "block";
    let videoId = (fscreen) ? "f_video_" : "video_"
    let videoEle = document.getElementById(videoId + panelName) as HTMLElement;
    return videoEle.getClientRects()[0];
  }
  getPopupDimentionsOnly(panelName: string, fscreen?: boolean) {
    let videoId = (fscreen) ? "f_video_" : "video_"
    let videoEle = document.getElementById(videoId + panelName) as HTMLElement;
    this.panelPopup = videoEle.getClientRects()[0];
  }
  showSiteInfoModal(event: any, fScreen: boolean = false) {
    if (fScreen) {
      let videoEle = document.getElementById("f_video_" + this.panelName) as HTMLElement;
      //console.log(videoEle.getClientRects()[0]);
      this.panelPopup = videoEle.getClientRects()[0];
      let modal1 = document.getElementById("f_cameraInfo_" + this.panelName) as HTMLElement;
      if (modal1.style.display == "block") {
        modal1.style.display = "none";
      } else if (modal1.style.display == "none") {
        modal1.style.display = "block";
      };
    } else {
      this.getPopupDimentionsOnly(this.panelName, fScreen);
      let modal1 = document.getElementById("cameraInfo_" + this.panelName) as HTMLElement;
      if (modal1.style.display == "block") {
        modal1.style.display = "none";
      } else if (modal1.style.display == "none") {
        modal1.style.display = "block";
      };
    }



    this.closeFeedbackModal(fScreen);
    this.closeTagsModal(fScreen);
    this.closeEscalationModal(fScreen);
    this.hideMasking(fScreen);
this.isShown = false;
    this.closeDaynightModal(fScreen);
    this.closeBoloModal(fScreen);

  }

  
  showBoloModal(event:any, fScreen: boolean = false){
    if (fScreen) {
      let modal1 = document.getElementById("boloNotes_" + this.panelName) as HTMLElement;
      if (modal1.style.display == "block") {
        modal1.style.display = "none";
      } else if (modal1.style.display == "none") {
        modal1.style.display = "block";
      };
    } else {
      this.getPopupDimentionsOnly(this.panelName, fScreen);
      let modal1 = document.getElementById("boloNotes_" + this.panelName) as HTMLElement;
      if (modal1.style.display == "block") {
        modal1.style.display = "none";
      } else if (modal1.style.display == "none") {
        modal1.style.display = "block";
      };
    }
    this.closeFeedbackModal(fScreen);
    this.closeTagsModal(fScreen);
    this.closeEscalationModal(fScreen);
    this.hideMasking(fScreen);
this.isShown = false;
    this.closeDaynightModal(fScreen);
    this.closeSiteinfoModal(fScreen);
  }
  showDaynightModal(event: any, fscreen: boolean) {
    this.getPopupDimentionsOnly(this.panelName, fscreen);
    let modal1 = document.getElementById(((fscreen) ? "f_daynightImg_" : "daynightImg_") + this.panelName) as HTMLElement;
    if (modal1.style.display == "block") {
      modal1.style.display = "none";
    } else if (modal1.style.display == "none") {
      modal1.style.display = "block";
    };
    this.closeFeedbackModal(fscreen);
    this.closeTagsModal(fscreen);
    this.closeEscalationModal(fscreen);
    this.closeSiteinfoModal(fscreen);
    this.closeBoloModal(fscreen);
    this.hideMasking(fscreen);
this.isShown = false;

  }
  closeFullScreen(fscreen: boolean = true) {
    // this.closeTagsModal(fscreen);
    // this.closeEscalationModal(fscreen);
    // this.closeFeedbackModal(fscreen);
    // this.closeSiteinfoModal(fscreen);
    // this.hideMasking(fScreen);
    //this.isShown = false;
    // this.closeDaynightModal(fscreen);
    this.closeAllWindows(true);
  }
  openCloseModal(event: any, fscreen?: boolean) {
    let myModalClose = (fscreen) ? "f_myModalClose" : "myModalClose";
    this.panelPopup = this.getPopupDimentions(myModalClose, this.panelName, fscreen);
    this.closeEscalationModal(fscreen);
    this.closeFeedbackModal(fscreen);
    this.closeSiteinfoModal(fscreen);
    this.hideMasking(fscreen);
    this.isShown = false;
    this.closeDaynightModal(fscreen);
    this.closeBoloModal(fscreen);
  }
  closeFeedbackModal(fscreen?: boolean) {
    const modal1 = document.getElementById("f_myModalfeedback" + this.panelName) as HTMLElement;
    const modal2 = document.getElementById("myModalfeedback" + this.panelName) as HTMLElement;
    if (modal1 && modal2) {
      modal1.style.display = "none";
      modal2.style.display = "none";
    }
  }
  closeTagsModal(fscreen?: boolean) {
    const modal1 = document.getElementById("f_myModalClose" + this.panelName) as HTMLElement;
    const modal2 = document.getElementById("myModalClose" + this.panelName) as HTMLElement;
    if (modal1 && modal2) {
      modal1.style.display = "none";
      modal2.style.display = "none";
    }
  }
  closeEscalationModal(fscreen?: boolean) {
    const modal1 = document.getElementById("f_myModalEscalation" + this.panelName) as HTMLElement;
    const modal2 = document.getElementById("myModalEscalation" + this.panelName) as HTMLElement;
    if (modal1 && modal2) {
      modal1.style.display = "none";
      modal2.style.display = "none";
    }
    // let myModalEscalation = (fscreen) ? "f_myModalEscalation" : "myModalEscalation";
    // let modal1 = document.getElementById(myModalEscalation + this.panelName) as HTMLElement;
    // modal1.style.display = "none";
  }
  closeSiteinfoModal(fscreen?: boolean) {
    const modal1 = document.getElementById("f_cameraInfo_" + this.panelName) as HTMLElement;
    const modal2 = document.getElementById("cameraInfo_" + this.panelName) as HTMLElement;
    if (modal1 && modal2) {
      modal1.style.display = "none";
      modal2.style.display = "none";
    }
  }
  closeBoloModal(fscreen?:boolean){
    const modal2 = document.getElementById("boloNotes_" + this.panelName) as HTMLElement;
    if ( modal2) {
      modal2.style.display = "none";
    }
  }
  closeDaynightModal(fscreen?: boolean) {
    const modal1 = document.getElementById("f_daynightImg_" + this.panelName) as HTMLElement;
    const modal2 = document.getElementById("daynightImg_" + this.panelName) as HTMLElement;
    if (modal1 && modal2) {
      modal1.style.display = "none";
      modal2.style.display = "none";
    }
  }
  hideMasking(fscreen?: boolean) {
    if(fscreen){
      let canvasEle1 = (document.getElementById("f_canvas_" + this.panelName) as HTMLCanvasElement);
      if (this.ctx) {
        this.ctx.clearRect(0, 0, 640, 640);
      }
      if (canvasEle1 && canvasEle1.style) {
        canvasEle1.style.display = 'none';
      }
    }else{
      let canvasEle2 = (document.getElementById("canvas_" + this.panelName) as HTMLCanvasElement);
      if (this.ctx) {
        this.ctx.clearRect(0, 0, 640, 640);
      }
      if (canvasEle2 && canvasEle2.style) {
        canvasEle2.style.display = 'none';
      }
    }
  }
  openPanelFullMode = (panelId: any) => {
    alert(panelId);
    let fPanel = document.getElementById("videowrapper_panel1") as HTMLElement;
    let cloneVideoEle = fPanel.cloneNode(true);
   // console.log(fPanel);
    let modal1 = document.getElementById("exampleModalToggle") as HTMLElement;
    let mBody = document.getElementById("fBody");
    //console.log("mboday", mBody);
    mBody?.replaceChildren(cloneVideoEle);
  }
  openfullscreenPopup = (event: any) => {
    this.closeAllWindows(false);
    this.isShown = false;
  }
  //This is for Closing all popups after click on full screen
  closeAllWindows(fscreen:boolean){
    this.closeTagsModal(fscreen);
    this.closeEscalationModal(fscreen);
    this.closeFeedbackModal(fscreen);
    this.closeSiteinfoModal(fscreen);
    this.hideMasking(fscreen);
    this.isShown = false;
    this.closeDaynightModal(fscreen);
    this.closeBoloModal(fscreen);
   }
  /* Function to open video fullscreen mode */
  // openFullscreen() {
  //   //@ts-ignore
  //   let isFullscreenNow = document.webkitFullscreenElement !== null
  //   for (let i = 1; i <= this.wallsCount; i++) {
  //     let videoELe = document.getElementById("video_panel" + i) as HTMLVideoElement;
  //     if (videoELe) {
  //       if (isFullscreenNow) {
  //         if (this.panelName === 'panel' + i) {
  //         } else {
  //           videoELe.pause();
  //         }
  //       } else if (!isFullscreenNow) {
  //         if (this.panelName === 'panel' + i) {
  //         } else {
  //           if (videoELe.paused) {
  //             videoELe.play();
  //           } else {
  //             videoELe.pause();
  //           }
  //         }
  //       }
  //     }
  //   }

  // }

  // goFullScreen = () => {

  //   let elem = (document.getElementById(this.panelName) as HTMLElement);

  //   // if(elem && elem.requestFullscreen){
  //   //     elem.requestFullscreen();
  //   // }
  //   // // @ts-ignore
  //   // else if(elem && elem.mozRequestFullScreen){
  //   //   // @ts-ignore
  //   //     elem.mozRequestFullScreen();
  //   // }
  //   // // @ts-ignore
  //   // else if(elem && elem.webkitRequestFullscreen){
  //   //   // @ts-ignore
  //   //     elem.webkitRequestFullscreen();
  //   // }
  //   // // @ts-ignore
  //   // else if(elem && elem.msRequestFullscreen){
  //   //   // @ts-ignore
  //   //     elem.msRequestFullscreen();
  //   // }

  //   if (elem) {
  //     elem.style.width = "190%";
  //     elem.style.height = "190%";
  //     //console.log("i am here", elem.style.width)
  //   }
  // }
  getReviewEventId() {
    if (this.wallsObj && this.wallsObj?.eventid) {
      this.wallsService.getReviewStartTime(this.wallsObj.eventid).subscribe((res: any) => {
        //console.log('reveiew call done---', res);
        //if (res) {
        /// success message and nothing to show here as per confluence doc.
        //}
      });
    }
  }

  copyInfoDetails(eventLink: any) {
    const unsecuredCopyToClipboard = (text:any) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try{
        document.execCommand('copy')
      } catch(err) {
        console.error('Unable to copy to clipboard',err)
      }
      document.body.removeChild(textArea)
    };
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(eventLink);  
    } else {
      unsecuredCopyToClipboard(eventLink);
    }
    this.toasterService.showInfo("Event URL copied!");
    const windowName = this.sharedSrv.prepareWindowName('viewCopy');
    const evtWindow = window.open(eventLink, "EventUrlPlay", "width=656,height=369");
    permissions.allWindows[windowName] = evtWindow;
  }

  inputEscalationChange() {
    // console.log(this.escalationMessage)
  }

  panelClick = (event: any, panelName: string) => {
    if (!this.panelEventTriggers.inshorttime) {
      this.applyIndicatorColors(panelName, "clicked");
    }
  }
  applyIndicatorColors = (panelName: string, colorEventType: string) => {
    switch (panelName) {
      case 'panel1':
        if (colorEventType == "clicked") {
          this.panelEventTriggers.clicked = true;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = false;
        } else if (colorEventType == "landed") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = true;
        } else if (colorEventType == "inshorttime") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = true;
          this.panelEventTriggers.landed = false;
        }
        break;
      case 'panel2':
        if (colorEventType == "clicked") {
          this.panelEventTriggers.clicked = true;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = false;
        } else if (colorEventType == "landed") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = true;
        } else if (colorEventType == "inshorttime") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = true;
          this.panelEventTriggers.landed = false;
        }
        break;
      case 'panel3':
        if (colorEventType == "clicked") {
          this.panelEventTriggers.clicked = true;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = false;
        } else if (colorEventType == "landed") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = true;
        } else if (colorEventType == "inshorttime") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = true;
          this.panelEventTriggers.landed = false;
        }
        break;
      case 'panel4':
        if (colorEventType == "clicked") {
          this.panelEventTriggers.clicked = true;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = false;
        } else if (colorEventType == "landed") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = false;
          this.panelEventTriggers.landed = true;
        } else if (colorEventType == "inshorttime") {
          this.panelEventTriggers.clicked = false;
          this.panelEventTriggers.inshorttime = true;
          this.panelEventTriggers.landed = false;
        }
        break;

      default:
        break;
    }
  }

}
