<div class="section-login" style="min-height:100vh !important; ">
    <div class="container-fluid px-lg-0 scroll_hide">
        <div class="background_box" style="height: 100% !important;padding: 20px;">
            <div class="row g-0 blue_border">
                <div class="col-lg-6">
                    <div class="login-slider-banner d-lg-block d-none">
                        <div class="swiper login-slider text-center">
                            <div>
                                <div class="login-slider-image">
                                    <img src="assets/images/banner03.png" class="img-fluid" alt="" />
                                </div>

                                <div class="login-slider-content mt-lg-95 mt-30 text-white">
                                    <h4 class="heading-02 mb-15p text-1">Remote Video | Surveillance</h4>
                                    <p class="infoLine">
                                        The Next generation monitoring platform that protects our customers assets.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-lg-6 he100vh">
                    <div class="container resbox" style="height:100%">
                        <div class="row g-0 mfaBox">
                            <div class="col-lg-12">
                                <ng-container>
                                    <div class="d-flex h100">
                                        <div class="login-form form_center" style="margin: auto;">
                                            <h1 class="heading-03 fw-bold text-center text-black-1a main_heading">
                                                Sign in to PRO-SAFE</h1>
                                            <h2
                                                class="heading-05 fw-light text-center text-grey-71 mb-38 mt-12 lh-base">
                                                Remote Video Surveillance
                                            </h2>
                                            <form novalidate="" id="adminLogin" autocomplete="off"
                                                class="adminLogin ng-pristine ng-invalid ng-touched">
                                                <div class="mb-15p position-relative input-gap">
                                                    <div class="icon-input">
                                                        <img src="assets/images/icon-user.svg" alt=""
                                                            class="img-fluid img-size">
                                                    </div><input type="text" name="username" id="email"
                                                        placeholder="User Name" [(ngModel)]="username"
                                                        (ngModelChange)="userInputChange('username')" #ctrl="ngModel"
                                                        class="form-control ng-pristine ng-invalid ng-touched"
                                                        [ngClass]="{'errorbg': error_username === true}">
                                                </div>
                                                <span class="error mb-15p position-relative input-gap"
                                                    *ngIf="error_username">* Please enter username</span>
                                                    <div class="mb-15p mt-20 position-relative input-gap">
                                                        <div class="icon-input">
                                                            <img src="assets/images/icon-lock.svg" alt="" class="img-fluid img-size">
                                                        </div>
                                                        <input type="{{ passwordHidden ? 'password' : 'text' }}" name="password" id="password"
                                                            placeholder="Password" [(ngModel)]="password"
                                                            (ngModelChange)="userInputChange('password')" #ctrl="ngModel"
                                                            class="form-control ng-untouched ng-pristine ng-invalid password-input"
                                                            [ngClass]="{'errorbg': error_password === true}">
                                                        <button type="button" class="password-toggle" (click)="togglePasswordVisibility()">
                                                            <i [ngClass]="passwordHidden ? 'fa fa-eye-slash' : 'fa fa-eye'" style="font-size: 16px;color: #0c5b82;"></i>
                                                        </button>
                                                    </div>
                                                    
                                                <span class="error" *ngIf="error_password">* Please enter
                                                    password</span>
                                                <div
                                                    class="d-flex align-items-center justify-content-between mt-24 mb-40">
                                                    <div class="form-check mb-0 d-flex align-items-center"></div>
                                                </div>
                                                <div class="mb-36">
                                                    <button type="submit" class="btn btn-primary1 w-100 btn-Btn"
                                                        (click)="checkLogin()" (keydown.enter)="checkLogin()" style="height: 45px;">
                                                        Sign in </button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </ng-container>




                                <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">

                                    <div class="modal-dialog modal-dialog-centered modal-md">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title text-truncate pe-10">
                                                    <p
                                                        class="heading-05 fw-normal d-md-flex align-items-center text-truncate">
                                                        <span class="me-lg-30 me-5 d-md-inline d-block"></span>
                                                        <span
                                                            class="p-8 bg-success rounded-circle  d-inline-block me-5 align-middle"></span>
                                                    </p>
                                                </h5>

                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close">Close</button>
                                            </div>
                                            <div class="modal-body p-0">
                                                <div class="ratio ratio-16x9">
                                                    <div id="hlsTimer"></div>
                                                    <div class="liveswitchVideo">

                                                        <div id="rtmpLiveStram">

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="d-flex">
                                <div class="d-flex bottom_box">
                                    <p class="text_copy text_lign">Copyright &#169; {{currentYear}}, Pro-Vigil, Inc. All rights
                                      reserved.
                                  </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>