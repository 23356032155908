import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { permissions } from 'src/app/common/models/globalpermissions';
import { WallsService } from 'src/app/services/walls/walls.service';
import { l1Data } from '../../common/models/apidata';
import { SharedsrvService } from '../../common/services/sharedsrv/sharedsrv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../common/services/toaster/toaster.service';
import { FirebaseNotificationService } from 'src/app/common/services/firebase-notification/firebase-notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit, OnDestroy {
  wallsCount: number = 4;
  isFirstPnlEnable: boolean = false;
  isSecondPnlEnable: boolean = false;
  isThirdPnlEnable: boolean = false;
  isFourthPnlEnable: boolean = false;
  showModalBox: boolean = false;
  panel1Data: any = {};
  panel2Data: any = {};
  panel3Data: any = {};
  panel4Data: any = {};
  isPageLoad: boolean = true;
  permissionObj: any;
  pageLoadTextMsg: string = 'Loading...';
  eventPayload: any;
  panel1ErrorMessage: string = "";
  panel2ErrorMessage: string = "";
  panel3ErrorMessage: string = "";
  panel4ErrorMessage: string = "";

  // landing
  wall1PullEventAPISub = new Subscription();
  wall2PullEventAPISub = new Subscription();
  wall3PullEventAPISub = new Subscription();
  wall4PullEventAPISub = new Subscription();

  // TODO: feedback api integration

  //close action
  wall1CloseEventAPISub = new Subscription();
  wall2CloseEventAPISub = new Subscription();
  wall3CloseEventAPISub = new Subscription();
  wall4CloseEventAPISub = new Subscription();

  //esclation action
  wall1EscalateEventAPISub = new Subscription();
  wall2EscalateEventAPISub = new Subscription();
  wall3EscalateEventAPISub = new Subscription();
  wall4EscalateEventAPISub = new Subscription();
  staticObj: any = l1Data[0];
  panel1FBMessageCheck: boolean = false;
  panel2FBMessageCheck: boolean = false;
  panel3FBMessageCheck: boolean = false;
  panel4FBMessageCheck: boolean = false;

  hasBoloNotes:any;
  constructor(
    public wallService: WallsService,
    private toastr: ToasterService,
    private sharedService: SharedsrvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fbService: FirebaseNotificationService) {

      
     //getting bolo Message status from share service 
     this.sharedService.boloNotes$.subscribe((hasBoloObj:any)=>{
       if(hasBoloObj?.panelName == 'panel1'){
        this.panel1Data.isBolo = hasBoloObj?.isBolo;
      } 
      else if(hasBoloObj.panelName == 'panel2'){
        this.panel2Data.isBolo = hasBoloObj?.isBolo;
      }
      else if(hasBoloObj.panelName == 'panel3'){
        this.panel3Data.isBolo = hasBoloObj?.isBolo;
      }
      else if(hasBoloObj.panelName == 'panel4'){
        this.panel4Data.isBolo = hasBoloObj?.isBolo;
      }
    })
     }

  ngOnInit(): void {
    // if (browserRefresh) {
    //   this.router.navigateByUrl('/login');
    //   this.toastr.showError("You are redirected to login page due to page refresh!");
    // }
    this.sharedService.initDisableBackButtonOnInit();
    this.activatedRoute.data.subscribe((response: any) => {
      if (response?.tagsResolverList?.data) {
        this.wallService.fetchTagsFromResolverService(response.tagsResolverList);
      }
    });
    permissions.autoplay = true;
    permissions.wallscount = this.wallsCount = 4;
    this.permissionObj = permissions;
    this.eventPayload = {
      //"userId": "1t1",
      "away": permissions.isSafeLogoutEnabled,
      "login": true
    };

    this.sharedService.getFullscreenEvent().subscribe((resp: any) => {
      if (resp) {
        /// this emmiter pass fullscreen status to wall-slot to hide masking
        this.sharedService.isFullScreenModeStatus = resp;
      }
    });
    // if (permissions.browserRefresh) {
    //   this.getAlleventsDataForRefresh();
    // } else {
    //   this.getWallsEventData();
    // }

    this.getAlleventsDataForRefresh();
    this.sharedService.getUserdataPlaypause().subscribe((resp: any) => {
      //// events to load on play pause.
      if (resp == "PLAY") {
        this.getWallsEventData();
      }
    });
  }
  getAlleventsDataForRefresh = () => {
    if (environment.FEATURE_FLAG.isFirebase) {
      this.fbService.requestFBAccessPermission();
    }
    permissions.browserRefresh = false;
    this.isFirstPnlEnable = true;
    this.panel1ErrorMessage = "Loading...";
    this.isSecondPnlEnable = true;
    this.panel2ErrorMessage = "Loading...";
    this.isThirdPnlEnable = true;
    this.panel3ErrorMessage = "Loading...";
    this.isFourthPnlEnable = true;
    this.panel4ErrorMessage = "Loading...";
    this.eventPayload = this.getUpdatedEventPayloadForPullReq();
    this.wallService.pullRefreshEventData(this.eventPayload).subscribe((resp: any) => {
      if (resp?.eventlist?.length > 0) {
        let length = resp.eventlist.length;
        switch (length) {
          case 1:
            this.extractFirstWallObj(resp.eventlist[0]);
            this.getSecondWallData();
            this.getThirdWallData();
            this.getFourthWallData();
            break;
          case 2:
            this.extractFirstWallObj(resp.eventlist[0]);
            this.extractSecondWallObj(resp.eventlist[1]);
            this.getThirdWallData();
            this.getFourthWallData();
            break;
          case 3:
            this.extractFirstWallObj(resp.eventlist[0]);
            this.extractSecondWallObj(resp.eventlist[1]);
            this.extractThirdWallObj(resp.eventlist[2]);
            this.getFourthWallData();
            break;
          case 4:
            this.extractFirstWallObj(resp.eventlist[0]);
            this.extractSecondWallObj(resp.eventlist[1]);
            this.extractThirdWallObj(resp.eventlist[2]);
            this.extractFourthWallObj(resp.eventlist[3]);
            break;
          default:
            this.extractFirstWallObj(resp.eventlist[0]);
            this.extractSecondWallObj(resp.eventlist[1]);
            this.extractThirdWallObj(resp.eventlist[2]);
            this.extractFourthWallObj(resp.eventlist[3]);
            break;

        }
      } else {
        this.getWallsEventData();
      }
    })
  }
  open = () => {
    this.showModalBox = true;
    let fPanel = document.getElementById("videowrapper_panel1") as HTMLElement;
    let cloneVideoEle = fPanel.cloneNode(true);
    let modal1 = document.getElementById("exampleModalToggle") as HTMLElement;
    let mBody = document.getElementById("fBody");
    mBody?.replaceChildren(cloneVideoEle);
  }

  reload = () => {
    let vid: any = document.getElementById("video_panel_1");
    vid.load();
  }

  getWallsEventData = () => {
    this.renderWallsBasedOnSelection(this.wallsCount, false);
  }
  renderWallsBasedOnSelection = (panelCount: number, isonPageload: boolean) => {
    switch (panelCount) {
      case 1:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = false;
        this.isThirdPnlEnable = false;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
        }
        break;
      case 2:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = true;
        this.isThirdPnlEnable = false;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
          this.getSecondWallData();
        }
        break;
      case 3:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = true;
        this.isThirdPnlEnable = true;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
          this.getSecondWallData();
          this.getThirdWallData();
        }
        break;
      case 4:
        this.getAllWallsData(isonPageload, this.wallsCount);
        break;
      default:
        this.getAllWallsData(isonPageload, this.wallsCount);
        break;
    }

  }
  getAllWallsData = (isonPageload: boolean, wallCount?: number) => {
    this.isFirstPnlEnable = true;
    this.isSecondPnlEnable = true;
    this.isThirdPnlEnable = true;
    this.isFourthPnlEnable = true;
    if (!isonPageload && wallCount == 4) {
      this.getFirstWallData();
      this.getSecondWallData();
      this.getThirdWallData();
      this.getFourthWallData();
    }
    if (!isonPageload && wallCount == 2) {
      this.getFirstWallData();
      this.getSecondWallData();
    }
  }
  getUpdatedEventPayloadForPullReq = () => {
    return {
      "away": permissions.isSafeLogoutEnabled,
      "login": permissions.isSafeLogoutEnabled ? false : true
    };
  }
  getFirstWallData = (fbForceCloseMsg?: string) => {
    this.panel1Data = {};
    this.isFirstPnlEnable = true;
    this.panel1ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall1PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isPageLoad = false;
        this.isFirstPnlEnable = false;
        this.extractFirstWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
            this.isFirstPnlEnable = true;
            this.panel1ErrorMessage = "No events available";
          } else {
            this.panel1ErrorMessage = `Unable to connect panel1 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel1 server. We are trying to connect.`);
            this.getAPIWallsError("panel1");
            this.getPanelsOnInterval("panel1");
          }
        });
    }, 100);
  }
  extractFirstWallObj = (res: any) => {
    setTimeout(() => {
      this.isFirstPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel1Data = res?.eventbo;
        this.panel1ErrorMessage = "";
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel1', false);
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel1Data = res;
        } else {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        }
      } else {
        this.isFirstPnlEnable = true;
        this.panel1ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getFirstWallData();
        }, 3000);
      }
    }, 0);
  }
  getSecondWallData = (fbForceCloseMsg?: string) => {
    this.panel2Data = {};
    this.isSecondPnlEnable = true;
    this.panel2ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall2PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isPageLoad = false;
        this.isSecondPnlEnable = false;
        this.extractSecondWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
            this.isSecondPnlEnable = true;
            this.panel2ErrorMessage = "No events available";
          } else {
            this.panel2ErrorMessage = `Unable to connect panel2 server. We are trying to connect.`;
            this.getAPIWallsError("panel2");
            this.getPanelsOnInterval("panel2");
          }
        });
    }, 100);
  }
  extractSecondWallObj = (res: any) => {
    setTimeout(() => {
      this.isSecondPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel2Data = res?.eventbo;
        this.panel2ErrorMessage = "";
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel2', false);
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel2Data = res;
        } else {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        }
      } else {
        this.isSecondPnlEnable = true;
        this.panel2ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getSecondWallData();
        }, 3000);
      }
    }, 0);
  }
  getThirdWallData = (fbForceCloseMsg?: string) => {
    this.panel3Data = {};
    this.isThirdPnlEnable = true;
    this.panel3ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall3PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isThirdPnlEnable = false;
        this.isPageLoad = false;
        this.extractThirdWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
            this.isThirdPnlEnable = true;
            this.panel3ErrorMessage = "No events available";
          } else {
            this.panel3ErrorMessage = `Unable to connect panel3 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel3 server. We are trying to connect.`);
            this.getPanelsOnInterval("panel3");
            this.getAPIWallsError("panel3");
          }
        });
    }, 10);
  }
  extractThirdWallObj = (res: any) => {
    setTimeout(() => {
      this.isThirdPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel3Data = res?.eventbo;
        this.panel3ErrorMessage = "";
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel3', false);
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel3Data = res;
        } else {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        }
      } else {
        this.isThirdPnlEnable = true;
        this.panel3ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getThirdWallData();
        }, 3000);
      }
    }, 0);
  }
  getFourthWallData = (fbForceCloseMsg?: string) => {
    this.isFourthPnlEnable = true;
    this.panel4Data = {};
    this.panel4ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall4PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isFourthPnlEnable = false;
        this.isPageLoad = false;
        this.extractFourthWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
            this.isFourthPnlEnable = true;
            this.panel4ErrorMessage = "No events available";
          } else {
            this.panel4ErrorMessage = `Unable to connect panel4 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel4 server. We are trying to connect.`);
            this.getPanelsOnInterval("panel4");
            this.getAPIWallsError("panel4");
          }
        });
    }, 10);
  }
  extractFourthWallObj = (res: any) => {
    setTimeout(() => {
      this.isFourthPnlEnable = true;
      if (res != null && res?.statusCode == 200) {
        this.panel4Data = res?.eventbo;
        this.panel4ErrorMessage = "";
      } else if (res != null && res?.statusCode != 200) {
        if (res?.statusMessage == permissions.eventPauseMsg) {
          ;
          this.panel4ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel4', false)
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.panel4ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isFourthPnlEnable = true;
          this.panel4ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel4Data = res;
        } else {
          this.panel4ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        }
      } else {
        this.panel4ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getFourthWallData();
        }, 3000);
      }
    }, 0);
  }
  getPanelsEventData = (data: any) => {
    let panelName = data.pannelName;
    let payload: any = {
      "eventId": data.eventId,
      "actionTag": data.actionTag,
      "actionNote": data.actionNote,
      // "userId": "1t1",
      "away": permissions.isSafeLogoutEnabled,
      "login": permissions.isSafeLogoutEnabled ? false : true
    };

    switch (panelName) {
      case "panel1":
        this.panel1Data = {};
        this.isFirstPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel1ErrorMessage = data.forceCloseMsg;
          this.panel1FBMessageCheck = true;
        } else {
          this.panel1ErrorMessage = "Loading...";
          this.panel1FBMessageCheck = false;
        }
        if (data.tagType == 'close') {
          this.wall1CloseEventAPISub = this.wallService.postCloseEventData(payload).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            this.extractFirstWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
                this.isFirstPnlEnable = true;
                this.panel1ErrorMessage = "No events available";
              } else {
                this.panel1ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );
        } else if (data.tagType == 'escalations') {
          this.isFirstPnlEnable = true;
          this.wall1EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            this.extractFirstWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
                this.isFirstPnlEnable = true;
                this.panel1ErrorMessage = "No events available";
              } else {
                this.panel1ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            this.extractFirstWallObj(res);
          }, (err: any) => {
            if (permissions.isSafeLogoutEnabled) {
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
              this.isFirstPnlEnable = true;
              this.panel1ErrorMessage = "No events available";
            } else {
              this.panel1ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
              this.getPanelsOnInterval(panelName);
              this.getAPIWallsError(panelName);
            }
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getFirstWallData(this.panel1ErrorMessage);
          }, permissions.fbNotificationShowTime);

        }
        break;
      case "panel2":
        this.panel2Data = {};
        this.isSecondPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel2ErrorMessage = data.forceCloseMsg;
          this.panel2FBMessageCheck = true;
        } else {
          this.panel2ErrorMessage = "Loading...";
          this.panel2FBMessageCheck = false;
        }
        if (data.tagType == 'close') {
          this.wall2CloseEventAPISub = this.wallService.postCloseEventData(payload).subscribe((res: any) => {
            this.isSecondPnlEnable = false;
            this.extractSecondWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
                this.isSecondPnlEnable = true;
                this.panel2ErrorMessage = "No events available";
              } else {
                this.panel2ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'escalations') {
          this.wall2EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isSecondPnlEnable = false;
            this.extractSecondWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
                this.isSecondPnlEnable = true;
                this.panel2ErrorMessage = "No events available";
              } else {
                this.panel2ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            this.isSecondPnlEnable = false;
            this.extractSecondWallObj(res);
            //this.getPanelsOnInterval(panelName);
          }, (err: any) => {
            if (permissions.isSafeLogoutEnabled) {
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
              this.isSecondPnlEnable = true;
              this.panel2ErrorMessage = "No events available";
            } else {
              this.panel2ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
              this.getPanelsOnInterval(panelName);
              this.getAPIWallsError(panelName);
            }
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getSecondWallData(this.panel2ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      case "panel3":
        this.panel3Data = {};
        this.isThirdPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel3ErrorMessage = data.forceCloseMsg;
          this.panel3FBMessageCheck = true;
        } else {
          this.panel3ErrorMessage = "Loading...";
          this.panel3FBMessageCheck = false;
        }
        if (data.tagType == 'close') {
          this.wall3CloseEventAPISub = this.wallService.postCloseEventData(payload).subscribe((res: any) => {
            this.isThirdPnlEnable = false;
            this.extractThirdWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
                this.isThirdPnlEnable = true;
                this.panel3ErrorMessage = "No events available";
              } else {
                this.panel3ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'escalations') {
          this.wall3EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isThirdPnlEnable = false;
            this.extractThirdWallObj(res);

          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
                this.isThirdPnlEnable = true;
                this.panel3ErrorMessage = "No events available";
              } else {
                this.panel3ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );

        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            this.isThirdPnlEnable = false;
            this.extractThirdWallObj(res);
            //this.getPanelsOnInterval(panelName);
          }, (err: any) => {
            if (permissions.isSafeLogoutEnabled) {
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
              this.isThirdPnlEnable = true;
              this.panel3ErrorMessage = "No events available";
            } else {
              this.panel3ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
              this.getPanelsOnInterval(panelName);
              this.getAPIWallsError(panelName);
            }
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getThirdWallData(this.panel3ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      case "panel4":
        this.panel4Data = {};
        this.isFourthPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel4ErrorMessage = data.forceCloseMsg;
          this.panel4FBMessageCheck = true;
        } else {
          this.panel4ErrorMessage = "Loading...";
          this.panel4FBMessageCheck = false;
        }
        if (data.tagType == 'close') {
          this.wall4CloseEventAPISub = this.wallService.postCloseEventData(payload).subscribe((res: any) => {
            this.isFourthPnlEnable = false;
            this.extractFourthWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
                this.isFourthPnlEnable = true;
                this.panel4ErrorMessage = "No events available";
              } else {
                this.panel4ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'escalations') {
          this.wall4EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isFourthPnlEnable = false;
            this.extractFourthWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
                this.isFourthPnlEnable = true;
                this.panel4ErrorMessage = "No events available";
              } else {
                this.panel4ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            this.isFourthPnlEnable = false;
            this.extractFourthWallObj(res);
            //this.getPanelsOnInterval(panelName);
          }, (err: any) => {
            if (permissions.isSafeLogoutEnabled) {
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
              this.isFourthPnlEnable = true;
              this.panel4ErrorMessage = "No events available";
            } else {
              this.panel4ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
              this.getPanelsOnInterval(panelName);
              this.getAPIWallsError(panelName);
            }
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getFourthWallData(this.panel4ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      default:
        break;
    }
  }
  getPanelsOnInterval(panelName: string) {
    switch (panelName) {
      case "panel1":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = "No events available";
        }
        break;
      case "panel2":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = "No events available";
        }
        break;
      case "panel3":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = "No events available";
        }
        break;
      case "panel4":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isFourthPnlEnable = true;
          this.panel4ErrorMessage = "No events available";
        }
        break;
      default:
        break;
    }
  }
  getAPIWallsError(panelName: string) {
    switch (panelName) {
      case "panel1":
        this.panel1Data = {};
        this.isFirstPnlEnable = true;
        this.panel1ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel2":
        this.panel2Data = {};
        this.isSecondPnlEnable = true;
        this.panel2ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel3":
        this.panel3Data = {};
        this.isThirdPnlEnable = true;
        this.panel3ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel4":
        this.panel4Data = {};
        this.isFourthPnlEnable = true;
        this.panel4ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      default:
        break;
    }
  }
  getPanelActiveDataStore(panelName: string) {
    this.sharedService.getPanelActiveDataStoreSharedSrv(panelName)
  }

  postPanelsAPIData = (payload: any) => {
    return this.wallService.postEscalateEventData(payload);
  }
  onCountChange = (event: any) => {
    this.wallsCount = parseInt(event.target.value);
    this.renderWallsBasedOnSelection(this.wallsCount, false)
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy() {
    this.wall1PullEventAPISub.unsubscribe();
    this.wall2PullEventAPISub.unsubscribe();
    this.wall3PullEventAPISub.unsubscribe();
    this.wall4PullEventAPISub.unsubscribe();

    this.wall1CloseEventAPISub.unsubscribe();
    this.wall2CloseEventAPISub.unsubscribe();
    this.wall3CloseEventAPISub.unsubscribe();
    this.wall4CloseEventAPISub.unsubscribe();

    this.wall1EscalateEventAPISub.unsubscribe();
    this.wall2EscalateEventAPISub.unsubscribe();
    this.wall3EscalateEventAPISub.unsubscribe();
    this.wall4EscalateEventAPISub.unsubscribe();

    this.sharedService.destroyDisableBackButtonOnInit();
  }

}
