import { Component, Inject } from '@angular/core';
//import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-site-map-modal',
  templateUrl: './site-map-modal.component.html',
  styleUrls: ['./site-map-modal.component.scss']
})
export class SiteMapModalComponent {
  siteMapImgSrc:string='';
  // constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  //   this.siteMapImgSrc = data;
  // }

 
}
