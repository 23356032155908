<header class="header py-1 bg-white-f5 w-100" *ngIf="showHead" style="height: 38px;">
  <nav class="navbar navbar-expand-md navbar-light bg-white-f5 py-0" aria-label="Fifth navbar example">
    <div class="container-fluid px-lg-7">
      <a class="navbar-brand">
        <img src="assets/images/logo.png" class="img-fluid d-block header-logo-bg" alt="Pro Vigil" />
      </a>

      <div class="notification_box" *ngIf="isDevStaticEnabled && levelName=='Level 3'">
        <div class="notice">
          <img class="filter-highlight" src="assets/images/icons/bell.svg" />
          Notification
        </div>
        <div class="banner-text">
          <marquee>
            {{boloMessage}}
          </marquee>
        </div>
      </div>
      <div class="admin-link" *ngIf="isMonitoringAdmin && levelName=='Level 3' || levelName=='Level 2' ">
        <a class="text-white" (click)="navigateToAdminPortal()">
          <h5>Admin Portal</h5>
        </a>
      </div>
      <div class="text-lg-end" id="mobileMenu">
        <ul class="navbar-nav ms-auto align-items-center flex-row">
          <li class="nav-item nav-item-level mr-10" (click)="fullscreen()">
            <a class="btn btn-primary btn-outline-warning w-100" data-toggle="tooltip" data-placement="bottom"
              title="Full screen">
              <img class="filter-highlight" src="assets/images/icons/icon-fullscreen.svg" />
            </a>
          </li>

          <li class="nav-item nav-item-level  mr-10" *ngIf="!hidePlayPauseFeature">
            <a class="btn btn-primary btn-outline-warning w-100 {{disableButton || pauseReason || isSafeLogoutEnabled ? 'disabled' : ''}}"
              *ngIf="!mirror" (click)="playPause(playText)">
              <img *ngIf="playText == 'PAUSE'" class="filter-highlight" src="assets/images/icons/icon-pause.svg"
                data-toggle="tooltip" data-placement="bottom" title="Pause monitoring" />
              <img *ngIf="playText == 'PLAY'" class="filter-highlight" src="assets/images/icons/icon-play.svg"
                data-toggle="tooltip" data-placement="bottom" title="Resume monitoring" />
            </a>
          </li>
          <li class="nav-item nav-item-level" *ngIf="levelName == 'Level 2' && !mirror">
            <a class="btn btn-primary btn-outline-warning w-100" data-toggle="tooltip"
              [ngClass]="{'disabled': isWindowOpen}" data-placement="bottom" (click)="newTab()" title="New events">
              <img class="filter-highlight" src="assets/images/icons/plus.svg" />
            </a>
          </li>

          <li class="nav-item nav-item-level" >
            <span class="nav-link" >
              <span class="nav-link-status bg-success" *ngIf="playText == 'PAUSE'"></span>
              <span class="nav-link-status bg-warning" *ngIf="playText == 'PLAY'"></span>
              <span class="nav-link-text l1-text"> {{levelName == 'L1_montoring_agent'? 'CLM User':levelName}}</span>
            </span>
          </li>

          <li class="nav-item nav-item-user">
            <a class="nav-link">
              <img src="assets/images/icons/icon-user.svg" />
              <span class="nav-link-text l1-text mg-5 text-capitalize" routerLink="/fullscreen">{{username}}</span>
            </a>
          </li>
          <li class="nav-item nav-item-logout cursor-pointer" (click)="openModal($event)" *ngIf="!mirror">
            <a class="nav-link">
              <img src="assets/images/icons/icon-logout.svg" />
            </a>
          </li>
          <li class="nav-item nav-item-logout text-white-f2">
            v{{versionStr}}
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- Logout popup -->
<div id="myModal" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup z-front modal-center"
  style="z-index:9999999;display: none;">
  <div class="modal-content z-front">
    <div class="modal-header h-40">
      <h5 class="text model-header-text text-center w-100">Safe logout</h5>
    </div>
    <div class="modal-body z-front">
      <h5 class="text model-body-text text-center w-100 h-auto">{{logoutText}}</h5>
      <div class="row gx-4 gy-2 z-front ok-cancelBtn justify-content-center mt-1" *ngIf="!isSafeLogoutEnabled">
        <button class="btn btn-outline-secondary w-30 ml-20 custom-btn" (click)="closeModal()"
          style="margin-right:25px">Cancel</button>
        <!-- disable safelogout when event paused -- start -->
        <!-- <button class="btn btn-primary w-30 " (click)="initiateSafeLogout()" [disabled]="isSafeDisabledOnPause">OK</button> -->
        <!-- disable safelogout when event paused -- end -->

        <!-- enable safelogout when event paused -- start -->
        <button class="btn btn-primary w-30 " (click)="initiateSafeLogout()">OK</button>
        <!-- enable safelogout when event paused -- end -->
      </div>
      <div class="row " *ngIf="isSafeLogoutEnabled">
        <!-- <button class="btn btn-outline-primary w-30 ml-20" (click)="cancelSafeLogout();">Cancel</button> -->
        <button class="btn btn-primary  w-30 m-auto mt-2" *ngIf="!isWallsCountPendingForLogout"
          (click)="safeLogout()">Ok</button>
        <button class="btn btn-outline-primary w-30 m-auto mt-2" (click)="closeModal()"
          *ngIf="isWallsCountPendingForLogout">OK</button>

      </div>
    </div>
  </div>
</div>
<div id="showTagPopup" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup modal-center" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-content">
    <div class="modal-header h-40">
      <h5 class="text model-header-text text-center w-100">Please choose a tag immediately</h5>
      <span class="breakTime" [ngClass]="{'red-color': staticIncreamentNo <= 20}"> {{staticIncreamentNo}}</span>
    </div>
    <div class="modal-body" style="height: auto;">
      <div class="row gx-4 gy-2">
        <div class="col-4" *ngFor="let pTag of pauseTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0" (click)="pauseRequest(pTag)"
            style="text-transform:none !important;">{{pTag}}</button>
        </div>
      </div>
      <div class="d-flex justify-content-center"> <!-- Add this div for center alignment -->
        <button class="btn btn-outline-secondary custom-btn" (click)="cancelTagPopup()" style="margin-right:25px;margin-right: 25px;
        width: 23%;">Cancel / Resume</button>
      </div>
    </div>
  </div>
</div>

<div id="breakModalPopup" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup z-front modal-center"
  style="z-index:9999999;display: none;">
  <div class="modal-content z-front">
    <div class="modal-header h-40">
      <h5 class="text model-header-text text-center w-100">Want to take a break ?</h5>
    </div>
    <div class="modal-body z-front">
      <h5 class="text model-body-text text-center w-100 h-auto">{{breakText}}</h5>
      <div class="row gx-4 gy-2 z-front ok-cancelBtn justify-content-center mt-1">
        <button class="btn btn-outline-secondary w-30 ml-20 custom-btn" (click)="cancelBreakModalPopup()"
          style="margin-right:25px">Cancel / Resume</button>
        <button class="btn btn-primary w-30 " (click)="breakModal()">OK</button>
      </div>
    </div>
  </div>
</div>