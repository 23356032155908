import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessPermissions } from 'src/app/common/models/loginpermissions';
import { TagsList } from 'src/app/common/models/tagsList';
import { SharedsrvService } from 'src/app/common/services/sharedsrv/sharedsrv.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as momentTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
import { permissions } from 'src/app/common/models/globalpermissions';
const base_url = environment.pathUrl;

@Injectable({
  providedIn: 'root'
})
export class WallsService {
  endpointUrl: string = "../assets/json/wallsData.json";
  headers_object: any;
  accesstoken: any = localStorage.getItem('pro-token');
  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedsrvService
  ) {

    if (environment.baseUrl) {
      this.endpointUrl = "";
    }

    this.headers_object = new HttpHeaders({
      "PRO-TOKEN": this.getAccessProToken()
    });
    this.accesstoken = this.accesstoken?.toString()?.slice(1, this.accesstoken.length - 1);
  }
  getAccessProToken = () => {
    //if (this.accesstoken) {
    this.accesstoken = localStorage.getItem('pro-token');
    //}
    this.accesstoken = this.accesstoken?.toString()?.slice(1, this.accesstoken.length - 1);
    //this.accesstoken = this.accesstoken.substring(this.accesstoken.length);
    return this.accesstoken;
  }
  createAuthorizationHeader(headers: Headers) {
    headers.append('PRO-TOKEN', this.getAccessProToken());
  }

  getEventData(noofEvents: number): any {
    return this.httpClient.get(`${this.endpointUrl}getEvent?noofevents=${noofEvents}`);
  }
  pullEventData(eventPayload: any): any {

    return this.httpClient.post(`${environment.baseUrl}event/pull`, eventPayload, {
      headers: this.headers_object
    });
  }
  pullRefreshEventData(eventPayload: any): any {
    let reqToken = localStorage.getItem('pro-token') || '';
    reqToken = reqToken.toString().slice(1, reqToken.length - 1);
    this.headers_object = new HttpHeaders({
      "PRO-TOKEN": reqToken
    });
    return this.httpClient.get(`${environment.baseUrl}event/refreshEvents?userName=${this.sharedService.getUserValue()?.user.username}&mirrorScreen=false`, {
      headers: this.headers_object
    });
  }
  ///Old API
  postEscalateEventDataOld(escalateEventData: any): any {
    let payload = {
      "potentialId": escalateEventData.potentialId,
      "sitename": escalateEventData.sitename,
      "eventLink": escalateEventData.eventLink
    }
    return this.httpClient.post(`${this.endpointUrl}EscalateEvent`, payload)
  }
  //event close by selecting event escalate tag
  postEscalateEventData(eventPayload: any): any {
    return this.httpClient.post(`${environment.baseUrl}event/esclate/`, eventPayload, {
      headers: this.headers_object
    });
  }
  //event close by selecting event tagClose
  postCloseEventData(eventPayload: any, levelName?: string, tagType?: any, notesAvailable?: any): any {
    let url = `${environment.baseUrl}event/eofEscalation`;
    if (levelName == 'Level 2') {
      url = `${environment.baseUrl}event/eofEscalation`;
    } else if (levelName == 'Level 3' && tagType == 'escalations' && notesAvailable == true) {
      url = `${environment.baseUrl}event/EscalationL3/`;
    } else if (levelName == 'Level 3' && tagType == 'escalations' && notesAvailable == false) {
      url = `${environment.baseUrl}event/eofEscalation`;
    }
    return this.httpClient.post(`${url}`, eventPayload, {
      headers: this.headers_object
    });
  }

  //event feedback by selecting event feedback option
  postFeedbackEventData(eventPayload: any): any {
    eventPayload["username"] = this.sharedService.getUserValue()?.user.username;
    return this.httpClient.post(`${environment.baseUrl}savefeedbacktag`, eventPayload, {
      headers: this.headers_object
    });
  }
  // review starttime for eventId when user clicks on play
  getReviewStartTime(eventId: string): any {
    return this.httpClient.get(`${environment.baseUrl}event/statechange/reveiew/${eventId}`, {
      headers: this.headers_object
    });
  }
  // auto close after 2 mins
  getAutoCloseAfter2Mins(eventId: string): any {
    return this.httpClient.get(`${environment.baseUrl}event/reveiewfailed/${eventId}`, {
      headers: this.headers_object
    });
  }

  // get EventGroup Data
  getEventGroupData(eventPayload: any): any {
    return this.httpClient.post(`${environment.eventstackUrl}estack/fetch/eventgroup`, eventPayload, {
      headers: this.headers_object
    });
  }
  /// get all camera All Cams
  getCameraLiveViews(potentialId: string, cameraId: string, isAllcameraData: boolean): any {
    let url = "";
    if (isAllcameraData) {
      url = `${environment.liveviewUrl}mp/psafe/all/${potentialId}/null`;
    } else {
      url = `${environment.liveviewUrl}mp/psafe/${potentialId}/${cameraId}`;
    }
    let username = this.sharedService.getUserValue()?.user.username || "";
    return this.httpClient.get(`${url}`, {
      headers: this.headers_object
    });
  }

  /// get tags list
  getTagsList(tagsId: string): any {
    let reqToken = localStorage.getItem('pro-token') || '';
    reqToken = reqToken.toString().slice(1, reqToken.length - 1);
    this.headers_object = new HttpHeaders({
      "PRO-TOKEN": reqToken
    });
    return this.httpClient.get(`${environment.baseUrl}auth/getTags/${tagsId}`, {
      headers: this.headers_object
    });
  }

  // getTagsListSubscribe = () => {
  //   let levelName = this.sharedService.getMonitorLevels();
  //   let accessKey: any = this.getTagsAccessKey();
  //   this.getTagsList(accessKey).subscribe((resp: any) => {
  //     if (resp && resp.data) {
  //       let tagsList = resp.data;

  //       if (levelName == "Level 1") {
  //         if (tagsList['Closing Tag'] && tagsList['Closing Tag'].length > 0) {
  //           TagsList.L1_CloseTagsList = tagsList['Closing Tag']
  //         }
  //         if (tagsList['Escalation Tag'] && tagsList['Escalation Tag'].length > 0) {
  //           TagsList.L1_EscalationTagsList = tagsList['Escalation Tag']
  //         }
  //         if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
  //           TagsList.L1_FeedbackTagsList = tagsList['Feedback Tag']
  //         }
  //       }
  //       if (levelName == "Level 2") {
  //         if (tagsList['Closing Tag'] && tagsList['Closing Tag'].length > 0) {
  //           TagsList.L2_CloseTagsList = tagsList['Closing Tag']
  //         }
  //         if (tagsList['Escalation Tag'] && tagsList['Escalation Tag'].length > 0) {
  //           TagsList.L2_EscalationTagsList = tagsList['Escalation Tag']
  //         }
  //         if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
  //           TagsList.L2_FeedbackTagsList = tagsList['Feedback Tag']
  //         }
  //       }
  //       if (levelName == "Level 3") {
  //         if (tagsList['Action Tag'] && tagsList['Action Tag'].length > 0) {
  //           TagsList.L3_ActionTagsList = tagsList['Feedback Tag'];
  //         }
  //         if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
  //           TagsList.L3_FeedbackTagsList = tagsList['Feedback Tag']
  //         }
  //       }
  //     }
  //   });
  // }

  getTagsAccessKey = () => {
    let levelName = this.sharedService.getMonitorLevels();
    let accessKey = "";
    if (levelName == "Level 1") {
      accessKey = AccessPermissions.l1_tagsKey;
    }
    if (levelName == "Level 2") {
      accessKey = AccessPermissions.l2_tagsKey;
    }
    if (levelName == "Level 3") {
      accessKey = AccessPermissions.l3_tagsKey;
    }
    if (levelName == "") {
      accessKey = AccessPermissions.l1_tagsKey;
    }
    return accessKey;
  }
  fetchTagsFromResolverService = (resp: any) => {
    let levelName = this.sharedService.getMonitorLevels();
    if (resp && resp.data) {
      let tagsList = resp.data;

      if (levelName == "Level 1") {
        if (tagsList['Closing Tag'] && tagsList['Closing Tag'].length > 0) {
          TagsList.L1_CloseTagsList = tagsList['Closing Tag']
        }
        if (tagsList['Escalation Tag'] && tagsList['Escalation Tag'].length > 0) {
          TagsList.L1_EscalationTagsList = tagsList['Escalation Tag']
        }
        if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
          TagsList.L1_FeedbackTagsList = tagsList['Feedback Tag']
        }
      }
      if (levelName == "Level 2") {
        if (tagsList['Closing Tag'] && tagsList['Closing Tag'].length > 0) {
          TagsList.L2_CloseTagsList = tagsList['Closing Tag']
        }
        if (tagsList['Escalation Tag'] && tagsList['Escalation Tag'].length > 0) {
          TagsList.L2_EscalationTagsList = tagsList['Escalation Tag']
        }
        if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
          TagsList.L2_FeedbackTagsList = tagsList['Feedback Tag']
        }
      }
      if (levelName == "Level 3") {
        if (tagsList['Action Tag'] && tagsList['Action Tag'].length > 0) {
          TagsList.L3_ActionTagsList = tagsList['Action Tag'];
        }
        if (tagsList['Feedback Tag'] && tagsList['Feedback Tag'].length > 0) {
          TagsList.L3_FeedbackTagsList = tagsList['Feedback Tag']
        }
        if (tagsList['Closing Tag'] && tagsList['Closing Tag'].length > 0) {
          TagsList.L3_CloseTagsList = tagsList['Closing Tag'];
          // console.log('TagsList.L3_CloseTagsList',TagsList.L3_CloseTagsList)}
          // this.L3ClosingTags = TagsList.L3_CloseTagsList;console.log('l3',this.L3ClosingTags)
        }
      }
    }
  }

  //API : https://dev-psafe.pro-vigil.us:8006/api/siteinfo/sitemap?potentialId=728255
  getSiteMapDataByPotentialId(potentialId: string): any {
    return this.httpClient.get(`${environment.baseUrl}siteinfo/sitemap?potentialId=${potentialId}`, { headers: this.headers_object }).pipe(map((res: any) => {
      return res;
    }));
  }

  getData(method: any, params: any) {
    return this.httpClient.get(base_url + method + params).pipe(map((res: any) => {
      return res;
    }));
  }
  postData(method: any, obj: any) {
    return this.httpClient.post(base_url + method, obj).pipe(map((res: any) => {
      return res;
    }));
  }
  postAPIData(apiUrl: any, obj: any) {
    return this.httpClient.post(apiUrl, obj, { headers: this.headers_object }).pipe(map((res: any) => {
      return res;
    }));
  }
  postFileData(method: any, params: any) {
    return this.httpClient.post(base_url + method + params, {}).pipe(map((res: any) => {
      return res;
    }));
  }
  deleteData(method: any, id: any) {
    return this.httpClient.delete(base_url + method + '/' + id).pipe(map((res: any) => {
      return res;
    }));
  }
  download(url: string) {
    return this.httpClient.get(url, {
      responseType: 'blob'
    })
  }
  getJSON(path: any) {
    return this.httpClient.get(path);
  }
  getAllMessageInfoPanelData(potentialId: string) {
    return this.httpClient.get(`${environment.fetchAllInfo}/api/psafe/siteInfoPopup?potentialId=${potentialId}`, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  recentEsclations(potentialId: string, payload: any, isType?: any, siteTime?: any, eventTimeStr?: any) {
    let currTime;
    if (eventTimeStr) {
      currTime = moment(new Date(eventTimeStr));
    } else {
      currTime = momentTZ(new Date());
    }
    let fromDate = moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss');
    let payload1 = {
      "fromDate": '-', // currTime -4 hrs time currTime - 1d 
      "toDate": currTime.format('YYYY-MM-DD HH:mm:ss').toString(), // no need any change
      "potentialId": potentialId,
    }
    // '748140'
    let apiURL = `${environment.baseURLwithPort}reports/recent/accountlevel/escalations?sort=eventTimeStampStr,DESC`;
    let prefix = '';
    if (!isType) {
      prefix = '&size=5';
      apiURL = `${apiURL}${prefix}`;
    }
    if (!payload?.startTime) {
      payload.startTime = "00:00";
    }
    if (!payload.endTime) {
      payload.endTime = "00:00";
    }

    if (payload?.records == "viewAll") {
      payload1['fromDate'] = moment(payload.startDate + " " + payload.startTime + ":00").format('YYYY-MM-DD HH:mm:ss').toString();
      payload1['toDate'] = moment(payload.endDate + " " + payload.endTime + ":00").format('YYYY-MM-DD HH:mm:ss').toString();
      prefix = '';
      apiURL = `${apiURL}${prefix}`;
    }
    if (isType == '4hours') {
      const toTime = moment(siteTime);
      const toTimeStr = currTime.format('YYYY-MM-DD HH:mm:ss').toString();
      const last4hrs = currTime.subtract(4, 'hours');
      if (siteTime) {
        payload1['fromDate'] = last4hrs.format('YYYY-MM-DD HH:mm:ss').toString();
        payload1['toDate'] = toTimeStr;
      }
      prefix = '';
      apiURL = `${apiURL}${prefix}`;
    }
    if (isType == '2hours') {
      const toTime = moment(siteTime);
      const toTimeStr = currTime.format('YYYY-MM-DD HH:mm:ss').toString();
      const last4hrs = currTime.subtract(2, 'hours');
      if (siteTime) {
        payload1['fromDate'] = last4hrs.format('YYYY-MM-DD HH:mm:ss').toString();
        payload1['toDate'] = toTimeStr;
      }
      apiURL = `${apiURL}${prefix}`;
    }
    if (isType == '24hrs') {
      const toTime = moment(siteTime);
      const toTimeStr = currTime.format('YYYY-MM-DD HH:mm:ss').toString();
      // const yesterday = currTime.subtract(1, 'days');
      const twentyFourHoursAgo = currTime.subtract(24, 'hours');
      payload1['fromDate'] = twentyFourHoursAgo.format('YYYY-MM-DD HH:mm:ss').toString();
      payload1['toDate'] = toTimeStr;
      apiURL = `${apiURL}${prefix}`;
    }
    if (isType == '7Days') {
      const toTime = moment(siteTime);
      const toTimeStr = currTime.format('YYYY-MM-DD HH:mm:ss').toString();
      const twentyFourHoursAgo = currTime.subtract(7, 'days');
      payload1['fromDate'] = twentyFourHoursAgo.format('YYYY-MM-DD HH:mm:ss').toString();
      payload1['toDate'] = toTimeStr;
      apiURL = `${apiURL}${prefix}`;
    }
    return this.httpClient.post(apiURL, payload1, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  getBroadCastList(potentialId: string, payload: object) {
    const currTime = momentTZ(new Date());
    const prevTime = momentTZ(currTime).subtract({ days: 15 }); // hours: 1
    payload = {
      "fromDate": momentTZ(prevTime).format('YYYY-MM-DD HH:mm:ss').toString(),
      "toDate": currTime.format('YYYY-MM-DD HH:mm:ss').toString(),
      "pageSize": 20,
      "potentialId": potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}broadcast/messages/fetch`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  getAlarmActivation(potentialId: string) {
    return this.httpClient.get(`${environment.baseUrl}event/getAlarmActivation?potentialId=${potentialId}`, {
      headers: this.headers_object
    });
  }

  getCameraViews(potentialId: string) {
    return this.httpClient.get(`${environment.baseUrl}cameralinks/fetch/cameraviews/forpotential?potentialId=${potentialId}`, {
      headers: this.headers_object
    });
  }

  updateBroadCast(potentialId: string, values: any) {


    const startTimeStamp = momentTZ(`${values.startDate} ${values.startTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');
    const endTimeStamp = momentTZ(`${values.endDate} ${values.endTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');

    let payload = {
      "message": values.message,
      "fromDate": startTimeStamp.toString(), //`${values.startDate} ${values.startTime+':00'}`,
      "toDate": endTimeStamp.toString(),//`${values.endDate} ${values.endTime+':00'}`,
      "canExpire": false,
      "potentialId": potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}broadcast/messages/update`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  UpdateAdhoc(potentialId: string, values: any) {

    const startTimeStamp = momentTZ(`${values.startDate} ${values.startTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');
    const endTimeStamp = momentTZ(`${values.endDate} ${values.endTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');
    let payload = {
      "message": values.message,
      "fromDate": startTimeStamp.toString(), //`${values.startDate} ${values.startTime+':00'}`,
      "toDate": endTimeStamp.toString(),//`${values.endDate} ${values.endTime+':00'}`,
      "canExpire": false,
      "potentialId": potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}adhoc/notes/update`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  // Adding BOLO Message API Call
  addBOLOMessage(potentialId: string, values: any) {
    const startTimeStamp = momentTZ(`${values.startDate} ${values.startTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');
    const endTimeStamp = momentTZ(`${values.endDate} ${values.endTime + ':00'}`).format('DD-MM-YYYY HH:mm:ss');
    let payload = {
      "fromDate": startTimeStamp.toString(),
      "toDate": endTimeStamp.toString(),
      "canExpire": false,
      "message": values.message,
      "potentialId": potentialId,
      "modifiedBy": this.sharedService.getUserValue()?.user.username
    }
    const URL = `${environment.baseUrl}bolo/notes/update`
    return this.httpClient.post(URL, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  getAdhocList(potentialId: string, payload: object) {
    const currTime = momentTZ(new Date());
    const prevTime = momentTZ(currTime).subtract({ days: 15 }); // hours: 1
    payload = {
      "fromDate": momentTZ(prevTime).format('YYYY-MM-DD HH:mm:ss').toString(),
      "toDate": currTime.format('YYYY-MM-DD HH:mm:ss').toString(),
      "pageSize": 20,
      "potentialId": potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}adhoc/notes/fetch`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  getCrewList(potentialId: any) {
    const payload = {
      potentialId: potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}contact/fetch`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  postAllArchiveUrls = (potentialId: string, timeStamp?: string) => {
    /// "2023-06-02-08:19:50"
    let url = "";
    if (timeStamp) {
      url = `mp/psafe/allarchive?potentialId=${potentialId}&timestamp=${timeStamp}`;
    } else {
      url = `mp/psafe/allarchive?potentialId=${potentialId}&timestamp=-`;
    }
    return this.httpClient.post(`${environment.baseUrl}${url}`, null, {
      headers: this.headers_object
    });
  }
  //manual alarm trigger
  alarmManualTrigger = (payload: any) => {
    let url = `${environment.alarmTrigger}event/alarm/manualtrigger?eventId=${payload.eventId}&userName=${this.sharedService.getUserValue()?.user.username}&levelId=${payload.levelId}&potentialId=${payload.potentialId}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    })
  }

  getRequest(endpoint: string, obj: any): Observable<any> {
    const httpOptions = {
      params: obj
    };
    return this.httpClient.get(`${endpoint}`, httpOptions);
  }

  //continue Stream API 

  continueStream = (payload: any) => {
    let wallSource = (AccessPermissions.accessl2 == localStorage.getItem('userType')) ? 'L2_Walls' : 'L3_Walls';
    let url = `${environment.liveviewUrl}mp/psafe/action/continue/${payload.streamKey}/${wallSource}/${payload.min}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    });
  }
  getTArmDisarmHistoryByPID(potentialId: string): any {
    return this.httpClient.get(`${environment.baseUrl}arm/gethistory/${potentialId}`, {
      headers: this.headers_object
    });
  }
  //getstream
  getarchiveStream = (payload: any) => {
    let url = `${environment.liveviewUrl}mp/psafe/archive/${payload.potentialId}/${payload.cameraUniqueId}/${payload.currentTimestamp}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    })
  }

  //get SMS request
  getSMSRequestCheck = (potentialId: any) => {
    let url = `${environment.baseUrl}notify/sms/checkstatus?potentialId=${potentialId}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    });
  }

  //get PSA List data
  getPsaList = (potentialId: any) => {
    let url = `${environment.baseUrl}psafe/psaList?potentialId=${potentialId}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    });
  }
  //get ArmedDisarmed Status for message panel data.
  getArmedDisarmedStatusForMsgPanel = (potentialId: any) => {
    let url = `${environment.baseUrl}psafe/siteStatus?potentialId=${potentialId}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    });
  }
  postSiteNotesForMsgPanel = (payload: any) => {
    return this.httpClient.post(`${environment.baseUrl}broadcast/messages/update`, payload, {
      headers: this.headers_object
    });
  }
  postAdhocData = (payload: any) => {
    return this.httpClient.post(`${environment.baseUrl}adhoc/notes/update`, payload, {
      headers: this.headers_object
    });
  }
  postBoloData = (payload: any) => {
    const URL = `${environment.baseUrl}bolo/notes/update`
    return this.httpClient.post(URL, payload, {
      headers: this.headers_object
    });
  }
  fetchByPotentialId = (potentialId: any) => {
    let url = `${environment.baseUrl}psafe/fetchByPotentialId?potentialId=${potentialId}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    });
  }
  crewUpdate(potentialId: any, values: any, isGuard = true) {
    const payload = {
      name: values.name,
      contact: values.contact,
      details: values.details,
      potentialId: potentialId,
      udid: '',
      contactType: isGuard ? 'GUARD' : 'CLEANING_CREW'
    }
    return this.httpClient.post(`${environment.baseUrl}contact/update`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  getArmHistory(potentialId: string, values: any) {
    const startTimeStamp = momentTZ(`${values.startDate} ${values.startTime + ':00'}`).format('YYYY-MM-DD HH:mm:ss');
    const endTimeStamp = momentTZ(`${values.endDate} ${values.endTime + ':00'}`).format('YYYY-MM-DD HH:mm:ss');
    let payload = {
      "fromDate": startTimeStamp.toString(),
      "toDate": endTimeStamp.toString(),
      "potentialId": potentialId
    }
    let url = `${environment.baseUrl}arm/gethistoryByDates?potentialId=${payload.potentialId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  recentActivationData(potentialId: string, values: any) {
    const startTimeStamp = momentTZ(`${values.startDate} ${values.startTime + ':00'}`).format('YYYY-MM-DD HH:mm:ss');
    const endTimeStamp = momentTZ(`${values.endDate} ${values.endTime + ':00'}`).format('YYYY-MM-DD HH:mm:ss');
    let payload = {
      "fromDate": startTimeStamp.toString(),
      "toDate": endTimeStamp.toString(),
      "potentialId": potentialId
    }
    return this.httpClient.post(`${environment.baseUrl}fetchalarminfo`, payload, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }
  //armApi
  armAction(potentialId: string, values: any) {
    // https://dev-psafe.pro-vigil.us:8006/api/psafe/armdisarmaction?action=ACTIVATE&potentialId=729976&
    // deviceId=Portal&sitename=Test-HD Stress Box 3 VGSTEST HD Stress Box 3&source=WEB&messageText=testing&user=portaladmin
    let payload = {
      'action': values.action,
      'potentialId': values.potentialId,
      'deviceId': values.deviceId,
      'messageText': values.messageText,
      'from': 'console',
      'hours': values.untilHour,
      'min': values.untilMin,
      'user': values.user,
      'sitename': values.sitename,
      'source': values.source,
    };

    let url = `${environment.baseUrl}psafe/armdisarmaction?action=${payload.action}&potentialId=${payload.potentialId}&deviceId=${payload.deviceId}&messageText=${payload.messageText}&from=${payload.from}&hours=${payload.hours}&min=${payload.min}&amPm=&user=${payload.user}`;
    return this.httpClient.get(url, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));;
  }
  disarmAction(values: any) {
    let payload = {
      'action': values.action,
      'potentialId': values.potentialId,
      'deviceId': values.deviceId,
      'messageText': values.messageText,
      'from': values.form,
      'untilHour': values.untilHour,
      'untilMin': values.untilMin
    };
    let url = `${environment.baseUrl}psafe/armdisarmaction` + `?action=${payload.action}` + `&potentialId=${payload.potentialId}` +
      `&deviceId=${payload.deviceId}` + `&messageText=${payload.messageText}` +
      `&from=${payload.from}` + `&untilHour=${payload.untilHour}` + `&untilMin=${payload.untilMin} +&amPm`
    return this.httpClient.get(url, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));;
  }


  getBroadAdhocFilterValues(potentialId: string, payload: any, isType?: any, siteTime?: any) {
    const currTime = momentTZ(new Date());
    let payload1 = {
      "fromDate": currTime.format('YYYY-MM-DD HH:mm:ss').toString(),
      "toDate": currTime.format('YYYY-MM-DD HH:mm:ss').toString(), // no need any change
      "potentialId": potentialId,
    }

    if (!payload?.startTime) {
      payload.startTime = "00:00";
    }
    if (!payload.endTime) {
      payload.endTime = "00:00";
    }

    if (payload?.records == "viewAll") {
      payload1['fromDate'] = moment(payload.startDate + " " + payload.startTime + ":00").format('YYYY-MM-DD HH:mm:ss').toString();
      payload1['toDate'] = moment(payload.endDate + " " + payload.endTime + ":00").format('YYYY-MM-DD HH:mm:ss').toString();
    }
    let url = `${environment.baseUrl}psafe/view/notes/all` + `?potentialId=${payload1.potentialId}` +
      `&fromDate=${payload1.fromDate}` + `&toDate=${payload1.toDate}`
    return this.httpClient.get(url, {
      headers: this.headers_object
    }).pipe(map((res: any) => {
      return res;
    }));
  }

  boloFetchEverySlot = (body) => {
    let payload = {
      potentialId: body
    }
    const URL = `${environment.baseUrl}bolo/notes/fetch/potentialId`
    return this.httpClient.post(URL, payload, {
      headers: this.headers_object
    })
  }
  /// check admin access
  checkAdminAccess(): any {
    return this.httpClient.get(`${environment.baseUrl}auth/sso`, {
      headers: new HttpHeaders({
        "PRO-TOKEN": this.getAccessProToken()
      })
    });
  }

  //L3 Dispatch Progess change api 

  getDispatchProgress(payload: any) {
    const payloadEntry = {
      "eventId": payload?.eventId,
      "actionTag": payload?.actionTag,
      "actionNote": "",
      "userId": this.sharedService.getUserValue()?.user.username
    }
    let url = `${environment.baseUrl}event/reviewEnd`
    return this.httpClient.post(url, payloadEntry, {
      headers: this.headers_object
    }).pipe((res: any) => {
      return res;
    })
  }

  startLiveViewAuditLog = (camItem: any) => {
    let payloadEntry = {
      "cameraId": camItem.cameraId,
      "potentialId": camItem.potentialId,
      "source": (AccessPermissions.accessl2 == localStorage.getItem('userType')) ? 'L2_Walls' : 'L3_Walls',
      "userName": this.sharedService.getUserValue()?.user.username,
      "cameraName": camItem.cameraName,
      "siteName": camItem.siteName,
      "unitId": camItem.unitId
    };
    let url = `${environment.apiDomain}:8007/api/mp/psafe/audit/liveviews/${payloadEntry.potentialId}/${payloadEntry.cameraId}/${payloadEntry.source}?username=${payloadEntry.userName}`;
    return this.httpClient.get(url, {
      headers: this.headers_object
    }).pipe((res: any) => {
      return res;
    });
  }

  endLiveViewAuditLog = (auditId: any) => {
    let payloadEntry = {
      "auditId": auditId
    }
    let url = `${environment.apiDomain}:8007/api/mp/customer/liveviews/audit/end`;
    this.httpClient.post(url, payloadEntry).subscribe((res: any) => {
      /// liveview audit end record will add in DB.
    });
  }

  getCLMliveview = () => {
    let userDetails = JSON.parse((localStorage.getItem('user'))).user;
    let username = userDetails?.username;
    return this.httpClient.get(`${environment.apiDomain}:8016/api/pull/streams/list?userName=${username}`, {
      headers: new HttpHeaders({
        "PRO-TOKEN": this.getAccessProToken()
      })
    });
  }

  getCLMCameraByliveview = (camDetails: any) => {
    return this.httpClient.get(`${environment.apiDomain}:8007/api/mp/psafe/stream/bycamera/${camDetails.potentialId}/${camDetails.cameraUniqueId}`);
  }

  getCLMRecordStartStop = (payload: any) => {
    let url = "";
    if (payload.action == "start") {
      // url = `https://dev-psafe.pro-vigil.us:8016/api/record/video6?camUniqueId=${payload.cameraUniqueId}&potentialId=${payload.potentialId}&action=${payload.action}`;
     url =  `${environment.apiDomain}:8016/api/record/video6?camUniqueId=${payload.cameraUniqueId}&potentialId=${payload.potentialId}&action=${payload.action}&tagName=${payload?.tagName}&notes=${payload?.notes}`;
    } else {
      // url = `https://dev-psafe.pro-vigil.us:8016/api/record/video6?camUniqueId=${payload.cameraUniqueId}&potentialId=${payload.potentialId}&action=${payload.action}&tagName=${payload.tagName}&notes=${payload.notes}`;
    url =  `${environment.apiDomain}:8016/api/record/video6?camUniqueId=${payload.cameraUniqueId}&potentialId=${payload.potentialId}&action=${payload.action}&tagName=${payload.tagName}&notes=${payload.notes}`;
    }
    debugger;
    return this.httpClient.get(`${url}`, {
      headers: new HttpHeaders({
        "PRO-TOKEN": this.getAccessProToken()
      })
    });
  }

}
