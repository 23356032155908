<div class="main fluid-container" class="mainBody">
  <div class="main_child" style="height:100%">
    <div class="container-fluid">
      <!--Main without MIRROR-->
      <div class="row" style="border: 2px solid #fff;" *ngIf="!mirror">
        <!-- First column of main row -->
        <div class="col-sm-6 com-md-6 col-lg-6 col-xl-6">
          <div class="row" style="overflow-y: auto;overflow-x:hidden;height:calc(100vh - 40px);">
            <div class="col-sm-12 col-xl-12 panel-hover" style="min-height: 50%;" id="panel1_slot" [ngClass]="{'border-redflag':panel1Redflag==true}" >
              <div class="col-sm-12 col-xl-12" *ngIf="isFirstPnlEnable "> 
                <app-l2-one-wallslot
                  *ngIf="(panel1Data | json) != ({} | json)" [wallsObj]="panel1Data" [panelName]="'panel1'"
                  [permissionsInputObj]="permissionObj" [panelIFTTData]="panel2IFTTdata" [mirror]="mirror"
                  (submitEmit)="getPanelsEventData($event)"
                  (submitPanelData)="getPanelTableData1($event)">
                </app-l2-one-wallslot>
                <div *ngIf="(panel1Data | json) == ({} | json)" class="col-sm-9 col-md-9 col-lg-9 min-height45vh">
                  <div class="errorMessage" [ngClass]="[panel1FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                    {{panel1ErrorMessage}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-xl-12 panel-hover" style="min-height: 50%;" id="panel2_slot" [ngClass]="{ 'border-redflag': (panel2Redflag==true)}">
              <div class="col-sm-12 col-xl-12"  *ngIf="isSecondPnlEnable && this.slotNumber !== 1">
                <app-l2-one-wallslot *ngIf="(panel2Data | json) != ({} | json)" [wallsObj]="panel2Data"
                  [panelName]="'panel2'" [permissionsInputObj]="permissionObj" [panelIFTTData]="panel2IFTTdata"
                  (submitEmit)="getPanelsEventData($event)"
                  (submitPanelData)="getPanelTableData1($event)"></app-l2-one-wallslot>
                <div *ngIf="(panel2Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
                  <div class="errorMessage" [ngClass]="[panel2FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                    {{panel2ErrorMessage}}
                  </div>
                </div>
              </div>
              <div *ngIf="this.slotNumber == 1" class="contentHidden">
                <p style="text-align: center; 
                color: white; margin: 15%; font-size: 18px; 
                font-weight: 900;">User Setup with single slot.</p>
            </div>
            </div>
          </div>
        </div>
        <!-- Second column of main row with table accordian-->
        <div class="col-sm-6 com-md-6 col-lg-6 right_panel" style="padding: 0px;">
          <app-l2-one-wallslot-tablepanel [panelTableData]="panelTabledata" *ngIf="flagToReloadTable"></app-l2-one-wallslot-tablepanel>
          <div class="d-flex justify-content-center" *ngIf="!flagToReloadTable && panelTabledata?.panelObj?.recentEsclations">
            <div class="spinner-border text-primary" role="status" style="width: 6rem; height: 6rem; margin-top: 35%;">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>


      <!--MIRROR Screen-->
      <div class="row" style="border: 2px solid #fff;" *ngIf="mirror">
        <!-- Second column of main row with table accordian-->
        <div class="col-sm-6 com-md-6 col-lg-6 right_panel" style="padding: 0px;">
          <app-l2-one-wallslot-tablepanel [panelTableData]="panelTabledata" *ngIf="flagToReloadTable" [mirror]="true"></app-l2-one-wallslot-tablepanel>
          <div class="d-flex justify-content-center" *ngIf="!flagToReloadTable &&panelTabledata?.panelObj?.recentEsclations">
            <div class="spinner-border text-primary" role="status" style="width: 6rem; height: 6rem; margin-top: 35%;">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <!-- First column of main row -->
        <div class="col-sm-6 com-md-6 col-lg-6 col-xl-6">
          <div class="row" style="overflow-y: auto;overflow-x:hidden;height:calc(100vh - 40px);">
            <div class="col-sm-12 col-xl-12 panel-hover" id="panel1_slot"  [ngClass]="{ 'border-redflag': (panel1Redflag==true)}">
              <div class="col-sm-12 col-xl-12" *ngIf="isFirstPnlEnable"> 
                <app-l2-one-wallslot
                  *ngIf="(panel1Data | json) != ({} | json)" [wallsObj]="panel1Data" [panelName]="'panel1'"
                  [permissionsInputObj]="permissionObj" [panelIFTTData]="panel2IFTTdata"
                  [mirror]="mirror"
                  (submitEmit)="getPanelsEventData($event)"
                  (submitPanelData)="getPanelTableData1($event)">
                </app-l2-one-wallslot>
                <div *ngIf="(panel1Data | json) == ({} | json)" class="col-sm-9 col-md-9 col-lg-9 min-height45vh">
                  <div class="errorMessage" [ngClass]="{'errorFBMessage':panel1FBMessageCheck}">
                    {{panel1ErrorMessage}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-xl-12 panel-hover" id="panel2_slot" [ngClass]="{ 'border-redflag': (panel2Redflag==true)}">
              <div class="col-sm-12 col-xl-12"  *ngIf="isSecondPnlEnable">
                <app-l2-one-wallslot *ngIf="(panel2Data | json) != ({} | json)" [wallsObj]="panel2Data"
                  [panelName]="'panel2'" [permissionsInputObj]="permissionObj" [panelIFTTData]="panel2IFTTdata"
                  [mirror]="mirror"
                  (submitEmit)="getPanelsEventData($event)"
                  (submitPanelData)="getPanelTableData1($event)"></app-l2-one-wallslot>
                <div *ngIf="(panel2Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
                  <div class="errorMessage" [ngClass]="{'errorFBMessage':panel2FBMessageCheck}">
                    {{panel2ErrorMessage}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>