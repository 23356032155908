import { Component, Input, OnInit } from '@angular/core';
import { permissions } from 'src/app/common/models/globalpermissions';
import { SharedsrvService } from 'src/app/common/services/sharedsrv/sharedsrv.service';
import { ToasterService } from 'src/app/common/services/toaster/toaster.service';
import { WallsService } from 'src/app/services/walls/walls.service';

@Component({
  selector: 'app-live-monitoring-child',
  templateUrl: './live-monitoring-child.component.html',
  styleUrls: ['./live-monitoring-child.component.scss']
})
export class LiveMonitoringChildComponent implements OnInit {
  @Input() camItemData: any;
  isrecordStartBtnShow: boolean = true;
  tagsList: any = ["Intruder Observed", "Vehicle Observed", "Un Authorized Activity", "No Activity"];
  selectedTagName: any = "";
  userNotes: any = "";
  constructor(
    private wallsService: WallsService,
    private toasterService: ToasterService,
    private sharedService: SharedsrvService,
  ) {

  }
  ngOnInit(): void {

  }
  alarmTrigger() {
    const payload = {
      'eventId': this.camItemData?.eventid || "",
      'levelId': this.camItemData?.currentLevelId || 'CLM',
      'potentialId': this.camItemData?.potentialId
    }
    this.wallsService.alarmManualTrigger(payload).subscribe((res: any) => {
      if (res.success === true) {
        this.toasterService.showSuccess("Alarm triggered!");
      } else {
        this.toasterService.showError("Unable to triggered the Alarm!");
      }
    }, error => {
      this.toasterService.showError("Unable to triggered the Alarm!");
    });
  }
  openWinArchivePage(item: any) {
    localStorage.setItem("cameraItem", JSON.stringify(item));
    let url = `${window.location.protocol}//${window.location.host}/live-view`;
    const windowName = this.sharedService.prepareWindowName('archive_');
    const evtWindow = window.open(url, "_blank", "width=656,height=422");
    permissions.allWindows[windowName] = evtWindow;
  }
  openCamLiveView(camDetails: any) {
    // localStorage.setItem('EVENT', JSON.stringify(url));
    this.wallsService.getCLMCameraByliveview(camDetails).subscribe((resp: any) => {
      if (resp.success == true) {
        const windowName = this.sharedService.prepareWindowName('cameraview_');
        const evtWindow = window.open('/clm-liveview-popup?unique_id=' + resp.data.hlsUrl, "_blank", "width=700,height=500");
        permissions.allWindows[windowName] = evtWindow;
      } else {
        this.toasterService.showError("Unable to open liveview.");
      }
    }, error => {
      this.toasterService.showError("Unable to open liveview.");
    })
    // const windowName = this.sharedService.prepareWindowName('cameraview_');
    // const evtWindow = window.open('/clm-liveview-popup?unique_id=' + url.hlsUrl, "_blank", "width=700,height=500");
    // permissions.allWindows[windowName] = evtWindow;
  }
  openWinPage() {
    localStorage.setItem('cameraItem', JSON.stringify(this.camItemData));
    let url = `${window.location.protocol}//${window.location.host}/live-view`;
    window.open(url, '_blank', 'width=656,height=369');
  }

  openTagsPopup = () => {
    let textDiv = document.getElementById('tagsItem_' + this.camItemData.cameraUniqueId) as HTMLElement;
    if (textDiv) {
      textDiv.style.display = "block";
    }
  }
  onTagSelect = (item: any) => {
    this.selectedTagName = item;
    let textDiv1 = document.getElementById('txtbx_' + this.camItemData.cameraUniqueId) as HTMLElement;
    if (textDiv1) {
      textDiv1.style.display = "block";
    }
  }
  cancelTxtPopup = () => {
    let textDiv = document.getElementById('tagsItem_' + this.camItemData.cameraUniqueId) as HTMLElement;
    if (textDiv) {
      textDiv.style.display = "none";
    }
    let textDiv1 = document.getElementById('txtbx_' + this.camItemData.cameraUniqueId) as HTMLElement;
    if (textDiv1) {
      textDiv1.style.display = "none";
    }
  }
  submitStartRecord = () => {
    let paylaod = {
      "cameraUniqueId": this.camItemData.cameraUniqueId,
      "potentialId": this.camItemData.potentialId,
      "action": "start"
    }
    this.wallsService.getCLMRecordStartStop(paylaod).subscribe((resp: any) => {
      if (resp) {
        this.isrecordStartBtnShow = false;
        this.cancelTxtPopup();
        this.toasterService.showSuccess("Liveview record started successfully.");
      }
    }, (error: any) => {
      this.cancelTxtPopup();
      this.toasterService.showError("Unable to record the liveview.");
    });
  }
  submitStopRecordPopup = () => {
    this.openTagsPopup();
  }
  submitStopRecord = () => {
    let paylaod = {
      "cameraUniqueId": this.camItemData.cameraUniqueId,
      "potentialId": this.camItemData.potentialId,
      "action": "stop",
      "tagName": this.selectedTagName,
      "notes": this.userNotes
    }
    this.isrecordStartBtnShow = true;
    this.cancelTxtPopup();
    this.wallsService.getCLMRecordStartStop(paylaod).subscribe((resp: any) => {
      if (resp) {        
        this.toasterService.showSuccess("Liveview recorded successfully.");
      } else {
        // this.toasterService.showError("Unable to stop the record. Please try again.");
      }
    }, (error: any) => {
      this.toasterService.showError("Unable to stop the record. Please try again.");
    });
  }

}
