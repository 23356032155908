import { Injectable } from '@angular/core';
import { AccessPermissions } from '../../models/loginpermissions'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || ('false'));

  setLoginStatus(value: any) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', 'true');
  }

  get LoginStatus() {
    return JSON.parse(localStorage.getItem('loggedIn') ||
      this.loggedInStatus?.toString());
  }
  getAccess(url: string) {
    let userType = localStorage.getItem("userType")?.toString();
    let isAccessReturn: boolean = false;
    if (url == AccessPermissions.routingl1 && userType == AccessPermissions.accessl1) {
      isAccessReturn = true;
    }
    if (url == AccessPermissions.routingl2 && userType == AccessPermissions.accessl2) {
      isAccessReturn = true;
    }
    if ((url == AccessPermissions.routingl3) && userType == AccessPermissions.accessl3) {
      isAccessReturn = true;
    }
    return isAccessReturn;
  }
  setRouteSegment() {
    let userType = localStorage.getItem("userType")?.toString();
    let segmentUrl = "/login";
   // userType="";
    if (userType?.includes(AccessPermissions.accessl1)) {
      segmentUrl = AccessPermissions.routingl1;
    } else if (userType?.includes(AccessPermissions.accessl2)) {
      segmentUrl = AccessPermissions.routingl2;
    } else if (userType?.includes(AccessPermissions.accessl3)) {
      segmentUrl = AccessPermissions.routingl3;
    }else if (userType?.includes(AccessPermissions.CLM_Agent) 
      || userType?.includes(AccessPermissions.CLM_Role)
      || userType?.includes(AccessPermissions.CLM_Group)) {
      segmentUrl = AccessPermissions.routingCLM;
    }else{
      segmentUrl = AccessPermissions.routingCLM;
    }
    return segmentUrl;
  }
  hasLoggedInAlready(requestBody:any) {
    return localStorage.getItem('loggedIn') && localStorage.getItem('pro-token') && JSON.parse(localStorage.getItem('user') || "")?.user?.username == requestBody.username;
  }
  navigateRouteTo(): string {
    let userType = localStorage.getItem("userType")?.toString();
    if (userType == AccessPermissions.accessl1) {
      return AccessPermissions.routingl1;
    } else if (userType == AccessPermissions.accessl2) {
      return AccessPermissions.routingl2;
    } else if (userType == AccessPermissions.accessl3) {
      return AccessPermissions.routingl3;
    }
    return ""
  }

}
