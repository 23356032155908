import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { permissions } from 'src/app/common/models/globalpermissions';
import { WallsService } from 'src/app/services/walls/walls.service';
import { ActivatedRoute } from '@angular/router';
import { SharedsrvService } from 'src/app/common/services/sharedsrv/sharedsrv.service';
import { environment } from 'src/environments/environment';
import { l2Data, l2Data2 } from 'src/app/common/models/apidata';
import { AccessPermissions } from 'src/app/common/models/loginpermissions';
import { HeaderComponent } from 'src/app/common/components/header/header.component';
import { FirebaseNotificationService } from 'src/app/common/services/firebase-notification/firebase-notification.service';

@Component({
  selector: 'app-l2-one-walls',
  templateUrl: './l2-one-walls.component.html',
  styleUrls: ['./l2-one-walls.component.scss'],
  providers: [HeaderComponent]
})
export class L2OneWallsComponent implements OnInit, OnDestroy {

  wallsCount: number = 2;
  isFirstPnlEnable: boolean = false;
  isSecondPnlEnable: boolean = false;
  isThirdPnlEnable: boolean = false;
  isFourthPnlEnable: boolean = false;
  mirror: boolean = false;
  panel1Data: any = {};
  panel2Data: any = {};
  panel3Data: any = {};
  panel4Data: any = {};
  isPageLoad: boolean = true;
  permissionObj: any;
  pageLoadTextMsg: string = 'Loading...';
  staticObj: any = l2Data;
  staticObjQempty: any = {
    "statusCode": 202,
    "statusMessage": "Queue is empty",
    "eventbo": null
  };
  flagToReloadTable: boolean = false;

  eventPayload: any;
  panel1ErrorMessage: string = "";
  panel2ErrorMessage: string = "";
  panel3ErrorMessage: string = "";
  panel4ErrorMessage: string = "";

  wall1PullEventAPISub = new Subscription();
  wall2PullEventAPISub = new Subscription();
  wall3PullEventAPISub = new Subscription();
  wall4PullEventAPISub = new Subscription();

  wall1CloseEventAPISub = new Subscription();
  wall2CloseEventAPISub = new Subscription();
  wall3CloseEventAPISub = new Subscription();
  wall4CloseEventAPISub = new Subscription();

  wall1EscalateEventAPISub = new Subscription();
  wall2EscalateEventAPISub = new Subscription();
  wall3EscalateEventAPISub = new Subscription();
  wall4EscalateEventAPISub = new Subscription();

  panelTabledata: any = {};
  panel1IFTTdata: any = {};
  panel2IFTTdata: any = {};
  IFTTStaticObj: any = {};
  isDevEnable: any = false;
  activePanelName: string = "";
  levelName: string = "";
  panel1Redflag: boolean = false;
  panel2Redflag: boolean = false;
  panel3Redflag: boolean = false;
  panel4Redflag: boolean = false;
  /// flags for firebase message show
  panel1FBMessageCheck: boolean = false;
  panel2FBMessageCheck: boolean = false;
  panel3FBMessageCheck: boolean = false;
  panel4FBMessageCheck: boolean = false;
  slotNumber: number = 0;

  constructor(
    public wallService: WallsService,
    private activateRoute: ActivatedRoute,
    public sharedService: SharedsrvService,
    private headerComponent: HeaderComponent,
    private fbService: FirebaseNotificationService
  ) {
    this.levelName = this.sharedService.getMonitorLevels();

  }
  ngOnInit(): void {

    const StoredSlot = localStorage.getItem('Slots')? parseInt(localStorage.getItem('Slots')): this.wallsCount;
    this.slotNumber = StoredSlot;
    if (this.levelName==AccessPermissions.levelName3 && this.slotNumber == 1) {
      AccessPermissions.l3_slotsCount = this.slotNumber;
      this.wallsCount = this.slotNumber;
    }
    else{
      permissions.wallscount = this.wallsCount = 2;
    }
    this.sharedService.initDisableBackButtonOnInit();
    this.activateRoute.queryParams.subscribe((params) => {
      this.isDevEnable = !!params['dev'] || environment.devFlag;
      this.mirror = !!params['mirror'];

    });
    if (permissions.browserRefresh) {
      localStorage.removeItem('popupPotentialId');
      localStorage.removeItem('messagePanelPotentialId');
      localStorage.removeItem('popup_mirror_PotentialId');
      if (this.levelName == "Level 3") {
        let url = `${window.location.protocol}//${window.location.host}/l3-messagepanel`;
        let msgWindow = window.open(url, "message_panel", 'toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no');
        msgWindow?.close();
      }
    }
    // if (browserRefresh && !this.mirror) {
    //   this.router.navigateByUrl('/login');
    //   this.toastr.showError("You are redirected to login page due to page refresh!");
    // }
    this.activateRoute.data.subscribe((response: any) => {
      if (response?.tagsResolverList?.data) {
        this.wallService.fetchTagsFromResolverService(response.tagsResolverList);
      }
    });
    localStorage.removeItem("isMirrorPanel4");
    localStorage.removeItem("isMirrorPanel3");
    if (this.levelName == AccessPermissions.levelName2) {
      if (!this.mirror && localStorage.getItem("isMirrorEnabled") == 'true') {
        var windowRef = window.open(window.location.href + "?mirror=true", "duplicate", "toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no");
        var popup = setInterval(() => {
          if (windowRef?.closed) {
            clearInterval(popup);
          }
          windowRef?.close();
        });
        localStorage.removeItem("isMirrorEnabled");
      } else {
        //  var windowRef = window.open(window.location.href + "?mirror=true", "duplicate", "toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no");
      }
    }

    if (this.mirror) {
      localStorage.setItem("isMirrorEnabled", "true");
      localStorage.setItem("isMirrorFirsttimeLoadChange", "true");
    } else {
      localStorage.setItem("isMirrorEnabled", "false");
      localStorage.setItem("isFirsttimeLoadChange", "true");
    }
    permissions.autoplay = true;
    //permissions.wallscount = this.wallsCount = 2;
    this.permissionObj = permissions;
    this.eventPayload = this.getUpdatedEventPayloadForPullReq();
    // if (!environment.devFlag) {
    //   //this.wallService.getTagsListSubscribe();
    // }
    // if (permissions.browserRefresh) {
    //   this.getAlleventsDataForRefresh();
    // } else {
    //   this.getWallsEventData();
    // }
    this.getAlleventsDataForRefresh();
    this.sharedService.getUserdataPlaypause().subscribe((resp: any) => {
      //// events to load on play pause.
      if (resp == "PLAY") {
        this.getWallsEventData();
      }
    });
  }
  getUpdatedEventPayloadForPullReq = () => {
    if (localStorage.getItem("isMirrorSafeLogout") == "true") {
      permissions.isSafeLogoutEnabled = true;
    }
    return {
      "away": permissions.isSafeLogoutEnabled,
      "login": permissions.isSafeLogoutEnabled ? false : true
    };
  }


  getWallsEventData = () => {
    if (this.mirror) {
      localStorage.setItem("isMirrorEnabled", "true");
    }
    this.renderWallsBasedOnSelection(this.wallsCount, false);
  }
  getAlleventsDataForRefresh = () => {
    if (environment.FEATURE_FLAG.isFirebase) {
      this.fbService.requestFBAccessPermission();
    }
    this.isFirstPnlEnable = true;
    this.panel1ErrorMessage = "Loading..."
    this.isSecondPnlEnable = true;
    this.panel2ErrorMessage = "Loading..."
    this.eventPayload = this.getUpdatedEventPayloadForPullReq();
    this.wallService.pullRefreshEventData(this.eventPayload).subscribe((resp: any) => {
      //resp["eventlist"] = [l2Data,l2Data,l2Data];
      let length = resp?.eventlist?.length;
      if (length >= 3) {
        if (!this.mirror) {
          if (this.levelName == AccessPermissions.levelName2) {
            this.headerComponent.newTab();
          }
          // @ts-ignore
          //permissions.mirrorWindow = window.open(window.location.href + "?mirror=true", "duplicate", "location=0,menubar=0,toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=4000,height=4000");
          //this.sharedService.setMirrorWindowEnabled(true);
        } else {
          // if(this.mirror){
          //   this.sharedService.setMirrorWindowEnabled(true);
          // }
        }

      }
      if (length > 0) {
        switch (length) {
          case 1:
            if (this.mirror) {
              this.getFirstWallData();
              this.getSecondWallData();
            } else {
              this.extractFirstWallObj(resp.eventlist[0]);
              if(this.wallsCount != 1) {
                this.getSecondWallData();
              }
            }
            break;
          case 2:
            if (this.mirror) {
              this.getFirstWallData();
              this.getSecondWallData();
            } else {
              this.extractFirstWallObj(resp.eventlist[0]);
              this.extractSecondWallObj(resp.eventlist[1]);
            }
            break;
          case 3:
            if (this.mirror) {
              this.extractFirstWallObj(resp.eventlist[2]);
              this.getSecondWallData();
            } else {
              this.sharedService.setMirrorWindowEnabled(true);
              // setTimeout(()=>{
              //   //this.headerComponent.isWindowOpen = true;
              //   // let ele = document.getElementById("newtabMirrorBtn") as HTMLButtonElement;
              //   // if(ele){
              //   //   ele.classList.add("disabled");
              //   // }
              //   this.sharedService.setMirrorWindowEnabled(true);
              // },1000);              
              this.extractFirstWallObj(resp.eventlist[0]);
              this.extractSecondWallObj(resp.eventlist[1]);
            }
            break;
          case 4:
            if (this.mirror) {
              this.extractFirstWallObj(resp.eventlist[2]);
              this.extractSecondWallObj(resp.eventlist[3]);
            } else {
              this.sharedService.setMirrorWindowEnabled(true);
              this.extractFirstWallObj(resp.eventlist[0]);
              this.extractSecondWallObj(resp.eventlist[1]);
            }
            break;
          default:
            if (this.mirror) {
              // this.getFirstWallData();
              // this.getSecondWallData();
              this.extractFirstWallObj(resp.eventlist[2]);
              this.extractSecondWallObj(resp.eventlist[3]);
            } else {
              this.extractFirstWallObj(resp.eventlist[0]);
              this.extractSecondWallObj(resp.eventlist[1]);
            }
            break;
        }
      } else {
        this.getWallsEventData();
      }
    })
  }
  renderWallsBasedOnSelection = (panelCount: number, isonPageload: boolean) => {
    switch (panelCount) {
      case 1:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = false;
        this.isThirdPnlEnable = false;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
        }
        break;
      case 2:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = true;
        this.isThirdPnlEnable = false;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
          this.getSecondWallData();
        }
        break;
      case 3:
        this.isFirstPnlEnable = true;
        this.isSecondPnlEnable = true;
        this.isThirdPnlEnable = true;
        this.isFourthPnlEnable = false;
        if (!isonPageload) {
          this.getFirstWallData();
          this.getSecondWallData();
          this.getThirdWallData();
        }
        break;
      case 4:
        this.getAllWallsData(isonPageload);
        break;
      default:
        this.getAllWallsData(isonPageload);
        break;
    }

  }
  getAllWallsData = (isonPageload: boolean, wallCount?: number) => {
    this.isFirstPnlEnable = true;
    this.isSecondPnlEnable = true;
    this.isThirdPnlEnable = true;
    this.isFourthPnlEnable = true;
    if (!isonPageload && wallCount == 4) {
      this.getFirstWallData();
      this.getSecondWallData();
      this.getThirdWallData();
      this.getFourthWallData();
    }
    if (!isonPageload && wallCount == 2) {
      this.getFirstWallData();
      this.getSecondWallData();
    }
  }
  getFirstWallData = (fbForceCloseMsg?: string) => {

    this.panel1Data = {};
    this.isFirstPnlEnable = true;
    this.panel1ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall1PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isPageLoad = false;
        this.isFirstPnlEnable = false;
        if (this.isDevEnable) {
          res = this.staticObj;
          this.panel1Data = res?.eventbo;
          this.panel1ErrorMessage = "";
        }
        this.extractFirstWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
            this.isFirstPnlEnable = true;
            this.panel1ErrorMessage = "No events available";
          } else {
            if (this.isDevEnable) {
              this.isFirstPnlEnable = true;
              let res = this.staticObj;
              this.panel1Data = res?.eventbo;
              this.panel1ErrorMessage = "";
              return;
            }
            this.panel1ErrorMessage = `Unable to connect panel1 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel1 server. We are trying to connect.`);
            this.getAPIWallsError("panel1");
            //this.getPanelsOnInterval("panel1");
          }
        });

    }, 100);
  }
  getSecondWallData = (fbForceCloseMsg?: string) => {
    this.panel2Data = {};
    this.isSecondPnlEnable = true;
    this.panel2ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall2PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isPageLoad = false;
        this.isSecondPnlEnable = false;
        if (this.isDevEnable) {
          res = l2Data2;
          this.panel2Data = res?.eventbo;
          this.panel2ErrorMessage = "";
        }
        this.extractSecondWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
            this.isSecondPnlEnable = true;
            this.panel2ErrorMessage = "No events available";
          } else {
            if (this.isDevEnable) {
              this.isSecondPnlEnable = true;
              let res = this.staticObj;
              this.panel2Data = res?.eventbo;
              this.panel2ErrorMessage = "";
              return;
            }
            this.panel2ErrorMessage = `Unable to connect panel2 server. We are trying to connect.`;
            this.getAPIWallsError("panel2");
            //this.getPanelsOnInterval("panel2");
          }
        });
    }, 100);
  }
  getThirdWallData = (fbForceCloseMsg?: string) => {
    this.panel3Data = {};
    this.isThirdPnlEnable = true;
    this.panel3ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall3PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isThirdPnlEnable = false;
        this.isPageLoad = false;
        if (this.isDevEnable) {
          this.panel3Data = res?.eventbo;
          this.panel3ErrorMessage = "";
          res = this.staticObj;
          return;
        }
        this.extractThirdWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
            this.isThirdPnlEnable = true;
            this.panel3ErrorMessage = "No events available";
          } else {
            if (this.isDevEnable) {
              this.isThirdPnlEnable = true;
              let res = this.staticObj;
              this.panel3Data = res?.eventbo;
              this.panel3ErrorMessage = "";
              return;
            }
            this.panel3ErrorMessage = `Unable to connect panel3 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel3 server. We are trying to connect.`);
            //this.getPanelsOnInterval("panel3");
            this.getAPIWallsError("panel3");
          }
        });
    }, 10);
  }
  getFourthWallData = (fbForceCloseMsg?: string) => {
    this.isFourthPnlEnable = true;
    this.panel4Data = {};
    this.panel4ErrorMessage = (fbForceCloseMsg) ? fbForceCloseMsg : "Loading...";
    setTimeout(() => {
      this.eventPayload = this.getUpdatedEventPayloadForPullReq();
      this.wall4PullEventAPISub = this.wallService.pullEventData(this.eventPayload).subscribe((res: any) => {
        this.isFourthPnlEnable = false;
        this.isPageLoad = false;
        if (this.isDevEnable) {
          res = this.staticObj;
          this.panel4Data = res?.eventbo;
          this.panel4ErrorMessage = "";
          return;
        }
        this.extractFourthWallObj(res);
      },
        (err: any) => {
          if (permissions.isSafeLogoutEnabled) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
            this.isFourthPnlEnable = true;
            this.panel4ErrorMessage = "No events available";
          } else {
            if (this.isDevEnable) {
              this.isFourthPnlEnable = true;
              let res = this.staticObj;
              this.panel4Data = res?.eventbo;
              this.panel4ErrorMessage = "";
              return;
            }
            this.panel4ErrorMessage = `Unable to connect panel4 server. We are trying to connect.`;
            //this.toastr.error(`Unable to connect panel4 server. We are trying to connect.`);
            //this.getPanelsOnInterval("panel4");
            this.getAPIWallsError("panel4");
          }
        });
    }, 10);
  }
  extractFirstWallObj = (res: any) => {
    setTimeout(() => {
      this.isFirstPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel1Data = res?.eventbo;
        this.panel1Redflag = (this.panel1Data['redFlagEvent']) ? this.panel1Data['redFlagEvent'] : false;
        this.panel1ErrorMessage = "";
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel1', false);
          this.sharedService.set_ppActivePanel({ panelName: 'panel1' });
          if (this.mirror == true && localStorage.getItem("isMirrorEnabled") == "true") {
            let childtoParentWinObj = {
              res: res,
              panel: "panel3"
            }
            parent.postMessage(childtoParentWinObj, window.location.href);
            this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel3', false);
            this.sharedService.set_ppActivePanel({ panelName: 'panel3' });
            if (!localStorage.getItem("isMirrorPanel3")) {
              localStorage.setItem("isMirrorPanel3", "panel3");

            }
            setTimeout(() => {
              if (localStorage.getItem("PlayText") == "PLAY") {
                permissions.isSafeLogoutEnabled = false;
                this.getFirstWallData();
              } else {
                this.getFirstWallData();
              }
            }, 3000);
          }
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          if (this.mirror == true && localStorage.getItem("isMirrorEnabled") == "true") {
            if (!localStorage.getItem("isMirrorPanelLogout3")) {
              localStorage.setItem("isMirrorPanelLogout3", "panel3");
              let childtoParentWinObj = {
                res: res,
                panel: "panel3"
              }
              parent.postMessage(childtoParentWinObj, window.location.href);
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
            }
          } else {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel1', true);
          }
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel1Data = res;
          this.panel1Redflag = (this.panel1Data['redFlagEvent']) ? this.panel1Data['redFlagEvent'] : false;
        } else {
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        }
      } else {
        this.isFirstPnlEnable = true;
        this.panel1ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getFirstWallData();
        }, 3000);
      }
    }, 0);
  }
  extractSecondWallObj = (res: any) => {
    setTimeout(() => {
      this.isSecondPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel2Data = res?.eventbo;
        this.panel2ErrorMessage = "";
        this.panel2Redflag = (this.panel2Data['redFlagEvent']) ? this.panel2Data['redFlagEvent'] : false;
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel2', false);
          this.sharedService.set_ppActivePanel({ panelName: 'panel2' });
          if (this.mirror == true && localStorage.getItem("isMirrorEnabled") == "true") {
            let childtoParentWinObj = {
              res: res,
              panel: "panel4"
            }
            this.sharedService.set_ppActivePanel({ panelName: 'panel4' });
            parent.postMessage(childtoParentWinObj, window.location.href);
            if (!localStorage.getItem("isMirrorPanel4")) {
              localStorage.setItem("isMirrorPanel4", "panel4");
            }
            this.sharedService.get_ppPanelActiveDataStoreSharedSrv('panel4', false);
            setTimeout(() => {
              if (localStorage.getItem("PlayText") == "PLAY") {
                permissions.isSafeLogoutEnabled = false;
                this.getSecondWallData();
              } else {
                this.getSecondWallData();
              }
            }, 3000);
          }
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          if (this.mirror == true && localStorage.getItem("isMirrorEnabled") == "true") {
            if (!localStorage.getItem("isMirrorPanelLogout4")) {
              localStorage.setItem("isMirrorPanelLogout4", "panel4");
              let childtoParentWinObj = {
                res: res,
                panel: "panel4"
              }
              parent.postMessage(childtoParentWinObj, window.location.href);
              this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
            };
          } else {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel2', true);
          }
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        } else if (res?.eventid && res.potentialId) {
          this.panel2Data = res;
          this.panel2Redflag = (this.panel2Data['redFlagEvent']) ? this.panel2Data['redFlagEvent'] : false;
        } else {
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        }
      } else {
        this.isSecondPnlEnable = true;
        this.panel2ErrorMessage = permissions.nullResponseMsg
        setTimeout(() => {
          this.getSecondWallData();
        }, 3000);
      }
    }, 0);
  }
  extractThirdWallObj = (res: any) => {
    setTimeout(() => {
      this.isThirdPnlEnable = true;
      //res = this.staticObj;
      if (res != null && res?.statusCode == 200) {
        this.panel3Data = res?.eventbo;
        this.panel3ErrorMessage = "";
        this.panel3Redflag = (this.panel3Data['redFlagEvent']) ? this.panel3Data['redFlagEvent'] : false;
      } else if (res != null && res?.statusCode != 200) {
        ////
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', false);
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
          if (this.mirror == true || localStorage.getItem("isMirrorEnabled") == "true") {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel3_1');
          }
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        } else {
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        }
      } else {
        this.isThirdPnlEnable = true;
        this.panel3ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getThirdWallData();
        }, 3000);
      }
    }, 0);
  }
  extractFourthWallObj = (res: any) => {
    setTimeout(() => {
      this.isFourthPnlEnable = true;
      if (res != null && res?.statusCode == 200) {
        this.panel4Data = res?.eventbo;
        this.panel4ErrorMessage = "";
        this.panel4Redflag = (this.panel4Data['redFlagEvent']) ? this.panel4Data['redFlagEvent'] : false;
      } else if (res != null && res?.statusCode != 200) {
        if (res?.statusMessage == permissions.eventPauseMsg) {
          this.panel4ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', false);
          return;
        }
        if (res?.statusMessage == permissions.userLogoutMsg) {
          this.panel4ErrorMessage = res?.statusMessage;
          this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
          if (this.mirror == true || localStorage.getItem("isMirrorEnabled") == "true") {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel4_1');
          }
          return;
        } else if (res?.statusMessage == permissions.autoClosingMsg) {
          this.isFourthPnlEnable = true;
          this.panel4ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        } else {
          this.panel4ErrorMessage = res?.statusMessage;
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        }
      } else {
        this.panel4ErrorMessage = permissions.nullResponseMsg;
        setTimeout(() => {
          this.getFourthWallData();
        }, 3000);
      }
    }, 0);
  }
  getPanelsEventData = (data: any) => {
    let panelName = data.pannelName;
    this.activePanelName = data.pannelName;
    if (permissions.initialPanelLoad == false) {
      this.getPanelTableData1(null);
    } else {
      this.getPanelTableData1(null);
    }
    if (localStorage.getItem("isMirrorSafeLogout") == "true") {
      permissions.isSafeLogoutEnabled = true;
    }
    // if(localStorage.getItem("isMirrorPanel3")=='panel3' && localStorage.getItem("isMirrorEnabled")=='true' ){
    //     this.sharedService.set_ppActivePanel({panelName:'panel3'});
    // }
    // if(localStorage.getItem("isMirrorPanel4")=='panel4' && localStorage.getItem("isMirrorEnabled")=='true' ){
    //   this.sharedService.set_ppActivePanel({panelName:'panel4'});
    // }
    //   let panel3Obj:any;
    //   let panel4Obj:any;
    //   if(localStorage.getItem("isMirrorPanel3") && JSON.parse(localStorage?.getItem("isMirrorPanel3"))=='true' ){
    //     this.sharedService.set_ppActivePanel({panelName:'panel3'});
    // }
    // if(localStorage.getItem("isMirrorPanel4")=='panel4' && localStorage.getItem("isMirrorEnabled")=='true' ){
    //   this.sharedService.set_ppActivePanel({panelName:'panel4'});
    // }

    /// Making Close API call for escalating on L3 only
    // b/c we no need the another stage to escalate the event
    let realActionNameBeforeOverWrite = data.tagType;
    let notesAvailableStatus = data?.notesAvailable;
    if (this.levelName == AccessPermissions.levelName3) {
      if (data.tagType == 'escalations') {
        data.tagType = 'close'
      }
    }
    /// end

    let payload: any = {
      "eventId": data.eventId,
      "actionTag": data.actionTag,
      "actionNote": data.actionNote,
      // "userId": "1t1",
      "away": permissions.isSafeLogoutEnabled,
      "login": permissions.isSafeLogoutEnabled ? false : true
    };
    switch (panelName) {
      case "panel1":
        this.panel1Data = {};
        this.isFirstPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel1ErrorMessage = data.forceCloseMsg;
          this.panel1FBMessageCheck = true;
        } else {
          this.panel1ErrorMessage = "Loading...";
          this.panel1FBMessageCheck = false;
        }
        this.panel1Redflag = false;
        if (data.tagType == 'close') {
          this.wall1CloseEventAPISub = this.wallService.postCloseEventData(payload,this.levelName,realActionNameBeforeOverWrite,notesAvailableStatus).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFirstPnlEnable = true;
              this.panel1Data = res.eventbo;
              this.panel1ErrorMessage = "";
              return;
            }
            this.extractFirstWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isFirstPnlEnable = true;
                this.panel1ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isFirstPnlEnable = true;
                  let res = this.staticObj;
                  this.panel1Data = res?.eventbo;
                  this.panel1ErrorMessage = "";
                  return;
                }
                this.panel1ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );
        } else if (data.tagType == 'escalations') {
          this.isFirstPnlEnable = true;
          this.wall1EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFirstPnlEnable = true;
              this.panel1Data = res.eventbo;
              this.panel1ErrorMessage = "";
              return;
            }
            this.extractFirstWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isFirstPnlEnable = true;
                this.panel1ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isFirstPnlEnable = true;
                  let res = this.staticObj;
                  this.panel1Data = res?.eventbo;
                  this.panel1ErrorMessage = "";
                  return;
                }
                this.panel1ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            this.isFirstPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFirstPnlEnable = true;
              this.panel1Data = res.eventbo;
              this.panel1ErrorMessage = "";
              return;
            }
            this.extractFirstWallObj(res);
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          console.log("permissions.fbNotificationShowTime",permissions.fbNotificationShowTime + typeof(permissions.fbNotificationShowTime));
          setTimeout(() => {
            this.getFirstWallData(this.panel1ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      case "panel2":
        this.panel2Data = {};
        this.isSecondPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel2ErrorMessage = data.forceCloseMsg;
          this.panel2FBMessageCheck = true;
        } else {
          this.panel2ErrorMessage = "Loading...";
          this.panel2FBMessageCheck = false;
        }
        this.panel2Redflag = false;
        if (data.tagType == 'close') {
          this.wall2CloseEventAPISub = this.wallService.postCloseEventData(payload,this.levelName,realActionNameBeforeOverWrite,notesAvailableStatus).subscribe((res: any) => {
            this.isSecondPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isSecondPnlEnable = true;
              this.panel2Data = res.eventbo;
              this.panel2ErrorMessage = "";
              return;
            }
            this.extractSecondWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isSecondPnlEnable = true;
                this.panel2ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isSecondPnlEnable = true;
                  let res = this.staticObj;
                  this.panel2Data = res?.eventbo;
                  this.panel2ErrorMessage = "";
                  return;
                }
                this.panel2ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'escalations') {
          this.wall2EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isSecondPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isSecondPnlEnable = true;
              this.panel2Data = res.eventbo;
              this.panel2ErrorMessage = "";
              return;
            }
            this.extractSecondWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isSecondPnlEnable = true;
                this.panel2ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isSecondPnlEnable = true;
                  let res = this.staticObj;
                  this.panel2Data = res?.eventbo;
                  this.panel2ErrorMessage = "";
                  return;
                }
                this.panel2ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            });
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            ////this.getPanelsOnInterval(panelName);
            this.isSecondPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isSecondPnlEnable = true;
              this.panel2Data = res.eventbo;
              this.panel2ErrorMessage = "";
              return;
            }
            this.extractSecondWallObj(res);
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          console.log(permissions.fbNotificationShowTime)
          setTimeout(() => {
            this.getSecondWallData(this.panel2ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      case "panel3":
        /// this panel data is not required as we have only 2 walls
        this.panel3Data = {};
        this.isThirdPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel3ErrorMessage = data.forceCloseMsg;
          this.panel3FBMessageCheck = true;
        } else {
          this.panel3ErrorMessage = "Loading...";
          this.panel3FBMessageCheck = false;
        }
        this.panel3Redflag = false;
        if (data.tagType == 'close') {
          this.wall3CloseEventAPISub = this.wallService.postCloseEventData(payload,this.levelName,realActionNameBeforeOverWrite,notesAvailableStatus).subscribe((res: any) => {
            this.isThirdPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isThirdPnlEnable = true;
              this.panel3Data = res.eventbo;
              this.panel3ErrorMessage = "";
              return;
            }
            this.extractThirdWallObj(res);

          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isThirdPnlEnable = true;
                this.panel3ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isThirdPnlEnable = true;
                  let res = this.staticObj;
                  this.panel3Data = res?.eventbo;
                  this.panel3ErrorMessage = "";
                  return;
                }
                this.panel3ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );
        } else if (data.tagType == 'escalations') {
          this.wall3EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isThirdPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isThirdPnlEnable = true;
              this.panel3Data = res.eventbo;
              this.panel3ErrorMessage = "";
              return;
            }
            this.extractThirdWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isThirdPnlEnable = true;
                this.panel3ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isThirdPnlEnable = true;
                  let res = this.staticObj;
                  this.panel3Data = res?.eventbo;
                  this.panel3ErrorMessage = "";
                  return;
                }
                this.panel3ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                //this.getPanelsOnInterval(panelName);
                this.getAPIWallsError(panelName);
              }
            }
          );

        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            // //this.getPanelsOnInterval(panelName);
            this.isThirdPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isThirdPnlEnable = true;
              this.panel3Data = res.eventbo;
              this.panel3ErrorMessage = "";
              return;
            }
            this.extractThirdWallObj(res);
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getThirdWallData(this.panel3ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      case "panel4":
        /// this panel data is not required as we have only 2 walls
        this.panel4Data = {};
        this.isFourthPnlEnable = true;
        if (data?.forceCloseMsg) {
          this.panel4ErrorMessage = data.forceCloseMsg;
          this.panel4FBMessageCheck = true;
        } else {
          this.panel4ErrorMessage = "Loading...";
          this.panel4FBMessageCheck = false;
        }
        this.panel2Redflag = false;
        if (data.tagType == 'close') {
          this.wall4CloseEventAPISub = this.wallService.postCloseEventData(payload,this.levelName,realActionNameBeforeOverWrite,notesAvailableStatus).subscribe((res: any) => {
            this.isFourthPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFourthPnlEnable = true;
              this.panel4Data = res.eventbo;
              this.panel4ErrorMessage = "";
              return;
            }
            this.extractFourthWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isFourthPnlEnable = true;
                this.panel4ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isFourthPnlEnable = true;
                  let res = this.staticObj;
                  this.panel4Data = res?.eventbo;
                  this.panel4ErrorMessage = "";
                  return;
                }
                this.panel4ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                this.getAPIWallsError(panelName);
                //this.getPanelsOnInterval(panelName);
              }
            });
        } else if (data.tagType == 'escalations') {
          this.wall4EscalateEventAPISub = this.wallService.postEscalateEventData(payload).subscribe((res: any) => {
            this.isFourthPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFourthPnlEnable = true;
              this.panel4Data = res.eventbo;
              this.panel4ErrorMessage = "";
              return;
            }
            this.extractFourthWallObj(res);
          },
            (err: any) => {
              if (permissions.isSafeLogoutEnabled) {
                this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
                this.isFourthPnlEnable = true;
                this.panel4ErrorMessage = "No events available";
              } else {
                if (this.isDevEnable) {
                  this.isFourthPnlEnable = true;
                  let res = this.staticObj;
                  this.panel4Data = res?.eventbo;
                  this.panel4ErrorMessage = "";
                  return;
                }
                this.panel4ErrorMessage = `Unable to connect ${panelName} server. We are trying to connect.`;
                // this.toastr.error(`Unable to connect ${panelName} server. We are trying to connect.`);
                this.getAPIWallsError(panelName);
                //this.getPanelsOnInterval(panelName);
              }
            });
        } else if (data.tagType == 'autoclose') {
          this.wallService.getAutoCloseAfter2Mins(payload.eventId).subscribe((res: any) => {
            ////this.getPanelsOnInterval(panelName);
            this.isFourthPnlEnable = false;
            if (this.isDevEnable) {
              res = this.staticObj;
              this.isFourthPnlEnable = true;
              this.panel4Data = res.eventbo;
              this.panel4ErrorMessage = "";
              return;
            }
            this.extractFourthWallObj(res);
          });
        } else if (data.tagType == 'FORCE_CLOSE') {
          setTimeout(() => {
            this.getFourthWallData(this.panel4ErrorMessage);
          }, permissions.fbNotificationShowTime);
        }
        break;
      default:
        break;
    }
  }

  getPanelsOnInterval(panelName: string) {
    switch (panelName) {
      case "panel1":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getFirstWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          if (this.mirror) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel3', true);
          }
          this.isFirstPnlEnable = true;
          this.panel1ErrorMessage = "No events available";
        }
        break;
      case "panel2":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getSecondWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          if (this.mirror) {
            this.sharedService.getPanelActiveDataStoreSharedSrv('panel4', true);
          }
          this.isSecondPnlEnable = true;
          this.panel2ErrorMessage = "No events available";
        }
        break;
      case "panel3":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getThirdWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isThirdPnlEnable = true;
          this.panel3ErrorMessage = "No events available";
        }
        break;
      case "panel4":
        if (!permissions.isSafeLogoutEnabled) {
          setTimeout(() => {
            this.getFourthWallData();
          }, 3000);
        } else {
          this.sharedService.getPanelActiveDataStoreSharedSrv(panelName);
          this.isFourthPnlEnable = true;
          this.panel4ErrorMessage = "No events available";
        }
        break;
      default:
        break;
    }
  }
  getAPIWallsError(panelName: string) {
    switch (panelName) {
      case "panel1":
        this.panel1Data = {};
        this.isFirstPnlEnable = true;
        this.panel1ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel2":
        this.panel2Data = {};
        this.isSecondPnlEnable = true;
        this.panel2ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel3":
        this.panel3Data = {};
        this.isThirdPnlEnable = true;
        this.panel3ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      case "panel4":
        this.panel4Data = {};
        this.isFourthPnlEnable = true;
        this.panel4ErrorMessage = "Unable to conenct server, Please try after sometime";
        break;
      default:
        break;
    }
  }

  postPanelsAPIData = (payload: any) => {
    return this.wallService.postEscalateEventData(payload);
  }
  onCountChange = (event: any) => {
    this.wallsCount = parseInt(event.target.value);
    this.renderWallsBasedOnSelection(this.wallsCount, false)
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy() {
    this.wall1PullEventAPISub.unsubscribe();
    this.wall2PullEventAPISub.unsubscribe();
    this.wall3PullEventAPISub.unsubscribe();
    this.wall4PullEventAPISub.unsubscribe();

    this.wall1CloseEventAPISub.unsubscribe();
    this.wall2CloseEventAPISub.unsubscribe();
    this.wall3CloseEventAPISub.unsubscribe();
    this.wall4CloseEventAPISub.unsubscribe();

    this.wall1EscalateEventAPISub.unsubscribe();
    this.wall2EscalateEventAPISub.unsubscribe();
    this.wall3EscalateEventAPISub.unsubscribe();
    this.wall4EscalateEventAPISub.unsubscribe();

    this.sharedService.destroyDisableBackButtonOnInit();
  }
  getPanelTableData1(event: any) {
    // console.log("this.activePanelName", this.activePanelName)
    if (permissions.initialPanelLoad) {
      this.flagToReloadTable = false;
      this.panelTabledata = event;
      setTimeout(() => {
        this.flagToReloadTable = true;
      }, 50);
      permissions.initialPanelLoad = false;
    } else {
      if (localStorage.getItem("isMirrorEnabled") == "true") {
        if (this.activePanelName == localStorage.getItem("activePanelInTablePanel")) {
          this.flagToReloadTable = false;
          this.panelTabledata = null;
          setTimeout(() => {
            this.flagToReloadTable = true;
          }, 50);
          localStorage.setItem("activePanelInTablePanel", "")
        } else {
          if (event?.["sendToParentClick"] == true) {
            this.flagToReloadTable = false;
            this.panelTabledata = event;
            setTimeout(() => {
              this.flagToReloadTable = true;
            }, 50);
          } else {
            this.flagToReloadTable = false;
            this.panelTabledata = null;
            setTimeout(() => {
              this.flagToReloadTable = true;
            }, 50);
          }
        }
      } else if (localStorage.getItem("isMirrorEnabled") == "false") {
        if (this.activePanelName == localStorage.getItem("activePanelInTablePanel")) {
          this.flagToReloadTable = false;
          this.panelTabledata = null;
          setTimeout(() => {
            this.flagToReloadTable = true;
          }, 50);
          localStorage.setItem("activePanelInTablePanel", "")
        } else {
          if (event?.["sendToParentClick"] == true) {
            this.flagToReloadTable = false;
            this.panelTabledata = event;
            setTimeout(() => {
              this.flagToReloadTable = true;
            }, 50);
          } else {
            this.flagToReloadTable = false;
            this.panelTabledata = null;
            setTimeout(() => {
              this.flagToReloadTable = true;
            }, 50);
          }
        }
      }
    }
  }
}