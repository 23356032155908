import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ToasterService } from '../services/toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard  {
  constructor(private authService: AuthService, private router: Router,
    private toasterService: ToasterService,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {

    /// check login status and route permissions
    // let accessPermissions = this.authService.getAccess(state.url);
    // let loginandAccessStatus = (this.authService.LoginStatus && accessPermissions);
    // if (!loginandAccessStatus) {
    //   this.router.navigate(['login']);
    //   // this.toasterService.showError("You don't have the permission to access "+state.url)
    // }
    // return loginandAccessStatus;

    /// check only login status    
    if (!this.authService.LoginStatus) {
      this.router.navigate(['login']);
    }
  //  else {
  //     if (!sessionStorage.getItem('sessionStarted')) {
  //       sessionStorage.setItem('sessionStarted', 'true')
  //     }
  //     else {
  //       sessionStorage.clear()
  //       localStorage.clear()
  //       this.router.navigate(['login']);
  //     }
  //   }
    return this.authService.LoginStatus;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(route, state);
  }

}
