import { Component, OnInit } from '@angular/core';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
})
export class LogoutPageComponent implements OnInit {
  content: string =
    'Please use desktop PRO-SAFE Exe file to LOGIN into PROSAFE Monitoring Wall';
  constructor(public sharedSrv: SharedsrvService, private http: HttpClient) {}

  ngOnInit(): void {
    this.sharedSrv.initDisableBackButtonOnInit();
    // Check if 'hostnameSuccess' is set to 'true' in local storage
    const hostnameSuccess = localStorage.getItem('hostnameSuccess');
    if (hostnameSuccess === 'true') {
      setTimeout(() => {
        this.closeBrowser();
      }, 5000)
    }
    console.log('Not calling close api')
  }

  closeBrowser() {
    const apiUrl = 'http://127.0.0.1:8000/api/browser/close';

    this.http.get(apiUrl).subscribe(
      (response) => {
        // Handle the response if needed
        console.log("CloseBrowser Success..", response);
      },
      (error) => {
        // Handle the error if needed
        console.log("Error Close browser..", error);
      }
    );
  }
  ngOnDestroy(): void {
    this.sharedSrv.destroyDisableBackButtonOnInit();
  }
}
