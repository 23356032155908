const domainName = "https://pro-safe-sandbox.pro-vigil.info";

export const environment = {
  production: false,
  title: `Prod Environment`,
  apiDomain: domainName,
  baseUrl1: `http://54.235.33.134:9054/`,
  baseUrl2: `http://3.214.218.82:9002/api/`,
  baseUrl: `${domainName}:8006/api/`,
  liveviewUrl: `${domainName}:8006/api/`,
  eventstackUrl: `${domainName}:8006/api/`,
  devFlag: false,
  pathUrl: `https://aws0002uatov2.pro-vigil.info:8443/pro-vigil/`,
  fetchAllInfo: `${domainName}:8006`,
  alarmTrigger: `${domainName}:8006/api/`,
  consoleUrl: `https://console-vigilx.pro-vigil.com:8443/ivigil-console/`,
  releaseVersion: `4.7.6`,
  psafeAdminUrl: `https://psafe-admin-sandbox-test.pro-vigil.info/login`,
  baseURLwithPort: `${domainName}:8006/api/`,
  firebaseUrl: `${domainName}:8018/api/notification/`,
  FEATURE_FLAG: {
    "isFirebase": true
  },
  firebase : {
  apiKey: "AIzaSyCQ4U2femE28tDB6M1Twv_6gqen2Rs-UuY",
  authDomain: "pro-safe-notification-uat.firebaseapp.com",
  projectId: "pro-safe-notification-uat",
  storageBucket: "pro-safe-notification-uat.appspot.com",
  messagingSenderId: "730147561528",
  appId: "1:730147561528:web:a10858352f6639654dc6cd",
  measurementId: "G-1XVX3M4R4Y",
  vapidKey: "BHWKtAD_aBADDkIEFTfzmRKxj-28BVcIW9Ratod1WjdyOMlR7pt39hd4yTo00mi0l2IbgTm29nu1ISlidN94ln8"
  }
};
