import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import { onBackgroundMessage } from "firebase/messaging/sw";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FirebaseNotificationService {
  private FBNoficationObj: BehaviorSubject<any | null>;
  public updatedFBNoficationObj: Observable<any | null>;
  constructor(
    public httpClient: HttpClient,
  ) {
    this.FBNoficationObj = new BehaviorSubject<any>(null);
    this.updatedFBNoficationObj = this.FBNoficationObj.asObservable();
  }
  requestFBAccessPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken: any) => {
          if (currentToken) {
            localStorage.setItem("fbToken", currentToken);
            this.userFBtokenRegister();
          } else {
            // console.log('No registration token available. Request permission to generate one.');
          }
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      // console.log('Message received. ', payload);
      this.setFBNotificationEventObj(payload);
    });
  }
  setFBNotificationEventObj = (value: any) => {
    this.FBNoficationObj.next(value);
  }
  getFBNotificationEventObj = () => {
    return this.updatedFBNoficationObj;
  }
  userFBtokenRegister = () => {
    let apiUrl = `${environment.firebaseUrl}register/user`;
    let headers: HttpHeaders = new HttpHeaders();
    let proToken = localStorage.getItem('pro-token') || '';
    proToken = proToken.replace(/['"]+/g, '');
    headers = headers.append('PRO-TOKEN', '' + proToken);
    let payload = {
      "token": (localStorage.getItem("fbToken")) ? localStorage.getItem("fbToken") : "",
      "userName": (localStorage.getItem("pro-user")) ? localStorage.getItem("pro-user") : "",
    }
    this.httpClient.post(apiUrl, payload).subscribe((resp: any) => {
      console.log("fb notication registered success");
      /// listen the FB notication to get force closed events
      this.listen();
    });
  }

  setFirebaseObjForTesting = (val?: any) => {
    if (!val) {
      val = {
        "collapseKey": "",
        "data": {
          "EventId": "N746910F1700477139012"
        },
        "from": "from123",
        "messageId": "message1234",
        "notification": {
          "body": "testing naresh",
          "title": "FORCE_CLOSE"
        }
      }
    }
    this.setFBNotificationEventObj(val);
  }

}
