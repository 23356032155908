<div style="display: contents;" [id]="'videowrapper_'+panelName" (click)="panelClick($event,panelName)"
  draggable="false">

  <audio controls autoplay style="display: none;">
    <source src="../../../../assets/incoming.wav" type="audio/wav">
    Your browser does not support the audio element.
  </audio>
  <!-- [ngClass]="{'disabled-timeline': isReplayEnabled,'enabled-timeline':!isReplayEnabled, 'border-green': (wallDuration >0 && wallDuration <=10), 'border-yellow': (wallDuration>10 && wallDuration<=20), 'border-orange': (wallDuration>20 && wallDuration<45), 'border-red': wallDuration>45}" -->
  <video class="ratio ratio-16x9" controls="" disablepictureinpicture="" draggable="false" ondragstart="return false;"
    controlslist="nodownload noplaybackrate nofullscreen" [muted]="'true'" preload="none" [id]="'video_'+panelName"
    appNoRightClick autoplay [ngClass]="{'disabled-timeline': isReplayEnabled,'enabled-timeline':!isReplayEnabled, 'border-orange': (wallDuration>20 && wallDuration<45), 'border-red': wallDuration>45}">
    <source src={{wallsData?.eventLink}}>
  </video>
  <span [ngClass]="{'siteNameHeading': wallDuration<45,'siteNameRedHeading':wallDuration>=45}">
      {{wallsData?.siteName}}
  </span>
  <span class="siteNameTimeHeading">{{eventCurrentTime}} / {{eventDuration}}</span>

  <p [left]="panelPopup?.left" *ngIf="true"
    class="event-time-info {{panelEventTriggers.inshorttime ? 'indicator-inshorttime' : ''}}">{{121 - eventAutoClosetime}}
  </p>

  <div class="card-video-status" [id]="'indicator_'+panelName" *ngIf="isDevFlag">
    <div>
      <span class="indicator" [class.indicator-landed]="panelEventTriggers.landed"
        [class.indicator-clicked]="panelEventTriggers.clicked"
        [class.indicator-inshorttime]="panelEventTriggers.inshorttime"></span>
    </div>
  </div>
  <canvas [id]="'canvas_'+panelName" class="canvas" [width]="this.panelPopup.width" [height]="this.panelPopup.height"
    style="display:none;"></canvas>
  <div [id]="'cameraInfo_'+panelName" class="cameraInfo" style="display:none;" [style.left]="panelPopup?.left+'px'"
    [style.top]="panelPopup?.bottom+(-140)+'px'" [style.width]="panelPopup?.width+'px'">
    <div><span class="close" style="color:black" (click)="showSiteInfoModal($event)">&times;</span></div>

    <div class="row" style="background-color: #ffffff;margin:-5px">
      <span>
        <span class="site-left">Camera Name:</span>
        <span class="site-right">{{wallsData?.cameraName}}</span>
      </span>
      <span>
        <span class="site-left">Site Name: </span>
        <span class="site-right">{{wallsData?.siteName}}</span>
      </span>

      <span>
        <span class="site-left">Event Time:</span>
        <span class="site-right"> {{wallsData?.eventTimeStampStr}}</span>
      </span>
      <span>
        <span class="site-left">Event Id:</span>
        <span class="site-right"> {{wallsData?.eventid}}, {{wallsData?.potentialId}} </span>
        <span class="site-right" (click)="copyInfoDetails(wallsData?.eventLink)">
          <i class="fa fa-solid fa-copy" style="cursor: pointer;"></i>
        </span>
      </span>
    </div>
  </div>

  <div [id]="'boloNotes_'+panelName" class="cameraInfo" style="display:none;" [style.left]="panelPopup?.left+'px'"
    [style.top]="panelPopup?.bottom+(-140)+'px'" [style.width]="panelPopup?.width+'px'">
    <div><span class="close" style="color:black" (click)="showBoloModal($event,true)">&times;</span></div>

    <div class="row" style="background-color: #ffffff;margin:-5px">
      <div class="boloNotes">
        <span class="bolomessage">Bolo Message</span>
        <span class="bolorecord">{{boloNotesMesaage}}</span>
      </div>
    </div>
  </div>
  <div [id]="'daynightImg_'+panelName" class="daynightImg day-images" style="display:none;"
    [style.left]="panelPopup?.left+'px'" [style.top]="panelPopup?.top+(30)+'px'">
    <div (click)="showDaynightModal($event,false)"> <span class="close"
        style="position: relative;top: -10px;left: 1px;">&times;</span></div>
    <div class="row">
      <div class="col-6 h-100">
        <span class="text-bold">Day Image</span>
        <img [src]="wallsData?.dayimage" style="width:100%" />
      </div>
      <div class="col-6 h-100">
        <span class="text-bold">Night Image</span>
        <img [src]="wallsData?.nightimage" style="width:100%" />
      </div>
    </div>
  </div>
  <div class="p_lr_10" style="z-index: 999999;">
    <div class="btn_background">
      <a class="d-block btn  btn-outline-secondary w-100 p-0" (click)="openEscalationModal($event)">
        <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Escalation" />

      </a>
    </div>
    <div class="btn_background" *ngIf="!showPanelOption">
      <a class="d-block btn  btn-outline-success w-100  p-0 disabled">
        <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Close" />

      </a>
    </div>
    <div class="btn_background" *ngIf="showPanelOption">
      <a class="d-block btn  btn-outline-success w-100  p-0" (click)="openCloseModal($event)">
        <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Close" />

      </a>
    </div>
    <div class="btn_background">
      <a class="d-block btn btn btn-outline-warning text-center p-0" (click)="openFeedbackModal($event)">
        <img src="assets/images/icons/feedback6.svg" class="svgImg_close" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Feedback" />
      </a>
    </div>
    <div class="btn_background">
      <a class="btn btn-outline-secondary w-100 p-0" (click)="enableMask(false)">
        <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px;top:-1px" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Masking" />
      </a>
    </div>
    <div class="btn_background">
      <a class="btn btn-outline-daynight w-100 icon_svg p-0" (click)="showDaynightModal($event,false)">
        <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Day/Night" />

      </a>
    </div>
    <!-- <div class="col_button">
      <a class="btn btn-dark btn-success btn-outline-secondary w-100" (click)="openCloseModal($event)">More info</a>
    </div> -->
    <!-- <hr> -->
    <div class="btn_background">
      <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="showSiteInfoModal($event,false)">
        <img src="assets/images/icons/info-circle.svg" class="icon_svg" style="margin-top:4px" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Site info" />
      </a>
    </div>
    <div class="btn_background" *ngIf="isReplayEnabled && showPanelOption">
      <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="replayClicked('video_')">
        <img src="assets/images/icons/replay33.png" class="pngimg" data-bs-toggle="tooltip" data-bs-placement="top"
          title="Replay1" />
      </a>
    </div>
    <div class="btn_background" *ngIf="!isReplayEnabled || !showPanelOption">
      <a class="d-block btn btn-outline-primary w-100 svg p-0 disabled" (click)="replayClicked('video_')">
        <img src="assets/images/icons/replay33.png" class="pngimg" data-bs-toggle="tooltip" data-bs-placement="top"
          title="Replay" />
      </a>
    </div>
    <div class="col_button icon_flex">
      <div class="btn_background w-70" [ngClass]="{'w-30': hasBoloNotes}">
        <a class="d-block btn btn-outline-primary w-100 svg p-0" data-bs-toggle="modal"
          (click)="openfullscreenPopup($event)" [href]="'#fullScreen'+panelName" role="button">
          <img src="assets/images/icons/icon-fullscreen.svg" class="repl_svg" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Fullscreen" />
        </a>
      </div>
      <div class="btn_background_bolo" style="width: 30px !important;" *ngIf="hasBoloNotes"
        [id]="'boloNotes'+panelName">
        <a class="d-block btnBolo" (click)="showBoloModal($event,false)" *ngIf="hasBoloNotes"
          [id]="'boloNotes'+panelName">
          <img src="assets/images/bolo.png" class="pngimg boloicon" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Bolo Notes" />
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Escalation Modal popup -->
<div [id]="'myModalEscalation'+panelName" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none escTags"
  [style.left]="panelPopup?.left+(80)+'px'" [style.top]="panelPopup?.top+(10)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">ESCALATION TAGS</h6>
      <span class="close" (click)="closeEscalationModal()">&times;</span>
    </div>
    <div class="modal-body">

      <div class="row gx-4 gy-2">
        <!-- <input type="text" style="width:100px;" placeholder="Type escalation"
         [(ngModel)]="escalationMessage" (change)="inputEscalationChange()" /> -->
        <div class="col-6" *ngFor="let etags of L1EscalatingTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0 model-btn"
            (click)="escalationTags('escalations',etags.tagname)">{{etags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Close Modal popup -->
<div [id]="'myModalClose'+panelName" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none escTags"
  [style.left]="panelPopup?.left+(80)+'px'" [style.top]="panelPopup?.top+(10)+'px'">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">CLOSE TAGS</h6>
      <span class="close" (click)="closeTagsModal()">&times;</span>
    </div>
    <div class="modal-body" style="overflow: auto;">
      <div class="row gx-4 gy-2">
        <div class="col-6" *ngFor="let ctags of L1ClosingTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0 model-btn"
            (click)="closingTags('close',ctags.tagname)">{{ctags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Feed Back Modal popup -->
<div [id]="'myModalfeedback'+panelName" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none escTags"
  [style.left]="panelPopup?.left+(80)+'px'" [style.top]="panelPopup?.top+(10)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">FEEDBACK</h6>
      <span class="close" (click)="closeFeedbackModal()">&times;</span>
    </div>
    <div class="modal-body" style="overflow: auto;">
      <div class="row gx-4 gy-2">
        <div class="col-6" *ngFor="let ftags of L1FeedbackTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0  model-btn"
            (click)="feedbackTags('feedback',ftags.tagname)">{{ftags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Site Info Modal popup -->
<div [id]="'myModalSiteInfo'+panelName" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none"
  [style.left]="panelPopup?.left+(80)+'px'" [style.top]="panelPopup?.top+(20)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">Site Information</h6>
      <span class="close" (click)="closeSiteinfoModal(false)">&times;</span>
    </div>
    <div class="modal-body">
      <div class="row gx-4 gy-2">
        <div class="row">
          <span>Site Name: {{wallsData?.siteName}}</span>
          <span>Camera Name: {{wallsData?.cameraName}}</span>
          <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
          <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Fullscreen Modal popup -->

<div [id]="'fullScreen'+panelName" data-bs-backdrop="false" class="modal modal-lg fade" aria-hidden="true"
  aria-labelledby="exampleModalToggleLabel" tabindex="-1" style="z-index: 9999999;">
  <div class="modal-dialog full-screen-width">
    <div class="modal-content content-width">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalToggleLabel">Full view of - {{wallsData?.cameraName}} {{panelName}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="closeFullScreen(true)"
          style="background: none;border: none;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="fBody" style="overflow: hidden;padding-right:0px" draggable="false">
        <video controls disablepictureinpicture controlslist="nodownload noplaybackrate nofullscreen" [muted]="'true'"
          class="ratio ratio-16x9" [ngClass]="{'disabled-timeline': isReplayEnabled,'enabled-timeline':!isReplayEnabled}"
          [id]="'f_video_'+panelName" appNoRightClick autoplay>
          <source type="video/mp4" [src]="wallsData?.eventLink">
        </video>

        <p [left]="panelPopup?.left" class="event-info" *ngIf="isDevFlag">{{wallsData.potentialId}} </p>

        <canvas [id]="'f_canvas_'+panelName" class="canvas" [width]="this.panelPopup.width"
          [height]="this.panelPopup.height" style="display:none;top: 10px; left: 15px;">
        </canvas>
        <div [id]="'f_cameraInfo_'+panelName" class="cameraInfo" style="display:none;" [style.left]="20+'px'"
          [style.bottom]="(70)+'px'" [style.width]="(panelPopup?.width-7)+'px'">
          <div><span class="close" style="color:black" (click)="showSiteInfoModal($event,true)">&times;</span></div>

          <div class="row" style="background-color: #ffffff;margin:-5px">
            <span>
              <span class="site-left">Camera Name:</span>
              <span class="site-right">{{wallsData?.cameraName}}</span>
            </span>
            <span>
              <span class="site-left">Site Name: </span>
              <span class="site-right">{{wallsData?.siteName}}</span>
            </span>

            <span>
              <span class="site-left">Event Time:</span>
              <span class="site-right"> {{wallsData?.eventTimeStampStr}}</span>
            </span>
            <span>
              <span class="site-left">Event Id:</span>
              <span class="site-right"> {{wallsData?.eventid}}, {{wallsData?.potentialId}} </span>
              <span class="site-right" (click)="copyInfoDetails(wallsData?.eventLink)">
                <i class="fa fa-solid fa-copy" style="cursor: pointer;"></i>
              </span>
            </span>
          </div>
        </div>
        <div [id]="'f_daynightImg_'+panelName" class="daynightImg day-images" style="display:none;"
          [style.left]="20+'px'" [style.top]="30+'px'" [style.width]="panelPopup?.width-(20)+'px'">
          <div (click)="showDaynightModal($event,true)"> <span class="close"
              style="position: relative;top: -10px;left: 1px;">&times;</span></div>
          <div class="row">
            <div class="col-6 h-100">
              <span class="text-bold">Day Image</span>
              <img [src]="wallsData?.dayimage" style="width:100%" />
            </div>
            <div class="col-6 h-100">
              <span class="text-bold">Night Image</span>
              <img [src]="wallsData?.nightimage" style="width:100%" />
            </div>
          </div>
        </div>
        <div style="width:100px;">
          <div class="p_lr_10" style="align-content: space-between;">
            <div class="btn_background" (click)="openEscalationModal($event,true)">
              <a class="d-block btn  btn-outline-secondary w-100 p-0">
                <img src="assets/images/icons/escalate1.svg" class="svgImg" style="padding: 3px;color:white" />

              </a>
            </div>

            <div class="btn_background" *ngIf="!showPanelOption">
              <a class="d-block btn  btn-outline-success w-100  p-0 disabled">
                <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Close" />

              </a>
            </div>
            <div class="btn_background" *ngIf="showPanelOption">
              <a class="d-block btn  btn-outline-success w-100  p-0" (click)="openCloseModal($event, true)">
                <img src="assets/images/icons/closeX_new.svg" class="svgImg_close" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Close" />

              </a>
            </div>
            <div class="btn_background">
              <a class="d-block btn btn-outline-warning text-center" (click)="openFeedbackModal($event,true)">
                <img src="assets/images/icons/icon-feedback.svg" class="msg" />
              </a>
            </div>

            <div class="btn_background">
              <a class="btn btn-outline-secondary w-100 p-0" (click)="enableMask(true)">
                <img src="assets/images/icons/mask7.svg" class="repl_svg" style="padding: 2px;top:-1px" />
              </a>

            </div>


            <div class="btn_background">
              <a class="btn btn-outline-daynight w-100 icon_svg p-0" (click)="showDaynightModal($event,true)">
                <img src="assets/images/icons/dn4.svg" class="" style="padding: 2px;width:26px" />
              </a>
            </div>

            <div class="btn_background">
              <a class="d-block btn btn-outline-primary w-100 svg" (click)="showSiteInfoModal($event, true)">
                <img src="assets/images/icons/info-circle.svg" class="icon_svg" />
              </a>
            </div>
            <div class="col_button" *ngIf="isReplayEnabled && showPanelOption">
              <a class="d-block btn btn-outline-primary w-100 svg p-0" (click)="replayClicked('f_video_')">
                <img src="assets/images/icons/replay.png" class="repl_svg" />
              </a>
            </div>
            <div class="col_button" *ngIf="!isReplayEnabled || !showPanelOption">
              <a class="d-block btn btn-outline-primary w-100 svg p-0 disabled" (click)="replayClicked('f_video_')">
                <img src="assets/images/icons/replay.png" class="repl_svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button class="btn btn-primary" [data-bs-target]="'#fullScreen'+panelName" data-bs-toggle="modal"
          data-bs-dismiss="modal">Cancel</button>
      </div> -->
    </div>
  </div>
</div>
<!-- Escalation Modal popup -->
<div [id]="'f_myModalEscalation'+panelName"
  class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay escTags"
  [style.left]="panelPopup?.left+(150)+'px'" [style.top]="panelPopup?.top+(10)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">ESCALATION TAGS</h6>
      <span class="close" (click)="closeEscalationModal(true)">&times;</span>
    </div>
    <div class="modal-body">

      <div class="row gx-4 gy-2">
        <!-- <input type="text" style="width:100px;" placeholder="Type escalation"
         [(ngModel)]="escalationMessage" (change)="inputEscalationChange()" /> -->
        <div class="col-6" *ngFor="let etags of L1EscalatingTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0 model-btn"
            (click)="escalationTags('escalations',etags.tagname)">{{etags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Close Modal popup -->
<div [id]="'f_myModalClose'+panelName"
  class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay escTags"
  [style.left]="panelPopup?.left+(150)+'px'" [style.top]="panelPopup?.top+(10)+'px'">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">CLOSE TAGS</h6>
      <span class="close" (click)="closeTagsModal(true)">&times;</span>
    </div>
    <div class="modal-body" style="overflow: auto;">
      <div class="row gx-4 gy-2">
        <div class="col-6" *ngFor="let ctags of L1ClosingTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0 model-btn"
            (click)="closingTags('close',ctags.tagname)">{{ctags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Feed Back Modal popup -->
<div [id]="'f_myModalfeedback'+panelName"
  class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay escTags"
  [style.left]="panelPopup?.left+(150)+'px'" [style.top]="panelPopup?.top+(10)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">FEEDBACK</h6>
      <span class="close" (click)="closeFeedbackModal(true)">&times;</span>
    </div>
    <div class="modal-body" style="overflow: auto;">
      <div class="row gx-4 gy-2">
        <div class="col-6" *ngFor="let ftags of L1FeedbackTags;let i=index;">
          <button class="btn btn-outline-primary w-100 pd-0  model-btn"
            (click)="feedbackTags('feedback',ftags.tagname,true)">{{ftags.tagname}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Site Info Modal popup -->
<div [id]="'f_myModalSiteInfo'+panelName"
  class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup popup-d-none fullscreen_overlay"
  [style.left]="panelPopup?.left+(150)+'px'" [style.top]="panelPopup?.top+(20)+'px'" draggable="false"
  ondragstart="return false;">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="text">Site Information</h6>
      <span class="close" (click)="closeSiteinfoModal(true)">&times;</span>
    </div>
    <div class="modal-body">
      <div class="row gx-4 gy-2">
        <div class="row">
          <span>Site Name: {{wallsData?.siteName}}</span>
          <span>Camera Name: {{wallsData?.cameraName}}</span>
          <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
          <span>Event Start time: {{wallsData?.eventData?.eventTimeStampStr}}</span>
        </div>
      </div>
    </div>
  </div>
</div>