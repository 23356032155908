<div class="logoutText">
  <div class="container">
    <div class="textLogout bg-warning text-dark">
      {{ content}}
      <img
        src="assets/images/Prosafe_login.png"
        class="img_psafe"
      />
    </div>
  </div>
</div>