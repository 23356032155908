import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { WallsService } from 'src/app/services/walls/walls.service';

@Injectable({
  providedIn: 'root'
})
export class TagsResolverService   {
  constructor(private wallservice: WallsService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.wallservice.getTagsList(this.wallservice.getTagsAccessKey()).pipe(
      catchError(error => {
        return of('No data');
      })
    );
  }
}