export const TagsList = {
    L1_EscalationTagsList: [],
    L1_CloseTagsList: [],
    L1_FeedbackTagsList: [],
    L2_EscalationTagsList: [],
    L2_CloseTagsList: [],
    L2_FeedbackTagsList: [],
    L3_ActionTagsList: [],
    L3_CloseTagsList: [],
    L3_FeedbackTagsList: []
};