import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WallsService } from 'src/app/services/walls/walls.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/common/services/toaster/toaster.service';
import { liveCamData } from 'src/app/common/models/apidata';
import { SharedsrvService } from 'src/app/common/services/sharedsrv/sharedsrv.service';
import { permissions } from 'src/app/common/models/globalpermissions';

@Component({
  selector: 'app-live-monitoring-list',
  templateUrl: './live-monitoring-list.component.html',
  styleUrls: ['./live-monitoring-list.component.scss']
})
export class LiveMonitoringListComponent {
  liveViewList: any=[];
  @Input() isDevEnable: any;
  isMaskingView: any;
  @Input() wallsData: any;
  @Input() triggerAlarmNeeded: any;
  @Input() panelName: any;
  @Output() closeAllViewModal = new EventEmitter();
  defaultAllCamLiveLength: number = 6;
  allArchiveCamList: any = [];
  currentTimestamp: any;
  eventIntialLoad: boolean = true;
  eventTimeZone: string = "";
  eventTimeAtLoad: any;
  currentDate: any;
  zoomBar: any;
  newLine: any;
  svgns: any = 'http://www.w3.org/2000/svg';
  zoomBarWidth: any = 20;
  zoomBarMinutes: any = 30;
  pixelsInMinute: any = 47;
  noOfMinutesInHour: any = 60;
  parentPosition: any;
  playing: any = false;
  playbarWidth: any = 1141;

  selectedFilter: any = '';
  startTime: any = 1;
  endTime: any = 3;
  times: any = [];
  timeCounter: any;
  staticArchiveUrlList: any = liveCamData;
  userSelectedTime: any;
  ///pagination parameters
  page_totalRecords: number = 0;
  page_numberOfPages: number = 0;
  page_previousPage: number = 0;
  page_nextPage: number = 1;
  page_recordsPerpage: number = 20;
  page_pageIndexs: any = [];
  ismessagePanel: boolean = false;

  //SiteMap
  showLoading: boolean = true;
  noImg: string = "";
  //showSiteMapPanel: boolean = true;
  siteMapImgSrc: string = "";
  windowHeight: number = window.innerHeight;
  windowWidth: number = window.innerWidth;
  constructor(
    private wallsService: WallsService,
    public sanitizer: DomSanitizer,
    private toasterService: ToasterService,
    private activateRoute: ActivatedRoute,
    private sharedService: SharedsrvService,
    private router: Router
  ) {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isDevEnable = !!params['dev'] || environment.devFlag;
    });
    console.log(this.windowHeight, this.windowWidth)


  }

  ngOnInit(): void {

    let eventTimestamp = "2024-05-03-05:49:25";
    if (eventTimestamp) {
      let eventtimestampStr = eventTimestamp.split(" ").join("-");
      ////this.initalizeAllLiveView(eventtimestampStr);
    } else {
      // this.initalizeAllLiveView();
    }
    this.getAllLiveviews();
   // this.initalizeAllLiveView();
  }
  initalizeAllLiveView = (selectedTimestamp?: string) => {
    this.defaultAllCamLiveLength = this.defaultAllCamLiveLength;
    this.allArchiveCamList = [];
    let success = {
      "success": true, "messages": null,
      "data": {
        "currentTimestamp": "2024-05-03-05:49:25", "archiveUrlList": [
          {
            "cameraName": "F3598 Cam27",
            "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=773c032b5aa44db8a99e25529368fbe2&minutes=",
            "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":0,\"y\":20},{\"x\":309,\"y\":19},{\"x\":309,\"y\":0},{\"x\":0,\"y\":0}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
            "cameraUniqueId": "754472F3598CAM44780",
            "connected": "true",
            "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera345&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          },
          {
            "cameraName": "F3598 Cam55",
            "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=8f78532757ce49cfa224fc1faed9acf3&minutes=",
            "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":312,\"y\":24},{\"x\":311,\"y\":0},{\"x\":0,\"y\":0},{\"x\":0,\"y\":25}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
            "cameraUniqueId": "754472F3598CAM29607",
            "connected": "true",
            "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera389&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          },
          {
            "cameraName": "F3598 Cam26",
            "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=82b8b62a89cd44719cc8083b8d22b592&minutes=",
            "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":312,\"y\":22},{\"x\":310,\"y\":0},{\"x\":0,\"y\":0},{\"x\":0,\"y\":26}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
            "cameraUniqueId": "754472F3598CAM69284",
            "connected": "true",
            "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera344&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          },
          {
            "cameraName": "F3598 Cam54",
            "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=9d1f7b4b33b14df881eb16ccdaa3c24b&minutes=",
            "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":0,\"y\":20},{\"x\":309,\"y\":24},{\"x\":310,\"y\":0},{\"x\":0,\"y\":0}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
            "cameraUniqueId": "754472F3598CAM10527",
            "connected": "true",
            "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera388&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          },
          // {
          //   "cameraName": "F3598 Cam25",
          //   "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=0b92953dbe01435ba3fe6f8298f7195d&minutes=",
          //   "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":310,\"y\":23},{\"x\":311,\"y\":0},{\"x\":0,\"y\":0},{\"x\":0,\"y\":25}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
          //   "cameraUniqueId": "754472F3598CAM64661",
          //   "connected": "true",
          //   "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera343&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          // },
          // {
          //   "cameraName": "F3598 Cam53",
          //   "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=3cd0841f8f6744339b957ddb805be0da&minutes=",
          //   "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":0,\"y\":25},{\"x\":310,\"y\":24},{\"x\":314,\"y\":0},{\"x\":0,\"y\":0}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
          //   "cameraUniqueId": "754472F3598CAM24011",
          //   "connected": "true",
          //   "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera387&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          // },
          // {
          //   "cameraName": "F3598 Cam24",
          //   "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=fbbfc62aaa8c4b89ade64f1a61c93f7a&minutes=",
          //   "maskingData": "{\"eventDetection\":{\"motionDetection\":true,\"tripWire\":false,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":0,\"y\":22},{\"x\":309,\"y\":20},{\"x\":310,\"y\":0},{\"x\":0,\"y\":0}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
          //   "cameraUniqueId": "754472F3598CAM88594",
          //   "connected": "true",
          //   "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera342&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          // },
          // {
          //   "cameraName": "F3598 Cam52",
          //   "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=0adfbca83b8d45689f8c6758f64a356c&minutes=",
          //   "maskingData": "{\"eventDetection\":{\"motionDetection\":false,\"tripWire\":true,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":0,\"y\":25},{\"x\":308,\"y\":25},{\"x\":312,\"y\":0},{\"x\":0,\"y\":0}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
          //   "cameraUniqueId": "754472F3598CAM1685",
          //   "connected": "true",
          //   "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera386&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          // },
          // {
          //   "cameraName": "F3598 Cam23",
          //   "archiveUrl": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/MobileArchivePlayer?event=play&sessionId=46dd9ce0a4484ee19a6f081348a90931&minutes=",
          //   "maskingData": "{\"eventDetection\":{\"motionDetection\":true,\"tripWire\":false,\"maskIn\":false},\"sensitivity\":14,\"bgs\":2,\"maskInList\":[],\"maskOutList\":[{\"polygonPoints\":[{\"x\":308,\"y\":20},{\"x\":309,\"y\":0},{\"x\":0,\"y\":0},{\"x\":0,\"y\":21}]}],\"tripWireList\":[],\"sensitivityList\":[]}",
          //   "cameraUniqueId": "754472F3598CAM63819",
          //   "connected": "true",
          //   "archivePlayerLink": "https://aws0086ov2.pro-vigil.info:8443/pro-vigil/GenerateArchiveLink?camraId=Camera341&timeStamp=2024-05-09-04-33-58&imageWidth=320&imageHeight=240"
          // }
        ]
      }
    };
    const data = success;
    this.currentTimestamp = success?.data?.currentTimestamp;
    this.currentDate = new Date(this.currentTimestamp?.split("-").flat(1).slice(0, 3).join("-") + " " + this.currentTimestamp?.split("-").flat(1).slice(3, 4)[0]);
    let archiveUrlList: any = [];
    archiveUrlList = success?.data?.archiveUrlList?.sort(this.dynamicSort("cameraName"));
    this.allArchiveCamList = archiveUrlList;
    this.liveViewList = this.allArchiveCamList;
    // for (let index = 0; index < this.liveViewList?.length; index++) {
    //   this.liveViewList[index].timeCount = 0;
    // }

    // this.timeCounter = setInterval(() => {
    //   this.updateTimeCount();
    // }, 1000);


  }
  getAllLiveviews = () => {
    this.wallsService.getCLMliveview().subscribe((success: any) => {
      // debugger;
      // success = {
      //   "data":[{
      //     "cameraName": "MDC318 Cam03",
      //     "archiveUrl": "https://pro-fit-farms.pro-vigil.info:441/api/pro-vigil/ArchivePlayer?event=next&sessionId=5bb53e54430b463c99197eb706e7a482",
      //     "maskingData": "{\"eventDetection\": {\"motionDetection\": false, \"tripWire\": false, \"maskIn\": false}, \"sensitivity\": 6, \"bgs\": 3, \"maskInList\": [], \"maskOutList\": [{\"polygonPoints\": [{\"x\": 1, \"y\": 16}, {\"x\": 114, \"y\": 16}, {\"x\": 114, \"y\": 0}, {\"x\": 0, \"y\": 0}]}], \"tripWireList\": [], \"sensitivityList\": []}",
      //     "cameraUniqueId": "759643CAM1718043309",
      //     "connected": null,
      //     "archivePlayerLink": "https://pro-fit-farms.pro-vigil.info:441/api/pro-vigil/GenerateArchiveLink?camraId=759643_759643CAM1718043309&timeStamp=2024-11-04-05:22:43&imageWidth=320&imageHeight=240",
      //     "potentialId": "759643",
      //     "siteAccessUrl": "https://pro-fit-camera-server.pro-vigil.info:9006/api",
      //     "snapshotUrl": "/SnapShot?channel=759643CAM1718043309&station=recordingStation&withmd=true"
      //   }]
      // }
      this.allArchiveCamList = success?.data?.sort(this.dynamicSort("cameraName")) ||[];
      this.liveViewList = this.allArchiveCamList;
      //console.log(this.liveViewList);
      for (let index = 0; index < this.liveViewList?.length; index++) {
        this.liveViewList[index].timeCount = 0;
      }
      this.timeCounter = setInterval(() => {
        this.updateTimeCount();
      }, 1000);

    }, error => {
      console.log(error)
    })
  }
  //sorting by cameraname from initalizeAllLiveView api response
  dynamicSort = (property: string) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }
  pageClick = (index: number) => {
    this.liveViewList = [];
    clearTimeout(this.timeCounter);
    this.liveViewList = this.allArchiveCamList?.slice((this.page_recordsPerpage * index - this.page_recordsPerpage), this.page_recordsPerpage * index);
    for (let index = 0; index < this.liveViewList?.length; index++) {
      this.liveViewList[index].timeCount = 0;
    }
    setTimeout(() => {
      this.timeCounter = setInterval(() => {
        this.updateTimeCount();
      }, 1000);
    }, 100);
    this.applyColorsonSelectedPage(index);
  }
  applyColorsonSelectedPage = (index: number) => {
    let pageEle = document.querySelectorAll('.pagePerItem');
    pageEle.forEach((ele: any) => {
      ele.classList.remove('pagePerItem_active');
    })
    let highEle = document.getElementById('pagination_' + index) as HTMLElement;
    if (highEle) {
      highEle.classList.add('pagePerItem_active')
    }

  }
  getTimes() {
    // Convert to number of half-hours
    var x = 5; //minutes interval
    var tt = 3 * 60 + 45; // start time
    var startTime = 2; // start time
    var endTime = 5 * 60 + 45; // start time
    var ap = ['AM', 'PM']; // AM-PM
    //loop to increment the time and push results in array
    /* for (var i = 0; tt <= endTime; i++) {
      var hh = Math.floor(tt / 60);
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      this.times[i] = ('' + hh).slice(-2) + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    } */
    for (var i = 0; i <= 24; i++) {
      var mm = Math.floor(i / 60);
      var hh = i % 60; // getting minutes of the hour in 0-55 format
      this.times[i] = ('' + hh).slice(-2) + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    return this.times;
  };
  oldArchiveDownload(item: any) {
    const windowName = this.sharedService.prepareWindowName('archiveOld_');
    const evtWindow = window.open(item?.archivePlayerLink);
    permissions.allWindows[windowName] = evtWindow;
  }
  openWinPage(item: any) {
    localStorage.setItem("cameraItem", JSON.stringify(item));
    let url = `${window.location.protocol}//${window.location.host}/live-view`;
    const windowName = this.sharedService.prepareWindowName('archive_');
    const evtWindow = window.open(url, "_blank", "width=656,height=422");
    permissions.allWindows[windowName] = evtWindow;
  }
  openCamView(url: any) {
    localStorage.setItem('EVENT', JSON.stringify(url));
    const windowName = this.sharedService.prepareWindowName('cameraview_');
    const evtWindow = window.open('/cameraview?unique_id=' + url.hlsUrl, "_blank", "width=700,height=500");
    permissions.allWindows[windowName] = evtWindow;
  }
  maskingCordinate(maskingObject: any) {
    if (maskingObject) {
      const polygonPointsArr = JSON.parse(maskingObject)
        .maskOutList[0].polygonPoints;
      const polygonPoints = polygonPointsArr.map(function (e: any) {
        //return e.x / 1.485 + ' ' + e.y / 1.485;
        return e.x / 1.220 + ' ' + e.y / 1.485;
      });
      return polygonPoints;
    }
    else {
      return null
    }
  }

  toggleMaskingView(): void {
    this.isMaskingView = !this.isMaskingView;
  }
  alarmTrigger() {
    const payload = {
      'eventId': this.wallsData.eventid,
      'levelId': this.wallsData.currentLevelId,
      'potentialId': this.wallsData.potentialId
    }
    this.wallsService.alarmManualTrigger(payload).subscribe((res: any) => {
      if (res.success === true) {
        if (this.ismessagePanel) {
          alert("Alarm triggered!");
        } else {
          this.toasterService.showSuccess("Alarm triggered!");
        }
      }
    });
  }
  closePopup() {
    //  // this.currentDate = new Date();
    //   let modal1 = document.getElementById("allcammaskingview1" + this.panelName) as HTMLElement;
    //   modal1.style.display = "none";
    //   //this.allViewsModal = !this.allViewsModal;
    this.liveViewList = [];
    this.isMaskingView = false;
    clearInterval(this.timeCounter);
    this.closeAllViewModal.emit();
  }
  onAllCamScrollEnd = (event: any) => {
    // console.log(Math.round(event.target.offsetHeight + event.target.scrollTop))
    // console.log("event.target.scrollHeight",event.target.scrollHeight)
    let roundEleheight = Math.round(event.target.offsetHeight + event.target.scrollTop);
    let scrollHeight = event.target.scrollHeight;
    if (
      ((roundEleheight) == (scrollHeight)) ||
      ((roundEleheight + 1) == (scrollHeight)) ||
      ((roundEleheight - 1) == (scrollHeight))
    ) {
      this.defaultAllCamLiveLength = this.defaultAllCamLiveLength + 3;
      let tempArchiveList: any = this.allArchiveCamList;
      this.liveViewList = tempArchiveList.slice(0, this.defaultAllCamLiveLength);
      for (let index = 0; index < this.liveViewList.length; index++) {
        this.liveViewList[index].timeCount = 0;
      }
      this.timeCounter = setInterval(() => {
        this.updateTimeCount();
      }, 1000);
    }
  }
  updateTimeCount() {
    if (this.liveViewList?.length > 0) {
      for (let index = 0; index < this.liveViewList?.length; index++) {
        const element = this.liveViewList[index];
        this.liveViewList[index].timeCount = this.liveViewList[index].timeCount + 1;
      }
    }
  }
  drawActivityBar() {
    // var svg = document.getElementById('svg') as SVGSVGElement;
    var svg = document.querySelector('#svg' + this.panelName) as SVGSVGElement;

    var activityBar = document.getElementById('activityBarView' + this.panelName);
    this.zoomBar = document.getElementById('zoomBarView');
    this.newLine = document.getElementById('selector');
    // Create an SVGPoint for future math

    var pt = svg.createSVGPoint();
    // Get point in global SVG space
    function cursorPoint(evt: any) {
      pt.x = evt.clientX;
      pt.y = evt.clientY;
      return pt.matrixTransform(svg?.getScreenCTM()?.inverse());
    }
    activityBar?.addEventListener(
      'mousemove',
      function (evt: any) {
        var loc = cursorPoint(evt);
        // Use loc.x and loc.y here
        /*if(mousedown)
      {
        if(loc.x>1080)
            {
             evt.preventDefault();
            }
          drawZoomBar(loc.x,30);
      }*/
        //  console.log(loc);
      },
      false
    );
    activityBar?.addEventListener(
      'mouseout',
      function () {
        // mousedown=false;
        //	  console.log("blur");
      },
      false
    );
    var mousedown = false;
    activityBar?.addEventListener(
      'mousedown',
      function (evt: any) {
        var loc = cursorPoint(evt);
        //		  console.log("Mouse down");
        mousedown = true;
      },
      false
    );
    activityBar?.addEventListener(
      'mouseup',
      function (evt: any) {
        var loc = cursorPoint(evt);
        mousedown = false;
      },
      false
    );
    activityBar?.addEventListener(
      'click',
      (evt: any) => {
        var loc = cursorPoint(evt);
        // this.drawZoomBar(loc.x, 1);
        //// checks selected line should be less than current time from timebar
        // var cDateminutes = this.currentDate.getMinutes();
        // const minutes =
        //   (this.pixelsInMinute * cDateminutes) /
        //   this.noOfMinutesInHour;
        // var zmbar = document.getElementById(
        //   'parent' + this.currentDate.getHours() + 'Line'
        // );
        // var xval = zmbar?.attributes.getNamedItem('x1');
        // const cmpX =
        //   xval && xval?.textContent ? +xval?.textContent + minutes : 0;
        // if (+loc.x >= cmpX) {
        //   this.drawZoomBar(
        //     xval && xval?.textContent ? +xval?.textContent + minutes : 0,
        //     1
        //   );
        // }
        //// checks selected line should be less than current event time from object
        if (!this.eventIntialLoad) {
          this.currentDate = new Date(this.eventTimeAtLoad.split("-").flat(1).slice(0, 3).join("-") + " " + this.eventTimeAtLoad.split("-").flat(1).slice(3, 4)[0]);
          var cDateminutes = this.currentDate.getMinutes();
          const minutes =
            (this.pixelsInMinute * cDateminutes) /
            this.noOfMinutesInHour;
          var zmbar = document.getElementById(
            'parent' + this.currentDate.getHours() + 'Line'
          );
          var xval = zmbar?.attributes.getNamedItem('x1');
          const cmpX =
            xval && xval?.textContent ? +xval?.textContent + minutes : 0;
          if (+loc.x >= cmpX) {
            this.drawZoomBar(
              xval && xval?.textContent ? +xval?.textContent + minutes : 0,
              1
            );
          }
        }
        zoomBarMouseDown = false;
        loc.x = 10;
        this.drawLine(loc.x);
      },
      false
    );
    var zoomBarMouseDown = false;

    for (var i = 0; i < this.times.length; i++) {
      this.addLine(
        svg,
        i * this.pixelsInMinute + 10,
        17,
        i * this.pixelsInMinute + 10,
        44,
        'visible',
        'parent' + i + 'Line'
      );
      var text = i;
      var txt = text.toString();
      if (i <= 9) txt = '0' + i;

      this.addLabel(
        this.times[i],
        svg,
        i * this.pixelsInMinute + 0,
        10,
        12,
        'parent' + i + 'Label',
        'visible'
      );
    }
  }
  drawLine(x: any) {
    let userClickOntimebar = true;
    var newLine = document.getElementById('selector');
    newLine?.setAttribute('x1', x);
    newLine?.setAttribute('y1', '100');
    newLine?.setAttribute('x2', x);
    newLine?.setAttribute('y2', '126');
    var time = this.calculateTime(x - 5);
    var timeFormat = time.split(":");
    this.userSelectedTime = "-" + parseInt(timeFormat[0]) + ":" + parseInt(timeFormat[1]) + ":" + parseInt(timeFormat[2]);
    newLine?.setAttribute('title', time);
    setTimeout(() => {
      if (this.userSelectedTime && userClickOntimebar) {
        userClickOntimebar = false;
        let userSelectedDate: any = this.currentTimestamp.split("-").flat(1).slice(0, 3).join("-") + this.userSelectedTime;
        this.liveViewList = [];
        this.initalizeAllLiveView(userSelectedDate);
      }
    }, 200);
    // timeSelected(time);
  }
  calculateTime(position: any) {
    var minute = this.getMinute(this.parentPosition);
    var hour = this.getHour(this.parentPosition);
    var childMinutes = (position * 4) / 60;
    var childSeconds = ((position * 4) % 60).toString();
    var totalMinutes = minute + +childMinutes;
    if (totalMinutes >= 60) {
      hour++;
      totalMinutes = totalMinutes - 60;
    }

    var hour1 = hour.toString();
    if (hour <= 9) hour1 = '0' + hour;

    var totalMinutes1 = totalMinutes.toString();
    if (totalMinutes <= 9) totalMinutes1 = '0' + totalMinutes;

    if (+childSeconds <= 9) childSeconds = '0' + childSeconds;

    return hour1 + ':' + totalMinutes1 + ':' + childSeconds;
  }
  drawZoomBar(x: any, width: any) {
    var hafPosition = width / 2;
    x = x - hafPosition;
    if (x < 10) x = 10;

    if (x + width >= this.playbarWidth) x = this.playbarWidth - width;

    var newLine = document.getElementById('zoomBar' + this.panelName);
    newLine?.setAttribute('x', x);
    newLine?.setAttribute('y', '18');
    newLine?.setAttribute('width', width);
    newLine?.setAttribute('height', '28');
    newLine?.setAttribute('visibility', 'visible');
    this.parentPosition = x - 10;
    this.updateZoomView(this.parentPosition);
  }
  updateZoomView(position: any) {
    for (var i = 0; i <= 10; i++) {
      // var line = document.getElementById('child' + i + 'Line');
      // line?.setAttribute('visibility', 'visible');
      // var lbl = document.getElementById('child' + i + 'Label') as HTMLElement;
      // lbl?.setAttribute('visibility', 'visible');
      var minute = this.getMinute(position);
      var hour = this.getHour(position);

      var minute1 = minute.toString();
      if (minute <= 9) minute1 = '0' + minute;

      var hour1 = parseInt(hour.toString()).toString();
      if (hour <= 9) hour1 = '0' + parseInt(hour.toString()).toString();

      position += 2;
    }
  }
  hideZoomView() {
    var newLine = document.getElementById('zoomBar');
    newLine?.setAttribute('visibility', 'visible');
    var zoomBar = document.getElementById('zoomBarView');
    this.zoomBar.style.visibility = 'visible';
    var selector = document.getElementById('selector');
    selector!.style.visibility = 'visible';

    for (var i = 0; i <= 10; i++) {
      var line = document.getElementById('child' + i + 'Line');
      line?.setAttribute('visibility', 'visible');
      var lbl = document.getElementById('child' + i + 'Label');
      lbl?.setAttribute('visibility', 'visible');
    }
  }
  getMinute(position: any) {
    position = position % this.pixelsInMinute;
    var min = parseInt(
      (
        (parseInt(this.noOfMinutesInHour) / parseInt(this.pixelsInMinute)) *
        parseInt(position)
      ).toFixed(0)
    );
    return min;
  }
  getHour(position: any) {
    // var px = parseInt(((parseInt(position) / parseInt(this.pixelsInMinute))).toFixed(0));
    var px: number = position / this.pixelsInMinute;
    return px;
  }
  addLabel(
    text: any,
    parent: any,
    x: any,
    y: any,
    fontSize: any,
    id: any,
    visibility: any
  ) {
    var rect = document.createElementNS(this.svgns, 'text');
    rect.setAttribute('x', x);
    rect.setAttribute('y', y);
    rect.setAttribute('id', id);
    rect.setAttribute('fill', 'Black');
    rect.setAttribute('font-size', fontSize);
    rect.setAttribute('visibility', visibility);
    var textNode = document.createTextNode(text);
    rect.appendChild(textNode);
    parent.appendChild(rect);
  }
  addLine(
    parent: any,
    x1: any,
    y1: any,
    x2: any,
    y2: any,
    visibility: any,
    id: any
  ) {
    var rect = document.createElementNS(this.svgns, 'line');
    rect.setAttribute('x1', x1);
    rect.setAttribute('id', id);
    rect.setAttribute('y1', y1);
    rect.setAttribute('x2', x2);
    rect.setAttribute('y2', y2);
    rect.setAttribute('fill', 'Black');
    rect.setAttribute('stroke', '#0C5B82');
    rect.setAttribute('visibility', visibility);
    parent.appendChild(rect);
  }
  addElement(text: any, parent: any, x: any, y: any) {
    var rect = document.createElementNS(this.svgns, 'rect');
    rect.setAttributeNS(null, 'x', x);
    rect.setAttributeNS(null, 'y', y);
    rect.setAttributeNS(null, 'height', '50');
    rect.setAttributeNS(null, 'width', '50');
    rect.setAttributeNS(
      null,
      'fill',
      '#' + Math.round(0xffffff * Math.random()).toString(16)
    );
    document.getElementById('svgOne')?.appendChild(rect);
  }
  loadInitialSelection() {
    this.zoomBar.style.visibility = 'visible';
    this.newLine.style.visibility = 'visible';
    this.drawZoomBar(10, 20);
    this.newLine.style.visibility = 'visible';
    // this.drawLine(11);
  }
  on_mouse_move(e: any) {
    var event = e;
    var x = event.clientX,
      y = event.clientY;

    document.getElementById('XLOc')!.innerHTML = x;
    document.getElementById('YLOc')!.innerHTML = y;
  }

  updateSessionId() {
    /*const currentTime = momentTZ.tz(new Date(), "Canada/Yukon").subtract(1, "minutes").format("YYYY-MM-DD-hh:mm:ss");
    for (let index = 0; index < this.liveViewList.length; index++) {
      const element = this.liveViewList[index];
      const params = '?event=sessionid&cameraid=' + this.liveViewList[index].cameraid + '&timestamp=' + currentTime;

      this.wallsService.getData("CameraService", params).subscribe((success: any) => {
        const data = success;
        this.liveViewList[index].sessionId = data.sessionId;
      }, (err: any) => {
      });
    } */
    const currentTime = moment
      .tz(new Date(), 'Canada/Yukon')
      .subtract(1, 'minutes')
      .format('YYYY-MM-DD-hh:mm:ss');

    // 747652 -> working data for this potential ID
    const params =
      '?potentialId=' + this.wallsData.potentialId + '&timestamp=' + currentTime;

    // this.wallsService.postAPIData('https://dev-psafe.pro-vigil.us:8007/api/mp/psafe/allarchive'+params, {}).subscribe(
    //   (success: any) => {
    //     const data = success;
    //     const currentTimestamp = success?.data?.currentTimestamp;
    //     this.liveViewList = success?.data?.archiveUrlList;
    //     // for (let index = 0; index < this.liveViewList.length; index++) {
    //     //   this.liveViewList[index].sessionId = data.sessionId;
    //     // }
    //   },
    //   (err: any) => {}
    // );

    // this.wallsService.getData('CameraService', params).subscribe(
    //   (success: any) => {
    //     const data = success;
    //     for (let index = 0; index < this.liveViewList.length; index++) {
    //       this.liveViewList[index].sessionId = data.sessionId;
    //     }
    //   },
    //   (err: any) => {}
    // );
  }



  showSitemapPopup() {
    this.getSiteMap();
    let siteMap = document.getElementById("siteMapModal" + this.panelName) as HTMLElement;
    if (siteMap.style.display == "none") {
      siteMap.style.display = "block";
    } else {
      siteMap.style.display = "none";
    }
  }

  //getting siteMap data by potentialId
  getSiteMap() {
    //make service call to fetch sitemap data by potentialId
    this.wallsService?.getSiteMapDataByPotentialId(this.wallsData.potentialId).subscribe((resp: any) => {
      if (resp?.data) {
        this.siteMapImgSrc = resp.data;
      }
    })
  }

  closeSiteMapModal() {
    //this.showSiteMapPanel = false;
    let modal1 = document.getElementById("siteMapModal" + this.panelName) as HTMLElement;
    if (modal1) {
      modal1.style.display = "none";
    }
  }
  openWinPage1(item: any) {
    localStorage.setItem('cameraItem', JSON.stringify(item));
    let url = `${window.location.protocol}//${window.location.host}/live-view`;
    window.open(url, '_blank', 'width=656,height=369');
  }


  ngOnDestroy(): void {
  }
}

