<div class="container app-foooter" style="width:100vw">
    <div class="row">
            <div class="col-md-3 text-center">
            </div>
            <div class="col-md-5 text-center">
                    <p>Copyright © 2023, Pro-vigil, All rights reserved.</p>
            </div>
            <div class="col-md-4 text-center">
                    <p>Version 1.0.0</p>
            </div>
    </div>
</div>

