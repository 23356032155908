<!-- <div class="row g-4" *ngIf="liveViewList.length > 0">
    <div class="col-xl-4 col-lg-6" *ngFor="let item of liveViewList; let i = index;">
        <div class="position-relative overflow-hidden" (dblclick)="openWinPage(item)">
            <div class="position-absolute top-0 start-0 bg-light bg-opacity-90 rounded-top gap-3 z-index-1 w-100">
                <div class="d-flex align-items-center justify-content-between py-2 px-3" style="background: #0c0404d4;">
                    <h6 class="text-white">
                        <span class="label-online-sm"></span>
                        {{item.cameraName}}
                    </h6>
                </div>
            </div>
            <div class="ratio ratio-16x9 rounded-2 overflow-hidden">
                <img [src]="item.archiveUrl+'&test='+item.timeCount" style="top: 35px;" allowfullscreen />
            </div>
        </div>
    </div>
</div> -->
<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-masking-view">
    <div class="modal-content" style="height:inherit">
      <div class="modal-header bg-dark flex-wrap gap-3 p-3">
        <h6 class="modal-title text-white" style="max-width: 42%;">
          <!-- <span class="label-online"></span> -->
           {{wallsData?.siteName}}
        </h6>
        
        <!-- <div class="popup-masking-toggle">
          <label>Masking View</label>
          <span>
            <img src="assets/images/icons/icon-armed.svg" />
          </span>
        </div> -->
        <div [class]="isMaskingView ? 'popup-masking-toggle' : 'popup-masking-toggle active'"
        (click)="toggleMaskingView()">
        <label>{{ isMaskingView ? 'Masking View' : 'Camera View' }}</label>
        <span>
          <img src="../../../assets/images/icons/icon-armed.svg" />
        </span>
      </div>
      <div class="d-flex">
          <button type="button" class="btn btn-outline-white" style="margin-right: 5px;" (click)="alarmTrigger()" *ngIf="triggerAlarmNeeded">
            Trigger Alarm
          </button>
        
          <button type="button" class="btn btn-outline-white" style="margin-right: 5px;" (click)="showSitemapPopup()">
            View Site Map
          </button>
        
          <button type="button" class="btn btn-orange px-5" (click)="closePopup()">
            Close
          </button>
      </div>
    </div>


      <div [id]="'siteMapModal'+panelName" style="display: none;" class="modal" tabindex="-1"  aria-labelledby="siteMapLabel">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style="height:100%">
            <div class="modal-header" style="background-color: black;color: white;">
              <h5 class="modal-title">Site Map of - {{wallsData?.siteName}} </h5>
              <button type="button" class="btn btn-orange px-5" (click)="closeSiteMapModal()">
                Close
              </button>
            </div>
            <div class="modal-body c-lv-view">
              <div class="siteMapImage-container">
                <img [src]="siteMapImgSrc" *ngIf="siteMapImgSrc" alt="Site plan preview is not available"
                  class="siteMapImage" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="modal-body modal-scroll-body" (scroll)="onAllCamScrollEnd($event)"> -->
        <div class="modal-body modal-scroll-body">
        <div class="row g-4" *ngIf="liveViewList?.length > 0" style="min-height: 300px;">
          <div class="col-xl-4 col-lg-6" *ngFor="let item of liveViewList; let i = index;">
            <div class="position-relative overflow-hidden">
              <div style="width: 100%;">
                <div style="background-color: black;color: white;padding: 6px; display: flex; justify-content: space-between;">
                  <h6 class="text-white">
                    <span class="status-camera"
                      [ngClass]="{'online-sm': item?.connected === 'true', 'offline-sm': item?.connected == 'false'}">
                    </span>
                    {{item?.cameraName}}
                  </h6>
                
                  <!-- <button type="button" class="btn btn-primary btn btn-sm" (click)="oldArchiveDownload(item)"> -->
                    <i class="fa fa-video-camera bg-primary" style="margin-left: 10px;cursor: pointer;padding: 5px; border-radius: 5px;" (click)="oldArchiveDownload(item)"
                        aria-hidden="true"></i>
                  <!-- </button> -->
                </div>
              <!-- <div class="ratio ratio-16x9 overflow-hidden">
                <svg style="position: absolute; top: 0px">
                  <image xmlns:xlink="http://www.w3.org/1999/xlink"
                    [attr.xlink:href]="item.archiveUrl+'&test='+item.timeCount" style="width: 100%;object-fit: fill;"></image>
                  <polygon *ngIf="isMaskingView" fill="rgb(255 47 0)" fill-opacity="60%"
                    [attr.points]="maskingCordinate(item.maskingData)"></polygon>
                </svg>
              </div> -->
              <div (dblclick)="openWinPage(item)">
                <img [src]="item.archiveUrl+'&test='+item.timeCount" style="width: 100%;" />
                <svg style="position: absolute;top: 38px;width: 100%;display: block;">
                 <polygon *ngIf="isMaskingView" fill="rgb(255 47 0)" fill-opacity="60%"
                    [attr.points]="maskingCordinate(item.maskingData)"></polygon>
                </svg>
              </div>
            </div>
            </div>
          </div>
        </div>
        <!-- Archive List Pagination -->
          <div class="pageDiv" *ngIf="page_pageIndexs.length>1">
            <div class="pageItemDiv" *ngFor="let index of page_pageIndexs;">
              <a [id]="'pagination_'+(index+1)" class="pagePerItem" (click)="pageClick(index+1)"> {{index+1}}</a>
            </div>          
          </div>
      
      </div>

      <div [id]="'modal-footer'+panelName" class="modal-footer bg-dark justify-content-center py-4 px-lg-7">
        <div [id]="'activityBarDiv'" class="modal-masking-control" #time_bar style="width: 100%; padding: 16px 60px;display: none;">
          <svg height="55" [id]="'svg'+panelName" xmlns="http://www.w3.org/2000/svg" version="1.1"
            style="overflow: visible; width: 100%;">
      
            <rect [id]="'activityBarView'+panelName" height="1" x="4" y="52"
              style="fill:white;stroke-width:7px;stroke:#0C5B82;cursor:pointer; width: calc(100% - 7px);">
            </rect>
      
            <rect [id]="'zoomBar'+panelName" style="fill:none;stroke-width:2;stroke:red;cursor:pointer" x="10" y="18"
              width="1" height="30"></rect>
          </svg>
        </div>
      </div>
    </div>
  </div>