<div class="main fluid-container" class="mainBody">
  <div class="main_child" style="height:100%">
    <div class="container-fluid" style="height:100%;">
      <div class="col-container">
        <div class="col min-width-50">
          <div class="col" *ngIf="isFirstPnlEnable">
            <app-wall-slot *ngIf="(panel1Data | json) != ({} | json)" [wallsObj]="panel1Data" [panelName]="'panel1'" 
              [permissionsInputObj]="permissionObj" (submitEmit)="getPanelsEventData($event)"></app-wall-slot>
            <div *ngIf="(panel1Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
              <div class="errorMessage" [ngClass]="[panel1FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                {{panel1ErrorMessage}}
              </div>
            </div>
          </div>
        </div>
        <div class="col min-width-50">
          <div class="col" *ngIf="isSecondPnlEnable">
            <app-wall-slot *ngIf="(panel2Data | json) != ({} | json)" [wallsObj]="panel2Data" [panelName]="'panel2'" 
              [permissionsInputObj]="permissionObj" (submitEmit)="getPanelsEventData($event)"></app-wall-slot>
            <div *ngIf="(panel2Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
              <div class="errorMessage" [ngClass]="[panel2FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                {{panel2ErrorMessage}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-container">
        <div class="col min-width-50">
          <div class="col " *ngIf="isThirdPnlEnable">
            <app-wall-slot *ngIf="(panel3Data | json) != ({} | json)" [wallsObj]="panel3Data" [panelName]="'panel3'"
              [permissionsInputObj]="permissionObj" (submitEmit)="getPanelsEventData($event)"></app-wall-slot>
            <div *ngIf="(panel3Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
              <div class="errorMessage"  [ngClass]="[panel3FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                {{panel3ErrorMessage}}
              </div>
            </div>
          </div>
        </div>
        <div class="col min-width-50">
          <div class="col " *ngIf="isFourthPnlEnable">
            <app-wall-slot *ngIf="(panel4Data | json) != ({} | json)" [wallsObj]="panel4Data" [panelName]="'panel4'"
              [permissionsInputObj]="permissionObj" (submitEmit)="getPanelsEventData($event)"></app-wall-slot>
            <div *ngIf="(panel4Data | json) == ({} | json)" class="col-sm-9 col-md-9 min-height45vh">
              <div class="errorMessage" [ngClass]="[panel4FBMessageCheck ? 'errorFBMessage' : 'errorMessage']">
                {{panel4ErrorMessage}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal modal-lg fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalToggleLabel">Full view of event - 1</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="fBody">
        Show a second modal and hide this one with the button below.
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
          data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> -->