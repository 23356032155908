<div>

	<iframe [src]="socketStreamUrl" class="iframe_vide" title="Video Not Available"></iframe>
	<div class="video-popup-model" style="display: none;">
		<div class="modal modalBlock" id="hdModal" role="dialog">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-body">

					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="closePop__11" class="modal col col-sm-2 col-md-2 col-xl-2 modal-popup z-front modal-center"
		style="z-index:9999999;">
		<div class="modal-content z-front">
			<div class="modal-header h-40">
				<h5 class="text model-header-text text-center w-100">Video Resume</h5>
			</div>
			<div class="modal-body">
				<h4 style="font-size:14px">Video feed has timed out. Do you want to continue?
				</h4>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary btn_btn" data-bs-dismiss="modal" (click)="confirm($event)">
					Confirm
				</button>
				<button type="button" class="btn btn-primary btn_btn" (click)="closeConfirmPopup()">
					Close
				</button>
			</div>
		</div>
	</div>
	<!-- Continue stream pop-up starts-->
</div>
<div class="errormessage">
	<div class="error">
		{{errorMessage}}
	</div>
</div>