import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner-load',
  templateUrl: './spinner-load.component.html',
  styleUrls: ['./spinner-load.component.scss']
})
export class SpinnerLoadComponent {

}
