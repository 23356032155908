import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { WallsService } from 'src/app/services/walls/walls.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../services/toaster/toaster.service';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LiveviewListComponent } from '../liveview-list/liveview-list.component';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import { permissions } from '../../models/globalpermissions';

@Component({
  selector: 'app-messagepanel',
  templateUrl: './messagepanel.component.html',
  styleUrls: ['./messagepanel.component.scss'],
  providers: [LiveviewListComponent]
})
export class MessagepanelComponent implements OnInit {
  @ViewChild('closeFilter') closeFilter: any;
  @ViewChild('filterModal') filterModal: any;
  @ViewChild('CleaningCrewModal') CleaningCrewModal: any;
  @ViewChild('guardModal') guardModal: any;
  @ViewChild('addPSAModal') addPSAModal: any;
  panelTableDataForMsgPanel: any;
  @Output() submitEmit = new EventEmitter();
  panelName: string = "";
  panelLSdata: any = "";
  messagePanelData: any = {};
  adhocDetails: any = [];
  ifttt: any = [];
  crew: any = [];
  broadcast: any = [];
  guard: any = [];
  adhoc: any = [];
  actionNew: [] = [];
  iftttData: string[] = [];
  newObject: any;
  eventdesc: string[] = [];
  siteInfoDetails: any = {};
  recentEscalationList: any = [];
  potentialId: string = "";
  recentActivations: any = [];
  armDisarmHistory: any = [];
  PSAListData: any = [];
  armDisarmStatusData: any = "";
  closeResult: string = "";
  reverseEscForm: any;
  recentEscList: any = [];
  addSiteNotesForm: any;
  cleaningCrewForm: any;
  guardform: any;
  psaform: any;
  maxlength: number = 400;
  failedFetchAll: boolean = false;
  text: string = '';
  selectedBroadcastType: string = "";
  showLiveviewPanel: boolean = false;
  allViewsModal: boolean = false;
  wallObjData: any;
  text_Crew: string = '';
  text_guard: string = '';
  text_broad: string = '';
  armFilterForm: any;
  alarmFilterForm: any;
  isDevEnable: any = false;
  showCameraviewPanel: boolean = false;
  modifiedDate_tz: any;
  eventInfo: boolean = false;
  eventUserData: any = [];
  selectedCrewAndGuard: string = '';
  currentlyLoading: any[] = []
  endDate = new Date();
  todayDate: any;
  todayTime: any;
  // modalOptions:NgbModalOptions;
  contactType: any;
  isMaskingView = false;
  armDisarmed: boolean = false;
  armDisarmStatus: string = '';
  unitIds: any;
  showToaster: boolean = false;
  errorToaster: boolean = false;
  toasterMessage: string = '';
  reloadMobileSite: boolean = true;
  submitted = false;
  cleancrew = false;
  validatedTodDate: any;
  validatedFromdDate: any;
  selectLevel: string = "L2L3";
  escId: string = "";

  //site map
  showLoading: boolean = true;
  noImg: string = "";
  showSiteMapPanel: boolean = false;
  siteMapImgSrc: string = "";
  recentEscFetchMsg: string = "in last 90 days";
  isShowMsgPanelInfoSpinner: boolean = false;
  // modalOptions:NgbModalOptions;

  selectedTimezone: string = 'CT';
  timeZoneSelect: string = 'CT';
  extractedDate: string;
  extractedTime: string;
  dateToday: any;
  siteTimeCustomer: any;

  constructor(private wallService: WallsService,
    private toasterService: ToasterService,
    private activateRoute: ActivatedRoute,
    public fb: FormBuilder,
    private sharedService: SharedsrvService,
    private liveviewListComponent: LiveviewListComponent,
  ) {


    this.activateRoute.queryParams.subscribe((params) => {
      this.isDevEnable = !!params['dev'] || environment.devFlag;
    });
  }

  addZero(i: any) {
    if (i < 10) { i = "0" + i }
    return i;
  }

  ngOnInit(): void {
    // const dateToday = new Date();
    // const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    // this.validatedFromdDate = currentDate.split(' ')[0];
    // const dateToday = localStorage.getItem('siteTime');
    
    this.sharedService.data$.subscribe((res:any)=>{
    this.dateToday = res; 
    if(this.siteTimeCustomer){
      const dateParts = this.dateToday?.split(' ');
      this.extractedDate = dateParts[0];
      this.todayTime = dateParts[1];
      this.validatedFromdDate = this.extractedDate
      this.addSiteNotesForm = this.fb.group({
        startDate: [this.validatedFromdDate, Validators.required],
        startTime: [this.todayTime, Validators.required],
        endDate: ['', Validators.required],
        endTime: [this.todayTime, Validators.required],
        recordsmessage: ['', Validators.required],
        broadcastType: 'Broadcast'
      });
    }
    })
 
    this.panelLSdata = localStorage.getItem("panelTableData");
    this.reverseEscForm = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
      startTime: new FormControl(),
      endTime: new FormControl(),
      records: new FormControl("viewAll")
    });
    this.cleaningCrewForm = this.fb.group({
      name: ['', Validators.required],
      contact: ['', Validators.required],
      details: ['', Validators.required],
      cleaningCrewType: 'Cleaning Crew',
      udid: new FormControl(""),
    });
    this.psaform = new FormGroup({
      accountName: new FormControl(),
      source: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      startTime: new FormControl(),
      endTime: new FormControl()
    })

    //adding viewall option for arm/disarm history
    this.armFilterForm = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
      startTime: new FormControl(),
      endTime: new FormControl(),
      records: new FormControl("viewAll")
    });
    this.alarmFilterForm = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
      startTime: new FormControl(),
      endTime: new FormControl(),
      records: new FormControl("viewAll")
    });
    if (this.panelLSdata) {
      this.panelTableDataForMsgPanel = JSON.parse(this.panelLSdata);
      this.wallObjData = this.panelTableDataForMsgPanel?.['panelObj'];
      this.potentialId = this.panelTableDataForMsgPanel?.['panelObj']?.potentialId;
      localStorage.setItem("messagePanelPotentialId", this.potentialId);
      this.intiateAll_API_requiredAPICalls();
    }
    this.changeDate();
  }
  returnCalculatedDate(dateStr: string, timeStr: string, addDays: number) {
    const dateToday = timeStr ? new Date(`${dateStr} ${timeStr}`) : new Date(`${dateStr}`);
    const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    const addedDate = moment(currentDate).add(addDays, 'days').format('YYYY-MM-DD HH:mm:ss');
    return {
      currentDate: currentDate?.split(' ')[0],
      addedDate: addedDate?.split(' ')[0],
    };
  }
  changeDate() {
    if (this.addSiteNotesForm?.value?.startDate) {
      if (this.addSiteNotesForm?.value?.broadcastType == 'Broadcast') {
        const broadcastToDate = this.returnCalculatedDate(this.addSiteNotesForm?.value?.startDate, this.addSiteNotesForm?.value?.startTime, 7);
        this.validatedFromdDate = broadcastToDate?.currentDate;
        this.validatedTodDate = broadcastToDate?.addedDate;
      } else if (this.addSiteNotesForm?.value?.broadcastType == 'Adhoc') {
        const adhocToDate = this.returnCalculatedDate(this.addSiteNotesForm?.value?.startDate, '', 30);
        this.validatedFromdDate = adhocToDate?.currentDate;
        this.validatedTodDate = adhocToDate?.addedDate;
      } 
      else if (this.addSiteNotesForm?.value?.broadcastType == 'bolo') {
        const adhocToDate = this.returnCalculatedDate(this.addSiteNotesForm?.value?.startDate, '', 30);
        this.validatedFromdDate = adhocToDate?.currentDate;
        this.validatedTodDate = adhocToDate?.addedDate;
      } 
    }
  }
  getFromDateTime() {
    //  set current time and date to enddate
    this.todayDate = this.endDate.toISOString().split('T')[0];
    let h = this.addZero(this.endDate.getHours());
    let m = this.addZero(this.endDate.getMinutes());
    this.todayTime = h + ":" + m;
  }
  get form(): { [key: string]: AbstractControl } {
    return this.addSiteNotesForm.controls;
  }
  get cleaningForm(): { [key: string]: AbstractControl } {
    return this.cleaningCrewForm.controls;
  }
  intiateAll_API_requiredAPICalls = () => {
    this.getAllInfo();
    //this.getRecentEsclations();
    this.alarmActivation();
    this.getArmDisarmHistoryData();
    //this.getPsaListData();
    this.getArmedDisarmedStatusForMsgPanelData();
    setTimeout(() => {
      this.reloadMobileSite = true;
    }, 1000)
  }
  allMessages: any[] = []; // Declare allMessages array 
  crewAndGuardList: any[] = [];

  getAllInfo = () => {
    this.isShowMsgPanelInfoSpinner = true;
    this.wallService.getAllMessageInfoPanelData(this.potentialId).subscribe((res: any) => {
      let respData = res?.data;
      this.isShowMsgPanelInfoSpinner = false;
      if (respData) {
        this.failedFetchAll = false;
        this.ifttt = respData?.ifttt?.site?.events;
        this.modifiedDate_tz = respData.ifttt?.site?.modifiedDate_tz || respData.ifttt?.site?.modifiedDate;
        this.eventdesc = [];
        this.crew = respData?.crew?.contacts;
        this.guard = respData?.guard?.contacts;
        const crewType = respData?.crew?.contacts || [];
        const guardType = respData?.guard?.contacts || [];
        this.unitIds = respData.siteInfo.sitelist;
        this.siteTimeCustomer = res?.data?.siteInfo?.siteTimeCustomer;
          this.sharedService.setTime(this.siteTimeCustomer);
        crewType.forEach((contact: any) => {
          contact.type = 'Cleaning Crew';
        });
        guardType.forEach((contact: any) => {
          contact.type = 'Guard';
        });
        if (respData?.siteNotes && respData?.siteNotes.length > 0) {
          this.allMessages = respData?.siteNotes;
        }
        //combining CleaningCrew and Guard
        this.crewAndGuardList = [...crewType, ...guardType];
        this.siteInfoDetails = respData?.siteInfo;
        this.last4Hours('4hours');
        this.siteInfoDetails.contactInfoList = this.siteInfoDetails.contactInfoList?.sort(this.liveviewListComponent.dynamicSort('contactType'));
        if (this.siteInfoDetails?.monitoringHours == null) {
          this.siteInfoDetails.monitoringHours = "--";
        }
        if (this.siteInfoDetails?.additionalMonitoringHours == null) {
          this.siteInfoDetails.additionalMonitoringHours = "--";
        }
        for (let i = 0; i < this.ifttt?.length; i++) {
          this.eventdesc.push(this.ifttt[i]?.eventDesc);
        }
        this.iftttData = [];

        for (let i = 0; i < this.ifttt?.length; i++) {
          this.iftttData.push(this.ifttt[i].recAction);
        }

        for (let i = 0; i < this.ifttt?.length; i++) {
          this.iftttData.push(this.ifttt[i].recAction);
        }

        this.newObject = {
          events: this.eventdesc,
          action: this.iftttData
        }

      } else {
        this.failedFetchAll = true;
      }
      this.getPsaListData();
    }, (err) => {
      console.error('Get all failed', err);
      this.toasterService.showError('Failed to fetch');
      this.failedFetchAll = true;
      this.isShowMsgPanelInfoSpinner = false;
    });

  }
  hideMobileSitesWith_F() {
    return this.unitIds?.some((unit: any) => unit.unitId.startsWith('F'));
  }
  clearAll = () => {
    this.adhocDetails = [];
    this.ifttt = [];
    this.iftttData = [];
    this.newObject = {
      events: [],
      action: []
    };
    // this.crew = [];
    // this.guard = [];
    this.crewAndGuardList = [];
    this.adhoc = [];
    this.allMessages = [];
    this.siteInfoDetails = null;
  }
  refresh = () => {
    this.clearAll();
    this.intiateAll_API_requiredAPICalls();
  }
  searchByPotentialId = (potentialId: string) => {
    this.potentialId = potentialId;
    this.clearAll();
    this.intiateAll_API_requiredAPICalls();
  }
  getUnitInformation = (potentialId: string) => {
    this.potentialId = potentialId;
    this.reloadMobileSite = false;
    this.intiateAll_API_requiredAPICalls();
  }
  getRemainingCount(text: any): number {
    const remainingCount = this.maxlength - (text ? text.length : 0);
    return remainingCount >= 0 ? remainingCount : 0;
  }

  onKeyDown(event: KeyboardEvent, text: string): void {
    if (this.getRemainingCount(text) <= 0 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
  getRecentEsclations(history: any = {}) {
    this.wallService.recentEsclations(this.potentialId, history).subscribe((res: any) => {
      if (res?.data) {
        this.recentEscalationList = res?.data?.content;
      }
    });
  }
  alarmActivation() {
    this.wallService.getAlarmActivation(this.potentialId).subscribe((res: any) => {
      if (res) {
        this.recentActivations = res;
      }
    });
  }
  getArmDisarmHistoryData = () => {
    this.wallService.getTArmDisarmHistoryByPID(this.potentialId).subscribe((res: any) => {
      if (res?.data) {
        this.armDisarmHistory = res.data;
      }
    });
  }

  getPsaListData = () => {
    this.wallService.getPsaList(this.potentialId).subscribe((res: any) => {
      if (res?.data) {
        this.PSAListData = res.data;
      }
    });
  }
  getArmedDisarmedStatusForMsgPanelData = () => {
    if (this.potentialId !== undefined || this.potentialId !== null) {
      this.wallService.getArmedDisarmedStatusForMsgPanel(this.potentialId).subscribe((res: any) => {
        if (res?.data) {
          this.armDisarmStatusData = res.data;
        }
      });
    }
  }
  getBroadCast_SiteNotesData() {
    this.wallService.getBroadCastList(this.potentialId, {}).subscribe((res: any) => {
      if (res?.data?.bmessages) {
        //this.broadCastList = res?.data?.bmessages;
      }
    });
  }
  // filterByLevel = () => {
  //   if (this.selectLevel == 'L2L3') {
  //     this.recentEscalationList = this.recentEscalationListCopy.filter((item: any) => {
  //       return item.previousLevel == 'L2' || item.previousLevel == 'L3' || item.currentLevelId == 'L2' || item.currentLevelId == 'L3'
  //     });
  //   } else if (this.selectLevel == 'L2') {
  //     this.recentEscalationList = this.recentEscalationListCopy.filter((item: any) => {
  //       return item.previousLevel == 'L2' || item.currentLevelId == 'L2';
  //     });
  //   } else if (this.selectLevel == 'L1') {
  //     this.recentEscalationList = this.recentEscalationListCopy.filter((item: any) => {
  //       return item.previousLevel == 'L1' || item.currentLevelId == 'L1';
  //     });
  //   } else if (this.selectLevel == 'L3') {
  //     this.recentEscalationList = this.recentEscalationListCopy.filter((item: any) => {
  //       return item.previousLevel == 'L3' || item.currentLevelId == 'L3';
  //     });
  //   }
  // }
  last4Hours(type: any) {
    this.recentEscalationList = [];
    // show spinner 
    this.currentlyLoading.push('las4hrsRemoveSpinner');
    const panelData = JSON.parse(this.panelLSdata);
   // const eventTimeStr = panelData?.panelObj?.eventTimeStampStr;
    const customerSiteTimeStr = this.siteInfoDetails.siteTimeCustomer;

    this.wallService.recentEsclations(this.potentialId, history, type, this.siteInfoDetails.siteTimeCT, customerSiteTimeStr).subscribe((res: any) => {
      // hide spinner 
      let lastFHData = res?.data?.content;
      if (lastFHData && lastFHData.length > 0) {
        // remove array of paticular element 
        const indexOfNoData = this.currentlyLoading.indexOf('las4hrsRemoveSpinner');
        if (indexOfNoData > -1) {
          this.currentlyLoading.splice(indexOfNoData, 1);
        }
        this.recentEscalationList = res?.data?.content;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('las4hrsRemoveSpinner'), 1);
      }
      if (lastFHData?.length == 0) {
        this.recentEscFetchMsg = " in last 4 hours";
      }
    });
  }
  yesterday(type: any) {
    this.recentEscalationList = [];
    const panelData = JSON.parse(this.panelLSdata);
    //const eventTimeStr = panelData?.panelObj?.eventTimeStampStr;
    const customerSiteTimeStr = this.siteInfoDetails.siteTimeCustomer;
    // show spinner 
    this.currentlyLoading.push('yesterdayRemoveSpinner');
    this.wallService.recentEsclations(this.potentialId, history, type, this.siteInfoDetails.siteTimeCT, customerSiteTimeStr).subscribe((res: any) => {
      // hide spinner 
      let yestData = res?.data?.content;
      if (yestData && yestData.length > 0) {
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('yesterdayRemoveSpinner');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.recentEscalationList = res?.data?.content;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('yesterdayRemoveSpinner'), 1);
      }
      if (yestData?.length == 0) {
        this.recentEscFetchMsg = " in last 1 day";
      }
    });
  }
  Past7Days(type: any) {
    this.recentEscalationList = [];
    const panelData = JSON.parse(this.panelLSdata);
   // const eventTimeStr = panelData?.panelObj?.eventTimeStampStr;
    const customerSiteTimeStr = this.siteInfoDetails.siteTimeCustomer;
    
    // show spinner 
    this.currentlyLoading.push('yesterdayRemoveSpinner');
    this.wallService.recentEsclations(this.potentialId, history, type, this.siteInfoDetails.siteTimeCT, customerSiteTimeStr).subscribe((res: any) => {
      // hide spinner 
      let yestData = res?.data?.content;
      if (yestData && yestData.length > 0) {
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('yesterdayRemoveSpinner');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.recentEscalationList = res?.data?.content;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('yesterdayRemoveSpinner'), 1);
      }
      if (yestData?.length == 0) {
        this.recentEscFetchMsg = " in last 7 Days";
      }
    });
  }
  //filter
  recentEsclations(history: any = {}) {
    this.recentEscalationList = [];
    // show spinner 
    this.currentlyLoading.push('reEsclationRemoveSpinner');
    this.wallService.recentEsclations(this.potentialId, history, '').subscribe((res: any) => {
      // hide spinner 
      let esData = res?.data?.content;
      if (esData && esData.length > 0) {
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('reEsclationRemoveSpinner');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.recentEscalationList = res?.data?.content;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('reEsclationRemoveSpinner'), 1);
      }
      if (esData?.length == 0) {
        this.recentEscFetchMsg = " in date range (Start date: " + history?.startDate + " " + history?.startTime + ", End date: " + history?.endDate + " " + history?.endTime + ")";
      }
    });
  }
  broadCastAdhocValues(history: any = {}) {
    this.allMessages = [];
    // show spinner 
    this.currentlyLoading.push('addNotesModal');
    this.wallService.getBroadAdhocFilterValues(this.potentialId, history, '').subscribe((res: any) => {
      let respData = res?.data;

      if (!respData) {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('addNotesModal'), 1);
      } else {
        const adhocData = res?.data?.ADHOC?.notes || [];
        const broadcastData = res?.data?.BROADCAST?.bmessages || [];
        const boloData = res?.data.BOLO?.notes || [];
        adhocData?.forEach((contact: any) => {
          contact["type"] = 'Adhoc'
        });

        broadcastData.forEach((contact: any) => {
          contact["type"] = 'Broadcast'
        });
        boloData?.forEach((contact:any)=>{
          contact["type"] = 'Bolo'
        });
        if (respData?.siteNotes && respData?.siteNotes.length > 0) {
          this.allMessages = res?.siteNotes;
        }
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('addNotesModal');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.allMessages = [...adhocData, ...broadcastData , ...boloData];
      }
    },
      () => {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('addNotesModal'), 1);
      });
  }

  toggleInputFields() {
    const recordsValue = this.reverseEscForm.get('records').value;
    if (recordsValue === 'recent') {
      this.reverseEscForm.get('startDate').reset();
      this.reverseEscForm.get('endDate').reset();
      this.reverseEscForm.get('startTime').reset();
      this.reverseEscForm.get('endTime').reset();
    }
  }
  isInputVisible() {
    const recordsValue = this.reverseEscForm.get('records').value;
    return recordsValue !== 'recent';
  }
  updateReverseEscForm(form: any) {
    this.recentEsclations(form.value);
    //this.closeFilter.nativeElement.click();
  }
  // armHistoryfilter(history:any = {}){
  //   this.wallService.getArmHistory(this.potentialId , history).subscribe((res:any)=>{
  //      //console.log('Getting Value',res)
  //   })
  // }
  updateBroadAdhocForm(form: any) {
    this.broadCastAdhocValues(form.value)
  }
  armDisarmFilter(form: any) {
    this.armHistoryfilter(form.value);
  }

  armHistoryfilter(history: any = {}) {
    this.armDisarmHistory = [];
    // show spinner
    this.currentlyLoading.push('armHistoryRemoveSpinner');
    this.wallService.getArmHistory(this.potentialId, history).subscribe((res: any) => {
      let armData = res?.data;
      if (armData != null && armData > 0) {
        // hide spinner
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('armHistoryRemoveSpinner');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.armDisarmHistory = res?.data;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('armHistoryRemoveSpinner'), 1);
      }
    })
  }

  getRecentActivationFilterData(history: any = {}) {
    this.recentActivations = [];
    // show spinner
    this.currentlyLoading.push('RecentActivationRemoveSpinner');
    this.wallService.recentActivationData(this.potentialId, history).subscribe((response: any) => {
      let recentADATA = response?.data;
      if (recentADATA != null && recentADATA.length > 0) {
        // hide spinner
        // remove array of paticular element 
        const index = this.currentlyLoading.indexOf('RecentActivationRemoveSpinner');
        if (index > -1) {
          this.currentlyLoading.splice(index, 1);
        }
        this.recentActivations = response?.data;
      } else {
        this.currentlyLoading.splice(this.currentlyLoading.indexOf('RecentActivationRemoveSpinner'), 1);
      }
    })
  }

  recentActivationFilter(form: any) {
    this.getRecentActivationFilterData(form.value);
  }
  onRadioChange(selectedValue: string) {
    this.changeDate();
    // const dateToday = new Date();
    // const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    // this.validatedFromdDate = currentDate.split(' ')[0];
    this.addSiteNotesForm.patchValue({
      startDate: this.validatedFromdDate,
      startTime: this.todayTime,
      endDate: '',
      endTime: this.todayTime,
      recordsmessage: '',
    })
  }

  onCrewAndGuardRadioChange(selectedValue: string) {
    this.selectedCrewAndGuard = selectedValue;
    //const crewAndGuardType = this.cleaningCrewForm?.get('cleaningCrew');
    // if (crewAndGuardType?.value === selectedValue) {
    //   crewAndGuardType.setValue(selectedValue);
    // }
    // else {
    //   crewAndGuardType.setValue(null);
    // }
    //this.cleaningCrewForm?.setValue(selectedValue);
  }
  createAddSiteNotesForm(form: any) {
    this.submitted = true;
    if (this.addSiteNotesForm.invalid) {
      return;
    }
    const startTimeStamp = moment(`${form.value.startDate} ${form.value.startTime + ':00'}`);
    const endTimeStamp = moment(`${form.value.endDate} ${form.value.endTime + ':00'}`);
    if (endTimeStamp.isBefore(startTimeStamp) || endTimeStamp.isSame(startTimeStamp)) {
      this.errorToaster = true;
      this.toasterMessage = 'Start date should be less than end date';
      setTimeout(() => {
        this.hideToaster();
      }, 3000);
      return;
    }
    let payload = {
      "message": form.value.recordsmessage,
      "fromDate": startTimeStamp.format('DD-MM-YYYY HH:mm:ss').toString(),
      "toDate": endTimeStamp.format('DD-MM-YYYY HH:mm:ss').toString(),
      "canExpire": false,
      "potentialId": this.potentialId
    };
    this.filterModal.nativeElement.click();
    this.currentlyLoading.push('addSitesSpinner');
    if (this.selectedBroadcastType === 'Broadcast' || this.addSiteNotesForm.value.broadcastType === 'Broadcast') {
      this.wallService.postSiteNotesForMsgPanel(payload).subscribe((resp: any) => {
        if (resp.success && resp.data) {
          this.addSiteNotesForm.reset();
          this.toasterMessage = 'Broadcast Notes Added!';
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
          this.showToaster = true;
          this.maxlength = 400;
          this.text_broad = '';
          this.changeDate();
          this.getSiteNotesData(payload);
          this.addSiteNotesForm.patchValue({
            startDate: this.validatedFromdDate,
            startTime: this.todayTime,
            endDate: '',
            endTime: this.todayTime,
            recordsmessage: '',
            broadcastType: 'Broadcast'
          })
        }
        else {
          this.toasterService.showError("Unable to create broadcast!");
        }
        this.submitted = false;
      });
    }
    else if (this.selectedBroadcastType === 'Adhoc' || this.addSiteNotesForm.value.broadcastType === 'Adhoc') {
      this.wallService.postAdhocData(payload).subscribe((res: any) => {
        if (res.success && res.data) {
          this.addSiteNotesForm.reset();
          this.toasterMessage = 'Adhoc Notes Added!';
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
          this.showToaster = true;
          this.maxlength = 400;
          this.text = '';
          this.changeDate();
          this.getSiteNotesData(payload);
          this.addSiteNotesForm.patchValue({
            startDate: this.validatedFromdDate,
            startTime: this.todayTime,
            endDate: '',
            endTime: this.todayTime,
            recordsmessage: '',
            broadcastType: 'Adhoc'
          })
        }
        else {
          this.toasterService.showError("Unable to create Adhoc!");
        }
        this.submitted = false;
      })
    }
    else if (this.selectedBroadcastType === 'bolo' || this.addSiteNotesForm.value.broadcastType === 'bolo'){
      this.wallService.postBoloData(payload).subscribe((res:any)=>{
        if (res?.success && res?.data) {
          this.addSiteNotesForm.reset();
          this.toasterMessage = 'BOLO Notes Added!';
          this.showToaster = true;
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
          this.maxlength = 400;
          this.text = '';
          this.changeDate();
          this.getSiteNotesData(payload);
          this.addSiteNotesForm.patchValue({
            startDate: this.validatedFromdDate,
            startTime: this.todayTime,
            endDate: '',
            endTime: this.todayTime,
            recordsmessage: '',
            broadcastType: 'bolo'
          })
        }
        else if(res && res?.success == false && res?.data){
          this.toasterMessage = `${res?.data}`;
          this.errorToaster = true;
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
        }
        else {
          this.toasterService.showError("Unable to create Bolo Message!");
        }
        this.submitted = false;
      })
    }

  }
  closebrodForm(event: Event) {
    const dateToday = new Date();
    const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    this.validatedFromdDate = currentDate.split(' ')[0];
    if (event) {
      this.filterModal.nativeElement.click();
      this.submitted = false;
      this.addSiteNotesForm.patchValue({
        startDate: this.validatedFromdDate,
        startTime: this.todayTime,
        endDate: '',
        endTime: '',
        recordsmessage: '',
        broadcastType: 'Broadcast'
      })
    }
  }
  closeCrewForm(event: Event) {
    if (event) {
      this.CleaningCrewModal.nativeElement.click();
      this.cleancrew = false;
      this.cleaningCrewForm.patchValue({
        name: '',
        contact: '',
        details: '',
        cleaningCrewType: 'Cleaning Crew',
      })
    }
  }
  //update cleaningcrew
  cleaningCrewUpdate(form: any) {
    this.cleancrew = true;
    if (this.cleaningCrewForm.invalid) {
      return
    }
    let payload = {
      "potentialId": this.potentialId
    }
    this.CleaningCrewModal.nativeElement.click();
    if (this.selectedCrewAndGuard === 'Guard' || this.cleaningCrewForm?.value.cleaningCrewType === 'Guard') {
      this.wallService.crewUpdate(payload.potentialId, this.cleaningCrewForm?.value, true).subscribe((res: any) => {
        if (res.success && res.data) {
          this.cleaningCrewForm.reset();
          this.maxlength = 400;
          this.text_Crew = '';
          this.getSiteNotesData(payload);
          this.cleaningCrewForm.patchValue({
            name: '',
            contact: '',
            details: '',
            cleaningCrewType: 'Cleaning Crew',
          })
        }
        else {
          this.toasterService.showError("Unable to create Guard!");
        }
        this.cleancrew = false;
      })
    }
    else {
      this.wallService.crewUpdate(payload.potentialId, this.cleaningCrewForm?.value, false).subscribe((res: any) => {
        this.cleaningCrewForm?.reset();
        this.maxlength = 400;
        this.text_Crew = '';
        this.getSiteNotesData(payload);
        this.cleaningCrewForm.patchValue({
          name: '',
          contact: '',
          details: '',
          cleaningCrewType: 'Cleaning Crew',
        })
        this.cleancrew = false;
      })
    }
  }
  guardUpdate(form: any) {
    let payload = {
      "potentialId": this.potentialId
    }
    this.guardModal.nativeElement.click();
    this.wallService.crewUpdate(payload.potentialId, this.guardform.value, true).subscribe((res: any) => {
      this.guardform.reset();
      this.maxlength = 400;
      this.text_guard = '';
      this.getSiteNotesData(payload);
    })
  }

  addOrUpdatePSA(form: any) {
    let payload = {
      "potentialId": this.potentialId
    }
    // this.addPSAModal.nativeElement.click();
    //need to call an API to store data
    //this.psaform.reset();
    //this.getSiteNotesData(payload);
  }


  getSiteNotesData = (payload: any) => {
    this.wallService.getAllMessageInfoPanelData(this.potentialId).subscribe((res: any) => {
      let respData = res?.data;
      if (respData) {
        //adding Type for both adhoc and broadcost
        const crewType = respData?.crew?.contacts;
        const guardType = respData?.guard?.contacts;

        crewType.forEach((contact: any) => {
          contact.type = 'Cleaning Crew';
        })

        guardType.forEach((contact: any) => {
          contact.type = 'Guard';
        });
        //combining CleaningCrew and Guard
        this.crewAndGuardList = [...crewType, ...guardType];
        const indexOfNoData = this.currentlyLoading.indexOf('addSitesSpinner');
        if (indexOfNoData > -1) {
          this.currentlyLoading.splice(indexOfNoData, 1);
        }
        if (respData?.siteNotes && respData?.siteNotes.length > 0) {
          this.allMessages = respData?.siteNotes;
        }
      }
    });
  }

  viewEvent(eventData: any) {
    const windowName = this.sharedService.prepareWindowName('viewevent_');
    let evtWindow: any = window.open("", "", "width=656,height=369");
    let name: any = `${eventData.cameraName}, ${eventData.eventid}`;
    let toWrite = `<video controls="" autoplay="true" disablepictureinpicture="" controlslist="nodownload noplaybackrate" muted="true"
      style="width: 100%; height: 100%; aspect-ratio: 16/9;">
      <source type="video/mp4" src="${eventData.eventLink}">
    </video>`
    evtWindow.document.write("<html><head><title>" + name + "</title></head><body>"
      + toWrite + '</body></html>');
    evtWindow.document.close();
    permissions.allWindows[windowName] = evtWindow;
  }
  copyToClip(eventData: any) {
    const unsecuredCopyToClipboard = (text: any) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Unable to copy to clipboard', err)
      }
      document.body.removeChild(textArea)
    };
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(eventData.eventLink);
    } else {
      unsecuredCopyToClipboard(eventData.eventLink);
    }
    alert('Event Link copied!')
    // this.toasterService.showInfo("Event Id copied!");
  }
  getIvigilURL(row: any) {
    const currTime = moment(new Date(row?.eventTimeStampStr));
    const timeStr = currTime.format('YYYY-MM-DD-HH-mm-ss').toString();
    const urlPrep = `${row.siteURL}/GenerateArchiveLink?camraId=${row.cameraId}&timeStamp=${timeStr}&imageWidth=${row.width}&imageHeight=${row.height}`;
    window.open(urlPrep);
  }
  showAllLiveViewsPopup = () => {
    let modal1 = document.getElementById("msgpanelLiveview" + this.panelName) as HTMLElement;
    if (modal1.style.display == "none") {
      modal1.style.display = "block";
    } else {
      modal1.style.display = "none";
    }
    this.showLiveviewPanel = true;
    this.allViewsModal = !this.allViewsModal;
  }
  closeAllViewModal = () => {
    this.showLiveviewPanel = false;
    this.allViewsModal = !this.allViewsModal;
    let modal1 = document.getElementById("msgpanelLiveview" + this.panelName) as HTMLElement;
    if (modal1) {
      modal1.style.display = "none";
    }
  }



  showSitemapPopup = () => {
    this.getSiteMap();
    let siteMap = document.getElementById("siteMapModal" + this.panelName) as HTMLElement;
    if (siteMap.style.display == "none") {
      siteMap.style.display = "block";
    } else {
      siteMap.style.display = "none";
    }
    this.showSiteMapPanel = true;
    this.allViewsModal = !this.allViewsModal;
  }

  //getting siteMap data by potentialId
  getSiteMap() {
    //make service call to fetch sitemap data by potentialId
    this.wallService.getSiteMapDataByPotentialId(this.potentialId).subscribe((resp: any) => {
      if (resp?.data) {
        this.siteMapImgSrc = resp.data;
      }
    });
  }

  closeSiteMapModal() {
    this.showSiteMapPanel = false;
    let modal1 = document.getElementById("siteMapModal" + this.panelName) as HTMLElement;
    if (modal1) {
      modal1.style.display = "none";
    }
  }

  navigateToConsole() {
    window.open(environment.consoleUrl + "searchBySiteNameCameraId.jsp?siteName=" + this.siteInfoDetails?.siteName, '_blank');
  }

  //  camera view pop up 
  showCamereViewsPopup() {
    let modal1 = document.getElementById("msgpanelCameraview") as HTMLElement;
    if (modal1.style.display == "none") {
      modal1.style.display = "block";
    } else {
      modal1.style.display = "none";
    }
    this.showCameraviewPanel = true;
    // this.allViewsModal = !this.allViewsModal;
  }

  closeCameraViewModal() {
    this.showCameraviewPanel = false;
    // this.allViewsModal = !this.allViewsModal;
    let modal1 = document.getElementById("msgpanelCameraview") as HTMLElement;
    if (modal1) {
      modal1.style.display = "none";
    }
  }

  recentEscMoreDetails(eventData: any) {
    if (eventData?.eventUserInfoModified?.length === 0) {
      this.eventInfo = true;
    } else {
      this.eventUserData = eventData?.eventUserInfoModified;
      this.escId = eventData?.eventid;
    }
    const modal = document.getElementById('eventUserInfo');
    if (modal) {
      modal.style.display = 'block';
    }
  }
  setTimezone(timezone: string) {
    this.selectedTimezone = timezone;
  }
  selectTimeZone(event: any) {
    this.timeZoneSelect = event.target.value;
  }
  getFormattedTimestamp(item: any): string {
    if (this.timeZoneSelect === 'IST') {
      return item.eventTimeStrInIST || '-';
    } else if (this.timeZoneSelect === 'CT') {
      return item.eventTimeStrInCT || '-';
    }
    return item.eventTimeStampStr || '-';
  }
  closeModal() {
    const modal = document.getElementById('eventUserInfo');
    if (modal) {
      modal.style.display = 'none';
    }

  }
  toggleMaskingView(): void {
    this.isMaskingView = !this.isMaskingView;
  }
  armDisarmModal(status: any) {
    this.armDisarmed = !this.armDisarmed;
    this.armDisarmStatus = status;
  }

  dismissModal() {
    this.armDisarmed = false;
  }
  submitArmDisarm(armedFormData: any) {
    if (armedFormData.status === 'Armed') {
      let history = {
        action: armedFormData.action,
        potentialId: this.potentialId,
        deviceId: armedFormData.deviceId,
        sitename: this.siteInfoDetails.siteName,
        source: armedFormData.source,
        messageText: armedFormData.notes,
        user:  (localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')||"")?.user.username:""
      }
      this.wallService.armAction(this.potentialId, history).subscribe((result: any) => {
        if (result.response === 'success') {
          this.toasterMessage = 'Site armed successfully';
          this.showToaster = true;
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
        }
      })
    }
    else if (armedFormData.status === 'Disarmed') {
      let history = {
        action: armedFormData.action,
        potentialId: this.potentialId,
        deviceId: armedFormData.deviceId,
        sitename: this.siteInfoDetails.siteName,
        source: armedFormData.source,
        messageText: armedFormData.notes,
        from: armedFormData.from,
        untilHour: armedFormData.hourTime,
        untilMin: armedFormData.minTime,
        user: (localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')||"")?.user.username:""
      }
      this.wallService.disarmAction(history).subscribe((result: any) => {
        if (result.response === 'success') {
          this.toasterMessage = 'Site disarmed successfully';
          this.showToaster = true;
          setTimeout(() => {
            this.hideToaster();
          }, 3000);
        }
      })
    }
  }
  hideToaster() {
    this.showToaster = false;
    this.errorToaster = false;
  }
  ngOnDestroy() {
    localStorage.removeItem("messagePanelPotentialId");
  }
}