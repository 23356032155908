import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-filtermodal',
  templateUrl: './filtermodal.component.html',
  styleUrls: ['./filtermodal.component.scss']
})
export class FiltermodalComponent {
  @Input() title!:string;
  @Input() filterForm!: FormGroup;
  @Output() filterApplied: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @ViewChild('alarmhistoryModal') alarmhistoryModal: any;
  endDate = new Date();
  todayDate: any;
  todayTime : any; 
constructor(){
  this.todayDate = this.endDate.toISOString().split('T')[0];
  let h = this.addZero(this.endDate.getHours());
  let m = this.addZero(this.endDate.getMinutes());
  this.todayTime= h + ":" + m ;
}

addZero(i: any) {
  if (i < 10) {i = "0" + i}
  return i;
}
applyFilter() {
  // Emit the filter data to the parent component
  this.filterApplied.emit(this.filterForm);
  this.alarmhistoryModal.nativeElement.click();
}
}
