<div style="margin-bottom: 2px;position: static;">
    <div class="imgDiv">
        <div style="width:68%;">
            <h6 class="text-white h-cls">
                <span class="status-camera"
                    [ngClass]="{'online-sm': camItemData?.connected === 'true', 'offline-sm': camItemData?.connected == 'false'}">
                </span>
                {{camItemData?.cameraName}}
            </h6>
        </div>
        <div class="icon-bg" *ngIf="isrecordStartBtnShow==true">
            <i class="fa fa-play-circle iconsImg" (click)="submitStartRecord()"></i>
        </div>
        <div class="icon-bg" *ngIf="isrecordStartBtnShow==false">
            <i class="fa fa-stop-circle faRecordIcon" (click)="submitStopRecordPopup()"></i>
        </div>
        <div class="icon-bg">
            <i class="fa fa-video-camera iconsImg" (click)="openWinPage()"></i>
        </div>
        <div class="icon-bg">
            <i class="fa fa-bell iconsImg" (click)="alarmTrigger()"></i>
        </div>
    </div>
    <div style="position: relative;">
        <div class="tagsDiv" [id]="'tagsItem_'+camItemData?.cameraUniqueId">
            <span class="col-sm-3 tagItem" (click)="onTagSelect(tagItem)" *ngFor="let tagItem of tagsList;">
                {{tagItem}}
            </span>
        </div>
        <div class="txtbxDiv" [id]="'txtbx_'+camItemData?.cameraUniqueId">
            <textarea [(ngModel)]="userNotes" [id]="'notesTxt_'+camItemData?.cameraUniqueId"
                placeholder="Please enter the notes here." name="w3review" rows="3" cols="50"
                style="width: 150px;height: 50px;padding: 5px;"></textarea>
            <div class="row d-flex">
                <div class="col-sm-2">
                    <!-- <button class="btn btn-secondary" (click)="cancelTxtPopup()">
                        Cancel
                    </button> -->
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-success" (click)="submitStopRecord()">
                        Submit
                    </button>
                </div>
            </div>
        </div>
        <img [src]="camItemData.siteAccessUrl+camItemData.snapshotUrl +'&test='+camItemData.timeCount"
            style="width: 100% !important;height: 30vh !important;" (dblclick)="openCamLiveView(camItemData)" />

    </div>
</div>