export const permissions:any = {
    wallscount:4,
    isMasking:true,
    autoplay:true,
    isEscalation:true,
    isSafeLogoutEnabled:false,
    replayNoOfTimes:3,
    isfullscreen: false,    
    eventPaused:false,
    windowCounter: 0,
    allWindows: {},
    eventPauseMsg:"Events Paused",
    userLogoutMsg:"No Events - User Logged Out",
    toekExpiredMsg:"TOKEN Expired",
    unabletoProcessEventMsg:"Unable to process Event",
    autoClosingMsg:"Event Auto-closing",
    nullResponseMsg:"unable to process request.we are retrying.",
    initialPanelLoad: true,
    messagePanel:null,
    browserRefresh:true,
    mirrorWindow:null,
    tblPnlLoadingMsg:"Please wait, details are loading...",
    tblPnlLoadingDataEmptyMsg:"There is no data.",
    tblPnlLoadingErrorMsg:"We are unable to fetch the service data.",
    MonitoringAdmin:["L3_AdvancedUser","L3_User"],
    fbNotificationShowTime:2000,
    boloMessageInterval : 18000000,
    isUnifiedMaskingLocalEnabled: true
  }