import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WallsService } from 'src/app/services/walls/walls.service';

@Component({
  selector: 'app-camera-views',
  templateUrl: './camera-views.component.html',
  styleUrls: ['./camera-views.component.scss']
})
export class CameraViewsComponent {
  @Input() potentialId: any;
  @Input() siteName: any;
  @Output() closeCameraViewModal = new EventEmitter();
  cameraViewList: any;
  isdivShow: boolean = false;

  constructor(
    private wallsService: WallsService,
  ) {

  }
  ngOnInit(): void {
    this.getcameralist();
  }

  getcameralist() {
    this.cameraViewList = [];
    this.wallsService.getCameraViews(this.potentialId).subscribe((res: any) => {

      if (res.success == true && res.data != null && res.data.length > 0) {
        this.isdivShow = false;
        this.cameraViewList = res.data
      } else {
        this.isdivShow = true;
      }

    })
  }

  closePopup() {
    this.closeCameraViewModal.emit();
  }

  ngOnDestroy(): void {
  }
}
