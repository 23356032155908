import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { permissions } from './common/models/globalpermissions';
import { Subscription } from 'rxjs';
import { HeartbeatService } from './common/services/sharedsrv/heartbeat.service';
import { WallsService } from './services/walls/walls.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'psafe-walls-app';
  devFlag = false;
  currentRoute: any = '';
  subscription: Subscription;

  // @HostListener('window:beforeunload') goToPage() {
  //   console.log('hi');
  //   this.router.navigate(['/l3-one']);
  // }  

  constructor(private activateRoute: ActivatedRoute, private heartbeat:HeartbeatService, 
              private router: Router, private wallsService:WallsService) {
    this.currentRoute = window.location.pathname;

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        permissions.browserRefresh = !router.navigated;
      }
    });
    
    const user = localStorage.getItem('user');
    if (user) {
        const heartbeatEnabled = JSON.parse(user)?.user?.heartbeat;
        //console.log("heartbeatEnabled ", heartbeatEnabled);
        if(heartbeatEnabled){
          // sheartbeat.start();
        }
        
    }
    this.clickJacking();
  }
  ngOnInit(): void {
    // this.requestPermission();
    // this.listen();
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event: any) {
  //   this.activateRoute.queryParams.subscribe((params) => {
  //     this.devFlag = !!params['dev'] || environment.devFlag;
  //   });
  //   if (permissions.isSafeLogoutEnabled || this.devFlag || this.currentRoute == "/live-view") {
  //     return;
  //   }
  //   event.preventDefault();
  //   event.returnValue = 'Your data will be lost!';
  //   return false;
  // }
  clickJacking = () => {
    if (window.location !== window.parent.location) {
      let theBody = document.getElementsByTagName('body')[0];
      theBody?.remove();
    }
  }
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event: any) {
  //   // this.activateRoute.queryParams.subscribe((params) => {
  //   //   this.devFlag = !!params['dev'] || environment.devFlag;
  //   // });
  //   // if (permissions.isSafeLogoutEnabled || this.devFlag || this.currentRoute == "/live-view") {
  //   //   return;
  //   // }
  //   // event.preventDefault();
  //   // event.returnValue = 'Your data will be lost!';
  //   //return false;

  //   /// Making API call for liveview close window;
  //   // event.returnValue = '';
  //   // event.preventDefault();
  //   // const windowUrl = event.srcElement?.URL;
  //   // const accountId = windowUrl?.split("LiveviewAuditId_")[1]?.split("=")[0];
  //   // console.log(accountId);
  //   // if (accountId) {
  //   //   this.wallsService.endLiveViewAuditLog(accountId);
  //   //   console.log(accountId);
  //   // }
  //   // setTimeout(()=>{
  //   //   return false;
  //   // },2000);
  //   ////
    
  // }
}
