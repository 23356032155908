<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" [ngClass]="{ 'show': armDisarmed }"
    [ngStyle]="{ 'display': armDisarmed ? 'block' : 'none', 'background-color': 'rgba(0, 0, 0, 0.5)' }">
    <div class="modal-dialog" style="margin-top: 40px;">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #0c5b82;color: white;">
                <h5 class="modal-title" id="filterModalLabel">Confirm {{armDisarmStatus }} ?</h5>
                    <button type="button" class="close"  data-bs-dismiss="modal" aria-label="Close"
                    style="background: none;border: none;" (click)="dismissModal()">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div class="modal-body" style="background-color: white;">
               <form #form="ngForm">
                <div>
                    <div class="mb-3">
                        <label for="nameInput" class="form-label">Name:</label>
                        <input type="text" class="form-control" id="nameInput" placeholder="Enter your name"
                            style="border: 1px solid #0c5b82;" [(ngModel)]="armedFormData.name" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="mb-3">
                        <label for="notesTextarea" class="form-label">Notes</label>
                        <textarea class="form-control" id="notesTextarea" rows="3" placeholder="Type text here"
                            style="border: 1px solid #0c5b82;" [(ngModel)]="armedFormData.notes" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                    <div class="mb-3" *ngIf="armDisarmStatus === 'Disarmed'">
                        <label class="form-label">Duration:</label>
                        <!-- <div class="d-flex align-items-center mt-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" 
                                name="durationOption" id="forOption" value="for" [(ngModel)]="selectedDurationOption">
                                <label class="form-check-label" for="forOption">For:</label>
                            </div>
                            <input type="number" class="form-control" placeholder="HH" [(ngModel)]="armedFormData.hourTime" [ngModelOptions]="{standalone: true}"
                                style="border: 1px solid #0c5b82;"  [disabled]="selectedDurationOption === 'until'">
                            <span>:</span>
                            <input type="number" class="form-control ms-2 " placeholder="MM" [(ngModel)]="armedFormData.minTime"
                                style="border: 1px solid #0c5b82;"  [disabled]="selectedDurationOption === 'until'">
                        </div> -->
                        <div class="d-flex align-items-center mt-3">
                            <div class="form-check">
                                <!-- <input class="form-check-input " 
                                type="radio" name="durationOption" id="untilOption" value="until" [(ngModel)]="selectedDurationOption"> -->
                                <label class="form-check-label " for="untilOption" style="margin-right: 20px;">Until:</label>
                            </div>
                            <select class="form-select me-2" style="border: 1px solid #0c5b82; width: 20%;" [disabled]="selectedDurationOption === 'for'"
                                [(ngModel)]="armedFormData.hourTime" [ngModelOptions]="{standalone: true}" placeholder="Hour">
                                <option [value]="0" selected disabled>Hour</option>
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select>
                            <span class="me-2">to</span>
                            <select class="form-select ms-2" style="border: 1px solid #0c5b82;  width: 20%;" [disabled]="selectedDurationOption === 'for'"
                                [(ngModel)]="armedFormData.minTime" [ngModelOptions]="{standalone: true}" placeholder="Minute">
                                <option  value="0" selected disabled>Minute</option>
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select>
                            </div>
                    </div>
                    
                </div>
               </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        (click)="dismissModal()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="onSubmitModal()">Submit</button>
                </div>
            </div>
        </div>
    </div>