import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastrService: ToastrService) {
  }
  public showSuccess(toasterDisplayMessage?:string): void {
    this.toastrService.success(toasterDisplayMessage, 'Success');
  }

  public showInfo(toasterDisplayMessage?:string): void {
    this.toastrService.info(toasterDisplayMessage, 'Info!');
  }

  public showWarning(toasterDisplayMessage?:string): void {
    this.toastrService.warning(toasterDisplayMessage, 'Warning!');
  }

  public showError(toasterDisplayMessage?:string): void {
    this.toastrService.error(toasterDisplayMessage, 'Error!');
  }
  public showErrorLeft(toasterDisplayMessage?:string): void {
    this.toastrService.error(toasterDisplayMessage, 'Error!', {
      positionClass: 'toast-bottom-left',
      timeOut:10000
   });
  }
}
