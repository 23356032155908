import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  headers_object: any = null;
  constructor(private httpClient: HttpClient) {}
  private interval: any;
  private intervalTime = 10000;
  private url = 'heartbeat-api/heartbeat/user';

  public start() {
    let self = this;
    localStorage.removeItem('heartbeat-interval');
    stop();
    self.interval = setInterval(function ping() {
      const loggedIn = localStorage.getItem('loggedIn');
      const proToken = localStorage.getItem('pro-token');
      const user = localStorage.getItem('user');
      if (loggedIn && proToken && user) {
        const hInterval = localStorage.getItem('heartbeat-interval');
        if (!hInterval) {
          localStorage.setItem('heartbeat-interval', self.interval.toString());
        }
        const username = JSON.parse(user)?.user?.username;
        const hUrl = self.url + '?userId=' + username;
        self.httpClient
          .get(`${environment.apiDomain + '/' + hUrl}`)
          .subscribe((data) => {
            
          });
      }
    }, self.intervalTime);
  }

  // public callMethod(){
  //     console.log('Call Function Every Five Seconds.', new Date());
  //     this.httpClient.get('localhost:8013/api/heartbeat/user').subscribe((data) => console.log(data));
  // }

  public stop() {
    let self = this;
    if (!self.interval || self.interval == 0) {
      return;
    }
    //console.warn('stopping heartbeat..', self.interval);
    clearInterval(self.interval);
    self.interval = 0;
  }
}