import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common/components/login/login.component';
import { HeaderComponent } from './common/components/header/header.component';
import { FooterComponent } from './common/components/footer/footer.component';
/// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FullscreenComponent } from './pages/fullscreen/fullscreen.component';
import { WallSlotComponent } from './common/components/wall-slot/wall-slot.component';
import { L2OneWallsComponent } from './pages/l2-one-walls/l2-one-walls.component';
import { L2OneWallslotComponent } from './common/components/l2-one-wallslot-video/l2-one-wallslot.component';
import { L2OneWallslotTablepanelComponent } from './common/components/l2-one-wallslot-tablepanel/l2-one-wallslot-tablepanel.component';
import { MessagepanelComponent } from './common/components/messagepanel/messagepanel.component';
import { HttpInterceptorService } from './common/services/HttpInterceptorService';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveviewListComponent } from './common/components/liveview-list/liveview-list.component';
import { CameraViewsComponent } from './common/components/camera-views/camera-views.component';
import { FiltermodalComponent } from './common/components/filtermodal/filtermodal.component';
import { SpinnerLoadComponent } from './common/components/spinner-load/spinner-load.component';
import { ArmdisarmModalComponent } from './common/components/armdisarm-modal/armdisarm-modal.component';
import { SiteMapModalComponent } from './common/components/site-map-modal/site-map-modal.component';
import { HeartbeatService } from './common/services/sharedsrv/heartbeat.service';
import { NoRightClickDirective } from './common/components/no-right-click.directive';
import { LogoutPageComponent } from './common/components/logout-page/logout-page.component';
import { SharedsrvService } from './common/services/sharedsrv/sharedsrv.service';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { LiveMonitoringChildComponent } from './pages/CLM/live-monitoring-child/live-monitoring-child.component';
import { LiveMonitoringListComponent } from './pages/CLM/live-monitoring-list/live-monitoring-list.component';
import { ClmLiveviewPopupComponent } from './pages/CLM/clm-liveview-popup/clm-liveview-popup.component';
initializeApp(environment.firebase);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    FullscreenComponent,
    WallSlotComponent,
    L2OneWallsComponent,
    L2OneWallslotComponent,
    L2OneWallslotTablepanelComponent,
    MessagepanelComponent,
    LiveviewListComponent,
    CameraViewsComponent,
    FiltermodalComponent,
    SpinnerLoadComponent,
    ArmdisarmModalComponent,
    SiteMapModalComponent,
    NoRightClickDirective,
    LogoutPageComponent,
    LiveMonitoringListComponent,
    LiveMonitoringChildComponent,
    ClmLiveviewPopupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      closeButton: true,
      progressBar: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgbModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    HeartbeatService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
