import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WallsService } from 'src/app/services/walls/walls.service';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import { eventHistoryData, siteInfo } from '../../models/apidata';
import { ToasterService } from '../../services/toaster/toaster.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { permissions } from '../../models/globalpermissions';

@Component({
  selector: 'app-l2-one-wallslot-tablepanel',
  templateUrl: './l2-one-wallslot-tablepanel.component.html',
  styleUrls: ['./l2-one-wallslot-tablepanel.component.scss']
})
export class L2OneWallslotTablepanelComponent implements OnInit {
  readonly my_permissions = permissions;
  @Input() panelTableData: any;
  @Input() mirror: any = false;
  @Output() submitEmit = new EventEmitter();
  @ViewChild('closeFilter') closeFilter: any;
  filterForm: any;
  panelName: string = "";
  panelLSdata: any = "";
  messagePanelData: any = {};
  adhocDetails: any = {};
  ifttt: any = {};
  crewAndGuards: any = [];
  broadcast: any = [];
  guard: any = [];
  adhoc: any = [];
  actionNew: [] = [];
  iftttData: string[] = [];
  newObject: any = {
    events: [],
    action: []
  };
  eventdesc: string[] = [];
  levelName: any;
  broadcastExpand: boolean = true;
  AdhocExpand: Boolean = true;
  cleaningCrewExpand: Boolean = true;
  guardExpand: boolean = true;
  eventHisoryData: any;
  // Tables
  recentEscList: any = [];
  recentEscListCopy: any = [];
  broadCastList: any = [];
  siteInfo: any = {}
  recentActivations: any = [];
  armDisarmHistory: any = [];
  iftttNotes: string = "";
  broadCastform: any;
  guardform: any;
  cleaningCrewform: any;
  showError = false;
  eventUserData: any = [];
  eventInfo: boolean = false;
  AdhocForm: any;
  maxlength: number = 400;
  text: string = '';
  text_Broadcost: string = ''
  text_Crew: string = '';
  text_guard: string = '';
  isFormInvalid: any;
  selectedBroadcastType: string = '';
  validatedTodDate: any;
  validatedFromdDate: string;
  adhocList: any[] = [];
  modifiedDate_tz: any;
  siteName: string = '';
  selectedCrewAndGuard: string = 'Cleaning Crew';
  showErrorMessage: string = '';
  submitted = false;
  cleaningSubmitted = false;
  siteInfoDetails: any = {};
  PSAListData: any = [];
  isInitialDataLoaded: boolean = false;
  isInitialDataLoadingMsg: string = permissions.tblPnlLoadingMsg;
  isFetchingRecentEsc: boolean = false;
  selectLevel: string = "L2L3";
  recentEscFetchMsg: string = "in last 90 days";
  hasApiError: boolean = false;
  showNoDataMessage: boolean = false;
  escId: string = "";
  timeZoneSelect: string = 'CT';
  selectedTimezone: string = 'CT';

  boloNotes:any = [];
  siteTimeCustomer: any;
  showSiteTimeZone: any;
  extractedDate: string;
  extractedTime: string;
  siteTimeZone: any;
  dateToday: any;
  constructor(private wallService: WallsService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder, private sharedService: SharedsrvService, private toasterService: ToasterService) {
    this.levelName = this.sharedService.getMonitorLevels();
    this.activateRoute.queryParams.subscribe((params) => {
      this.mirror = !!params['mirror'];
    });
    this.sharedService.getPanelTableData().subscribe((resp: any) => {
      if (resp) {
                this.panelTableData = resp;
      }
    });
    this.AdhocForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      message: ['', Validators.required],
      broadcastType: 'Adhoc'
    });
    this.guardform = this.formBuilder.group({
      name: ['', Validators.required],
      contact: ['', Validators.required],
      details: ['', Validators.required],
      udid: [''],
    });
    this.cleaningCrewform = this.formBuilder.group({
      name: ['', Validators.required],
      contact: ['', Validators.required],
      details: ['', Validators.required],
      udid: [''],
      cleaningCrewType: 'Cleaning Crew'
    });
    // this.cleaningCrewform = new FormGroup({
    //   name: new FormControl(),
    //   contact: new FormControl(),
    //   details: new FormControl(),
    //   cleaningCrewType: new FormControl('cleaningCrew'),
    //   udid: new FormControl(""),
    // });
    const dateToday = new Date();
    const currentDate = moment(dateToday).format('YYYY-MM-DD');
    const currentTime = moment(dateToday).format('HH:mm');

    this.filterForm = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(currentDate),
      startTime: new FormControl(),
      endTime: new FormControl(currentTime),
      records: new FormControl("viewAll")
    });
    // console.log("this.panelTableData", this.panelTableData);

    //TODO for L3
    // this.panelLSdata = localStorage.getItem("panelTableData")?.toString();
    // if(this.panelLSdata && typeof(this.panelLSdata) != 'object'){
    //   this.panelTableData = JSON.parse(this.panelLSdata);
    // }else{
    //   this.panelTableData = this.panelLSdata;
    // }

  }
  ngOnInit(): void {
    if (this.panelTableData?.['panelObj']?.potentialId) {
      localStorage.setItem(
        'activePanelInTablePanel',
        this.panelTableData?.panelName
      );
      if (this.mirror) {
        localStorage.setItem(
          'popup_mirror_PotentialId',
          this.panelTableData?.['panelObj']?.potentialId
        );
      } else {
        localStorage.setItem(
          'popupPotentialId',
          this.panelTableData?.['panelObj']?.potentialId
        );
      }
      this.getAllInfo();
    }
   //const dateToday = localStorage.getItem('siteTime');
   this.sharedService.data$.subscribe((res:any)=>{
    this.dateToday = res;  
    //const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    //this.validatedFromdDate = currentDate.split(' ')[0];
    if(this.siteTimeCustomer){
      const dateParts = this.dateToday?.split(' ');
      this.extractedDate = dateParts[0];
      this.extractedTime = dateParts[1];
      this.validatedFromdDate = this.extractedDate;
      this.broadCastform = this.formBuilder.group({
        startDate: [this.validatedFromdDate, Validators.required],
        startTime: [this.extractedTime, Validators.required],
        endDate: ['', Validators.required],
        endTime: [this.extractedTime, Validators.required],
        message: ['', Validators.required],
        broadcastType: 'Broadcast',
      });
      this.changeDate();
    }
    });
  
  }
  returnCalculatedDate(dateStr: string, timeStr: string, addDays: number) {
    const dateToday = timeStr ? new Date(`${dateStr} ${timeStr}`) : new Date(`${dateStr}`);
    const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    const addedDate = moment(currentDate).add(addDays, 'days').format('YYYY-MM-DD HH:mm:ss');
    return {
      currentDate: currentDate?.split(' ')[0],
      addedDate: addedDate?.split(' ')[0],
    };
  }
  changeDate() {
    if (this.broadCastform?.value?.startDate) {
      if (this.broadCastform?.value?.broadcastType == 'Broadcast') {
        const broadcastToDate = this.returnCalculatedDate(this.broadCastform?.value?.startDate, this.broadCastform?.value?.startTime, 7);
        this.validatedFromdDate = broadcastToDate?.currentDate;
        this.validatedTodDate = broadcastToDate?.addedDate;
      } else if (this.broadCastform?.value?.broadcastType == 'Adhoc') {
        const adhocToDate = this.returnCalculatedDate(this.broadCastform?.value?.startDate, '', 30);
        this.validatedFromdDate = adhocToDate?.currentDate;
        this.validatedTodDate = adhocToDate?.addedDate;
      }
      else if (this.broadCastform?.value?.broadcastType == 'bolo') {
        const adhocToDate = this.returnCalculatedDate(this.broadCastform?.value?.startDate, '',30);
        this.validatedFromdDate = adhocToDate?.currentDate;
        this.validatedTodDate = adhocToDate?.addedDate;
      }
    }
  }
  get form(): { [key: string]: AbstractControl } {
    return this.broadCastform.controls;
  }
  get cleaningForm(): { [key: string]: AbstractControl } {
    return this.cleaningCrewform.controls;
  }
  broadcastPanel() {
    this.broadcastExpand = !this.broadcastExpand;
  }
  adhocPanelExpand() {
    this.AdhocExpand = !this.AdhocExpand;
  }
  cleaningCrewExpandPanel() {
    this.cleaningCrewExpand = !this.cleaningCrewExpand;
  }
  closeCleaningCrew() {
    this.cleaningCrewExpand = !this.cleaningCrewExpand;
    this.cleaningSubmitted = false;
    this.cleaningCrewform.patchValue({
      name: '',
      contact: '',
      details: '',
    })
  }
  onRadioChange(selectedValue: string) {
    this.changeDate();
    // const dateToday = new Date();
    // const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    // this.validatedFromdDate = currentDate.split(' ')[0];
    this.broadCastform.patchValue({
      startDate: this.validatedFromdDate,
      startTime: this.extractedTime,
      endDate: '',
      endTime: this.extractedTime,
      message: '',
    });
  }

  onCrewAndGuardRadioChange(selectedValue: string) {
    this.selectedCrewAndGuard = selectedValue;
    const crewAndGuardType = this.cleaningCrewform.get('cleaningCrewType');

    if (crewAndGuardType.value === selectedValue) {
      crewAndGuardType.setValue(selectedValue);
    }
    else {
      crewAndGuardType.setValue(null);
    }
  }

  updateBroadCast() {
    this.submitted = true;
    if (this.broadCastform.invalid) {
      return;
    }
    this.showError = false;
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const fromDate = moment(this.broadCastform.value.startDate + " " + this.broadCastform.value.startTime + ":00").format('YYYY-MM-DD HH:mm:ss');
    const toDate = moment(this.broadCastform.value.endDate + " " + this.broadCastform.value.endTime + ":00").format('YYYY-MM-DD HH:mm:ss');
    if (fromDate > toDate || (fromDate === toDate)) {
      this.showError = true;
      this.toasterService.showError("Start date should be less than end date");
      return;
    }
    if (!this.showError) {
      if (this.selectedBroadcastType === 'Broadcast' || this.broadCastform.value.broadcastType === 'Broadcast') {
        this.wallService.updateBroadCast(pid, this.broadCastform.value).subscribe((res: any) => {
          if (res.success && res.data) {
            this.broadcast = res.bmessages;
            this.toasterService.showSuccess("Added Broadcast!");
            this.broadCastform.reset();
            this.broadcastExpand = !this.broadcastExpand;
            this.getByPotentialId();
            this.maxlength = 400;
            this.text_Broadcost = '';
            this.broadCastform.patchValue({
              startDate: this.validatedFromdDate,
              startTime: this.extractedTime,
              endTime:this.extractedTime,
              broadcastType: 'Broadcast'
            });
            this.changeDate();
          } else {
            this.toasterService.showError("Unable to create broadcast!");
          }
          this.submitted = false;
        });
      } else if (this.selectedBroadcastType === 'Adhoc' || this.broadCastform.value.broadcastType === 'Adhoc') {
        this.wallService.UpdateAdhoc(pid, this.broadCastform.value).subscribe((res: any) => {
          if (res.success && res.data) {
            this.toasterService.showSuccess("Adhoc Notes Added!");
            this.broadCastform.reset();
            this.broadcastExpand = !this.broadcastExpand;
            this.getByPotentialId();
            this.maxlength = 400;
            this.broadCastform.patchValue({
              startDate: this.validatedFromdDate,
              startTime: this.extractedTime, 
              endTime:this.extractedTime,
              broadcastType: 'Broadcast'
            });
            this.changeDate();
          } else {
            this.toasterService.showError("Unable to create Adhoc Notes!");
          }
          this.submitted = false;
        });
      }
      else if ((this.selectedBroadcastType === 'bolo' || this.broadCastform.value.broadcastType === 'bolo')){
        this.wallService.addBOLOMessage(pid,this.broadCastform.value).subscribe((res:any)=>{
          if(res?.success && res?.data){
            this.toasterService.showSuccess("BOLO Notes Added!");
            this.broadCastform.reset();
            this.broadcastExpand = !this.broadcastExpand;
            this.getByPotentialId();
            this.maxlength = 400;
            this.broadCastform.patchValue({
              broadcastType : 'Broadcast',
              startDate: this.validatedFromdDate,
              startTime: this.extractedTime,
              endTime:this.extractedTime,
            });
            this.changeDate();
          }
           else if(res && res?.success == false && res?.data){
            this.toasterService.showError(`${res?.data}`);
           }
          else {
            this.toasterService.showError("Unable to create BOLO Notes!");
          }
          this.submitted = false;
        });
      }
    }
  }
  closeBroadcast() {
    this.submitted = false;
    const dateToday = new Date();
    const currentDate = moment(dateToday).format('YYYY-MM-DD HH:mm:ss');
    this.validatedFromdDate = currentDate.split(' ')[0];
    this.broadCastform.patchValue({
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      message: '',
    });
    console.log('After Reset:', this.broadCastform.value);
    this.broadcastExpand = !this.broadcastExpand
  }
  getRemainingCount(text: any) {
    const remainingCount = this.maxlength - (text ? text.length : 0);
    return remainingCount >= 0 ? remainingCount : 0;
  }

  onKeyDown(event: KeyboardEvent, text: string): void {
    if (this.getRemainingCount(text) <= 0 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
  UpdateAdhocForm() {
    this.showError = false;
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const fromDate = moment(this.AdhocForm.value.startDate + " " + this.AdhocForm.value.startTime + ":00").format('YYYY-MM-DD HH:mm:ss');
    const toDate = moment(this.AdhocForm.value.endDate + " " + this.AdhocForm.value.endTime + ":00").format('YYYY-MM-DD HH:mm:ss');
    if (fromDate > toDate) {
      this.showError = true;
      this.toasterService.showError("Start date should be less than end date");
      return;
    }
    if (this.AdhocForm.valid && !this.showError) {
      this.wallService.UpdateAdhoc(pid, this.AdhocForm.value).subscribe((res: any) => {
        this.broadcast = res.bmessages;
        this.toasterService.showSuccess("Updated Adhoc Notes!");
        this.AdhocForm.reset();
        this.AdhocExpand = !this.AdhocExpand;
        //this.getAdhocNotes();
        this.getByPotentialId();
        this.maxlength = 400;
        //this.text = '';
      });
    }

  }
  //for guard update
  guardUpdate() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    const formValue = this.guardform.value;
    if (formValue.details.trim() === '' || formValue.contact.trim() === '' || formValue.name.trim() === '') {
      this.isFormInvalid = 'Please enter a valid value';
      return;
    }
    this.wallService.crewUpdate(pid, this.guardform.value, true).subscribe((res: any) => {
      this.toasterService.showSuccess("Update Guard Notes!");
      this.guardform.reset();
      this.guardExpand = !this.guardExpand;
      //this.getCleaningCrew();
      this.getByPotentialId();
      this.maxlength = 400;
      this.text_guard = '';
    });
  }
  //for Cleaning Crew
  cleaningCrewUpdate() {
    this.cleaningSubmitted = true;
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    if (this.cleaningCrewform.invalid) {
      return;
    }
    const formValue = this.cleaningCrewform.value;
    if (formValue.details.trim() === '' || formValue.contact.trim() === '' || formValue.name.trim() === '') {
      this.isFormInvalid = 'Please enter a valid value';
      return;
    }
    else {
      if (this.selectedCrewAndGuard === 'Guard' || this.cleaningCrewform.value.cleaningCrewType === 'Guard') {
        this.wallService.crewUpdate(pid, this.cleaningCrewform.value, true).subscribe((res: any) => {
          if (res.success && res.data) {
            this.toasterService.showSuccess("Updated Guard Contact!");
            this.cleaningCrewform.reset();
            this.cleaningCrewExpand = !this.cleaningCrewExpand;
            this.getByPotentialId();
            this.maxlength = 400;
            this.text_Crew = '';
            this.isFormInvalid = '';
            this.cleaningCrewform.patchValue({
              cleaningCrewType: 'Cleaning Crew'
            })
          }
          else {
            this.toasterService.showError("Unable to create Guard!");
          }
          this.cleaningSubmitted = false;
        });
      }
      else {
        this.wallService.crewUpdate(pid, this.cleaningCrewform.value, false).subscribe((res: any) => {
          if (res.success && res.data) {
            this.toasterService.showSuccess("Updated Cleaning Crew Contact!");
            this.cleaningCrewform.reset();
            this.cleaningCrewExpand = !this.cleaningCrewExpand;
            //this.getCleaningCrew();
            this.getByPotentialId();
            this.maxlength = 400;
            this.text_Crew = '';
            this.isFormInvalid = '';
            this.cleaningCrewform.patchValue({
              cleaningCrewType: 'Cleaning Crew'
            });
          }
          else {
            this.toasterService.showError("Unable to create Cleaning Crew!");
          }
          this.cleaningSubmitted = false;
        });
      }
    }
  }
  updateFilterForm(form: any) {
    this.recentEsclations(form.value);
    this.closeFilter.nativeElement.click();
  }

  getBroadCast() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getBroadCastList(pid, {}).subscribe((res: any) => {
      //this.broadCastList = res?.data?.bmessages;
    });
  }
  getAdhocNotes() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getAdhocList(pid, {}).subscribe((res: any) => {
      this.adhoc = res?.data?.notes;
    });
  }
  getCleaningCrew() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getCrewList(pid).subscribe((res: any) => {
      this.crewAndGuards = res?.data?.contacts;
    })
  }
  recentEsclations(history: any = {}) {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.isFetchingRecentEsc = true;
    this.recentEscList = [];
    this.recentEscListCopy = [];
    this.wallService.recentEsclations(pid, history, history?.records).subscribe((res: any) => {
      this.recentEscList = res?.data?.content;
      this.isFetchingRecentEsc = false;
      if (history?.startDate && history?.endDate) {
        this.recentEscFetchMsg = " in date range (Start date: " + history?.startDate + " " + history?.startTime + ", End date: " + history?.endDate + " " + history?.endTime + ")";
      }
    }, (fail) => {
      this.isFetchingRecentEsc = false;
    });
  }
  pastEsclations(type: any, history: any = {}) {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.recentEscList = [];
    // const eventTimeStr = this.panelTableData?.['panelObj']?.eventTimeStampStr;
    let customerSiteTimeStr = this.siteInfoDetails.siteTimeCustomer;
    this.isFetchingRecentEsc = true;
    this.wallService.recentEsclations(pid, history, type, this.siteInfoDetails.siteTimeCT, customerSiteTimeStr).subscribe((res: any) => {
      let lastFHData = res?.data?.content;
      if (lastFHData && lastFHData.length > 0) {
        this.recentEscList = res?.data?.content;
      }
      if (lastFHData.length == 0) {
        if (type == '4hours') {
          this.recentEscFetchMsg = ' in last 4 hours.';
        }
        if (type == '24hrs') {
          this.recentEscFetchMsg = ' in last 1 Day.';
        }
        //checking condition with 7 Days
        if (type == '7Days') {
          this.recentEscFetchMsg = ' in last 7 Days'
        }
      }

      this.isFetchingRecentEsc = false;
    }, (fail) => {
      this.isFetchingRecentEsc = false;
    });
  }
  // yesterday(type: any) {    
  //   const pid = this.panelTableData?.['panelObj']?.potentialId;
  //   this.recentEscList = [];
  //   const eventTimeStr = this.panelTableData?.['panelObj']?.eventTimeStampStr;
  //   this.wallService.recentEsclations(pid, history, type, this.siteInfoDetails.siteTimeCT, eventTimeStr).subscribe((res: any) => {
  //     this.recentEscList = res?.data?.content;
  //   });
  // }

  alarmActivation() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getAlarmActivation(pid).subscribe((res: any) => {
      this.recentActivations = res;
    });
  }
  allMessages: any[] = []; // Declare allMessages array
  //crewAndGuards:any[] = [];
  getByPotentialId() {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.isInitialDataLoadingMsg = permissions.tblPnlLoadingMsg;
    try {
      this.wallService.getAllMessageInfoPanelData(pid).subscribe((res: any) => {
        if (this.mirror) {
          localStorage.setItem("isMirrorFirsttimeLoadChange", "false");
        } else {
          localStorage.setItem("isFirsttimeLoadChange", "false");
        }
        this.isInitialDataLoaded = true;
        if (res?.data && res?.success) {
          this.isInitialDataLoadingMsg = "";
          this.broadCastList = res?.data?.broadcast?.bmessages;
          let boloObj:any = {
            panelName:this.panelTableData.panelName,
            isBolo : false
          }
          // if (res?.data?.boloNotes?.notes && res?.data?.boloNotes?.notes?.length > 0) {
          //   this.boloNotes = res.data.boloNotes.notes;
          //   console.log('boloNotes',this.boloNotes);
          //   boloObj.isBolo = true;
          //   this.sharedService.updateBoloNoteStatus(boloObj);
          //   this.boloNotes?.forEach((bolo:any)=>{
          //     bolo.type = 'Bolo';
          //   });
          // } else {
          //   boloObj.isBolo = false;
          //   this.sharedService.updateBoloNoteStatus(boloObj);
          // }
          if (res?.data?.siteNotes && res?.data?.siteNotes?.length > 0) {
            this.boloNotes = res?.data?.siteNotes.filter(note => note?.type === 'Bolo');
            if (this.boloNotes?.length > 0) {
                boloObj.isBolo = true;
                this.sharedService.updateBoloNoteStatus(boloObj);
            } else {
                boloObj.isBolo = false;
                this.sharedService.updateBoloNoteStatus(boloObj);
            }
        } else {
            boloObj.isBolo = false;
            this.sharedService.updateBoloNoteStatus(boloObj);
        }
        
          this.adhocList = res?.data?.adhoc?.notes;
          this.crewAndGuards = res?.data?.crew?.contacts;
          this.guard = res?.data?.guard?.contacts;
          this.siteInfoDetails = res?.data?.siteInfo;
          this.siteTimeCustomer = res?.data?.siteInfo?.siteTimeCustomer;
          this.sharedService.setTime(this.siteTimeCustomer);
          if (res?.data?.siteNotes && res?.data?.siteNotes?.length > 0) {
            this.allMessages = res?.data?.siteNotes;
          }
          const crewType = res?.data?.crew?.contacts || [];
          const GuardType = res?.data?.guard?.contacts || [];
          this.siteTimeZone = res?.data?.siteInfo?.timeZone;
          crewType?.forEach((contact: any) => {
            contact.type = 'Cleaning Crew';
          });
          GuardType.forEach((contact: any) => {
            contact.type = 'Guard';
          });
          this.crewAndGuards = [...crewType, ...GuardType];
          this.pastEsclations('4hours')
        } else {
          this.isInitialDataLoaded = false;
          this.isInitialDataLoadingMsg = permissions.tblPnlLoadingDataEmptyMsg;
        }        
        this.getPsaListData();
      }, error => ((error: any) => {
        this.isInitialDataLoaded = false;
        this.isInitialDataLoadingMsg = permissions.tblPnlLoadingErrorMsg;
      }));
    } catch (err) {
      this.isInitialDataLoaded = false;
      this.isInitialDataLoadingMsg = permissions.tblPnlLoadingErrorMsg;
    }
  }
  getAllInfo() {
    // Call the service methods 
    //let tempId = "728255" 
    this.showSiteTimeZone = this.panelTableData?.['panelObj']?.siteTimeZone;
    if (this.panelTableData?.['panelObj']?.siteInfo) {
      this.siteInfo = this.panelTableData?.['panelObj']?.siteInfo;
      this.siteName = this.panelTableData?.['panelObj']?.siteName + ' - ' + this.panelTableData?.['panelObj']?.siteTimeZone;
    }

    if (this.panelTableData?.['panelObj']?.eventHisoryData) {
      this.eventHisoryData = JSON.parse(this.panelTableData?.['panelObj']?.eventHisoryData);
    } else {
      this.eventHisoryData = null;
    }

    // this.getBroadCast();
    this.getAdhocNotes();
    //this.recentEsclations();
    //this.pastEsclations('4hours');
    this.alarmActivation();
    this.getArmDisarmHistoryData();
    setTimeout(() => {
      this.getByPotentialId();
    }, 0);

    this.crewAndGuards = this.eventHisoryData?.data?.crew?.contacts;
    this.guard = this.eventHisoryData?.data?.guard?.contacts;
    //this.ifttt = this.eventHisoryData?.data?.ifttt?.site?.events || [];
    //this.modifiedDate_tz = this.eventHisoryData?.data?.ifttt?.site?.modifiedDate_tz || this.eventHisoryData?.data?.ifttt?.site?.modifiedDate;
    //this.iftttNotes = this.eventHisoryData?.data?.ifttt?.site?.note || '';
    this.adhoc = this.eventHisoryData?.data?.adhoc?.notes || [];
    // this.eventdesc = [];

    // for (let i = 0; i < this.ifttt?.length; i++) {
    //   this.eventdesc.push(this.ifttt[i].eventDesc)
    // }
    // for (let i = 0; i < this.ifttt.length; i++) {
    //   this.iftttData.push(this.ifttt[i].recAction)
    // }
    // this.newObject = {
    //   events: this.eventdesc,
    //   action: this.iftttData
    // }
    /////////////////////////////////////////


    this.wallService.getAllMessageInfoPanelData(this.panelTableData?.panelObj?.potentialId).subscribe((res: any) => {
    //console.log('DOne..', res);
    this.messagePanelData = res?.data;
    //this.adhocDetails = res?.data?.adhoc?.notes;
    this.ifttt = this.messagePanelData?.ifttt?.site?.events || [];
    this.modifiedDate_tz = this.messagePanelData.ifttt?.site?.modifiedDate_tz || this.eventHisoryData?.data?.ifttt?.site?.modifiedDate;
    this.iftttNotes = this.messagePanelData?.ifttt?.site?.note || '';
    
    // this.eventdesc = [];
    // //this.crew = this.messagePanelData?.crew?.contacts;
    // this.guard = this.messagePanelData?.guard?.contacts;
    // this.adhoc = this.messagePanelData?.adhoc?.notes;
    // this.broadcast = this.messagePanelData?.broadcast?.bmessages; 
    // for(let i=0; i<this.ifttt.length; i++){
    //   // console.log('iftt-length',this.ifttt[i].eventDesc)
    //   this.eventdesc.push(this.ifttt[i].eventDesc)
    //   // console.log('eventdesc',this.eventdesc)
    // }
    // let newArray = [];
    // if (this.messagePanelData?.ifttt?.site?.actions) {
    //   newArray.push(this.messagePanelData?.ifttt?.site?.actions);
    // }
    // // console.log('newArray',newArray)
    // this.iftttData = [];
    // if (newArray.length >= 1) {
    //   for(let i = 0 ; i<newArray[0].length;i++){
    //     this.iftttData.push(newArray[0][i]?.actionDesc);
    //     // console.log('iftttData',this.iftttData)
    //   }
    // }

    // this.newObject = {
    //   events: this.eventdesc,
    //   action: this.iftttData
    // }

    // this.newObject = {
    //   events:this.eventdesc,
    //   action:this.iftttData
    // }

    });

  }
  getRecentEscalation() {
    return (this.panelTableData?.panelObj?.recentEsclations) ? JSON.parse(this.panelTableData?.panelObj?.recentEsclations) : [];
  }
  getArmDisarmHistory() {
    return (this.panelTableData?.panelObj?.armDisarmHistory) ? JSON.parse(this.panelTableData?.panelObj?.armDisarmHistory) : [];

  }
  getIftttdata() {
    return (this.panelTableData?.panelObj?.iftttNotes) ? (JSON.parse(this.panelTableData?.panelObj?.iftttNotes)?.data?.site?.events) : [];
  }

  viewEvent(eventData: any) {
    const windowName = this.sharedService.prepareWindowName('viewevent_');
    let evtWindow: any = window.open("", windowName, "width=656,height=369");
    let name: any = `${eventData.cameraName}, ${eventData.eventid}`;
    let toWrite = `<video controls="" autoplay="true" disablepictureinpicture="" controlslist="nodownload noplaybackrate" muted="true"
      style="width: 100%; height: 100%; aspect-ratio: 16/9;">
      <source type="video/mp4" src="${eventData.eventLink}">
    </video>`
    evtWindow.document.write("<html><head><title>" + name + "</title></head><body>"
      + toWrite + '</body></html>');
    permissions.allWindows[windowName] = evtWindow;
    evtWindow.document.close();
  }

  copyToClip(eventData: any) {
    const unsecuredCopyToClipboard = (text: any) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Unable to copy to clipboard', err)
      }
      document.body.removeChild(textArea)
    };
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(eventData.eventid);
    } else {
      unsecuredCopyToClipboard(eventData.eventid);
    }
    this.toasterService.showInfo("Event Id copied!");
  }

  getIvigilURL(row: any) {
    const currTime = moment(new Date(row?.eventTimeStampStr));
    const timeStr = currTime.format('YYYY-MM-DD-HH-mm-ss').toString();
    const urlPrep = `${row.siteURL}/GenerateArchiveLink?camraId=${row.cameraId}&timeStamp=${timeStr}&imageWidth=${row.width}&imageHeight=${row.height}`;
    window.open(urlPrep);
  }

  recentEscMoreDetails(eventData: any) {
    this.eventInfo = false;
    this.escId = eventData?.eventid;
    if (eventData?.eventUserInfoModified?.length === 0) {
      this.eventInfo = true;
    } else {
      this.eventUserData = [];
      this.eventUserData = eventData?.eventUserInfoModified;
      this.eventInfo = true;
    }
    const modal = document.getElementById('eventUserInfo');
    if (modal) {
      modal.style.display = 'block';
    }
  }
  setTimezone(timezone: string) {
    this.selectedTimezone = timezone;
  }
  selectTimeZone(event: any) {
    this.timeZoneSelect = event.target.value;
  }
  getFormattedTimestamp(item: any): string {
    if (this.timeZoneSelect === 'IST') {
      return item.eventTimeStrInIST || '-';
    } else if (this.timeZoneSelect === 'CT') {
      return item.eventTimeStrInCT || '-';
    }
    return item.eventTimeStampStr || '-';
  }
  closeModal() {
    const modal = document.getElementById('eventUserInfo');
    if (modal) {
      modal.style.display = 'none';
    }

  }
  getArmDisarmHistoryData = () => {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getTArmDisarmHistoryByPID(pid).subscribe((res: any) => {
      this.armDisarmHistory = res.data;
    });
  }
  getPsaListData = () => {
    const pid = this.panelTableData?.['panelObj']?.potentialId;
    this.wallService.getPsaList(pid).subscribe((res: any) => {
      if (res?.data) {
        this.PSAListData = res?.data;
        this.showNoDataMessage = this.PSAListData?.length === 0;
      }
    }, (error) => {
      if (error) {
        this.hasApiError = true;
        console.log('error', error)
      }
    });
  }
  ngOnDestroy() {
  }
}