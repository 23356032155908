import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';
import { AccessPermissions } from '../../models/loginpermissions'
import { permissions } from '../../models/globalpermissions';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login/login.service';
import { WallsService } from 'src/app/services/walls/walls.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { FirebaseNotificationService } from '../../services/firebase-notification/firebase-notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  showHead: boolean = false;
  panelPopup: any = {
    left: 0,
    top: 0,
  };
  username: any = "";
  playText: string = "PAUSE";
  pauseReason: string = "";
  isFullScreen: boolean = false;
  docElement!: HTMLElement;
  levelName: string = "";
  logoutText: string = "Please take action on the wall events, then try to logout.";
  isSafeLogoutEnabled: boolean = false;
  isDevStaticEnabled: any = false;
  isWallsCountPendingForLogout: any = true;
  logOutTextError: string = "Events are still available, please take action on walls.";
  versionStr: string = environment.releaseVersion;
  mirror: boolean = false;
  pauseTags: any = ["Break", "Lunch", "Coaching", "Training", "Meeting/Huddle", "End of Shift", "Sick"];
  isWindowOpen: boolean = false;
  windowRef: any = null;
  isSafeDisabledOnPause: boolean = false;
  breakText: string = "Please clear all the events on the wall";
  disableButton: boolean = false;
  showPopup: boolean = false;
  staticIncreamentNo: number = 15;
  clearTimerInterval: any;
  initialIncreamentNo: number = 30;
  isAllWallsPaused: boolean = false;
  isMonitoringAdmin: boolean = false;
  boloMessage: string = "";
  slotNumber: any;
  hidePlayPauseFeature: boolean = false;
  constructor(
    private router: Router,
    private sharedSrv: SharedsrvService,
    private activateRoute: ActivatedRoute,
    private loginService: LoginService,
    private wallsService: WallsService,
    private toasterService: ToasterService,
    private fbServie: FirebaseNotificationService
  ) {
    permissions.eventPaused = false;
    this.playText = "PAUSE";
    localStorage.setItem("pauseDisabled", "false");
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login' || event['url'].includes('/cameraview') || event['url'] == '/' || event['url'].includes('/login') || event['url'] == '/logout' || event['url'].includes('clm-liveview-popup')) {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
        this.isMonitoringAdmin = this.checkUserRole();
        this.setUserName();
      }
    });
    // this.sharedSrv.getHeadingEnabled().subscribe((resp: any) => {
    //   this.showHead = resp?.setHeadingEnabled;
    //   this.setUserName();
    // });
    this.sharedSrv.getMirrorWindowEnabled().subscribe((res: any) => {
      this.isWindowOpen = res;
      this.isMonitoringAdmin = this.checkUserRole();
    });
    if (environment.FEATURE_FLAG.isFirebase) {
      this.fbServie.getFBNotificationEventObj().subscribe((fbEvent: any) => {
        if (fbEvent?.notification?.title !== "FORCE_CLOSE") {

          // console.log("fbEvent?.notification?.body", fbEvent?.notification?.body)
          this.boloMessage = fbEvent?.notification?.body;
        }
      });
    }
  }
  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isMonitoringAdmin = this.checkUserRole();
      this.isDevStaticEnabled = params['dev'] || environment.devFlag;
      this.mirror = !!params['mirror'];
    });
    if (!this.isDevStaticEnabled) {
      this.isDevStaticEnabled = environment.devFlag;
    }
    this.setUserName();
    this.docElement = document.documentElement;
    this.sharedSrv.get_ppActivePanel().subscribe((data: any) => {
      this.isMonitoringAdmin = this.checkUserRole();
      let dataLength = data.length;
      /// Enable play pause button for each level
      if (dataLength > 0) {
        if (this.levelName == AccessPermissions.levelName1) {
          if (dataLength == AccessPermissions.l1_slotsCount) {
            //this.clearPlayPauseEventSlots();
            this.openBreakTagsPopup(true);
          }
        }
        if (this.levelName == AccessPermissions.levelName2) {
          if (localStorage.getItem("isMirrorEnabled") == "true") {
            if (dataLength == AccessPermissions.l2_mirrorSlotsCount) {
              if (!this.mirror) {
                this.openBreakTagsPopup(true);
              }
            }
          } else if (dataLength == AccessPermissions.l2_slotsCount && localStorage.getItem("isMirrorEnabled") == "false") {
            this.openBreakTagsPopup(true);
          }
        }
        if (this.levelName == AccessPermissions.levelName3) {
          if (dataLength == AccessPermissions.l3_slotsCount) {
            this.openBreakTagsPopup(true);
          }
        }
      }
    });
  }
  openBreakTagsPopup = (isPaused?: boolean) => {
    if (isPaused) {
      this.isAllWallsPaused = isPaused;
    }
    if (!this.mirror) {
      this.sharedSrv._panelactiveDataArray = [];
      this.sharedSrv._ppPanelactiveDataArray = [];
      let modal_tag = document.getElementById('showTagPopup')
      if (modal_tag) {
        modal_tag.style.display = "block";
      }
      clearInterval(this.clearTimerInterval);
      this.staticIncreamentNo = 0;
      this.startTimer(isPaused);
    }
  }
  setUserName() {
    if (localStorage.getItem('user')) {
      //@ts-ignore
      let userDetails = JSON.parse((localStorage.getItem('user'))).user;
      this.username = userDetails?.firstname + ' ' + userDetails?.lastname;
      this.levelName = this.getMonitorLevels();
      this.hidePlayPauseFeature = (this.levelName == AccessPermissions.CLM_Agent
        ||this.levelName == AccessPermissions.CLM_Group
        || this.levelName == AccessPermissions.CLM_Role) ? true : false;
    }
  }
  getMonitorLevels() {
    let monitorLeve1 = localStorage.getItem('userType') || "";
    let levelname = "";
    if (monitorLeve1 == AccessPermissions.accessl1) {
      levelname = AccessPermissions.levelName1
    }
    if (monitorLeve1 == AccessPermissions.accessl2) {
      levelname = AccessPermissions.levelName2
    }
    if (monitorLeve1 == AccessPermissions.accessl3) {
      levelname = AccessPermissions.levelName3
    }
    if (monitorLeve1 == AccessPermissions.CLM_Agent 
      || monitorLeve1 == AccessPermissions.CLM_Role
      || monitorLeve1 == AccessPermissions.CLM_Group) {
      levelname = AccessPermissions.CLM_Agent
    }
    return levelname;
  }
  openModal(event: any) {
    let userType = localStorage.getItem("userType")
    if (this.isDevStaticEnabled || userType?.toString().includes(AccessPermissions.CLM_Agent) 
      || userType?.toString().includes(AccessPermissions.CLM_Role)
      || userType?.toString().includes(AccessPermissions.CLM_Group)) {
      this.makeSafeLogout();
      return
    }
    let modal1 = document.getElementById("myModal") as HTMLElement;
    modal1.style.display = "block";
    let dimentions = event?.srcElement?.getClientRects()[0];
    this.panelPopup = dimentions;
    if (this.isSafeLogoutEnabled) {
      this.safeLogout();
    }
  }
  closeModal() {
    let modal1 = document.getElementById("myModal") as HTMLElement;
    modal1.style.display = "none";
  }
  cancelBreakModalPopup() {
    let modal1 = document.getElementById("breakModalPopup") as HTMLElement;
    modal1.style.display = "none";
  }
  initiateSafeLogout = () => {
    this.isSafeLogoutEnabled = true;
    permissions.isSafeLogoutEnabled = true;
    this.closeModal();
    this.logoutText = this.logOutTextError;
    if (this.playText == "PLAY" && this.isAllWallsPaused) {
      this.directSafeLogout();
    }
    if (this.levelName == AccessPermissions.levelName2 && localStorage.getItem("isMirrorEnabled") == "true") {
      localStorage.setItem("isMirrorSafeLogout", "true");
    }
    let pausedEventList = this.sharedSrv._ppPanelactiveDataArray;
    if (pausedEventList?.length > 0) {
      pausedEventList.forEach((panelData: any) => {
        this.sharedSrv.getPanelActiveDataStoreSharedSrv(panelData.panelName, true);
      })
      return;
    }
    this.pauseRequest("Safe logout");
  }
  cancelSafeLogout = () => {
    if (this.isSafeLogoutEnabled == true) {
      this.isSafeLogoutEnabled = permissions.isSafeLogoutEnabled = false;
      this.closeModal();
      this.logoutText = this.logOutTextError;
    }
  }
  safeLogout = () => {
    if (this.playText == "PLAY" && this.isAllWallsPaused) {
      this.directSafeLogout();
    }
    this.sharedSrv.getActivePanel().subscribe((resp: any) => {
      if (resp && resp.length >= 0) {
        let dataLength = resp.length;
        if (this.levelName == AccessPermissions.levelName1) {
          if (dataLength == AccessPermissions.l1_slotsCount) {
            this.isWallsCountPendingForLogout = false;
            this.makeSafeLogout();
          } else if (localStorage.getItem("isMirrorEnabled") == "true") {
            if (dataLength == AccessPermissions.l2_mirrorSlotsCount) {
              let popupwindow: any = window.open(window.location.href + "?mirror=true", "duplicate", "toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no");
              popupwindow.close();
              this.makeSafeLogout();
            }
          } else {
            this.isWallsCountPendingForLogout = true;
            this.logoutText = this.logOutTextError;
          }
        }
        if (this.levelName == AccessPermissions.levelName2) {
          if (dataLength == AccessPermissions.l2_slotsCount) {
            this.isWallsCountPendingForLogout = false;
            this.makeSafeLogout();
          } else {
            this.isWallsCountPendingForLogout = true;
            this.logoutText = this.logOutTextError;
          }
        }
        if (this.levelName == AccessPermissions.levelName3) {
          if (dataLength == AccessPermissions.l3_slotsCount) {
            this.isWallsCountPendingForLogout = false;
            this.makeSafeLogout();
          } else {
            this.isWallsCountPendingForLogout = true;
            this.logoutText = this.logOutTextError;
          }
        }
      }
    });
  }
  makeSafeLogout() {
    this.closeModal();
    this.sharedSrv.clearWindows();
    if (localStorage.getItem('user')) {
      //@ts-ignore
      let userDetails = JSON.parse((localStorage.getItem('user'))).user;
      this.username = userDetails?.username;
    }
    const userId = this.username;
    this.loginService.signOutRequest(userId).subscribe((res: any) => {
      this.loginService.handleSignOutResponse(res);
      permissions.isSafeLogoutEnabled = false;
      this.isWallsCountPendingForLogout = true;
    });
  }
  fullscreen = () => {
    this.sharedSrv.clearWindows();
    permissions.isfullscreen = !this.isFullScreen;
    if (!this.isFullScreen) {
      this.docElement?.requestFullscreen();
    }
    else {
      document?.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
    this.sharedSrv.setFullscreenEvent(this.isFullScreen);
  }

  newTab = () => {
    //mirror window open after that disabling icon 
    this.isWindowOpen = true;
    localStorage.setItem("isMirrorEnabled", "true");
    this.sharedSrv.setMirrorWindowEnabled(true);
    // @ts-ignore
    permissions.mirrorWindow = window.open(window.location.href + "?mirror=true", "duplicate", "location=0,location=no,menubar=0,toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=4000,height=4000");
    let popup = setInterval(() => {
      //@ts-ignore
      if (permissions.mirrorWindow?.closed) {
        clearInterval(popup);
        this.isWindowOpen = false;
        this.sharedSrv.setMirrorWindowEnabled(false);
      }
    });
    // @ts-ignore
    if ((permissions.mirrorWindow).addEventListener) {
      // @ts-ignore
      permissions.mirrorWindow.addEventListener("message", this.receivemessage.bind(this), false);
      // @ts-ignore
      permissions.mirrorWindow.addEventListener("unload", this.closeWindow.bind(this), false);
    } else {
      // @ts-ignore
      permissions.mirrorWindow.attachEvent("onmessage", this.receivemessage.bind(this));
      // @ts-ignore
      permissions.mirrorWindow.attachEvent("unload", this.closeWindow.bind(this));
    }
  }

  receivemessage = (evt: any) => {
    /// here you will get listeners from mirror window
    let eventData = evt?.data;
    if (eventData) {
      if (!eventData.type) {
        this.extractEventForSafelogout(eventData.res, eventData.panel)
      }
    }
  }
  closeWindow = (evt: any) => {
    this.sharedSrv.clearWindows();
    localStorage.setItem("isMirrorEnabled", "false");
  }
  extractEventForSafelogout = (res: any, panel: any) => {
    setTimeout(() => {
      if (res != null && res?.statusCode != 200) {
        if (res?.statusMessage == permissions.userLogoutMsg) {
          if (localStorage.getItem("isMirrorEnabled") == "true") {
            this.sharedSrv.getPanelActiveDataStoreSharedSrv(panel, true);
          }
        }
        if (res?.statusMessage == permissions.eventPauseMsg) {
          if (localStorage.getItem("isMirrorEnabled") == "true") {
            this.sharedSrv.get_ppPanelActiveDataStoreSharedSrv(panel, false);
          }
        }
      }
    }, 0);
  }
  cancelBtnPause() {
    let modal1 = document.getElementById("showTagPopup") as HTMLElement;
    modal1.style.display = "none";
  }
  closePauseModal(flag: boolean = false) {
    if (flag) {
      this.playText = 'PAUSE';
    }
    // let modal1 = document.getElementById("pauseModal") as HTMLElement;
    // modal1.style.display = "none";
  }

  breakModal() {
    let modal3 = document.getElementById('breakModalPopup');
    if (modal3) {
      modal3.style.display = 'none';
    }
    this.clearPlayPauseEventSlots();
    let obj = {
      "action": "PAUSING",
      "actionDescription": ""
    }
    this.disableButton = true;
    this.loginService.pauseOrResume(obj).subscribe(resp => {
      this.sharedSrv._panelactiveDataArray = [];
      this.sharedSrv._ppPanelactiveDataArray = [];
      this.playText = "PLAY";
      this.pauseReason = "";
      this.sharedSrv.setPlayPause("PAUSE");
      this.sharedSrv.setUserdataPlaypause("PAUSE");
      if (localStorage.getItem("isMirrorEnabled") == "true") {
        localStorage.setItem("PlayText", "PLAY");
      }
      localStorage.setItem("pauseDisabled", "true");
      //this.closePauseModal();
      this.getUpdatedPausedStateForSafeLogEnabled();
      let modal1 = document.getElementById("breakModalPopup") as HTMLElement;
      modal1.style.display = "none";
    });
  }
  startTimer(isPaused?: boolean) {
    this.staticIncreamentNo = this.initialIncreamentNo;
    this.clearTimerInterval = setInterval(() => {
      this.staticIncreamentNo = this.staticIncreamentNo - 1;
      if (this.staticIncreamentNo == 0) {
        this.disableButton = false;
        this.playText = 'PLAY';
        if (isPaused) {
          this.playPause('PLAY');
        }
        let modal_tag = document.getElementById('showTagPopup')
        if (modal_tag) {
          modal_tag.style.display = "none";
          clearInterval(this.clearTimerInterval);
        }
      }

    }, 1000);
  }
  cancelTagPopup() {
    this.isAllWallsPaused = false;
    this.disableButton = false;
    this.playText = 'PLAY';
    this.playPause('PLAY');
    clearInterval(this.clearTimerInterval);
    let modal_tag = document.getElementById('showTagPopup')
    if (modal_tag) {
      modal_tag.style.display = "none";
    }
  }
  pauseRequest = (pTag: any) => {
    //clearInterval(this.clearTimerInterval);
    clearInterval(this.clearTimerInterval);
    let modal_tag = document.getElementById('showTagPopup')
    if (modal_tag) {
      modal_tag.style.display = "none";
    }
    this.pauseReason = pTag;
    this.disableButton = false;
    let obj = {
      "action": "PAUSE",
      "actionDescription": this.pauseReason
    }
    this.pauseReason = "";
    this.loginService.pauseOrResume(obj).subscribe(resp => {
      this.sharedSrv._panelactiveDataArray = [];
      this.sharedSrv._ppPanelactiveDataArray = [];
      this.playText = "PLAY";
      this.sharedSrv.setPlayPause("PAUSE");
      this.sharedSrv.setUserdataPlaypause("PAUSE");
      if (localStorage.getItem("isMirrorEnabled") == "true") {
        localStorage.setItem("PlayText", "PLAY");
      }
      localStorage.setItem("pauseDisabled", "true");
      this.closePauseModal();
      this.getUpdatedPausedStateForSafeLogEnabled();
    });
  }

  playPause = (value: string) => {
    let obj = {};
    // if (permissions.viewEventCount > 0) {
    //   for (let index = 0; index < permissions.viewEventCount; index++) {
    //   }
    // }
    this.sharedSrv.clearWindows();
    if (this.playText == "PAUSE") {
      permissions.eventPaused = true;
      let modal1 = document.getElementById("breakModalPopup") as HTMLElement;
      modal1.style.display = "block";
      return;
    } else if (this.playText == "PLAY") {
      let modal1 = document.getElementById("breakModalPopup") as HTMLElement;
      modal1.style.display = "none";
      permissions.eventPaused = false;
      obj = {
        "action": "RESUME",
        "actionDescription": ""
      }
      this.loginService.pauseOrResume(obj).subscribe(resp => {
        this.sharedSrv._panelactiveDataArray = [];
        this.sharedSrv._ppPanelactiveDataArray = [];
        localStorage.setItem("pauseDisabled", "false");
        permissions.eventPaused = false;
        this.playText = "PAUSE";
        this.pauseReason = "";
        this.closePauseModal();
        this.sharedSrv.setPlayPause(value);
        this.sharedSrv.setUserdataPlaypause(value);
        if (localStorage.getItem("isMirrorEnabled") == "true") {
          localStorage.setItem("PlayText", "PAUSE");
          localStorage.setItem("isMirrorSafeLogout", "false")
        }
        this.getUpdatedPausedStateForSafeLogEnabled();
      });
    }
  }
  clearPlayPauseEventSlots = () => {
    localStorage.setItem("pauseDisabled", "true");
    this.pauseReason = "";
    this.playText = "PLAY"
    this.sharedSrv.clear_ppActivePanel();
    this.getUpdatedPausedStateForSafeLogEnabled();
    this.sharedSrv._ppPanelactiveDataArray = [];
    this.sharedSrv._panelactiveDataArray = [];
  }
  getUpdatedPausedStateForSafeLogEnabled = () => {
    if (localStorage.getItem("pauseDisabled") == "true") {
      this.isSafeDisabledOnPause = true;
    } else if (localStorage.getItem("pauseDisabled") == "false") {
      this.isSafeDisabledOnPause = false;
    }
  }
  directSafeLogout = () => {
    if (this.levelName == AccessPermissions.levelName1) {
      this.makeSafeLogout();
    }
    if (this.levelName == AccessPermissions.levelName2) {
      if (localStorage.getItem("isMirrorEnabled") == "true") {
        let popupwindow: any = window.open(window.location.href + "?mirror=true", "duplicate", "toolbar=0,location=0,menubar=0,fullscreen=yes,location=no,resizable=no,toolbar=no");
        popupwindow.close();
        this.makeSafeLogout();
      } else {
        this.makeSafeLogout();
      }
    }
    if (this.levelName == AccessPermissions.levelName3) {
      if (permissions?.messagePanel) {
        //@ts-ignore
        permissions?.messagePanel?.close();
      }
      this.makeSafeLogout();
    }
  }
  navigateToAdminPortal = () => {
    try {
      this.wallsService.checkAdminAccess().subscribe((resp: any) => {
        let userData = resp;
        if (userData && userData?.proToken) {
          //window.open(`http://localhost:4201/login?accessData=${JSON.stringify(userData)}&pro-token=${userData.proToken}&popupPotentialId=${localStorage.getItem('popupPotentialId') ? localStorage.getItem('popupPotentialId') : ''}`, '_blank');
          window.open(`${environment.psafeAdminUrl}?accessData=${JSON.stringify(userData)}&pro-token=${userData.proToken}&popupPotentialId=${localStorage.getItem('popupPotentialId') ? localStorage.getItem('popupPotentialId') : ''}`, '_blank');
        } else {
          this.toasterService.showInfo("You don't have permisssion to access admin portal.")
        }
        if (resp?.statusCode == 401) {
          this.toasterService.showInfo("Unable to navigate to the Admin portal.")
        }
      }, (error: any) => {
        console.log(error);
      });
    } catch (err: any) {
      this.toasterService.showInfo(err);
    }
  }
  checkUserRole = () => {
    let userData: any = localStorage.getItem('user');
    let roles: any;
    if (userData) {
      userData = JSON.parse(localStorage.getItem('user') || "");
      if (userData?.user?.role) {
        roles = userData.user.role;
        const roleMatched = roles.filter((element: any) => permissions.MonitoringAdmin?.indexOf(element) !== -1);
        // console.log(roleMatched)
        if (roleMatched?.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
