import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SharedsrvService } from '../../services/sharedsrv/sharedsrv.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  showHead: boolean = false;
  constructor(
    private router: Router,
    private sharedsrv:SharedsrvService
  ) {
  router.events.forEach((event) => {
    if (event instanceof NavigationStart) {
      if (event['url'] == '/login' || event['url'] == '/' || event['url'].includes('/login')
      || event['url'].includes('lscreen')) {
        this.showHead = false;
      } else {
        this.showHead = true;
      }
    }
  });
  this.sharedsrv.getHeadingEnabled().subscribe((resp:any)=>{
    this.showHead = resp?.setHeadingEnabled;
  })
}
ngOnInit(): void {
  
}

}
