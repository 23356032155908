<div class="py-0">
  <div class="container-fluid px-0">
    <div class="row g-0">
      <div class="col-lg-12">
        <div class="video-seperate">
          <!-- <div class="position-absolute top-0 start-0 bg-dark bg-opacity-90 gap-3 z-index-1 w-100">
            <div class="d-flex align-items-center justify-content-between py-2 px-3">
              <p class="text-white">2022-08-28 07:30:55 (CT)</p>
              <h6 class="text-white">
                <span class="label-online label-online-sm"></span> {{cameraItem.cameraName}} -
                {{cameraItem.cameraUniqueId}}
              </h6>

              <button type="button" class="btn btn-sm btn-orange px-4"
                onClick="javascript:window.close('','_parent',''); clearIntervalData()">Close</button>
            </div>
          </div> -->

          <div class="video-seperate-text" *ngIf="archiveUrl">
            <div class="ratio ratio-16x9" >
              <img [src]="archiveUrl+'&test='+timeCount" class="img-fluid"
              [style.width]="zoomMeasures?.width+'%'" [style.height]="zoomMeasures?.height+'%'">
            </div>
            <!-- <p> {{cameraItem.cameraName}} -
              {{cameraItem.cameraUniqueId}}</p> -->
          </div>
          <div class="video-seperate-text" *ngIf="!archiveUrl">
            <div class="ratio ratio-16x9" >
              <img [src]="archiveUrl+'&test='+timeCount" class="img-fluid">
              No image available.
            </div>
          </div>

          <div class="video-seperate-control">
            <div class="video-seperate-control-left">
              <div class="control" style="visibility: hidden;">
                <img src="assets/images/icons/icon-home.svg" class="img-fluid mt-1" onClick="javascript:window.close('','_parent',''); clearIntervalData()"/>
              </div>
            </div>

            <div class="video-seperate-control-center" >
              <div class="control">
                <img src="assets/images/icons/icon-video-prev.svg" class="img-fluid mt-1" (click)="dbprevious()" />
              </div>
              <div class="control">
                <img src="assets/images/icons/icon_arrow_left.svg" class="img-fluid icon-height mt-1" (click)="previous()" />
              </div>
              <div class="control" *ngIf="isPlay">
                <i class="fa fa-pause-circle fa-3x" aria-hidden="true" (click)="pause()" style="color: #f47738;"></i>
                <!-- <img src="assets/images/icons/icon-video-play.svg" class="img-fluid" (click)="pause()" /> -->
              </div>
              <div class="control" *ngIf="!isPlay">
                <img src="assets/images/icons/icon-video-play.svg" class="img-fluid mt-1" (click)="play()" />
              </div>
              <div class="control">
                <img src="assets/images/icons/icon_arrow_right.svg" class="img-fluid icon-height mt-1" (click)="next()" />
              </div>
              <div class="control">
                <img src="assets/images/icons/icon-video-next.svg" class="img-fluid mt-1" (click)="dbnext()" />
              </div>
            </div>

            <div class="video-seperate-control-right" style="visibility: hidden;">
              <div class="control">
                <img src="assets/images/icons/icon-plus.svg" class="img-fluid" (click)="zoomIn()"/>
              </div>

              <div class="control">
                <img src="assets/images/icons/icon-minus.svg" class="img-fluid" (click)="zoomOut()"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>